import React, { useEffect, useState, Fragment } from 'react';
import styles from './Management.module.css';
import cn from 'classnames';

import { API, URL } from "../constants";
import { removeToken } from "./helpers";

import { ReactComponent as Logo } from '../../logo.svg';

export function Panel({user, setUser}) {
    const [page, setPage] = useState('plans');
    const [plans, setPlans] = useState([]);
    const [pagination, setPagination] = useState({});

    const handleLogout = () => {
        removeToken();
        setUser(null);
    };

    const getPlans = async () => {
        try {
            const response = await fetch(`${API}/api/plans`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const data = await response.json();
            if (data?.error) {
                throw data?.error;
            } else {
                setPlans(pl => { return data.data });
                setPagination(pg => { return data.meta.pagination});
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getPlans();
    }, []);

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div>
                    <Logo />
                </div>
                <div>
                    <span>{user.username}</span>
                    <button onClick={handleLogout} className={styles.logout_button}>Выйти</button>
                </div>
            </div>
            <div className={styles.panel}>
                <div className={styles.panel_tabs}>
                    <span className={cn({ [styles.tab_active]: page === 'plans', [styles.tab]: page !== 'plans' })} onClick={(e) => setPage(pg => {return 'plans'})}>Планы</span>
                    <span className={cn({ [styles.tab_active]: page === 'users', [styles.tab]: page !== 'users' })} onClick={(e) => setPage(pg => {return 'users'})}>Пользователи</span>
                </div>
                {page === 'plans' && (
                    <>
                        <div className={cn(styles.plans_panel, styles.plans_panel_header)}>
                            <div>
                                <span>Номер проекта</span>
                                <span>Дата создания</span>
                                <span>Дата изменения</span>
                                <span></span>
                            </div>
                        </div>
                        <div className={styles.plans_panel}>
                            {plans.map((plan, index) => (
                                <div key={index} className={styles.plans_panel_rows}>
                                    <span>{plan.id.toString().padStart(12, '0')}</span>
                                    <span>{new Intl.DateTimeFormat().format(new Date(plan.attributes.createdAt))}</span>
                                    <span>{new Intl.DateTimeFormat().format(new Date(plan.attributes.updatedAt))}</span>
                                    <span><a href={`${URL}?id=${plan.id}`} target='_blank'>Редактировать</a></span>
                                </div>
                            ))}
                        </div>
                        <div className={styles.plan_panels_pagination}>
                            <div>
                                {pagination.page === 1 && (
                                    <button>Назад</button>
                                )}
                            </div>
                            <div>{ }</div>
                            <div>
                                {pagination.page < pagination.pageCount && (
                                    <button>Вперёд</button>
                                )}
                            </div>
                        </div>
                    </>
                )}
                {page === 'users' && (
                    <>
                    </>
                )}
            </div>
        </div>
    )
}