import React, { useEffect, useState, Fragment } from 'react';
import styles from './Management.module.css';
import cn from 'classnames';

import { API } from "../constants";
import { setToken } from "./helpers";

import { ReactComponent as Logo } from '../../logo.svg';

export function Login({setUser}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleLogin = async (event) => {
        try {
            const value = {
                identifier: email,
                password: password,
            };
            const response = await fetch(`${API}/api/auth/local`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(value),
            });

            const data = await response.json();
            if (data?.error) {
                throw data?.error;
            } else {
                // set the token
                setToken(data.jwt);
                // set the user
                setUser(data.user);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Fragment>
            <div className={styles.login_wrapper}>
                <div className={styles.login}>
                    <div>
                        <Logo />
                    </div>
                    <div>
                        <label htmlFor='authEmail'>E-mail</label>
                        <input id='authEmail' type='email' onChange={handleEmailChange} />
                        <label htmlFor='authPassword'>Пароль</label>
                        <input id='authPassword' type='password' onChange={handlePasswordChange} />
                        <button className={styles.login_button} onClick={handleLogin}>
                            <span>Вход</span>
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}