import React, { useRef, useEffect, useState, Fragment } from 'react';
import styles from './Calculator.module.css';
import { Layer, Rect, Stage, Line, Text, Circle, Group, Arrow, TextPath, Arc } from 'react-konva';
import { Html } from 'react-konva-utils';
import cn from 'classnames';
import GridLoader from 'react-spinners/GridLoader';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Scrollbar } from "smooth-scrollbar-react";

import { API } from "../constants";

//plans
import defaultPlan from './plans/default';

//functions
import {
    calculateAddDeleteAxes,
    calculateChangeWidth,
    calculatePiles,
    addErker,
    resizeErker,
    recalculateLines,
    addCornerStripe,
    changeAxeWidth,
    calculatePositionPile,
    calculatePositionBake,
    calculatePositionStaticBake,
    calculationSizes,
    deleteAxe,
    calculationAllSizes,
} from './Func';

//icons
import { ReactComponent as BigPlanIcon } from '../../assets/icons/ic_1_1_big.svg';
import { ReactComponent as OsiX } from '../../assets/icons/ic_1_2.svg';
import { ReactComponent as OsiY } from '../../assets/icons/ic_1_3.svg';
import { ReactComponent as DeleteOs } from '../../assets/icons/ic_1_4.svg';
import { ReactComponent as AddOsX } from '../../assets/icons/ic_1_5.svg';
import { ReactComponent as AddOsY } from '../../assets/icons/ic_1_6.svg';
import { ReactComponent as LentaWidth } from '../../assets/icons/ic_1_7.svg';
import { ReactComponent as LentaWidthOs } from '../../assets/icons/ic_1_8.svg';
import { ReactComponent as LentaWidthOsS } from '../../assets/icons/ic_1_9.svg';
import { ReactComponent as Rotate90 } from '../../assets/icons/ic_1_10.svg';
import { ReactComponent as AddLenta } from '../../assets/icons/ic_1_11.svg';
import { ReactComponent as AddLentaN } from '../../assets/icons/ic_1_12.svg';
import { ReactComponent as AddErker } from '../../assets/icons/ic_1_13.svg';
import { ReactComponent as AddErkerK } from '../../assets/icons/ic_1_14.svg';
import { ReactComponent as DeleteErker } from '../../assets/icons/ic_1_15.svg';
import { ReactComponent as AddPile } from '../../assets/icons/ic_2_1.svg';
import { ReactComponent as MovePile } from '../../assets/icons/ic_2_2.svg';
import { ReactComponent as DeletePile } from '../../assets/icons/ic_2_3.svg';
import { ReactComponent as DeleteStripeSavePile } from '../../assets/icons/ic_2_4.svg';
import { ReactComponent as ChangePilesStep } from '../../assets/icons/ic_2_5.svg';
import { ReactComponent as ChangePilesStepOnStripe } from '../../assets/icons/ic_2_6.svg';
import { ReactComponent as AddBake } from '../../assets/icons/ic_2_7.svg';
import { ReactComponent as AddStaticBake } from '../../assets/icons/ic_2_8.svg';
import { ReactComponent as DeleteBake } from '../../assets/icons/ic_2_9.svg';
import { ReactComponent as RunningMeter } from '../../assets/icons/ic_3_1.svg';
import { ReactComponent as PileCount } from '../../assets/icons/ic_3_2.svg';
import { ReactComponent as Square } from '../../assets/icons/ic_3_3.svg';
import { ReactComponent as PrintPlan } from '../../assets/icons/ic_3_4.svg';
import { ReactComponent as SavePlanPDF } from '../../assets/icons/ic_3_5.svg';
import { ReactComponent as SavePlanJPG } from '../../assets/icons/ic_3_6.svg';
import { ReactComponent as AddSize } from '../../assets/icons/ic_3_7.svg';
import { ReactComponent as DeleteSize } from '../../assets/icons/ic_3_8.svg';
import { ReactComponent as BackBtn } from '../../assets/icons/ic_back.svg';
import { ReactComponent as NextBtn } from '../../assets/icons/ic_next.svg';
import { ReactComponent as CloseBtn } from '../../assets/icons/ic_close.svg';
import { ReactComponent as Logo } from '../../logo.svg';

const LENGTH_STRIPE_DEFAULT = 3000;
const WIDTH_STRIPE_DEFAULT = 400;
const MARGIN_TOP = 60;
const MARGIN_LEFT = 120;
const MARGIN_BOTTOM = 120;
const MARGIN_RIGHT = 60;
const CHARS = ['A', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Э', 'Ю', 'Я'];
const CIRCLE_CHAR_RADIUS = 20;
const TEXT_SIZE_CHAR = 16;
const MIN_STEP_PILE = 1500;
const CIRCLE_PILE_RADIUS = 150;
const _MIN_STEP_PILE = 375;

export function Calculator() {
    const stageRef = useRef(null);

    const [search, setSearch] = useSearchParams();

    const [ID, setID] = useState(0);

    const [loading, setLoading] = useState(true);
    const [container, setContainer] = useState({
        width: 0,
        height: 0,
    });
    const [countX, setCountX] = useState(3);
    const [countY, setCountY] = useState(3);
    const [widthStripe, setWidthStripe] = useState(400);
    const [defaultPlanWidthStripe, setDefaultPlanWidthStripe] = useState(400);
    const [stripes, setStripes] = useState({
        horizontal: [],
        vertical: [],
        corner: [],
    });
    const [extendedStripes, setExtendedStripes] = useState([]);
    const [deletedStripes, setDeletedStripes] = useState([]);
    const [erkers, setErkers] = useState({
        top: [],
        bottom: [],
        left: [],
        right: [],
    });
    const [erkerSide, setErkerSide] = useState('');
    const [erkerSize, setErkerSize] = useState(1000);
    const [erkerExtension, setErkerExtension] = useState(1000);
    const [erkerSelection, setErkerSelection] = useState('');
    const [erkerAxes, setErkerAxes] = useState({
        top: '',
        bottom: '',
        left: '',
        right: '',
    });
    const [step, setStep] = useState(1);
    const [action, setAction] = useState('AddDeleteStripe');
    const [stripeSelection, setStripeSelection] = useState('');
    const [stripeForWidth, setStripeForWidth] = useState('');
    const [stripeForWidthAxes, setStripeForWidthAxes] = useState({
        x: 0,
        y: 0,
        width: 0,
        direction: '',
    });
    const [stripeForWidthValue, setStripeForWidthValue] = useState(400);
    const [addCornerStripeFrom, setAddCornerStripeFrom] = useState({
        x: -1,
        y: -1,
        coords: {
            x: -1000,
            y: -1000,
        },
    });
    const [axes, setAxes] = useState({
        horizontal: [],
        vertical: [],
        lengthX: 0,
        lengthY: 0,
        offsetX: 0,
        offsetY: 0,
        leftExtension: 0,
        topExtension: 0,
    });
    const [axeInputs, setAxeInputs] = useState({
        horizontal: [],
        vertical: [],
        sloped: [],
    });
    const [axeSelection, setAxeSelection] = useState('');
    const [scale, setScale] = useState({
        value: 1,
        offsetX: 0,
        offsetY: 0,
    });
    const [piles, setPiles] = useState([]);
    const [pileStep, setPileStep] = useState(MIN_STEP_PILE);
    const [stripePileStep, setStripePileStep] = useState(MIN_STEP_PILE);
    const [pileSelection, setPileSelection] = useState('');
    const [stripeChangePilesStep, setStripeChangePilesStep] = useState('');
    const [addPileAxes, setAddPileAxes] = useState({
        x: -1000,
        y: -1000,
        enable: false,
        axeX: -1,
        axeY: -1,
        leftDelta: 0,
        rightDelta: 0,
        topDelta: 0,
        bottomDelta: 0,
    });
    const [modifyPileSize, setModifyPileSize] = useState(0);
    const [bakes, setBakes] = useState([]);
    const [bakeSize, setBakeSize] = useState({
        width: 1000,
        height: 1000,
    });
    const [addBakeAxes, setAddBakeAxes] = useState({
        x: -1000,
        y: -1000,
        enable: false,
        top: {
            index: -1,
            offset: 0,
        },
        bottom: {
            index: -1,
            offset: 0,
        },
        left: {
            index: -1,
            offset: 0,
        },
        right: {
            index: -1,
            offset: 0,
        },
    });
    const [bakeSelection, setBakeSelection] = useState('');
    const [calculation, setCalculation] = useState({
        allSizes: 0,
        crossSizes: 0,
        pileCount: 0,
        square: 0,
        perimeter: [], //временно
    });
    const [sizes, setSizes] = useState([]);

    const [userEmail, setUserEmail] = useState('');
    const [errorUserMail, setErrorUserMail] = useState('');

    const getID = async () => {
        if (!search.get('id')) {
            try {
                const value = {
                    data: {
                        plan: "",
                        publishedAt: null,
                    },
                };
                const response = await fetch(`${API}/api/plans`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(value),
                });

                const data = await response.json();
                if (data?.error) {
                    throw data?.error;
                } else {
                    setID(id => { return data.data.id });
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    const getPlan = async () => {
        if (search.get('id')) {
            try {
                const response = await fetch(`${API}/api/plans/${search.get('id')}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                const data = await response.json();
                if (data?.error) {
                    throw data?.error;
                } else {
                    return data.data;
                }
            } catch (error) {
                return {
                    error: error,
                }
            }
        } else {
            return {
                error: 'Отсутствует ID плана',
            }
        }
    }

    const savePlan = async (_plan, _email) => {
        try {
            const value = {
                data: {
                    plan: _plan,
                    email: _email,
                    publishedAt: (new Date()).toISOString(),
                },
            };

            const response = await fetch(`${API}/api/plans/${ID}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(value),
            });

            const data = await response.json();
            if (data?.error) {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.error(error);
        }
    }

    const DeleteCircle = ({ name, x, y, index, type }) => {
        return (
            <Group
                id={name}
                onMouseEnter={handleAxeSelection}
                onMouseLeave={handleAxeSelection}
                onClick={(e) => handleAxeDelete(index, type)}
            >
                <Circle
                    x={x}
                    y={y}
                    radius={CIRCLE_CHAR_RADIUS}
                    fill='#FFFFFF'
                    stroke='#FF2400'
                    strokeWidth={2}
                    opacity={name === axeSelection ? 1 : 0}
                />
                <Rect
                    x={x - 6}
                    y={y - 2}
                    width={12}
                    height={4}
                    fill='#FF2400'
                    opacity={name === axeSelection ? 1 : 0}
                />
            </Group>
        );
    }

    const DeletePileCircle = ({ x, y }) => {
        return (
            <Group
                id={`${x}_${y}`}
                onMouseEnter={handlePileSelection}
                onMouseLeave={handlePileSelection}
                onClick={handlePileDelete}
            >
                <Circle
                    x={x}
                    y={y}
                    radius={CIRCLE_PILE_RADIUS}
                    fill='#FFFFFF'
                    stroke='#FF2400'
                    strokeWidth={2 / scale.value}
                    opacity={`${x}_${y}` === pileSelection ? 1 : 0}
                />
                <Rect
                    x={x - 5 / scale.value}
                    y={y - 2 / scale.value}
                    width={10 / scale.value}
                    height={4 / scale.value}
                    fill='#FF2400'
                    opacity={`${x}_${y}` === pileSelection ? 1 : 0}
                />
            </Group>
        );
    }

    const MovePileCircle = ({ x, y }) => {
        <Group
            id={`move_${x}_${y}`}
            onMouseEnter={handlePileSelection}
            onMouseLeave={handlePileSelection}
            onClick={handlePileMove}
        >
            <Circle
                x={x}
                y={y}
                radius={CIRCLE_PILE_RADIUS}
                fill='#FFFFFF'
                stroke='#FF2400'
                strokeWidth={2 / scale.value}
                opacity={`${x}_${y}` === pileSelection ? 1 : 0}
            />
            <Arrow
                points={[x, y - CIRCLE_PILE_RADIUS, x, y + CIRCLE_PILE_RADIUS]}
                pointerAtBeginning={true}
                pointerAtEnding={true}
                pointerLength={10 / scale.value}
                pointerWidth={10 / scale.value}
                strokeWidth={8 / scale.value}
                stroke='#000000'
                fill='#000000'
                opacity={`${x}_${y}` === pileSelection ? 1 : 0}
            />
            <Arrow
                points={[x - CIRCLE_PILE_RADIUS, y, x + CIRCLE_PILE_RADIUS, y]}
                pointerAtBeginning={true}
                pointerAtEnding={true}
                strokeWidth={8 / scale.value}
                stroke='#000000'
                fill='#000000'
                opacity={`${x}_${y}` === pileSelection ? 1 : 0}
            />
        </Group>
    }

    const ShowPileCircle = ({ x, y }) => {
        return (
            <Group
                id={`${x}_${y}`}
                onMouseEnter={handlePileSelection}
                onMouseLeave={handlePileSelection}
                onClick={handlePileShow}
            >
                <Circle
                    x={x}
                    y={y}
                    radius={CIRCLE_PILE_RADIUS}
                    fill='#FFFFFF'
                    stroke='#FF2400'
                    strokeWidth={2 / scale.value}
                    opacity={`${x}_${y}` === pileSelection ? 1 : 0}
                />
                <Rect
                    x={x - 5 / scale.value}
                    y={y - 2 / scale.value}
                    width={10 / scale.value}
                    height={4 / scale.value}
                    fill='#FF2400'
                    opacity={`${x}_${y}` === pileSelection ? 1 : 0}
                />
                <Rect
                    x={x - 2 / scale.value}
                    y={y - 5 / scale.value}
                    width={4 / scale.value}
                    height={10 / scale.value}
                    fill='#FF2400'
                    opacity={`${x}_${y}` === pileSelection ? 1 : 0}
                />
            </Group>
        );
    }

    const SelectErkerCircle = ({ name, x, y, rotation }) => {
        return (
            <Group
                id={name}
                onClick={handleErkerSideSelect}
            >
                <Circle
                    x={x}
                    y={y}
                    radius={CIRCLE_CHAR_RADIUS / scale.value}
                    fill='#A64BB9'
                />
                <Arrow
                    x={x}
                    offsetX={-(5 / scale.value)}
                    y={y}
                    pointerWidth={10 / scale.value}
                    pointerLength={10 / scale.value}
                    rotation={rotation}
                    fill='#FFFFFF'
                />
            </Group>
        );
    }

    useEffect(() => {
        const canvas = document.getElementById('canvas');
        setContainer(cnt => { return { width: canvas.clientWidth, height: canvas.clientHeight } });
        window.addEventListener('resize', resizeWindow);
        if (search.get('id')) {
            setID(id => { return Number(search.get('id')) });
            getPlan()
                .then((data) => {
                    const plan = JSON.parse(data.attributes.plan);
                    setStripes(st => { return plan.stripes });
                    setErkers(er => { return plan.erkers });
                    setDeletedStripes(dst => { return plan.deletedStripes });
                    setBakes(bk => { return plan.bakes });
                    setPiles(pl => { return plan.piles });
                })
                .catch((errror) => {
                    console.error(error);
                });
        } else if (ID === 0) {
            getID();
            setStripes(st => { return defaultPlan });
        }
        setTimeout(() => {
            setLoading(ld => { return false });
        }, 2000);
    }, []);

    const resizeWindow = () => {
        const canvas = document.getElementById('canvas');
        setContainer(cn => {
            return { width: canvas.clientWidth, height: canvas.clientHeight }
        });
    }

    useEffect(() => {
        if (stripes.horizontal.length !== 0 && stripes.vertical.length !== 0) {
            calculateAxes();
        }
    }, [stripes, erkers, container, countX, countY, widthStripe]);

    useEffect(() => {
        if (axes.horizontal.length !== 0 && axes.vertical.length !== 0) {
            setPiles(pl => { return calculatePiles(stripes, erkers, bakes, deletedStripes, piles, pileStep, _MIN_STEP_PILE, countX, countY) });
            calculateScale();
        }
    }, [axes]);

    useEffect(() => {
        if (axes.horizontal.length !== 0 && axes.vertical.length !== 0) {
            const sizes = calculationAllSizes(axes, scale, stripes, deletedStripes, erkers, bakes, piles, countX, countY, MIN_STEP_PILE, _MIN_STEP_PILE);
            setSizes(sz => { return sizes });
        }
    }, [scale, piles]);

    const calculateScale = () => {
        const scaleX = (container.width - MARGIN_LEFT - MARGIN_RIGHT) / (axes.lengthX + axes.offsetX + axes.leftExtension);
        const scaleY = (container.height - MARGIN_TOP - MARGIN_BOTTOM) / (axes.lengthY + axes.offsetY + axes.topExtension);
        let _scale = 0;
        let _offsetX = 0;
        let _offsetY = 0;
        if (scaleX < scaleY) {
            _scale = scaleX;
        } else {
            _scale = scaleY;
        }
        _offsetX = Math.round(((container.width - axes.lengthX * _scale - axes.offsetX * _scale - axes.leftExtension * _scale - MARGIN_LEFT - MARGIN_RIGHT) / 2)) + MARGIN_LEFT + axes.offsetX * _scale + axes.leftExtension * _scale;
        _offsetY = Math.round(((container.height - axes.lengthY * _scale - axes.offsetY * _scale - axes.topExtension * _scale - MARGIN_TOP - MARGIN_BOTTOM) / 2)) + MARGIN_TOP + axes.offsetY * _scale + axes.topExtension * _scale;

        const __scale = {
            value: _scale,
            offsetX: _offsetX,
            offsetY: _offsetY,
        };
        setScale(sc => { return __scale });
    }

    const calculateAxes = () => {
        let horizontalAxes = [];
        let horizontalInputs = [];
        let verticalAxes = [];
        let verticalInputs = [];
        let verticalLength = 0;
        let horizontalLength = 0;
        let _offsetX = 0;
        let _offsetY = 0;
        // Проверяем нижние эркеры для горизонтальных осей
        let _topErkers = [];
        let _topExtension = 0;
        let _topCount = 0;
        const topErkerStripe = stripes.vertical.find((stripe) => stripe.name === `ver_0_0`);
        erkers.top.forEach((erker) => {
            if (!_topErkers.includes(erker.extension)) {
                if (erker.extension > _topExtension) _topExtension = erker.extension;
                _topErkers.push(erker.extension);
                _topCount++;
            }
        })
        _topErkers.sort().reverse();
        _topErkers.forEach(erker => {
            horizontalAxes.push({
                name: `hor_${horizontalAxes.length}`,
                index: -1,
                letter: CHARS[horizontalAxes.length],
                x: -topErkerStripe.leftWidth,
                y: -erker,
            });
        })
        // Горизонтальные оси
        for (let i = 0; i < countX - 1; i++) {
            const tempStripe = stripes.vertical.find((stripe) => stripe.name === `ver_${i}_0`);
            const tempStripe_0 = stripes.horizontal.find((stripe) => stripe.name === `hor_0_0`);
            const tempStripe_1 = stripes.horizontal.find((stripe) => stripe.name === `hor_0_${i + 1}`);
            const tempStripe_2 = stripes.vertical.find((stripe) => stripe.name === `ver_0_0`);
            horizontalAxes.push({
                name: `hor_${_topCount + i}`,
                index: i,
                letter: CHARS[_topCount + i],
                x: - tempStripe_2.leftWidth,
                y: tempStripe.pointTop.y,
            });
            if (i === countX - 2) {
                horizontalAxes.push({
                    name: `hor_${_topCount + i + 1}`,
                    index: i + 1,
                    letter: CHARS[_topCount + i + 1],
                    x: - tempStripe_2.leftWidth,
                    y: tempStripe.pointBottom.y,
                });
            }
            verticalLength += tempStripe.length;
            if (i === 0) {
                verticalLength += tempStripe_0.topWidth;
                _offsetY = tempStripe_0.topWidth;
            }
            if (i === countX - 2) {
                verticalLength += tempStripe_1.bottomWidth;
            }
            if (i < countX - 1) {
                verticalInputs.push({
                    index: i,
                    value: tempStripe.length,
                    tempValue: (tempStripe.length).toString(),
                    x: - tempStripe_2.leftWidth,
                    y: tempStripe.pointTop.y + tempStripe.length / 2,
                });
            }
        }
        // Проверяем нижние эркеры для горизонтальных осей
        let _bottomErkers = [];
        let _bottomExtension = 0;
        erkers.bottom.forEach((erker) => {
            if (!_bottomErkers.includes(erker.extension)) {
                if (erker.extension > _bottomExtension) _bottomExtension = erker.extension;
                _bottomErkers.push(erker.extension);
            }
        })
        _bottomErkers.sort();
        const bottom_index = horizontalAxes.length - 1;
        _bottomErkers.forEach(erker => {
            horizontalAxes.push({
                name: `hor_${horizontalAxes.length}`,
                index: -1,
                letter: CHARS[horizontalAxes.length + 1],
                x: horizontalAxes[bottom_index].x,
                y: horizontalAxes[bottom_index].y + erker,
            });
        })
        if (_bottomExtension > 0) verticalLength += _bottomExtension;
        for (let index = 0; index < horizontalAxes.length / 2; index++) {
            const buffer = horizontalAxes[index].letter;
            horizontalAxes[index].letter = horizontalAxes[horizontalAxes.length - 1 - index].letter;
            horizontalAxes[horizontalAxes.length - 1 - index].letter = buffer;
        }
        // Проверяем левые эркеры для вертикальных осей
        let _leftErkers = [];
        let _leftExtension = 0;
        let _leftCount = 0;
        const tempStripe_2 = stripes.horizontal.find((stripe) => stripe.name === `hor_0_0`);
        erkers.left.forEach((erker) => {
            if (!_leftErkers.includes(erker.extension)) {
                if (erker.extension > _leftExtension) _leftExtension = erker.extension;
                _leftErkers.push(erker.extension);
                _leftCount++;
            }
        })
        _leftErkers.sort().reverse();
        _leftErkers.forEach(erker => {
            verticalAxes.push({
                name: `ver_${verticalAxes.length}`,
                index: -1,
                letter: (verticalAxes.length + 1).toString(),
                x: -erker,
                y: -tempStripe_2.topWidth,
            });
        })
        // Вертикальные оси
        for (let i = 0; i < countY - 1; i++) {
            const tempStripe = stripes.horizontal.find((stripe) => stripe.name === `hor_${i}_0`);
            const tempStripe_0 = stripes.vertical.find((stripe) => stripe.name === `ver_0_0`);
            const tempStripe_1 = stripes.vertical.find((stripe) => stripe.name === `ver_0_${i + 1}`);
            verticalAxes.push({
                name: `ver_${_leftCount + i}`,
                index: i,
                letter: (i + _leftCount + 1).toString(),
                x: tempStripe.pointLeft.x,
                y: tempStripe_0.pointTop.y - tempStripe_2.topWidth,
            });
            if (i === countY - 2) {
                verticalAxes.push({
                    name: `ver_${_leftCount + i + 1}`,
                    index: i + 1,
                    letter: (i + _leftCount + 2).toString(),
                    x: tempStripe.pointRight.x,
                    y: tempStripe_0.pointTop.y - tempStripe_2.topWidth,
                });
            }
            horizontalLength += tempStripe.length;
            if (i === 0) {
                horizontalLength += tempStripe_0.leftWidth;
                _offsetX = tempStripe_0.leftWidth;
            }
            if (i === countY - 2) {
                horizontalLength += tempStripe_1.rightWidth;
            }
            if (i < countY - 1) {
                horizontalInputs.push({
                    index: i,
                    value: tempStripe.length,
                    tempValue: (tempStripe.length).toString(),
                    x: tempStripe.pointLeft.x + tempStripe.length / 2,
                    y: tempStripe_0.pointTop.y - tempStripe_2.topWidth,
                });
            }
        }
        // Проверяем правые эркеры для вертикальных осей
        let _rightErkers = [];
        let _rightExtension = 0;
        erkers.right.forEach((erker) => {
            if (!_rightErkers.includes(erker.extension)) {
                if (erker.extension > _rightExtension) _rightExtension = erker.extension;
                _rightErkers.push(erker.extension);
            }
        })
        _rightErkers.sort();
        const right_index = verticalAxes.length - 1;
        _rightErkers.forEach(erker => {
            verticalAxes.push({
                name: `ver_${verticalAxes.length}`,
                index: -1,
                letter: (verticalAxes.length + 1).toString(),
                x: verticalAxes[right_index].x + erker,
                y: verticalAxes[right_index].y,
            });
        })
        if (_rightExtension > 0) horizontalLength += _rightExtension;

        const _axes = {
            horizontal: horizontalAxes,
            vertical: verticalAxes,
            lengthX: horizontalLength,
            lengthY: verticalLength,
            offsetX: _offsetX,
            offsetY: _offsetY,
            leftExtension: _leftExtension,
            topExtension: _topExtension,
        };

        setAxes(ax => { return _axes });

        setAxeInputs(axi => {
            return {
                horizontal: horizontalInputs,
                vertical: verticalInputs,
            }
        });
    }

    const handleAxeDelete = (index, type) => {
        const resultAxeDelete = deleteAxe(axes, index, type, stripes, deletedStripes, erkers, bakes, countX, countY);
        setStripes(st => { return resultAxeDelete.stripes });
        setDeletedStripes(dst => { return resultAxeDelete.deletedStripes });
        setErkers(er => { return resultAxeDelete.erkers });
        setBakes(er => { return resultAxeDelete.bakes });
        setCountX(cx => { return resultAxeDelete.countX });
        setCountY(cy => { return resultAxeDelete.countY });
        setAxeSelection(as => { return '' });
    }

    const handleOnChangeAxeInputs = (event, index, value, type) => {
        let horInputs = JSON.parse(JSON.stringify(axeInputs.horizontal));
        let verInputs = JSON.parse(JSON.stringify(axeInputs.vertical));
        let newValue = '';
        if (value.length > 0) {
            const digit = value.slice(-1);
            if (digit === ',') {
                newValue = value.substring(0, value.length - 1) + '.';
                const repeat = newValue.split('.').length > 2 ? true : false;
                if (repeat) {
                    newValue = newValue.substring(0, value.length - 1);
                }
            } else if (digit === '.') {
                const repeat = value.split('.').length > 2 ? true : false;
                if (repeat) {
                    newValue = value.substring(0, value.length - 1);
                } else {
                    newValue = value;
                }
            } else if (/^[0-9]+$/.test(digit)) {
                newValue = value;
            } else {
                newValue = value.substring(0, value.length - 1);
            }
        }
        if (type === 'horizontal') {
            horInputs.forEach(input => {
                if (input.index === index) {
                    input.tempValue = newValue;
                }
            });
        } else if (type === 'vertical') {
            verInputs.forEach(input => {
                if (input.index === index) {
                    input.tempValue = newValue;
                }
            });
        }
        setAxeInputs(ai => {
            return {
                horizontal: horInputs,
                vertical: verInputs,
            }
        });
        event.cancelBubble = true;
    }

    const handleOnEnterAxeInputs = (event, index, value, type) => {
        if (event.charCode == 13) {
            handleChangeAxeInputs(event, index, value, type);
        }
    }

    const handleChangeAxeInputs = (event, index, value, type) => {
        const resultAxeWidth = changeAxeWidth(index, value < 400 ? 400 : value, type, stripes, erkers, countX, countY);
        const calculateLines = recalculateLines(resultAxeWidth.stripes, deletedStripes, resultAxeWidth.erkers, bakes, countX, countY);
        setStripes(st => { return calculateLines.stripes });
        setExtendedStripes(exSt => { return calculateLines.extendedStripes });
        setErkers(er => { return calculateLines.erkers });
        calculateAxes();
        // setPiles(pl => {
        //     return calculatePiles(stripes, erkers, deletedStripes, piles, pileStep, countX, countY)
        // });
        // const sizes = calculationAllSizes(axes, scale, stripes, deletedStripes, erkers, bakes, countX, countY);
        // setSizes(sz => { return sizes });
        event.cancelBubble = true;
    }

    const handleValidateAxeInputs = (event) => {

    }

    const handleStripeSelection = (event) => {
        if (event.type === 'mouseenter') {
            if (stripeSelection !== event.target.id()) setStripeSelection(event.target.id());
        }
        if (event.type === 'mouseleave') {
            if (stripeSelection !== '') setStripeSelection('');
        }
        event.cancelBubble = true;
    }

    const handleErkerSelection = (event) => {
        if (event.type === 'mouseenter') {
            if (erkerSelection !== event.target.id()) setErkerSelection(event.target.id());
        }
        if (event.type === 'mouseleave') {
            if (erkerSelection !== '') setErkerSelection('');
        }
        event.cancelBubble = true;
    }

    const handleBakeSelection = (event) => {
        if (event.type === 'mouseenter') {
            if (erkerSelection !== event.target.id()) setBakeSelection(event.target.id());
        }
        if (event.type === 'mouseleave') {
            if (erkerSelection !== '') setBakeSelection('');
        }
        event.cancelBubble = true;
    }

    const handleDeleteErker = (event, currentErker) => {
        let _erkers = JSON.parse(JSON.stringify(erkers));
        switch (currentErker.direction) {
            case 'leftErker':
                _erkers = {
                    top: erkers.top,
                    bottom: erkers.bottom,
                    left: erkers.left.filter(erker => erker.type !== currentErker.type || erker.name !== currentErker.name),
                    right: erkers.right,
                };
                break;

            case 'rightErker':
                _erkers = {
                    top: erkers.top,
                    bottom: erkers.bottom,
                    left: erkers.left,
                    right: erkers.right.filter(erker => erker.type !== currentErker.type || erker.name !== currentErker.name),
                };
                break;

            case 'topErker':
                _erkers = {
                    top: erkers.top.filter(erker => erker.type !== currentErker.type || erker.name !== currentErker.name),
                    bottom: erkers.bottom,
                    left: erkers.left,
                    right: erkers.right,
                };
                break;

            case 'bottomErker':
                _erkers = {
                    top: erkers.top,
                    bottom: erkers.bottom.filter(erker => erker.type !== currentErker.type || erker.name !== currentErker.name),
                    left: erkers.left,
                    right: erkers.right,
                };
                break;

            default:
                break;
        }
        const calculateLines = recalculateLines(stripes, deletedStripes, _erkers, bakes, countX, countY);
        setErkers(er => { return calculateLines.erkers });
        setStripes(st => { return calculateLines.stripes });
        setExtendedStripes(exSt => { return calculateLines.extendedStripes });
        calculateAxes();
        // const sizes = calculationAllSizes(axes, scale, stripes, deletedStripes, erkers, bakes, countX, countY);
        // setSizes(sz => { return sizes });
        event.cancelBubble = true;
    }

    const handleDeleteBake = (event) => {
        let _bakes = JSON.parse(JSON.stringify(bakes));
        const coords = event.target.id().split('_');
        _bakes = _bakes.filter(bake => `${bake.x}_${bake.y}` !== `${coords[0]}_${coords[1]}`);
        setBakes(bk => { return _bakes });
        const calculateLines = recalculateLines(stripes, deletedStripes, erkers, _bakes, countX, countY);
        setErkers(er => { return calculateLines.erkers });
        setStripes(st => { return calculateLines.stripes });
        setExtendedStripes(exSt => { return calculateLines.extendedStripes });
        // const sizes = calculationAllSizes(axes, scale, stripes, deletedStripes, erkers, bakes, countX, countY);
        // setSizes(sz => { return sizes });
        event.cancelBubble = true;
    }

    const handleAxeSelection = (event) => {
        if (event.type === 'mouseenter') {
            if (axeSelection !== event.currentTarget.id()) setAxeSelection(event.currentTarget.id());
        }
        if (event.type === 'mouseleave') {
            if (axeSelection !== '') setAxeSelection('');
        }
        event.cancelBubble = true;
    }

    const handleErkerSideSelect = (event) => {
        const side = event.currentTarget.id();
        if (side === 'topErker' || side === 'bottomErker') {
            setErkerAxes({
                top: '',
                bottom: '',
                left: axes.vertical.filter(axe => axe.index > -1)[0].index,
                right: axes.vertical.filter(axe => axe.index > -1)[countY - 1].index,
            })
        } else if (side === 'leftErker' || side === 'rightErker') {
            setErkerAxes({
                top: axes.horizontal.filter(axe => axe.index > -1)[countX - 1].index,
                bottom: axes.horizontal.filter(axe => axe.index > -1)[0].index,
                left: '',
                right: '',
            })
        }
        setErkerSide(side);
        if (action === 'AddAngelErker') {
            setAction('ConstructAngelErker');
        } else if (action === 'AddRoundErker') {
            setAction('ConstructRoundErker');
        }
        event.cancelBubble = true;
    }

    const handleAddErker = (event) => {
        if (action === 'ConstructAngelErker') {
            const result = addErker('angel', erkerSide, axes, erkerAxes, stripes, bakes, deletedStripes, widthStripe, erkers, erkerSize, erkerExtension, countX, countY);
            if (result) {
                setErkers(result[0]);
                setStripes(result[1]);
                setAction('AddDeleteStripe');
                calculateAxes();
                // setPiles(calculatePiles(stripes, erkers, deletedStripes, piles, pileStep, countX, countY));
            }
        } else if (action === 'ConstructRoundErker') {
            const result = addErker('round', erkerSide, axes, erkerAxes, stripes, bakes, deletedStripes, widthStripe, erkers, erkerSize, erkerExtension, countX, countY);
            if (result) {
                setErkers(result[0]);
                setStripes(result[1]);
                setAction('AddDeleteStripe');
                calculateAxes();
                // setPiles(calculatePiles(stripes, erkers, deletedStripes, piles, pileStep, countX, countY));
            }
        }
        event.cancelBubble = true;
    }

    const handleChangeCountAxes = (_countX, _countY) => {
        const result = calculateAddDeleteAxes(stripes, deletedStripes, erkers, bakes, _countX, countX, _countY, countY, LENGTH_STRIPE_DEFAULT, widthStripe);
        if (_countX !== countX) {
            setCountX(_countX);
        }
        if (_countY !== countY) {
            setCountY(_countY);
        }
        if (result) {
            setStripes(st => { return result.stripes });
            setErkers(er => { return result.erkers });
            setBakes(bk => { return result.bakes })
            setDeletedStripes(dst => { return result.deletedStripes });
        }
    }

    const handleChangeWidth = (event) => {
        const _width = event.target.value;
        if (_width >= 100) {
            setWidthStripe(_width);
            const _stripes = calculateChangeWidth(stripes, countX, countY, _width);
            const _erkers = {
                top: [],
                bottom: [],
                left: [],
                right: [],
            }
            const calculateLines = recalculateLines(_stripes, deletedStripes, _erkers, bakes, countX, countY);
            setStripes(st => { return calculateLines.stripes });
            setExtendedStripes(exSt => { return calculateLines.extendedStripes });
            setErkers(er => { return calculateLines.erkers });
            // const sizes = calculationAllSizes(axes, scale, stripes, deletedStripes, erkers, bakes, countX, countY);
            // setSizes(sz => { return sizes });
        }
        event.cancelBubble = true;
    }

    const handleStripeAddDelete = (event) => {
        if (action === 'AddDeleteStripe') {
            let _deletedStripes = deletedStripes;
            if (_deletedStripes.includes(event.target.id())) {
                _deletedStripes = _deletedStripes.filter((stripe) => stripe !== event.target.id());
                const calculateLines = recalculateLines(stripes, _deletedStripes, erkers, bakes, countX, countY);
                setStripes(st => { return calculateLines.stripes });
                setExtendedStripes(exSt => { return calculateLines.extendedStripes });
                setErkers(er => { return calculateLines.erkers });
                // const sizes = calculationAllSizes(axes, scale, stripes, deletedStripes, erkers, bakes, countX, countY);
                // setSizes(sz => { return sizes });
            } else {
                _deletedStripes.push(event.target.id());
                const calculateLines = recalculateLines(stripes, _deletedStripes, erkers, bakes, countX, countY);
                setStripes(st => { return calculateLines.stripes });
                setExtendedStripes(exSt => { return calculateLines.extendedStripes });
                setErkers(er => { return calculateLines.erkers });
                // const sizes = calculationAllSizes(axes, scale, stripes, deletedStripes, erkers, bakes, countX, countY);
                // setSizes(sz => { return sizes });
            }
            setDeletedStripes(_deletedStripes);
            // setPiles(calculatePiles(stripes, erkers, _deletedStripes, piles, pileStep, countX, countY));
        }
        if (action === 'ChangeStripeWidth') {
            setStripeForWidth(event.target.id());
            let currentStripe = null;
            if (event.target.id().includes('ver')) {
                currentStripe = stripes.vertical.find(stripe => stripe.name === event.target.id());
                setStripeForWidthAxes({
                    x: currentStripe.pointTop.x,
                    y: currentStripe.pointTop.y + (currentStripe.length / 2),
                    width: currentStripe.width,
                    direction: 'ver',
                });
            } else if (event.target.id().includes('hor')) {
                currentStripe = stripes.horizontal.find(stripe => stripe.name === event.target.id());
                setStripeForWidthAxes({
                    x: currentStripe.pointLeft.x + (currentStripe.length / 2),
                    y: currentStripe.pointLeft.y,
                    width: currentStripe.width,
                    direction: 'hor',
                });
            }
            setStripeForWidthValue(WIDTH_STRIPE_DEFAULT);
            setAction('InputStripeWidth');
        }
        if (action === 'DeleteStripeSavePile') {
            let _stripes = JSON.parse(JSON.stringify(stripes));
            let currentStripe = null;
            if (event.target.id().includes('ver')) {
                currentStripe = _stripes.vertical.find(stripe => stripe.name === event.target.id());
            } else if (event.target.id().includes('hor')) {
                currentStripe = _stripes.horizontal.find(stripe => stripe.name === event.target.id());
            }
            if (currentStripe.savePiles) {
                delete currentStripe.savePiles;
            } else {
                currentStripe.savePiles = true;
            }
            let _deletedStripes = deletedStripes;
            if (!_deletedStripes.includes(event.target.id())) {
                _deletedStripes.push(event.target.id());
            }
            const calculateLines = recalculateLines(_stripes, _deletedStripes, erkers, bakes, countX, countY);
            setStripes(st => { return calculateLines.stripes });
            setExtendedStripes(exSt => { return calculateLines.extendedStripes });
            setErkers(er => { return calculateLines.erkers });
            setDeletedStripes(dst => { return _deletedStripes });
            // setPiles(pl => { return calculatePiles(_stripes, erkers, _deletedStripes, piles, pileStep, countX, countY) });
            // const sizes = calculationAllSizes(axes, scale, stripes, deletedStripes, erkers, bakes, countX, countY);
            // setSizes(sz => { return sizes });
        }
        if (action === 'ChangePilesStepOnStripe') {
            let currentStripe = null;
            if (event.target.id().includes('ver') && !event.target.id().includes(':')) {
                currentStripe = stripes.vertical.find(stripe => stripe.name === event.target.id());
            } else if (event.target.id().includes('hor') && !event.target.id().includes(':')) {
                currentStripe = stripes.horizontal.find(stripe => stripe.name === event.target.id());
            }
            if (currentStripe.pileStep) {
                setStripePileStep(currentStripe.pileStep);
            } else setStripePileStep(pileStep);
            setStripeChangePilesStep(stpl => { return event.target.id() });
        }
        event.cancelBubble = true;
    }

    const handleCornerStripeDelete = (event) => {
        if (action === 'AddDeleteStripe') {
            const _stripes = {
                horizontal: stripes.horizontal,
                vertical: stripes.vertical,
                corner: stripes.corner.filter(cornerStripe => cornerStripe.name !== event.target.id()),
            }
            const calculateLines = recalculateLines(_stripes, deletedStripes, erkers, bakes, countX, countY);
            setStripes(st => { return calculateLines.stripes });
            setExtendedStripes(exSt => { return calculateLines.extendedStripes });
            setErkers(er => { return calculateLines.erkers });
        }
        if (action === 'ChangePilesStepOnStripe') {
            let currentStripe = null;
            if (event.target.id().includes(':')) {
                currentStripe = stripes.corner.find(stripe => stripe.name === event.target.id());
            }
            if (currentStripe.pileStep) {
                setStripePileStep(currentStripe.pileStep);
            } else setStripePileStep(pileStep);
            setStripeChangePilesStep(stpl => { return event.target.id() });
        }
    }

    const handleChangeStripeWidth = (event) => {
        setStripeForWidthValue(Number(event.target.value) / 10);
        event.cancelBubble = true;
    }

    const handleChangeModifyPileSize = (event) => {
        setModifyPileSize(mps => { return event.target.value; });
        event.cancelBubble = true;
    }

    const handleValidateStripeWidth = (event) => {
        if (Number(event.target.value) < 100) {
            setStripeForWidthValue(100);
        }
        event.cancelBubble = true;
    }

    const handleUpdateModifyPileSize = (event) => {
        setPiles(pl => {
            let _piles = JSON.parse(JSON.stringify(pl));
            let _pile = _piles.find(pile => pile.x === addPileAxes.x && pile.y === addPileAxes.y);
            if (_pile) {
                if (_pile.axeX > -1 && _pile.axeY === -1) {
                    if (addPileAxes.leftDelta < addPileAxes.rightDelta) {
                        _pile.x = _pile.x - (addPileAxes.leftDelta - modifyPileSize);
                    } else {
                        _pile.x = _pile.x + (addPileAxes.rightDelta - modifyPileSize);
                    }
                }
                if (_pile.axeY > -1 && _pile.axeX === -1) {
                    if (addPileAxes.topDelta < addPileAxes.bottomDelta) {
                        _pile.y = _pile.y - (addPileAxes.topDelta - modifyPileSize);
                    } else {
                        _pile.y = _pile.y + (addPileAxes.bottomDelta - modifyPileSize);
                    }
                }
            }
            return _piles;
        });
        setAddPileAxes(apl => {
            return {
                x: -1000,
                y: -1000,
                enable: false,
                axeX: -1,
                axeY: -1,
                leftDelta: 0,
                rightDelta: 0,
                topDelta: 0,
                bottomDelta: 0,
            };
        });
        setAction(ac => { return ''; });
        event.cancelBubble = true;
    }

    const handleUpdateStripeWidth = (event) => {
        let _stripes = JSON.parse(JSON.stringify(stripes));
        let currentStripe = null;
        if (stripeForWidthAxes.direction === 'ver') {
            currentStripe = _stripes.vertical.find(stripe => stripe.name === stripeForWidth);
            const delta = stripeForWidthValue - currentStripe.width;
            currentStripe.width = stripeForWidthValue;
            currentStripe.leftWidth = stripeForWidthValue / 2;
            currentStripe.rightWidth = stripeForWidthValue / 2;
            currentStripe.pointLeftTop.x -= delta / 2;
            currentStripe.pointLeftBottom.x -= delta / 2;
            currentStripe.pointRightTop.x += delta / 2;
            currentStripe.pointRightBottom.x += delta / 2;
        }
        if (stripeForWidthAxes.direction === 'hor') {
            currentStripe = _stripes.horizontal.find(stripe => stripe.name === stripeForWidth);
            const delta = stripeForWidthValue - currentStripe.width;
            currentStripe.width = stripeForWidthValue;
            currentStripe.topWidth = stripeForWidthValue / 2;
            currentStripe.bottomWidth = stripeForWidthValue / 2;
            currentStripe.pointTopLeft.y -= delta / 2;
            currentStripe.pointTopRight.y -= delta / 2;
            currentStripe.pointBottomLeft.y += delta / 2;
            currentStripe.pointBottomRight.y += delta / 2;
        }
        const calculateLines = recalculateLines(_stripes, deletedStripes, erkers, bakes, countX, countY);
        setStripes(st => { return calculateLines.stripes; });
        setExtendedStripes(exSt => { return calculateLines.extendedStripes });
        setErkers(er => { return calculateLines.erkers });
        setStripeSelection('');
        // const sizes = calculationAllSizes(axes, scale, stripes, deletedStripes, erkers, bakes, countX, countY);
        // setSizes(sz => { return sizes });
        setAction('AddDeleteStripe');
        event.cancelBubble = true;
    }

    const handleSelectCornerStripeFrom = (event, pile) => {
        setAddCornerStripeFrom(cst => {
            return {
                x: pile.axeX,
                y: pile.axeY,
                coords: {
                    x: pile.x,
                    y: pile.y,
                },
            }
        });
        setAction('AddCornerStripeTo');
        event.cancelBubble = true;
    }

    const handleSelectCornerStripeTo = (event, pile) => {
        const _stripes = addCornerStripe({
            from: {
                x: addCornerStripeFrom.x,
                y: addCornerStripeFrom.y,
            },
            to: {
                x: pile.axeX,
                y: pile.axeY,
            }
        }, stripes, widthStripe);
        if (_stripes) {
            const calculateLines = recalculateLines(_stripes, deletedStripes, erkers, bakes, countX, countY);
            setStripes(st => { return calculateLines.stripes });
            setExtendedStripes(exSt => { return calculateLines.extendedStripes });
            setErkers(er => { return calculateLines.erkers });
            // const sizes = calculationAllSizes(axes, scale, stripes, deletedStripes, erkers, bakes, countX, countY);
            // setSizes(sz => { return sizes });
        }
        setAction('AddDeleteStripe');
        event.cancelBubble = true;
    }

    const handleStepNext = (event) => {
        if (step < 3) {
            if (step + 1 == 3) {
                setCalculation(calc => { return calculationSizes(piles, stripes, deletedStripes, erkers, countX, countY, stageRef) });
            }
            setStep(st => { return step + 1 });
            setAction(ac => { return '' });
        }
        if (step === 3) {
            setAction(st => { return 'SaveProject' });
        }
        event.cancelBubble = true;
    }

    const handleStepBack = (event) => {
        if (step > 1) {
            setStep(st => { return step - 1 });
            setAction(ac => { return '' });
        }
        event.cancelBubble = true;
    }

    const handleChangePileStep = (event) => {
        setPiles(pl => { return calculatePiles(stripes, erkers, bakes, deletedStripes, piles, pileStep, _MIN_STEP_PILE, countX, countY) });
        setAction(ac => { return '' });
        event.cancelBubble = true;
    }

    const handleChangePilesStepOnStripe = (event) => {
        setStripes(st => {
            let _stripes = JSON.parse(JSON.stringify(stripes));
            let currentStripe = null;
            if (stripeChangePilesStep.includes('ver') && !stripeChangePilesStep.includes(':')) {
                currentStripe = _stripes.vertical.find(stripe => stripe.name === stripeChangePilesStep);
            } else if (stripeChangePilesStep.includes('hor') && !stripeChangePilesStep.includes(':')) {
                currentStripe = _stripes.horizontal.find(stripe => stripe.name === stripeChangePilesStep);
            } else {
                currentStripe = _stripes.corner.find(stripe => stripe.name === stripeChangePilesStep);
            }
            currentStripe.pileStep = stripePileStep;
            return _stripes;
        });
        setStripeChangePilesStep('');
        event.cancelBubble = true;
    }

    const handlePileSelection = (event) => {
        if (event.type === 'mouseenter') {
            if (pileSelection !== event.currentTarget.id()) setPileSelection(event.currentTarget.id());
        }
        if (event.type === 'mouseleave') {
            if (pileSelection !== '') setPileSelection('');
        }
        event.cancelBubble = true;
    }

    const handlePileDelete = (event) => {
        const coords = event.currentTarget.id().split('_');
        setPiles(pl => {
            let _piles = JSON.parse(JSON.stringify(piles));
            let currentPile = _piles.find(pile => pile.x === Number(coords[0]) && pile.y === Number(coords[1]));
            if (currentPile) currentPile.deleted = true;
            return _piles;
        });
        event.cancelBubble = true;
    }

    const handlePileShow = (event) => {
        const coords = event.currentTarget.id().split('_');
        setPiles(pl => {
            let _piles = JSON.parse(JSON.stringify(piles));
            let currentPile = _piles.find(pile => pile.x === Number(coords[0]) && pile.y === Number(coords[1]));
            if (currentPile) currentPile.deleted = false;
            return _piles;
        });
        event.cancelBubble = true;
    }

    const handleMouseMovingInAction = (event) => {
        if (action === 'AddPile') {
            setAddPileAxes(pa => { return calculatePositionPile(event.target.getStage().getPointerPosition(), scale, stripes, piles, erkers, axes, countX, countY, _MIN_STEP_PILE) });
        }
        if (action === 'AddBakeMoving') {
            setAddBakeAxes(ba => { return calculatePositionBake(event.target.getStage().getPointerPosition(), bakeSize, scale, bakes, stripes, erkers, axes, countX, countY) });
        }
        if (action === 'AddStaticBakeMoving') {
            setAddBakeAxes(ba => { return calculatePositionStaticBake(event.target.getStage().getPointerPosition(), bakeSize, scale, bakes, stripes, erkers, axes, countX, countY) })
        }
        event.cancelBubble = true;
    }

    const handleMouseLeaveInAction = (event) => {
        if (action === 'AddPile') {
            setAddPileAxes(apl => {
                return {
                    x: -1000,
                    y: -1000,
                    enable: false,
                    axeX: -1,
                    axeY: -1,
                }
            });
        }
        if (action === 'AddBakeMoving' || action === 'AddStaticBakeMoving') {
            setAddBakeAxes(aba => {
                return {
                    x: -1000,
                    y: -1000,
                    enable: false,
                    top: {
                        index: -1,
                        offset: 0,
                    },
                    bottom: {
                        index: -1,
                        offset: 0,
                    },
                    left: {
                        index: -1,
                        offset: 0,
                    },
                    right: {
                        index: -1,
                        offset: 0,
                    },
                }
            });
        }
        event.cancelBubble = true;
    }

    const handleMouseClickInAction = (event) => {
        if (action === 'AddPile') {
            if (addPileAxes.enable) {
                setPiles(pl => {
                    let _piles = JSON.parse(JSON.stringify(pl));
                    _piles.push({
                        axeX: addPileAxes.axeX,
                        axeY: addPileAxes.axeY,
                        deleted: false,
                        x: addPileAxes.x,
                        y: addPileAxes.y,
                        extended: true,
                    });
                    return _piles;
                });
                setModifyPileSize(mps => {
                    if (addPileAxes.axeX > -1) {
                        if (addPileAxes.leftDelta < addPileAxes.rightDelta) {
                            return addPileAxes.leftDelta;
                        } else {
                            return addPileAxes.rightDelta;
                        }
                    } else if (addPileAxes.axeY > -1) {
                        if (addPileAxes.topDelta < addPileAxes.bottomDelta) {
                            return addPileAxes.topDelta;
                        } else {
                            return addPileAxes.bottomDelta;
                        }
                    }
                });
                setAction(ac => { return 'ModifyPile' });
                // setAddPileAxes(apl => {
                //     return {
                //         x: -1000,
                //         y: -1000,
                //         enable: false,
                //         axeX: -1,
                //         axeY: -1,
                //     }
                // });
            }
        }
        if (action === 'AddBakeMoving' || action === 'AddStaticBakeMoving') {
            if (addBakeAxes.enable) {
                const _bakes = JSON.parse(JSON.stringify(bakes));
                _bakes.push({
                    x: addBakeAxes.x,
                    y: addBakeAxes.y,
                    width: bakeSize.width,
                    height: bakeSize.height,
                    top: {
                        index: addBakeAxes.top.index,
                        offset: addBakeAxes.top.offset,
                        left: {
                            x: addBakeAxes.x,
                            y: addBakeAxes.y - bakeSize.height / 2,
                        },
                        right: {
                            x: addBakeAxes.x,
                            y: addBakeAxes.y - bakeSize.height / 2,
                        },
                    },
                    bottom: {
                        index: addBakeAxes.bottom.index,
                        offset: addBakeAxes.bottom.offset,
                        left: {
                            x: addBakeAxes.x,
                            y: addBakeAxes.y + bakeSize.height / 2,
                        },
                        right: {
                            x: addBakeAxes.x,
                            y: addBakeAxes.y + bakeSize.height / 2,
                        },
                    },
                    left: {
                        index: addBakeAxes.left.index,
                        offset: addBakeAxes.left.offset,
                        top: {
                            x: addBakeAxes.x - bakeSize.width / 2,
                            y: addBakeAxes.y,
                        },
                        bottom: {
                            x: addBakeAxes.x - bakeSize.width / 2,
                            y: addBakeAxes.y,
                        },
                    },
                    right: {
                        index: addBakeAxes.right.index,
                        offset: addBakeAxes.right.offset,
                        top: {
                            x: addBakeAxes.x + bakeSize.width / 2,
                            y: addBakeAxes.y,
                        },
                        bottom: {
                            x: addBakeAxes.x + bakeSize.width / 2,
                            y: addBakeAxes.y,
                        },
                    },
                });
                setAction(ac => { return '' });
                setAddBakeAxes(aba => {
                    return {
                        x: -1000,
                        y: -1000,
                        enable: false,
                        top: {
                            index: -1,
                            offset: 0,
                        },
                        bottom: {
                            index: -1,
                            offset: 0,
                        },
                        left: {
                            index: -1,
                            offset: 0,
                        },
                        right: {
                            index: -1,
                            offset: 0,
                        },
                    }
                });
                const calculateLines = recalculateLines(stripes, deletedStripes, erkers, _bakes, countX, countY);
                setBakes(bk => { return calculateLines.bakes; });
                setStripes(st => { return calculateLines.stripes });
                setExtendedStripes(exSt => { return calculateLines.extendedStripes });
                setErkers(er => { return calculateLines.erkers });
                // const sizes = calculationAllSizes(axes, scale, stripes, deletedStripes, erkers, bakes, countX, countY);
                // setSizes(sz => { return sizes });
            }
        }
        if (action === 'AddStaticBakeMoving') {

        }
        event.cancelBubble = true;
    }

    const handlePileMove = (event) => {
        const coords = event.currentTarget.id().split('_');
        let _piles = JSON.parse(JSON.stringify(piles));
        setPiles(pl => {
            _piles = _piles.filter(pile => pile.x !== Number(coords[1]) || pile.y !== Number(coords[2]));
            return _piles;
        });
        setAction(ac => { return 'AddPile' });
        setPileSelection(ps => { return '' });
        setAddPileAxes(pa => { return calculatePositionPile(event.target.getStage().getPointerPosition(), scale, stripes, _piles, erkers, axes, countX, countY, _MIN_STEP_PILE) });
        event.cancelBubble = true;
    }

    const handleSavePlan = (event) => {
        console.log(userEmail);
        if (!userEmail) {
            setErrorUserMail(eum => { return 'Адрес электронной почты обязателен для заполнения' });
            console.log(errorUserMail);
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userEmail)) {
            setErrorUserMail(eum => { return 'Неправильный формат адреса электронной почты. Пример заполнения - yourname@email.ru' });
            console.log(errorUserMail);
        } else {
            const response = savePlan(
                JSON.stringify({
                    stripes: stripes,
                    deletedStripes: deletedStripes,
                    erkers: erkers,
                    piles: piles,
                    bakes: bakes,
                }),
                userEmail
            );
            if (response) {
                setAction(ac => { return 'PlanSaved' });
            } else {
                setAction(ac => { return 'PlanErrored' });
            }
            console.log(action);
        }
        event.cancelBubble = true;
    }

    return (
        <div className={styles.main}>
            <div id='loading' className={cn(styles.loading, { [styles.hide]: !loading })}>
                <GridLoader color="#F48D70" />
            </div>
            <div className={styles.header}>
                <div>
                    <Logo />
                </div>
                <div>
                    <span>Получить консультацию</span>
                    <span>8 800 535-35-35</span>
                </div>
            </div>
            <div className={styles.calculator}>
                <div className={styles.leftside}>
                    <Scrollbar
                        alwaysShowTracks
                        style={{ marginBottom: '1rem' }}
                        plugins={{
                            overscroll: {
                                effect: "glow"
                            }
                        }}>
                        {step === 1 && (
                            <div className={styles.step}>
                                <button className={styles.type_plan_button} onClick={(e) => { setAction(ac => { return 'ChoosePlan' }) }}>
                                    <BigPlanIcon />
                                    <span>Выбрать типовой план</span>
                                </button>
                                <button className={styles.step_button_input}>
                                    <OsiX />
                                    <span>Количество<br />горизонтальных<br />осей</span>
                                    <input type='number' value={countX} min={2} step={1} onChange={(e) => handleChangeCountAxes(e.target.value, countY)} />
                                </button>
                                <button className={styles.step_button_input}>
                                    <OsiY />
                                    <span>Количество<br />вертикальных<br />осей</span>
                                    <input type='number' value={countY} min={2} step={1} onChange={(e) => handleChangeCountAxes(countX, e.target.value)} />
                                </button>
                                <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'DeleteAxe' })} onClick={(e) => setAction('DeleteAxe')}>
                                    <DeleteOs />
                                    <span>Удалить<br />ось</span>
                                </button>
                                <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'AddAxeX' })} onClick={(e) => setAction('AddAxeX')}>
                                    <AddOsX />
                                    <span>Добавить<br />ось 'Х'</span>
                                </button>
                                <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'AddAxeY' })} onClick={(e) => setAction('AddAxeY')}>
                                    <AddOsY />
                                    <span>Добавить<br />ось 'Y'</span>
                                </button>
                                <button className={styles.step_button_input}>
                                    <LentaWidth />
                                    <span>Ширина ленты,<br />мм</span>
                                    <input type='number' value={widthStripe} step={50} onChange={handleChangeWidth} />
                                </button>
                                <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'ChangeStripeWidth' })} onClick={(e) => setAction('ChangeStripeWidth')}>
                                    <LentaWidthOs />
                                    <span>Ширина ленты<br />на указанной оси</span>
                                </button>
                                {/* <button className={styles.step_button_primary}>
                            <LentaWidthOsS />
                            <span>Ширина ленты<br />со смещением</span>
                        </button> */}
                                <button className={styles.step_button_primary}>
                                    <Rotate90 />
                                    <span>Повернуть<br />план на 90<sup>о</sup></span>
                                </button>
                            </div>
                        )}
                        {step === 2 && (
                            <div className={styles.step}>
                                <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'AddPile' })} onClick={(e) => setAction(ac => { return 'AddPile' })}>
                                    <AddPile />
                                    <span>Добавить<br />сваю</span>
                                </button>
                                <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'MovePile' })} onClick={(e) => setAction(ac => { return 'MovePile' })}>
                                    <MovePile />
                                    <span>Переместить<br />сваю</span>
                                </button>
                                <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'DeletePile' })} onClick={(e) => setAction(ac => { return 'DeletePile' })}>
                                    <DeletePile />
                                    <span>Удалить<br />сваю</span>
                                </button>
                                <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'DeleteStripeSavePile' })} onClick={(e) => setAction(ac => { return 'DeleteStripeSavePile' })}>
                                    <DeleteStripeSavePile />
                                    <span>Удалить перемычку,<br />сваи оставить</span>
                                </button>
                                <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'ChangePileStep' })} onClick={(e) => setAction('ChangePileStep')}>
                                    <ChangePilesStep />
                                    <span>Изменить шаг<br />между сваями</span>
                                </button>
                                <div className={cn(styles.pile_step, { [styles.hide]: action !== 'ChangePileStep' })}>
                                    <span>
                                        Шаг между сваями
                                    </span>
                                    <input type='number' value={pileStep} min={100} step={10} onChange={(e) => setPileStep(e.target.value)} />
                                    <button className={styles.step_button_action} onClick={handleChangePileStep}>
                                        <span>Изменить</span>
                                    </button>
                                </div>
                                <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'ChangePilesStepOnStripe' })} onClick={(e) => setAction('ChangePilesStepOnStripe')}>
                                    <ChangePilesStepOnStripe />
                                    <span>Изменить шаг<br />между сваями<br />на указанной оси</span>
                                </button>
                                <div className={cn(styles.pile_step, { [styles.hide]: action !== 'ChangePilesStepOnStripe' || stripeChangePilesStep === '' })}>
                                    <span>
                                        Шаг между сваями
                                    </span>
                                    <input type='number' value={stripePileStep} min={100} step={10} onChange={(e) => setStripePileStep(e.target.value)} />
                                    <button className={styles.step_button_action} onClick={handleChangePilesStepOnStripe}>
                                        <span>Изменить</span>
                                    </button>
                                </div>
                            </div>
                        )}
                        {step === 3 && (
                            <div className={cn(styles.step, styles.calculation)}>
                                <RunningMeter />
                                <span className={styles.calculation_margin}>Количество<br />погонных метров</span>
                                <span className={styles.calculation_count}>{calculation.allSizes.toFixed(2)} м</span>
                                <span>(Все размеры<br />по краям)</span>
                                <span className={styles.calculation_count}>{calculation.crossSizes.toFixed(2)} м</span>
                                <span>(С вычетом<br />пересечений)</span>
                                <hr />
                                <PileCount />
                                <span className={styles.calculation_margin}>Количество<br />свай</span>
                                <span className={styles.calculation_count}>{calculation.pileCount.toFixed(0)}</span>
                                <hr />
                                <Square />
                                <span className={styles.calculation_margin}>Площадь</span>
                                <span className={styles.calculation_count}>{calculation.square.toFixed(2)} м<sup>2</sup></span>
                            </div>
                        )}
                    </Scrollbar>
                    {(step !== 1 || search.get('back')) && (
                        <button className={cn(styles.navigation_button, styles.navigation_button_back)} onClick={handleStepBack}>
                            <BackBtn />
                            <span>{step === 1 ? 'Вернуться на сайт' : 'Предыдущий шаг'}</span>
                        </button>
                    )}
                </div>
                <div className={styles.canvas}>
                    <div className={styles.step_title}>
                        <span>Шаг {step}:</span>
                        <div className={styles.step_info}>
                            <span>{step === 1 ? 'Чертим план фундамента' : step === 2 ? 'Работа со сваями' : step === 3 ? 'Сохраняем проект' : 'Ваш проект сохранён'}</span>
                            <span>ID: {ID.toString().padStart(12, '0')}</span>
                        </div>
                    </div>
                    <div id='canvas' className={styles.frame}>
                        <Stage width={container.width} height={container.height} ref={stageRef}>
                            <Layer>
                                <Rect
                                    x={0}
                                    y={0}
                                    width={container.width}
                                    height={container.height}
                                    fill='#F8F8F8'
                                />
                            </Layer>
                            <Layer scale={{ x: scale.value, y: scale.value }} x={scale.offsetX} y={scale.offsetY}>
                                {stripes.horizontal.map((stripe, index) => {
                                    if (!deletedStripes.includes(stripe.name)) {
                                        let right = { x: 0, y: 0 }, left = { x: 0, y: 0 };
                                        if (stripe.pointTopLeft.x > stripe.pointBottomLeft.x || stripe.pointTopLeft.x < stripe.pointBottomLeft.x) {
                                            left.x = stripe.pointLeft.x;
                                            left.y = stripe.pointLeft.y;
                                        } else if (stripe.pointTopLeft.x < stripe.pointLeft.x) {
                                            left.x = stripe.pointTopLeft.x;
                                            left.y = stripe.pointTopLeft.y;
                                        } else {
                                            left.x = stripe.pointLeft.x;
                                            left.y = stripe.pointLeft.y;
                                        }
                                        if (stripe.pointTopRight.x > stripe.pointBottomRight.x || stripe.pointTopRight.x < stripe.pointBottomRight.x) {
                                            right.x = stripe.pointRight.x;
                                            right.y = stripe.pointRight.y;
                                        } else if (stripe.pointTopRight.x > stripe.pointRight.x) {
                                            right.x = stripe.pointTopRight.x;
                                            right.y = stripe.pointTopRight.y;
                                        } else {
                                            right.x = stripe.pointRight.x;
                                            right.y = stripe.pointRight.y;
                                        }
                                        return (
                                            <Line
                                                key={index}
                                                points={[
                                                    stripe.pointTopLeft.x,
                                                    stripe.pointTopLeft.y,
                                                    stripe.pointTopRight.x,
                                                    stripe.pointTopRight.y,
                                                    right.x,
                                                    right.y,
                                                    stripe.pointBottomRight.x,
                                                    stripe.pointBottomRight.y,
                                                    stripe.pointBottomLeft.x,
                                                    stripe.pointBottomLeft.y,
                                                    left.x,
                                                    left.y,
                                                ]}
                                                closed
                                                strokeWidth={0}
                                                fill='#FFFFFF'
                                            />
                                        );
                                    }
                                })}
                                {stripes.vertical.map((stripe, index) => {
                                    if (!deletedStripes.includes(stripe.name)) {
                                        let bottom = { x: 0, y: 0 }, top = { x: 0, y: 0 };
                                        if (stripe.pointLeftTop.y > stripe.pointRightTop.y || stripe.pointLeftTop.y < stripe.pointRightTop.y) {
                                            top.x = stripe.pointTop.x;
                                            top.y = stripe.pointTop.y;
                                        } else if (stripe.pointLeftTop.y < stripe.pointTop.y) {
                                            top.x = stripe.pointLeftTop.x;
                                            top.y = stripe.pointLeftTop.y;
                                        } else {
                                            top.x = stripe.pointTop.x;
                                            top.y = stripe.pointTop.y;
                                        }
                                        if (stripe.pointLeftBottom.y > stripe.pointRightBottom.y || stripe.pointLeftBottom.y < stripe.pointRightBottom.y) {
                                            bottom.x = stripe.pointBottom.x;
                                            bottom.y = stripe.pointBottom.y;
                                        } else if (stripe.pointLeftBottom.y > stripe.pointBottom.y) {
                                            bottom.x = stripe.pointLeftBottom.x;
                                            bottom.y = stripe.pointLeftBottom.y;
                                        } else {
                                            bottom.x = stripe.pointBottom.x;
                                            bottom.y = stripe.pointBottom.y;
                                        }
                                        return (
                                            <Line
                                                key={index}
                                                points={[
                                                    stripe.pointLeftTop.x,
                                                    stripe.pointLeftTop.y,
                                                    stripe.pointLeftBottom.x,
                                                    stripe.pointLeftBottom.y,
                                                    bottom.x,
                                                    bottom.y,
                                                    stripe.pointRightBottom.x,
                                                    stripe.pointRightBottom.y,
                                                    stripe.pointRightTop.x,
                                                    stripe.pointRightTop.y,
                                                    top.x,
                                                    top.y,
                                                ]}
                                                closed
                                                strokeWidth={0}
                                                fill='#FFFFFF'
                                            />
                                        );
                                    }
                                })}
                                {stripes.horizontal.map((stripe, index) => {
                                    if (!deletedStripes.includes(stripe.name)) {
                                        return (
                                            <Fragment key={index}>
                                                {!stripe.topBakes && (
                                                    <Line
                                                        points={[stripe.pointTopLeft.x, stripe.pointTopLeft.y, stripe.pointTopRight.x, stripe.pointTopRight.y]}
                                                        strokeWidth={0.8 / scale.value}
                                                        stroke='#000000'
                                                    />
                                                )}
                                                {!stripe.bottomBakes && (<Line
                                                    points={[stripe.pointBottomLeft.x, stripe.pointBottomLeft.y, stripe.pointBottomRight.x, stripe.pointBottomRight.y]}
                                                    strokeWidth={0.8 / scale.value}
                                                    stroke='#000000'
                                                />)}
                                                {stripe.capLeft && (
                                                    <Line
                                                        points={[stripe.capLeftTop.x, stripe.capLeftTop.y, stripe.capLeftBottom.x, stripe.capLeftBottom.y]}
                                                        strokeWidth={0.8 / scale.value}
                                                        stroke='#000000'
                                                    />
                                                )}
                                                {stripe.capRight && (
                                                    <Line
                                                        points={[stripe.capRightTop.x, stripe.capRightTop.y, stripe.capRightBottom.x, stripe.capRightBottom.y]}
                                                        strokeWidth={0.8 / scale.value}
                                                        stroke='#000000'
                                                    />
                                                )}
                                            </Fragment>
                                        )
                                    } else {
                                        return (
                                            <Rect
                                                key={index}
                                                x={stripe.pointLeft.x}
                                                y={stripe.pointLeft.y - stripe.topWidth}
                                                width={stripe.length}
                                                height={stripe.width}
                                                fill='#000000'
                                                opacity={0.05}
                                            />
                                        )
                                    }
                                })}
                                {stripes.vertical.map((stripe, index) => {
                                    if (!deletedStripes.includes(stripe.name)) {
                                        return (
                                            <Fragment key={index}>
                                                {!stripe.leftBakes && (
                                                    <Line
                                                        points={[stripe.pointLeftTop.x, stripe.pointLeftTop.y, stripe.pointLeftBottom.x, stripe.pointLeftBottom.y]}
                                                        strokeWidth={0.8 / scale.value}
                                                        stroke='#000000'
                                                    />
                                                )}
                                                {!stripe.rightBakes && (
                                                    <Line
                                                        points={[stripe.pointRightTop.x, stripe.pointRightTop.y, stripe.pointRightBottom.x, stripe.pointRightBottom.y]}
                                                        strokeWidth={0.8 / scale.value}
                                                        stroke='#000000'
                                                    />
                                                )}
                                                {stripe.capTop && (
                                                    <Line
                                                        points={[stripe.capTopLeft.x, stripe.capTopLeft.y, stripe.capTopRight.x, stripe.capTopRight.y]}
                                                        strokeWidth={0.8 / scale.value}
                                                        stroke='#000000'
                                                    />
                                                )}
                                                {stripe.capBottom && (
                                                    <Line
                                                        points={[stripe.capBottomLeft.x, stripe.capBottomLeft.y, stripe.capBottomRight.x, stripe.capBottomRight.y]}
                                                        strokeWidth={0.8 / scale.value}
                                                        stroke='#000000'
                                                    />
                                                )}
                                            </Fragment>
                                        )
                                    } else {
                                        return (
                                            <Rect
                                                key={index}
                                                x={stripe.pointTop.x - stripe.leftWidth}
                                                y={stripe.pointTop.y}
                                                width={stripe.width}
                                                height={stripe.length}
                                                fill='#000000'
                                                opacity={0.05}
                                            />
                                        )
                                    }
                                })}
                                {extendedStripes.map((exStripe, index) => (
                                    <Line
                                        key={index}
                                        points={exStripe.points}
                                        strokeWidth={0.8 / scale.value}
                                        stroke='#000000'
                                    />
                                ))}
                                {stripes.corner.map((cornerStripe, index) => (
                                    <Fragment key={index}>
                                        <Line
                                            points={[cornerStripe.pointTopLeft.x, cornerStripe.pointTopLeft.y, cornerStripe.pointTopCenterLeft.x, cornerStripe.pointTopCenterLeft.y]}
                                            strokeWidth={0.8 / scale.value}
                                            stroke='#000000'
                                        />
                                        <Line
                                            points={[cornerStripe.pointTopCenterRight.x, cornerStripe.pointTopCenterRight.y, cornerStripe.pointTopRight.x, cornerStripe.pointTopRight.y]}
                                            strokeWidth={0.8 / scale.value}
                                            stroke='#000000'
                                        />
                                        <Line
                                            points={[cornerStripe.pointBottomLeft.x, cornerStripe.pointBottomLeft.y, cornerStripe.pointBottomCenterLeft.x, cornerStripe.pointBottomCenterLeft.y]}
                                            strokeWidth={0.8 / scale.value}
                                            stroke='#000000'
                                        />
                                        <Line
                                            points={[cornerStripe.pointBottomCenterRight.x, cornerStripe.pointBottomCenterRight.y, cornerStripe.pointBottomRight.x, cornerStripe.pointBottomRight.y]}
                                            strokeWidth={0.8 / scale.value}
                                            stroke='#000000'
                                        />
                                    </Fragment>
                                ))}
                                {erkers.left.filter(erker => erker.type === 'angel').map((erker, index) => (
                                    <Fragment key={index}>
                                        <Line
                                            points={[erker.pathRight.x1, erker.pathRight.y1, erker.pathRight.x2, erker.pathRight.y2, erker.pathRight.x3, erker.pathRight.y3, erker.pathRight.x4, erker.pathRight.y4]}
                                            strokeWidth={0.8 / scale.value}
                                            stroke='#000000'
                                        />
                                        <Line
                                            points={[erker.pathLeft.x1, erker.pathLeft.y1, erker.pathLeft.x2, erker.pathLeft.y2, erker.pathLeft.x3, erker.pathLeft.y3, erker.pathLeft.x4, erker.pathLeft.y4]}
                                            strokeWidth={0.8 / scale.value}
                                            stroke='#000000'
                                        />
                                        {/* <Line
                                            points={[erker.erkerPointTop.x, erker.erkerPointTop.y, erker.erkerPointLeftTop.x, erker.erkerPointLeftTop.y]}
                                            strokeWidth={0.8}
                                            stroke='#000000'
                                        />
                                        <Line
                                            points={[erker.erkerPointBottom.x, erker.erkerPointBottom.y, erker.erkerPointLeftBottom.x, erker.erkerPointLeftBottom.y]}
                                            strokeWidth={0.8}
                                            stroke='#000000'
                                        /> */}
                                    </Fragment>
                                ))}
                                {erkers.right.filter(erker => erker.type === 'angel').map((erker, index) => (
                                    <Fragment key={index}>
                                        <Line
                                            points={[erker.pathLeft.x1, erker.pathLeft.y1, erker.pathLeft.x2, erker.pathLeft.y2, erker.pathLeft.x3, erker.pathLeft.y3, erker.pathLeft.x4, erker.pathLeft.y4]}
                                            strokeWidth={0.8 / scale.value}
                                            stroke='#000000'
                                        />
                                        <Line
                                            points={[erker.pathRight.x1, erker.pathRight.y1, erker.pathRight.x2, erker.pathRight.y2, erker.pathRight.x3, erker.pathRight.y3, erker.pathRight.x4, erker.pathRight.y4]}
                                            strokeWidth={0.8 / scale.value}
                                            stroke='#000000'
                                        />
                                        {/* <Line
                                            points={[erker.erkerPointTop.x, erker.erkerPointTop.y, erker.erkerPointRightTop.x, erker.erkerPointRightTop.y]}
                                            strokeWidth={0.8}
                                            stroke='#000000'
                                        />
                                        <Line
                                            points={[erker.erkerPointBottom.x, erker.erkerPointBottom.y, erker.erkerPointRightBottom.x, erker.erkerPointRightBottom.y]}
                                            strokeWidth={0.8}
                                            stroke='#000000'
                                        /> */}
                                    </Fragment>
                                ))}
                                {erkers.top.filter(erker => erker.type === 'angel').map((erker, index) => (
                                    <Fragment key={index}>
                                        <Line
                                            points={[erker.pathTop.x1, erker.pathTop.y1, erker.pathTop.x2, erker.pathTop.y2, erker.pathTop.x3, erker.pathTop.y3, erker.pathTop.x4, erker.pathTop.y4]}
                                            strokeWidth={0.8 / scale.value}
                                            stroke='#000000'
                                        />
                                        <Line
                                            points={[erker.pathBottom.x1, erker.pathBottom.y1, erker.pathBottom.x2, erker.pathBottom.y2, erker.pathBottom.x3, erker.pathBottom.y3, erker.pathBottom.x4, erker.pathBottom.y4]}
                                            strokeWidth={0.8 / scale.value}
                                            stroke='#000000'
                                        />
                                        {/* <Line
                                            points={[erker.erkerPointLeft.x, erker.erkerPointLeft.y, erker.erkerPointTopLeft.x, erker.erkerPointTopLeft.y]}
                                            strokeWidth={0.8}
                                            stroke='#000000'
                                        />
                                        <Line
                                            points={[erker.erkerPointRight.x, erker.erkerPointRight.y, erker.erkerPointTopRight.x, erker.erkerPointTopRight.y]}
                                            strokeWidth={0.8}
                                            stroke='#000000'
                                        /> */}
                                    </Fragment>
                                ))}
                                {erkers.bottom.filter(erker => erker.type === 'angel').map((erker, index) => (
                                    <Fragment key={index}>
                                        <Line
                                            points={[erker.pathTop.x1, erker.pathTop.y1, erker.pathTop.x2, erker.pathTop.y2, erker.pathTop.x3, erker.pathTop.y3, erker.pathTop.x4, erker.pathTop.y4]}
                                            strokeWidth={0.8 / scale.value}
                                            stroke='#000000'
                                        />
                                        <Line
                                            points={[erker.pathBottom.x1, erker.pathBottom.y1, erker.pathBottom.x2, erker.pathBottom.y2, erker.pathBottom.x3, erker.pathBottom.y3, erker.pathBottom.x4, erker.pathBottom.y4]}
                                            strokeWidth={0.8 / scale.value}
                                            stroke='#000000'
                                        />
                                        {/* <Line
                                            points={[erker.erkerPointLeft.x, erker.erkerPointLeft.y, erker.erkerPointBottomLeft.x, erker.erkerPointBottomLeft.y]}
                                            strokeWidth={0.8}
                                            stroke='#000000'
                                        />
                                        <Line
                                            points={[erker.erkerPointRight.x, erker.erkerPointRight.y, erker.erkerPointBottomRight.x, erker.erkerPointBottomRight.y]}
                                            strokeWidth={0.8}
                                            stroke='#000000'
                                        /> */}
                                    </Fragment>
                                ))}
                                {erkers.left.filter(erker => erker.type === 'round').map((erker, index) => (
                                    <Fragment key={index}>
                                        <Arc
                                            x={erker.center.x}
                                            y={erker.center.y}
                                            innerRadius={erker.innerRadius}
                                            outerRadius={erker.innerRadius}
                                            angle={erker.innerAngle.top + erker.innerAngle.bottom}
                                            stroke='#000000'
                                            strokeWidth={0.4 / scale.value}
                                            rotation={180 - erker.innerAngle.top}
                                        />
                                        <Arc
                                            x={erker.center.x}
                                            y={erker.center.y}
                                            innerRadius={erker.outerRadius}
                                            outerRadius={erker.outerRadius}
                                            angle={erker.outerAngle.top + erker.outerAngle.bottom}
                                            stroke='#000000'
                                            strokeWidth={0.4 / scale.value}
                                            rotation={180 - erker.outerAngle.top}
                                        />
                                    </Fragment>
                                ))}
                                {erkers.right.filter(erker => erker.type === 'round').map((erker, index) => (
                                    <Fragment key={index}>
                                        <Arc
                                            x={erker.center.x}
                                            y={erker.center.y}
                                            innerRadius={erker.innerRadius}
                                            outerRadius={erker.innerRadius}
                                            angle={erker.innerAngle.top + erker.innerAngle.bottom}
                                            stroke='#000000'
                                            strokeWidth={0.4 / scale.value}
                                            rotation={-erker.innerAngle.top}
                                        />
                                        <Arc
                                            x={erker.center.x}
                                            y={erker.center.y}
                                            innerRadius={erker.outerRadius}
                                            outerRadius={erker.outerRadius}
                                            angle={erker.outerAngle.top + erker.outerAngle.bottom}
                                            stroke='#000000'
                                            strokeWidth={0.4 / scale.value}
                                            rotation={-erker.outerAngle.top}
                                        />
                                    </Fragment>
                                ))}
                                {erkers.top.filter(erker => erker.type === 'round').map((erker, index) => (
                                    <Fragment key={index}>
                                        <Arc
                                            x={erker.center.x}
                                            y={erker.center.y}
                                            innerRadius={erker.innerRadius}
                                            outerRadius={erker.innerRadius}
                                            angle={erker.innerAngle.left + erker.innerAngle.right}
                                            stroke='#000000'
                                            strokeWidth={0.4 / scale.value}
                                            rotation={-90 - erker.innerAngle.left}
                                        />
                                        <Arc
                                            x={erker.center.x}
                                            y={erker.center.y}
                                            innerRadius={erker.outerRadius}
                                            outerRadius={erker.outerRadius}
                                            angle={erker.outerAngle.left + erker.outerAngle.right}
                                            stroke='#000000'
                                            strokeWidth={0.4 / scale.value}
                                            rotation={-90 - erker.outerAngle.left}
                                        />
                                    </Fragment>
                                ))}
                                {erkers.bottom.filter(erker => erker.type === 'round').map((erker, index) => (
                                    <Fragment key={index}>
                                        <Arc
                                            x={erker.center.x}
                                            y={erker.center.y}
                                            innerRadius={erker.innerRadius}
                                            outerRadius={erker.innerRadius}
                                            angle={erker.innerAngle.left + erker.innerAngle.right}
                                            stroke='#000000'
                                            strokeWidth={0.4 / scale.value}
                                            rotation={90 - erker.innerAngle.left}
                                        />
                                        <Arc
                                            x={erker.center.x}
                                            y={erker.center.y}
                                            innerRadius={erker.outerRadius}
                                            outerRadius={erker.outerRadius}
                                            angle={erker.outerAngle.left + erker.outerAngle.right}
                                            stroke='#000000'
                                            strokeWidth={0.4 / scale.value}
                                            rotation={90 - erker.outerAngle.left}
                                        />
                                    </Fragment>
                                ))}
                                {bakes.map((bake, index) => (
                                    <Fragment key={index}>
                                        {(bake.top.left.x > -1000) && (
                                            <Line
                                                points={[bake.x - bake.width / 2, bake.y - bake.height / 2, bake.top.left.x, bake.top.left.y]}
                                                strokeWidth={0.8 / scale.value}
                                                stroke='#000000'
                                            />
                                        )}
                                        {(bake.top.right.x > -1000) && (
                                            <Line
                                                points={[bake.x + bake.width / 2, bake.y - bake.height / 2, bake.top.right.x, bake.top.right.y]}
                                                strokeWidth={0.8 / scale.value}
                                                stroke='#000000'
                                            />
                                        )}
                                        {(bake.bottom.left.x > -1000) && (
                                            <Line
                                                points={[bake.x - bake.width / 2, bake.y + bake.height / 2, bake.bottom.left.x, bake.bottom.left.y]}
                                                strokeWidth={0.8 / scale.value}
                                                stroke='#000000'
                                            />
                                        )}
                                        {(bake.bottom.right.x > -1000) && (
                                            <Line
                                                points={[bake.x + bake.width / 2, bake.y + bake.height / 2, bake.bottom.right.x, bake.bottom.right.y]}
                                                strokeWidth={0.8 / scale.value}
                                                stroke='#000000'
                                            />
                                        )}
                                        {(bake.left.top.y > -1000) && (
                                            <Line
                                                points={[bake.x - bake.width / 2, bake.y - bake.height / 2, bake.left.top.x, bake.left.top.y]}
                                                strokeWidth={0.8 / scale.value}
                                                stroke='#000000'
                                            />
                                        )}
                                        {(bake.left.bottom.y > -1000) && (
                                            <Line
                                                points={[bake.x - bake.width / 2, bake.y + bake.height / 2, bake.left.bottom.x, bake.left.bottom.y]}
                                                strokeWidth={0.8 / scale.value}
                                                stroke='#000000'
                                            />
                                        )}
                                        {(bake.right.top.y > -1000) && (
                                            <Line
                                                points={[bake.x + bake.width / 2, bake.y - bake.height / 2, bake.right.top.x, bake.right.top.y]}
                                                strokeWidth={0.8 / scale.value}
                                                stroke='#000000'
                                            />
                                        )}
                                        {(bake.right.bottom.y > -1000) && (
                                            <Line
                                                points={[bake.x + bake.width / 2, bake.y + bake.height / 2, bake.right.bottom.x, bake.right.bottom.y]}
                                                strokeWidth={0.8 / scale.value}
                                                stroke='#000000'
                                            />
                                        )}
                                    </Fragment>
                                ))}
                            </Layer>
                            <Layer scale={{ x: scale.value, y: scale.value }} x={scale.offsetX} y={scale.offsetY}>
                                {piles.filter(pile => !pile.deleted).map((pile, index) => (
                                    <Circle
                                        key={index}
                                        x={pile.x}
                                        y={pile.y}
                                        radius={CIRCLE_PILE_RADIUS}
                                        fill={action === 'AddCornerStripeFrom' || action === 'AddCornerStripeTo' ? '#E6E2E2' : '#F8BC49'}
                                    />
                                ))}
                                {piles.filter(pile => pile.deleted).map((pile, index) => (
                                    <Circle
                                        key={index}
                                        x={pile.x}
                                        y={pile.y}
                                        radius={CIRCLE_PILE_RADIUS}
                                        stroke='#E6E2E2'
                                        strokeWidth={2 / scale.value}
                                    />
                                ))}
                                {piles.filter(pile => pile.axeX > -1 && pile.axeY > -1).map((pile, index) => (
                                    <Fragment key={index}>
                                        <Text
                                            x={pile.x}
                                            y={pile.y}
                                            text={`${pile.axeX}_${pile.axeY}`}
                                            fontSize={TEXT_SIZE_CHAR / (1 - (1 - scale.value) / 2)}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='black'
                                        />
                                    </Fragment>
                                ))}
                            </Layer>
                            <Layer scale={{ x: scale.value, y: scale.value }} x={scale.offsetX} y={scale.offsetY}>
                                {sizes.filter(size => size.type === 'stripe' && size.orientation === 'horizontal').map((size, index) => (
                                    <Fragment key={index}>
                                        <Line
                                            points={[
                                                size.points.one.x,
                                                size.points.one.y,
                                                size.points.one.x,
                                                size.points.one.y - size.extension,
                                            ]}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                        />
                                        <Line
                                            points={[
                                                size.points.two.x,
                                                size.points.two.y,
                                                size.points.two.x,
                                                size.points.two.y - size.extension,
                                            ]}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                        />
                                        <Arrow
                                            points={[size.points.one.x, size.points.one.y - size.extension, size.points.two.x, size.points.two.y - size.extension]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={5 / scale.value}
                                            pointerWidth={5 / scale.value}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                            fill='#C5C5C5'
                                        />
                                        <Text
                                            x={size.points.one.x + size.size / 2 - 30 / scale.value}
                                            y={size.points.one.y - size.extension - 14 / scale.value}
                                            text={(size.size).toFixed(0)}
                                            width={60 / scale.value}
                                            height={14 / scale.value}
                                            fontSize={14 / scale.value}
                                            rotation={0}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#C5C5C5'
                                        />
                                    </Fragment>
                                ))}
                                {sizes.filter(size => size.type === 'stripe' && size.orientation === 'vertical').map((size, index) => (
                                    <Fragment key={index}>
                                        <Line
                                            points={[
                                                size.points.one.x,
                                                size.points.one.y,
                                                size.points.one.x - size.extension,
                                                size.points.one.y,
                                            ]}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                        />
                                        <Line
                                            points={[
                                                size.points.two.x,
                                                size.points.two.y,
                                                size.points.two.x - size.extension,
                                                size.points.two.y,
                                            ]}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                        />
                                        <Arrow
                                            points={[size.points.one.x - size.extension, size.points.one.y, size.points.two.x - size.extension, size.points.two.y]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={5 / scale.value}
                                            pointerWidth={5 / scale.value}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                            fill='#C5C5C5'
                                        />
                                        <Text
                                            x={size.points.one.x - size.extension - 14 / scale.value}
                                            y={size.points.one.y + size.size / 2 + 30 / scale.value}
                                            text={(size.size).toFixed(0)}
                                            width={60 / scale.value}
                                            height={14 / scale.value}
                                            fontSize={14 / scale.value}
                                            rotation={-90}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#C5C5C5'
                                        />
                                    </Fragment>
                                ))}
                                {sizes.filter(size => size.type === 'stripe' && size.orientation === 'corner').map((size, index) => (
                                    <Fragment key={index}>
                                        <Line
                                            points={[
                                                size.points.one.x,
                                                size.points.one.y,
                                                size.points.one.x_,
                                                size.points.one.y_,
                                            ]}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                        />
                                        <Line
                                            points={[
                                                size.points.two.x,
                                                size.points.two.y,
                                                size.points.two.x_,
                                                size.points.two.y_,
                                            ]}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                        />
                                        <Arrow
                                            points={[size.points.one.x_, size.points.one.y_, size.points.two.x_, size.points.two.y_]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={5 / scale.value}
                                            pointerWidth={5 / scale.value}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                            fill='#C5C5C5'
                                        />
                                        <Text
                                            x={size.points.text.x}
                                            y={size.points.text.y}
                                            text={(size.size).toFixed(0)}
                                            width={60 / scale.value}
                                            height={14 / scale.value}
                                            fontSize={14 / scale.value}
                                            rotation={size.rotation}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#C5C5C5'
                                        />
                                    </Fragment>
                                ))}
                                {sizes.filter(size => size.type === 'bake' && size.orientation === 'normal').map((size, index) => (
                                    <Fragment key={index}>
                                        <Arrow
                                            points={[size.points.one.x1, size.points.one.y1, size.points.one.x2, size.points.one.y2]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={5 / scale.value}
                                            pointerWidth={5 / scale.value}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                            fill='#C5C5C5'
                                        />
                                        <Arrow
                                            points={[size.points.one.x2, size.points.one.y2, size.points.one.x3, size.points.one.y3]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={5 / scale.value}
                                            pointerWidth={5 / scale.value}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                            fill='#C5C5C5'
                                        />
                                        <Arrow
                                            points={[size.points.two.x1, size.points.two.y1, size.points.two.x2, size.points.two.y2]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={5 / scale.value}
                                            pointerWidth={5 / scale.value}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                            fill='#C5C5C5'
                                        />
                                        <Arrow
                                            points={[size.points.two.x2, size.points.two.y2, size.points.two.x3, size.points.two.y3]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={5 / scale.value}
                                            pointerWidth={5 / scale.value}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                            fill='#C5C5C5'
                                        />
                                        <Text
                                            x={(size.points.one.x1 + size.points.one.x2) / 2 - 30 / scale.value}
                                            y={size.points.one.y1 - 14 / scale.value}
                                            text={(size.width).toFixed(0)}
                                            width={60 / scale.value}
                                            height={14 / scale.value}
                                            fontSize={14 / scale.value}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#C5C5C5'
                                        />
                                        <Text
                                            x={(size.points.one.x2 + size.points.one.x3) / 2 - 30 / scale.value}
                                            y={size.points.one.y1 - 14 / scale.value}
                                            text={(size.sizeX).toFixed(0)}
                                            width={60 / scale.value}
                                            height={14 / scale.value}
                                            fontSize={14 / scale.value}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#C5C5C5'
                                        />
                                        <Text
                                            x={size.points.two.x1 - 14 / scale.value}
                                            y={(size.points.two.y1 + size.points.two.y2) / 2 + 30 / scale.value}
                                            text={(size.height).toFixed(0)}
                                            width={60 / scale.value}
                                            height={14 / scale.value}
                                            fontSize={14 / scale.value}
                                            rotation={-90}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#C5C5C5'
                                        />
                                        <Text
                                            x={size.points.two.x1 - 14 / scale.value}
                                            y={(size.points.two.y2 + size.points.two.y3) / 2 + 30 / scale.value}
                                            text={(size.sizeY).toFixed(0)}
                                            width={60 / scale.value}
                                            height={14 / scale.value}
                                            fontSize={14 / scale.value}
                                            rotation={-90}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#C5C5C5'
                                        />
                                    </Fragment>
                                ))}
                                {sizes.filter(size => size.type === 'all_axes' && size.orientation === 'horizontal').map((size, index) => (
                                    <Fragment key={index}>
                                        <Line
                                            points={[
                                                size.points.one.x,
                                                size.points.one.y,
                                                size.points.one.x + size.extension,
                                                size.points.one.y,
                                            ]}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                        />
                                        <Line
                                            points={[
                                                size.points.two.x,
                                                size.points.two.y,
                                                size.points.two.x + size.extension,
                                                size.points.two.y,
                                            ]}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                        />
                                        <Arrow
                                            points={[size.points.one.x + size.extension, size.points.one.y, size.points.two.x + size.extension, size.points.two.y]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={5 / scale.value}
                                            pointerWidth={5 / scale.value}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                            fill='#C5C5C5'
                                        />
                                        <Text
                                            x={size.points.one.x + size.extension + 14 / scale.value}
                                            y={size.points.one.y + size.size / 2 - 30 / scale.value}
                                            text={(size.size).toFixed(0)}
                                            width={60 / scale.value}
                                            height={14 / scale.value}
                                            fontSize={14 / scale.value}
                                            rotation={90}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#C5C5C5'
                                        />
                                    </Fragment>
                                ))}
                                {sizes.filter(size => size.type === 'all_axes' && size.orientation === 'vertical').map((size, index) => (
                                    <Fragment key={index}>
                                        <Line
                                            points={[
                                                size.points.one.x,
                                                size.points.one.y,
                                                size.points.one.x,
                                                size.points.one.y - size.extension,
                                            ]}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                        />
                                        <Line
                                            points={[
                                                size.points.two.x,
                                                size.points.two.y,
                                                size.points.two.x,
                                                size.points.two.y - size.extension,
                                            ]}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                        />
                                        <Arrow
                                            points={[size.points.one.x, size.points.one.y - size.extension, size.points.two.x, size.points.two.y - size.extension]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={5 / scale.value}
                                            pointerWidth={5 / scale.value}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                            fill='#C5C5C5'
                                        />
                                        <Text
                                            x={size.points.one.x + size.size / 2 - 30 / scale.value}
                                            y={size.points.one.y - size.extension - 14 / scale.value}
                                            text={(size.size).toFixed(0)}
                                            width={60 / scale.value}
                                            height={14 / scale.value}
                                            fontSize={14 / scale.value}
                                            rotation={0}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#C5C5C5'
                                        />
                                    </Fragment>
                                ))}
                                {sizes.filter(size => size.type === 'axe' && size.orientation === 'horizontal').map((size, index) => (
                                    <Fragment key={index}>
                                        <Arrow
                                            points={[size.points.one.x - size.extension, size.points.one.y, size.points.two.x - size.extension, size.points.two.y]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={5 / scale.value}
                                            pointerWidth={5 / scale.value}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                            fill='#C5C5C5'
                                        />
                                        <Text
                                            x={size.points.one.x - size.extension - 14 / scale.value}
                                            y={size.points.one.y + size.size / 2 + 30 / scale.value}
                                            text={(size.size).toFixed(0)}
                                            width={60 / scale.value}
                                            height={14 / scale.value}
                                            fontSize={14 / scale.value}
                                            rotation={-90}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#C5C5C5'
                                        />
                                    </Fragment>
                                ))}
                                {sizes.filter(size => size.type === 'axe' && size.orientation === 'vertical').map((size, index) => (
                                    <Fragment key={index}>
                                        <Arrow
                                            points={[size.points.one.x, size.points.one.y + size.extension, size.points.two.x, size.points.two.y + size.extension]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={5 / scale.value}
                                            pointerWidth={5 / scale.value}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                            fill='#C5C5C5'
                                        />
                                        <Text
                                            x={size.points.one.x + size.size / 2 - 30 / scale.value}
                                            y={size.points.one.y + size.extension - 14 / scale.value}
                                            text={(size.size).toFixed(0)}
                                            width={60 / scale.value}
                                            height={14 / scale.value}
                                            fontSize={14 / scale.value}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#C5C5C5'
                                        />
                                    </Fragment>
                                ))}
                                {sizes.filter(size => size.type === 'erker' && size.orientation === 'normal').map((size, index) => (
                                    <Fragment key={index}>
                                        <Line
                                            points={[
                                                size.points.one.x,
                                                size.points.one.y,
                                                size.points.one.x_,
                                                size.points.one.y_,
                                            ]}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                        />
                                        <Line
                                            points={[
                                                size.points.two.x,
                                                size.points.two.y,
                                                size.points.two.x_,
                                                size.points.two.y_,
                                            ]}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                        />
                                        <Arrow
                                            points={[size.points.one.x_, size.points.one.y_, size.points.two.x_, size.points.two.y_]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={5 / scale.value}
                                            pointerWidth={5 / scale.value}
                                            strokeWidth={1 / scale.value}
                                            stroke='#C5C5C5'
                                            fill='#C5C5C5'
                                        />
                                        <Text
                                            x={size.points.text.x}
                                            y={size.points.text.y}
                                            text={(size.size).toFixed(0)}
                                            width={60 / scale.value}
                                            height={14 / scale.value}
                                            fontSize={14 / scale.value}
                                            rotation={size.rotation}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#C5C5C5'
                                        />
                                    </Fragment>
                                ))}
                                {axes.horizontal.map((axe, index) => (
                                    <Fragment key={index}>
                                        <Rect
                                            x={axe.x - axes.leftExtension - MARGIN_LEFT / scale.value}
                                            y={axe.y}
                                            width={axes.lengthX + axes.leftExtension + MARGIN_LEFT / scale.value}
                                            height={1 / scale.value}
                                            fill='#F59277'
                                        />
                                        <Circle
                                            x={axe.x - axes.leftExtension - (MARGIN_LEFT - CIRCLE_CHAR_RADIUS) / scale.value}
                                            y={axe.y}
                                            radius={CIRCLE_CHAR_RADIUS / scale.value}
                                            fill='#A64BB9'
                                        />
                                        <Text
                                            x={axe.x - axes.leftExtension - (MARGIN_LEFT - CIRCLE_CHAR_RADIUS) / scale.value - (TEXT_SIZE_CHAR / scale.value) / 2}
                                            y={axe.y - (TEXT_SIZE_CHAR / scale.value) / 2}
                                            text={axe.letter}
                                            fontSize={TEXT_SIZE_CHAR / scale.value}
                                            width={TEXT_SIZE_CHAR / scale.value}
                                            height={TEXT_SIZE_CHAR / scale.value}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#FFFFFF'
                                        />
                                    </Fragment>
                                ))}
                                {axes.vertical.map((axe, index) => (
                                    <Fragment key={index}>
                                        <Rect
                                            x={axe.x}
                                            y={axe.y - axes.topExtension}
                                            width={1 / scale.value}
                                            height={axes.lengthY + axes.topExtension + MARGIN_BOTTOM / scale.value}
                                            fill='#F59277'
                                        />
                                        <Circle
                                            x={axe.x}
                                            y={axe.y + axes.lengthY + (MARGIN_BOTTOM - CIRCLE_CHAR_RADIUS) / scale.value}
                                            radius={CIRCLE_CHAR_RADIUS / scale.value}
                                            fill='#A64BB9'
                                        />
                                        <Text
                                            x={axe.x - (TEXT_SIZE_CHAR / scale.value) / 2}
                                            y={axe.y + axes.lengthY + (MARGIN_BOTTOM - CIRCLE_CHAR_RADIUS) / scale.value - (TEXT_SIZE_CHAR / scale.value) / 2}
                                            text={axe.letter}
                                            fontSize={TEXT_SIZE_CHAR / scale.value}
                                            width={TEXT_SIZE_CHAR / scale.value}
                                            height={TEXT_SIZE_CHAR / scale.value}
                                            align='center'
                                            verticalAlign='middle'
                                            fill='#FFFFFF'
                                        />
                                    </Fragment>
                                ))}
                                {step === 1 && axeInputs.horizontal.map((input, index) => (
                                    <Html
                                        key={index}
                                        transformFunc={(transformAttrs) => {
                                            return {
                                                x: transformAttrs.x + input.x * scale.value - 32.8,
                                                y: transformAttrs.y + (axes.lengthY + input.y) * scale.value + MARGIN_BOTTOM - 27.6,
                                                scaleX: 1,
                                                scaleY: 1,
                                                rotation: transformAttrs.rotation,
                                                skewX: transformAttrs.skewX,
                                                skewY: transformAttrs.skewY,
                                            }
                                        }}
                                        divProps={{
                                            className: styles.axe_input,
                                            style: {
                                                position: 'absolute',
                                            },
                                        }}
                                    >
                                        <input id={`horInput_${input.index}`} value={input.tempValue} onChange={(e) => handleOnChangeAxeInputs(e, input.index, e.target.value, 'horizontal')} onKeyPress={(e) => handleOnEnterAxeInputs(e, input.index, Number(input.tempValue), 'horizontal')} onBlur={(e) => handleChangeAxeInputs(e, input.index, Number(input.tempValue), 'horizontal')} />
                                    </Html>
                                ))}
                                {step === 1 && axeInputs.vertical.map((input, index) => (
                                    <Html
                                        key={index}
                                        transformFunc={(transformAttrs) => {
                                            return {
                                                x: transformAttrs.x + (input.x - axes.leftExtension) * scale.value - MARGIN_LEFT,
                                                y: transformAttrs.y + input.y * scale.value - 13.8,
                                                scaleX: 1,
                                                scaleY: 1,
                                                rotation: transformAttrs.rotation,
                                                skewX: transformAttrs.skewX,
                                                skewY: transformAttrs.skewY,
                                            }
                                        }}
                                        divProps={{
                                            className: styles.axe_input,
                                            style: {
                                                position: 'absolute',
                                            },
                                        }}
                                    >
                                        <input id={`verInput_${input.index}`} value={input.tempValue} onChange={(e) => handleOnChangeAxeInputs(e, input.index, e.target.value, 'vertical')} onKeyPress={(e) => handleOnEnterAxeInputs(e, input.index, Number(input.tempValue), 'vertical')} onBlur={(e) => handleChangeAxeInputs(e, input.index, Number(input.tempValue), 'vertical')} />
                                    </Html>
                                ))}
                                {action === 'InputStripeWidth' && (
                                    <Fragment>
                                        {stripeForWidthAxes.direction === 'ver' && stripes.vertical.filter(stripe => stripe.name === stripeForWidth).map((stripe, index) => {
                                            return (
                                                <Rect
                                                    key={index}
                                                    x={stripe.pointTop.x - stripe.leftWidth}
                                                    y={stripe.pointTop.y}
                                                    width={stripe.width}
                                                    height={stripe.pointBottom.y - stripe.pointTop.y}
                                                    fill='#000000'
                                                    opacity={0.05}
                                                />
                                            )
                                        })}
                                        {stripeForWidthAxes.direction === 'hor' && stripes.horizontal.filter(stripe => stripe.name === stripeForWidth).map((stripe, index) => {
                                            return (
                                                <Rect
                                                    key={index}
                                                    x={stripe.pointLeft.x}
                                                    y={stripe.pointLeft.y - stripe.topWidth}
                                                    width={stripe.pointRight.x - stripe.pointLeft.x}
                                                    height={stripe.width}
                                                    fill='#000000'
                                                    opacity={0.05}
                                                />
                                            )
                                        })}
                                        {stripeForWidthAxes.direction === 'ver' && (
                                            <Arrow
                                                points={[stripeForWidthAxes.x - stripeForWidthAxes.width / 2, stripeForWidthAxes.y, stripeForWidthAxes.x + stripeForWidthAxes.width / 2, stripeForWidthAxes.y]}
                                                pointerAtBeginning={true}
                                                pointerAtEnding={true}
                                                strokeWidth={1 / scale.value}
                                                pointerLength={10 / scale.value}
                                                pointerWidth={10 / scale.value}
                                                stroke='#000000'
                                                fill='#000000'
                                            />
                                        )}
                                        {stripeForWidthAxes.direction === 'hor' && (
                                            <Arrow
                                                points={[stripeForWidthAxes.x, stripeForWidthAxes.y - stripeForWidthAxes.width / 2, stripeForWidthAxes.x, stripeForWidthAxes.y + stripeForWidthAxes.width / 2]}
                                                pointerAtBeginning={true}
                                                pointerAtEnding={true}
                                                strokeWidth={1 / scale.value}
                                                pointerLength={10 / scale.value}
                                                pointerWidth={10 / scale.value}
                                                stroke='#000000'
                                                fill='#000000'
                                            />
                                        )}
                                        <Html
                                            transformFunc={(transformAttrs) => {
                                                return {
                                                    x: scale.offsetX + stripeForWidthAxes.x * scale.value,
                                                    y: scale.offsetY + stripeForWidthAxes.y * scale.value,
                                                    scaleX: 1,
                                                    scaleY: 1,
                                                    rotation: transformAttrs.rotation,
                                                    skewX: transformAttrs.skewX,
                                                    skewY: transformAttrs.skewY,
                                                }
                                            }}
                                            divProps={{
                                                style: {
                                                    position: 'absolute',
                                                },
                                            }}
                                        >
                                            <div className={cn(styles.axe_input, { [styles.translate_input_width_hor]: stripeForWidthAxes.direction === 'hor', [styles.translate_input_width_ver]: stripeForWidthAxes.direction === 'ver' })}>
                                                <input id={`stripeWidthInput`} type='number' min={100} step={50} value={stripeForWidthValue} onChange={handleChangeStripeWidth} onBlur={handleValidateStripeWidth} />
                                                <button className={styles.step_button_action} onClick={handleUpdateStripeWidth}>ОК</button>
                                            </div>
                                        </Html>
                                    </Fragment>
                                )}
                                {action === 'ModifyPile' && (
                                    <Html
                                        transformFunc={(transformAttrs) => {
                                            return {
                                                x: scale.offsetX + addPileAxes.x * scale.value,
                                                y: scale.offsetY + addPileAxes.y * scale.value,
                                                scaleX: 1,
                                                scaleY: 1,
                                                rotation: transformAttrs.rotation,
                                                skewX: transformAttrs.skewX,
                                                skewY: transformAttrs.skewY,
                                            }
                                        }}
                                        divProps={{
                                            style: {
                                                position: 'absolute',
                                            },
                                        }}
                                    >
                                        <div className={cn(styles.axe_input, { [styles.translate_input_width_hor]: addPileAxes.axeX > -1, [styles.translate_input_width_ver]: addPileAxes.axeY > -1 })}>
                                            <input id={`pilePosition`} type='number' min={100} step={50} value={modifyPileSize} onChange={handleChangeModifyPileSize} />
                                            <button className={styles.step_button_action} onClick={handleUpdateModifyPileSize}>ОК</button>
                                        </div>
                                    </Html>
                                )}
                                {(action === 'ChangePilesStepOnStripe' && stripeChangePilesStep !== '' && stripeChangePilesStep.startsWith('hor') && !stripeChangePilesStep.includes(':')) &&
                                    stripes.horizontal.filter(stripe => stripe.name === stripeChangePilesStep).map((stripe, index) => {
                                        return (
                                            <Rect
                                                key={index}
                                                x={stripe.pointLeft.x}
                                                y={stripe.pointLeft.y - stripe.topWidth}
                                                width={stripe.pointRight.x - stripe.pointLeft.x}
                                                height={stripe.width}
                                                fill='#000000'
                                                opacity={0.1}
                                            />
                                        )
                                    })}
                                {(action === 'ChangePilesStepOnStripe' && stripeChangePilesStep !== '' && stripeChangePilesStep.startsWith('ver') && !stripeChangePilesStep.includes(':')) &&
                                    stripes.vertical.filter(stripe => stripe.name === stripeChangePilesStep).map((stripe, index) => {
                                        return (
                                            <Rect
                                                key={index}
                                                x={stripe.pointTop.x - stripe.leftWidth}
                                                y={stripe.pointTop.y}
                                                width={stripe.width}
                                                height={stripe.pointBottom.y - stripe.pointTop.y}
                                                fill='#000000'
                                                opacity={0.1}
                                            />
                                        )
                                    })}
                                {/* <Line
                                    points={calculation.perimeter.map(point => { return [point.x, point.y] }).flat()}
                                    strokeWidth={4 / scale.value}
                                    stroke='#000000'
                                /> */}
                            </Layer>
                            <Layer scale={{ x: scale.value, y: scale.value }} x={scale.offsetX} y={scale.offsetY}>
                                {(action === 'AddDeleteStripe' || action === 'ChangeStripeWidth' || action === 'DeleteStripeSavePile' || action === 'ChangePilesStepOnStripe') && (
                                    stripes.horizontal.map((stripe, index) => (
                                        <Fragment key={index}>
                                            <Text
                                                text={stripe.name}
                                                x={(stripe.pointLeft.x + (stripe.length / 2))}
                                                y={stripe.pointLeft.y}
                                                fontSize={TEXT_SIZE_CHAR / scale.value}
                                            />
                                            <Rect
                                                id={stripe.name}
                                                x={stripe.pointLeft.x}
                                                y={stripe.pointLeft.y - stripe.topWidth}
                                                width={stripe.pointRight.x - stripe.pointLeft.x}
                                                height={stripe.width}
                                                fill='#000000'
                                                opacity={stripe.name === stripeSelection ? 0.05 : 0}
                                                onMouseEnter={handleStripeSelection}
                                                onMouseLeave={handleStripeSelection}
                                                onClick={handleStripeAddDelete}
                                            />
                                        </Fragment>
                                    ))
                                )}
                                {(action === 'AddDeleteStripe' || action === 'ChangeStripeWidth' || action === 'DeleteStripeSavePile' || action === 'ChangePilesStepOnStripe') && (
                                    stripes.vertical.map((stripe, index) => (
                                        <Fragment key={index}>
                                            <Text
                                                text={stripe.name}
                                                x={stripe.pointTop.x}
                                                y={(stripe.pointTop.y + (stripe.length / 2))}
                                                fontSize={TEXT_SIZE_CHAR / scale.value}
                                            />
                                            <Rect
                                                id={stripe.name}
                                                x={stripe.pointTop.x - stripe.leftWidth}
                                                y={stripe.pointTop.y}
                                                width={stripe.width}
                                                height={stripe.pointBottom.y - stripe.pointTop.y}
                                                fill='#000000'
                                                opacity={stripe.name === stripeSelection ? 0.05 : 0}
                                                onMouseEnter={handleStripeSelection}
                                                onMouseLeave={handleStripeSelection}
                                                onClick={handleStripeAddDelete}
                                            />
                                        </Fragment>
                                    ))
                                )}
                                {(action === 'AddDeleteStripe' || action === 'DeleteStripeSavePile' || action === 'ChangePilesStepOnStripe') && (
                                    stripes.corner.map((cornerStripe, index) => (
                                        <Fragment key={index}>
                                            <Line
                                                id={cornerStripe.name}
                                                points={[
                                                    cornerStripe.pointTopLeft.x,
                                                    cornerStripe.pointTopLeft.y,
                                                    cornerStripe.pointTopRight.x,
                                                    cornerStripe.pointTopRight.y,
                                                    cornerStripe.pointBottomRight.x,
                                                    cornerStripe.pointBottomRight.y,
                                                    cornerStripe.pointBottomLeft.x,
                                                    cornerStripe.pointBottomLeft.y
                                                ]}
                                                closed={true}
                                                fill='#000000'
                                                opacity={cornerStripe.name === stripeSelection ? 0.05 : 0}
                                                onMouseEnter={handleStripeSelection}
                                                onMouseLeave={handleStripeSelection}
                                                onClick={handleCornerStripeDelete}
                                            />
                                        </Fragment>
                                    ))
                                )}
                                {action === 'DeleteAxe' && axes.horizontal.map((axe, index) => axe.index >= 0 && (
                                    <Fragment key={index}>
                                        <DeleteCircle
                                            name={axe.name}
                                            x={axe.x - axes.leftExtension - MARGIN_LEFT / (1 - (1 - scale.value) / 2) + CIRCLE_CHAR_RADIUS / (1 - (1 - scale.value) / 2)}
                                            y={axe.y}
                                            index={axe.index}
                                            type={"horizontal"}
                                        />
                                    </Fragment>
                                ))}
                                {action === 'DeleteAxe' && axes.vertical.map((axe, index) => axe.index >= 0 && (
                                    <Fragment key={index}>
                                        <DeleteCircle
                                            name={axe.name}
                                            x={axe.x}
                                            y={axe.y + axes.lengthY + MARGIN_BOTTOM / (1 - (1 - scale.value) / 2) - CIRCLE_CHAR_RADIUS / (1 - (1 - scale.value) / 2)}
                                            index={axe.index}
                                            type={"vertical"}
                                        />
                                    </Fragment>
                                ))}
                                {(action === 'AddAngelErker' || action === 'AddRoundErker') && (
                                    <Fragment>
                                        <Rect
                                            x={- scale.offsetX / scale.value}
                                            y={- scale.offsetY / scale.value}
                                            width={container.width / scale.value}
                                            height={container.height / scale.value}
                                            fill='#000000'
                                            opacity={0.2}
                                        />
                                        <Text
                                            text='Выберите сторону для эркера'
                                            x={axes.lengthX / 2 - 70 / scale.value - axes.offsetX}
                                            y={axes.lengthY / 2 - 70 / scale.value - axes.offsetY}
                                            width={140 / scale.value}
                                            height={140 / scale.value}
                                            fill='#000000'
                                            align='center'
                                            verticalAlign='middle'
                                            fontSize={16 / scale.value}
                                            fontStyle='bold'
                                        />
                                        <SelectErkerCircle
                                            name='topErker'
                                            x={axes.lengthX / 2 - axes.offsetX}
                                            y={-axes.offsetY}
                                            rotation={-90}
                                        />
                                        <SelectErkerCircle
                                            name='bottomErker'
                                            x={axes.lengthX / 2 - axes.offsetX}
                                            y={axes.lengthY - axes.offsetY}
                                            rotation={90}
                                        />
                                        <SelectErkerCircle
                                            name='leftErker'
                                            x={-axes.offsetX}
                                            y={axes.lengthY / 2 - axes.offsetY}
                                            rotation={180}
                                        />
                                        <SelectErkerCircle
                                            name='rightErker'
                                            x={axes.lengthX - axes.offsetX}
                                            y={axes.lengthY / 2 - axes.offsetY}
                                        />
                                    </Fragment>
                                )}
                                {action === 'DeleteErker' && erkers.left.filter(erker => erker.type === 'angel').map((erker, index) => (
                                    <Line
                                        key={index}
                                        id={`${erker.type}_${erker.direction}_${erker.name}`}
                                        points={[
                                            erker.pathLeft.x1,
                                            erker.pathLeft.y1,
                                            erker.pathLeft.x2,
                                            erker.pathLeft.y2,
                                            erker.pathLeft.x3,
                                            erker.pathLeft.y3,
                                            erker.pathLeft.x4,
                                            erker.pathLeft.y4,
                                            erker.pathRight.x4,
                                            erker.pathRight.y4,
                                            erker.pathRight.x3,
                                            erker.pathRight.y3,
                                            erker.pathRight.x2,
                                            erker.pathRight.y2,
                                            erker.pathRight.x1,
                                            erker.pathRight.y1,
                                        ]}
                                        closed={true}
                                        fill='#000000'
                                        opacity={erkerSelection === `${erker.type}_${erker.direction}_${erker.name}` ? 0.05 : 0}
                                        onMouseEnter={handleErkerSelection}
                                        onMouseLeave={handleErkerSelection}
                                        onClick={(e) => handleDeleteErker(e, erker)}
                                    />
                                ))}
                                {action === 'DeleteErker' && erkers.right.filter(erker => erker.type === 'angel').map((erker, index) => (
                                    <Line
                                        key={index}
                                        id={`${erker.type}_${erker.direction}_${erker.name}`}
                                        points={[
                                            erker.pathLeft.x1,
                                            erker.pathLeft.y1,
                                            erker.pathLeft.x2,
                                            erker.pathLeft.y2,
                                            erker.pathLeft.x3,
                                            erker.pathLeft.y3,
                                            erker.pathLeft.x4,
                                            erker.pathLeft.y4,
                                            erker.pathRight.x4,
                                            erker.pathRight.y4,
                                            erker.pathRight.x3,
                                            erker.pathRight.y3,
                                            erker.pathRight.x2,
                                            erker.pathRight.y2,
                                            erker.pathRight.x1,
                                            erker.pathRight.y1,
                                        ]}
                                        closed={true}
                                        fill='#000000'
                                        opacity={erkerSelection === `${erker.type}_${erker.direction}_${erker.name}` ? 0.05 : 0}
                                        onMouseEnter={handleErkerSelection}
                                        onMouseLeave={handleErkerSelection}
                                        onClick={(e) => handleDeleteErker(e, erker)}
                                    />
                                ))}
                                {action === 'DeletePile' && piles.filter(pile => !pile.deleted).map((pile, index) => (
                                    <Fragment key={index}>
                                        <DeletePileCircle
                                            x={pile.x}
                                            y={pile.y}
                                        />
                                    </Fragment>
                                ))}
                                {action === 'DeletePile' && piles.filter(pile => pile.deleted).map((pile, index) => (
                                    <Fragment key={index}>
                                        <ShowPileCircle
                                            x={pile.x}
                                            y={pile.y}
                                        />
                                    </Fragment>
                                ))}
                                {action === 'MovePile' && piles.filter(pile => !pile.deleted).map((pile, index) => (
                                    <Group
                                        id={`move_${pile.x}_${pile.y}`}
                                        onMouseEnter={handlePileSelection}
                                        onMouseLeave={handlePileSelection}
                                        onClick={handlePileMove}
                                        key={index}
                                    >
                                        <Circle
                                            x={pile.x}
                                            y={pile.y}
                                            radius={CIRCLE_PILE_RADIUS}
                                        //fill='#FFFFFF'
                                        //opacity={`move_${x}_${y}` === pileSelection ? 1 : 0}
                                        />
                                        <Arrow
                                            points={[pile.x, pile.y - CIRCLE_PILE_RADIUS, pile.x, pile.y + CIRCLE_PILE_RADIUS]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={4 / scale.value}
                                            pointerWidth={4 / scale.value}
                                            strokeWidth={2 / scale.value}
                                            stroke='#000000'
                                            fill='#000000'
                                            opacity={`move_${pile.x}_${pile.y}` === pileSelection ? 1 : 0}
                                        />
                                        <Arrow
                                            points={[pile.x - CIRCLE_PILE_RADIUS, pile.y, pile.x + CIRCLE_PILE_RADIUS, pile.y]}
                                            pointerAtBeginning={true}
                                            pointerAtEnding={true}
                                            pointerLength={4 / scale.value}
                                            pointerWidth={4 / scale.value}
                                            strokeWidth={2 / scale.value}
                                            stroke='#000000'
                                            fill='#000000'
                                            opacity={`move_${pile.x}_${pile.y}` === pileSelection ? 1 : 0}
                                        />
                                    </Group>
                                ))}
                                {action === 'DeleteErker' && erkers.top.filter(erker => erker.type === 'angel').map((erker, index) => (
                                    <Line
                                        key={index}
                                        id={`${erker.type}_${erker.direction}_${erker.name}`}
                                        points={[
                                            erker.pathTop.x1,
                                            erker.pathTop.y1,
                                            erker.pathTop.x2,
                                            erker.pathTop.y2,
                                            erker.pathTop.x3,
                                            erker.pathTop.y3,
                                            erker.pathTop.x4,
                                            erker.pathTop.y4,
                                            erker.pathBottom.x4,
                                            erker.pathBottom.y4,
                                            erker.pathBottom.x3,
                                            erker.pathBottom.y3,
                                            erker.pathBottom.x2,
                                            erker.pathBottom.y2,
                                            erker.pathBottom.x1,
                                            erker.pathBottom.y1,
                                        ]}
                                        closed={true}
                                        fill='#000000'
                                        opacity={erkerSelection === `${erker.type}_${erker.direction}_${erker.name}` ? 0.05 : 0}
                                        onMouseEnter={handleErkerSelection}
                                        onMouseLeave={handleErkerSelection}
                                        onClick={(e) => handleDeleteErker(e, erker)}
                                    />
                                ))}
                                {action === 'DeleteErker' && erkers.bottom.filter(erker => erker.type === 'angel').map((erker, index) => (
                                    <Line
                                        key={index}
                                        id={`${erker.type}_${erker.direction}_${erker.name}`}
                                        points={[
                                            erker.pathTop.x1,
                                            erker.pathTop.y1,
                                            erker.pathTop.x2,
                                            erker.pathTop.y2,
                                            erker.pathTop.x3,
                                            erker.pathTop.y3,
                                            erker.pathTop.x4,
                                            erker.pathTop.y4,
                                            erker.pathBottom.x4,
                                            erker.pathBottom.y4,
                                            erker.pathBottom.x3,
                                            erker.pathBottom.y3,
                                            erker.pathBottom.x2,
                                            erker.pathBottom.y2,
                                            erker.pathBottom.x1,
                                            erker.pathBottom.y1,
                                        ]}
                                        closed={true}
                                        fill='#000000'
                                        opacity={erkerSelection === `${erker.type}_${erker.direction}_${erker.name}` ? 0.05 : 0}
                                        onMouseEnter={handleErkerSelection}
                                        onMouseLeave={handleErkerSelection}
                                        onClick={(e) => handleDeleteErker(e, erker)}
                                    />
                                ))}
                                {action === 'DeleteErker' && erkers.left.filter(erker => erker.type === 'round').map((erker, index) => (
                                    <Arc
                                        key={index}
                                        id={`${erker.type}_${erker.direction}_${erker.name}`}
                                        x={erker.center.x}
                                        y={erker.center.y}
                                        innerRadius={erker.innerRadius}
                                        outerRadius={erker.outerRadius}
                                        angle={erker.angle * 2}
                                        fill='#000000'
                                        opacity={erkerSelection === `${erker.type}_${erker.direction}_${erker.name}` ? 0.05 : 0}
                                        rotation={180 - erker.angle}
                                        onMouseEnter={handleErkerSelection}
                                        onMouseLeave={handleErkerSelection}
                                        onClick={(e) => handleDeleteErker(e, erker)}
                                    />
                                ))}
                                {action === 'DeleteErker' && erkers.right.filter(erker => erker.type === 'round').map((erker, index) => (
                                    <Arc
                                        key={index}
                                        id={`${erker.type}_${erker.direction}_${erker.name}`}
                                        x={erker.center.x}
                                        y={erker.center.y}
                                        innerRadius={erker.innerRadius}
                                        outerRadius={erker.outerRadius}
                                        angle={erker.angle * 2}
                                        fill='#000000'
                                        opacity={erkerSelection === `${erker.type}_${erker.direction}_${erker.name}` ? 0.05 : 0}
                                        rotation={-erker.angle}
                                        onMouseEnter={handleErkerSelection}
                                        onMouseLeave={handleErkerSelection}
                                        onClick={(e) => handleDeleteErker(e, erker)}
                                    />
                                ))}
                                {action === 'DeleteErker' && erkers.top.filter(erker => erker.type === 'round').map((erker, index) => (
                                    <Arc
                                        key={index}
                                        id={`${erker.type}_${erker.direction}_${erker.name}`}
                                        x={erker.center.x}
                                        y={erker.center.y}
                                        innerRadius={erker.innerRadius}
                                        outerRadius={erker.outerRadius}
                                        angle={erker.angle * 2}
                                        fill='#000000'
                                        opacity={erkerSelection === `${erker.type}_${erker.direction}_${erker.name}` ? 0.05 : 0}
                                        rotation={-90 - erker.angle}
                                        onMouseEnter={handleErkerSelection}
                                        onMouseLeave={handleErkerSelection}
                                        onClick={(e) => handleDeleteErker(e, erker)}
                                    />
                                ))}
                                {action === 'DeleteErker' && erkers.bottom.filter(erker => erker.type === 'round').map((erker, index) => (
                                    <Arc
                                        key={index}
                                        id={`${erker.type}_${erker.direction}_${erker.name}`}
                                        x={erker.center.x}
                                        y={erker.center.y}
                                        innerRadius={erker.innerRadius}
                                        outerRadius={erker.outerRadius}
                                        angle={erker.angle * 2}
                                        fill='#000000'
                                        opacity={erkerSelection === `${erker.type}_${erker.direction}_${erker.name}` ? 0.05 : 0}
                                        rotation={90 - erker.angle}
                                        onMouseEnter={handleErkerSelection}
                                        onMouseLeave={handleErkerSelection}
                                        onClick={(e) => handleDeleteErker(e, erker)}
                                    />
                                ))}
                                {action === 'DeleteBake' && bakes.map((bake, index) => (
                                    <Fragment key={index}>
                                        <Rect
                                            id={`${bake.x}_${bake.y}`}
                                            x={bake.x - bake.width / 2}
                                            y={bake.y - bake.height / 2}
                                            width={bake.width}
                                            height={bake.height}
                                            fill='#000000'
                                            opacity={bakeSelection === `${bake.x}_${bake.y}` ? 0.05 : 0}
                                            onMouseEnter={handleBakeSelection}
                                            onMouseLeave={handleBakeSelection}
                                            onClick={handleDeleteBake}
                                        />
                                    </Fragment>
                                ))}
                                {action === 'AddCornerStripeFrom' && piles.filter(pile => (pile.axeX > -1 && !pile.extended)).map((pile, index) => (
                                    <Group
                                        key={index}
                                        id={`${pile.axeX}_${pile.axeY}`}
                                        onClick={(e) => handleSelectCornerStripeFrom(e, pile)}
                                    >
                                        <Circle
                                            x={pile.x}
                                            y={pile.y}
                                            radius={CIRCLE_PILE_RADIUS}
                                            stroke='#F48D70'
                                            strokeWidth={4 / scale.value}
                                        />
                                        <Circle
                                            x={pile.x}
                                            y={pile.y}
                                            radius={CIRCLE_PILE_RADIUS - 6 / scale.value}
                                            fill='#F48D70'
                                        />
                                    </Group>
                                ))}
                                {action === 'AddCornerStripeTo' && addCornerStripeFrom && (
                                    <Circle
                                        x={addCornerStripeFrom.coords.x}
                                        y={addCornerStripeFrom.coords.y}
                                        radius={CIRCLE_PILE_RADIUS}
                                        stroke='#F48D70'
                                        strokeWidth={4 / scale.value}
                                        fill='#E6E2E2'
                                    />
                                )}
                                {action === 'AddCornerStripeTo' && piles.filter(pile => (pile.axeX > -1 && !pile.extended) && `${pile.axeX}_${pile.axeY}` !== `${addCornerStripeFrom.x}_${addCornerStripeFrom.y}`).map((pile, index) => (
                                    <Group
                                        key={index}
                                        id={`${pile.axeX}_${pile.axeY}`}
                                        onClick={(e) => handleSelectCornerStripeTo(e, pile)}
                                    >
                                        <Circle
                                            x={pile.x}
                                            y={pile.y}
                                            radius={CIRCLE_PILE_RADIUS}
                                            stroke='#F48D70'
                                            strokeWidth={4 / scale.value}
                                        />
                                        <Circle
                                            x={pile.x}
                                            y={pile.y}
                                            radius={CIRCLE_PILE_RADIUS - 6 / scale.value}
                                            fill='#F48D70'
                                        />
                                    </Group>
                                ))}
                            </Layer>
                            <Layer onMouseMove={handleMouseMovingInAction} onMouseLeave={handleMouseLeaveInAction} onClick={handleMouseClickInAction}>
                                {action === 'AddPile' && (
                                    <Fragment>
                                        <Rect
                                            x={0}
                                            y={0}
                                            width={container.width}
                                            height={container.height}
                                            fill='#000000'
                                            opacity={0}
                                        />
                                        <Group>
                                            <Circle
                                                x={(addPileAxes.x * scale.value) + scale.offsetX}
                                                y={(addPileAxes.y * scale.value) + scale.offsetY}
                                                radius={CIRCLE_PILE_RADIUS * scale.value}
                                                stroke={addPileAxes.enable ? '#F48D70' : '#E6E2E2'}
                                                strokeWidth={4}
                                            />
                                            <Circle
                                                x={(addPileAxes.x * scale.value) + scale.offsetX}
                                                y={(addPileAxes.y * scale.value) + scale.offsetY}
                                                radius={CIRCLE_PILE_RADIUS * scale.value - 6}
                                                fill={addPileAxes.enable ? '#F48D70' : '#E6E2E2'}
                                            />
                                        </Group>
                                    </Fragment>
                                )}
                                {(action === 'AddBakeMoving' || action === 'AddStaticBakeMoving') && (
                                    <Fragment>
                                        <Rect
                                            x={0}
                                            y={0}
                                            width={container.width}
                                            height={container.height}
                                            fill='#000000'
                                            opacity={0}
                                        />
                                        <Rect
                                            x={((addBakeAxes.x - bakeSize.width / 2) * scale.value) + scale.offsetX}
                                            y={((addBakeAxes.y - bakeSize.height / 2) * scale.value) + scale.offsetY}
                                            width={bakeSize.width * scale.value}
                                            height={bakeSize.height * scale.value}
                                            fill={addBakeAxes.enable ? '#F48D70' : '#E6E2E2'}
                                        />
                                        {addBakeAxes.top.offset > 0 && (
                                            <Fragment>
                                                <Arrow
                                                    points={[
                                                        ((addBakeAxes.x) * scale.value) + scale.offsetX,
                                                        ((addBakeAxes.y - bakeSize.height / 2) * scale.value) + scale.offsetY,
                                                        ((addBakeAxes.x) * scale.value) + scale.offsetX,
                                                        ((addBakeAxes.y - bakeSize.height / 2 - addBakeAxes.top.offset) * scale.value) + scale.offsetY,
                                                    ]}
                                                    pointerAtBeginning={true}
                                                    pointerAtEnding={true}
                                                    pointerLength={4}
                                                    pointerWidth={4}
                                                    strokeWidth={1}
                                                    stroke='#C5C5C5'
                                                    fill='#C5C5C5'
                                                />
                                                <Text
                                                    x={((addBakeAxes.x) * scale.value) + scale.offsetX + 10}
                                                    y={((addBakeAxes.y - bakeSize.height / 2 - addBakeAxes.top.offset / 2) * scale.value) + scale.offsetY - 30}
                                                    text={(addBakeAxes.top.offset).toFixed(2)}
                                                    width={60}
                                                    height={10}
                                                    fontSize={10}
                                                    align='center'
                                                    verticalAlign='middle'
                                                    fill='#C5C5C5'
                                                    rotation={90}
                                                />
                                            </Fragment>
                                        )}
                                        {addBakeAxes.bottom.offset > 0 && (
                                            <Fragment>
                                                <Arrow
                                                    points={[
                                                        ((addBakeAxes.x) * scale.value) + scale.offsetX,
                                                        ((addBakeAxes.y + bakeSize.height / 2) * scale.value) + scale.offsetY,
                                                        ((addBakeAxes.x) * scale.value) + scale.offsetX,
                                                        ((addBakeAxes.y + bakeSize.height / 2 + addBakeAxes.bottom.offset) * scale.value) + scale.offsetY,
                                                    ]}
                                                    pointerAtBeginning={true}
                                                    pointerAtEnding={true}
                                                    pointerLength={4}
                                                    pointerWidth={4}
                                                    strokeWidth={1}
                                                    stroke='#C5C5C5'
                                                    fill='#C5C5C5'
                                                />
                                                <Text
                                                    x={((addBakeAxes.x) * scale.value) + scale.offsetX + 10}
                                                    y={((addBakeAxes.y + bakeSize.height / 2 + addBakeAxes.bottom.offset / 2) * scale.value) + scale.offsetY - 30}
                                                    text={(addBakeAxes.bottom.offset).toFixed(2)}
                                                    width={60}
                                                    height={10}
                                                    fontSize={10}
                                                    align='center'
                                                    verticalAlign='middle'
                                                    fill='#C5C5C5'
                                                    rotation={90}
                                                />
                                            </Fragment>
                                        )}
                                        {addBakeAxes.left.offset > 0 && (
                                            <Fragment>
                                                <Arrow
                                                    points={[
                                                        ((addBakeAxes.x - bakeSize.width / 2) * scale.value) + scale.offsetX,
                                                        ((addBakeAxes.y) * scale.value) + scale.offsetY,
                                                        ((addBakeAxes.x - bakeSize.width / 2 - addBakeAxes.left.offset) * scale.value) + scale.offsetX,
                                                        ((addBakeAxes.y) * scale.value) + scale.offsetY,
                                                    ]}
                                                    pointerAtBeginning={true}
                                                    pointerAtEnding={true}
                                                    pointerLength={4}
                                                    pointerWidth={4}
                                                    strokeWidth={1}
                                                    stroke='#C5C5C5'
                                                    fill='#C5C5C5'
                                                />
                                                <Text
                                                    x={((addBakeAxes.x - bakeSize.width / 2 - addBakeAxes.left.offset / 2) * scale.value) + scale.offsetX - 30}
                                                    y={((addBakeAxes.y) * scale.value) + scale.offsetY - 10}
                                                    text={(addBakeAxes.left.offset).toFixed(2)}
                                                    width={60}
                                                    height={10}
                                                    fontSize={10}
                                                    align='center'
                                                    verticalAlign='middle'
                                                    fill='#C5C5C5'
                                                />
                                            </Fragment>
                                        )}
                                        {addBakeAxes.right.offset > 0 && (
                                            <Fragment>
                                                <Arrow
                                                    points={[
                                                        ((addBakeAxes.x + bakeSize.width / 2) * scale.value) + scale.offsetX,
                                                        ((addBakeAxes.y) * scale.value) + scale.offsetY,
                                                        ((addBakeAxes.x + bakeSize.width / 2 + addBakeAxes.right.offset) * scale.value) + scale.offsetX,
                                                        ((addBakeAxes.y) * scale.value) + scale.offsetY,
                                                    ]}
                                                    pointerAtBeginning={true}
                                                    pointerAtEnding={true}
                                                    pointerLength={4}
                                                    pointerWidth={4}
                                                    strokeWidth={1}
                                                    stroke='#C5C5C5'
                                                    fill='#C5C5C5'
                                                />
                                                <Text
                                                    x={((addBakeAxes.x + bakeSize.width / 2 + addBakeAxes.right.offset / 2) * scale.value) + scale.offsetX - 30}
                                                    y={((addBakeAxes.y) * scale.value) + scale.offsetY - 10}
                                                    text={(addBakeAxes.right.offset).toFixed(2)}
                                                    width={60}
                                                    height={10}
                                                    fontSize={10}
                                                    align='center'
                                                    verticalAlign='middle'
                                                    fill='#C5C5C5'
                                                />
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                            </Layer>
                        </Stage>
                    </div>
                </div>
                <div className={styles.rightside}>
                    {step === 1 && (
                        <div className={cn(styles.step)}>
                            <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'AddDeleteStripe' })} onClick={(e) => setAction('AddDeleteStripe')}>
                                <AddLenta />
                                <span>Добавить\удалить<br />перемычку</span>
                            </button>
                            <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'AddCornerStripeFrom' || action === 'AddCornerStripeTo' })} onClick={(e) => setAction('AddCornerStripeFrom')}>
                                <AddLentaN />
                                <span>Добавить перемычку<br />под углом</span>
                            </button>
                            <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'AddAngelErker' || action === 'ConstructAngelErker' })} onClick={(e) => setAction('AddAngelErker')}>
                                <AddErker />
                                <span>Добавить<br />угловой эркер</span>
                            </button>
                            <div className={cn(styles.add_erker, { [styles.hide]: action !== 'ConstructAngelErker' })}>
                                <span>
                                    Выберите между каких<br />осей установить эркер
                                </span>
                                {(erkerSide === 'leftErker' || erkerSide === 'rightErker') && (
                                    <Fragment>
                                        <select value={erkerAxes.top} onChange={(e) => setErkerAxes({
                                            top: Number(e.target.value),
                                            bottom: erkerAxes.bottom,
                                            left: erkerAxes.left,
                                            right: erkerAxes.right,
                                        })}>
                                            {axes.horizontal.filter(axe => axe.index > -1).reverse().map((axe, index) => (
                                                <option key={index} value={axe.index}>{axe.letter}</option>
                                            ))}
                                        </select>
                                        <select value={erkerAxes.bottom} onChange={(e) => setErkerAxes({
                                            top: erkerAxes.top,
                                            bottom: Number(e.target.value),
                                            left: erkerAxes.left,
                                            right: erkerAxes.right,
                                        })}>
                                            {axes.horizontal.filter(axe => axe.index > -1).map((axe, index) => (
                                                <option key={index} value={axe.index}>{axe.letter}</option>
                                            ))}
                                        </select>
                                    </Fragment>
                                )}
                                {(erkerSide === 'topErker' || erkerSide === 'bottomErker') && (
                                    <Fragment>
                                        <select value={erkerAxes.left} onChange={(e) => setErkerAxes({
                                            top: erkerAxes.top,
                                            bottom: erkerAxes.bottom,
                                            left: Number(e.target.value),
                                            right: erkerAxes.right,
                                        })}>
                                            {axes.vertical.filter(axe => axe.index > -1).map((axe, index) => (
                                                <option key={index} value={axe.index}>{axe.letter}</option>
                                            ))}
                                        </select>
                                        <select value={erkerAxes.right} onChange={(e) => setErkerAxes({
                                            top: erkerAxes.top,
                                            bottom: erkerAxes.bottom,
                                            left: erkerAxes.left,
                                            right: Number(e.target.value),
                                        })}>
                                            {axes.vertical.filter(axe => axe.index > -1).reverse().map((axe, index) => (
                                                <option key={index} value={axe.index}>{axe.letter}</option>
                                            ))}
                                        </select>
                                    </Fragment>
                                )}
                                <span>
                                    Размер выноса эркера
                                </span>
                                <input type='number' value={erkerExtension} min={1000} step={10} onChange={(e) => setErkerExtension(e.target.value)} />
                                <span>
                                    Размер эркера
                                </span>
                                <input type='number' value={erkerSize} min={1000} step={10} onChange={(e) => setErkerSize(e.target.value)} />
                                <button className={styles.step_button_action} onClick={handleAddErker}>
                                    <span>Добавить</span>
                                </button>
                            </div>
                            <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'AddRoundErker' })} onClick={(e) => setAction('AddRoundErker')}>
                                <AddErkerK />
                                <span>Добавить<br />круглый эркер</span>
                            </button>
                            <div className={cn(styles.add_erker, { [styles.hide]: action !== 'ConstructRoundErker' })}>
                                <span>
                                    Выберите между каких<br />осей установить эркер
                                </span>
                                {(erkerSide === 'leftErker' || erkerSide === 'rightErker') && (
                                    <Fragment>
                                        <select value={erkerAxes.top} onChange={(e) => setErkerAxes({
                                            top: Number(e.target.value),
                                            bottom: erkerAxes.bottom,
                                            left: erkerAxes.left,
                                            right: erkerAxes.right,
                                        })}>
                                            {axes.horizontal.filter(axe => axe.index > -1).reverse().map((axe, index) => (
                                                <option key={index} value={axe.index}>{axe.letter}</option>
                                            ))}
                                        </select>
                                        <select value={erkerAxes.bottom} onChange={(e) => setErkerAxes({
                                            top: erkerAxes.top,
                                            bottom: Number(e.target.value),
                                            left: erkerAxes.left,
                                            right: erkerAxes.right,
                                        })}>
                                            {axes.horizontal.filter(axe => axe.index > -1).map((axe, index) => (
                                                <option key={index} value={axe.index}>{axe.letter}</option>
                                            ))}
                                        </select>
                                    </Fragment>
                                )}
                                {(erkerSide === 'topErker' || erkerSide === 'bottomErker') && (
                                    <Fragment>
                                        <select value={erkerAxes.left} onChange={(e) => setErkerAxes({
                                            top: erkerAxes.top,
                                            bottom: erkerAxes.bottom,
                                            left: Number(e.target.value),
                                            right: erkerAxes.right,
                                        })}>
                                            {axes.vertical.filter(axe => axe.index > -1).map((axe, index) => (
                                                <option key={index} value={axe.index}>{axe.letter}</option>
                                            ))}
                                        </select>
                                        <select value={erkerAxes.right} onChange={(e) => setErkerAxes({
                                            top: erkerAxes.top,
                                            bottom: erkerAxes.bottom,
                                            left: erkerAxes.left,
                                            right: Number(e.target.value),
                                        })}>
                                            {axes.vertical.filter(axe => axe.index > -1).reverse().map((axe, index) => (
                                                <option key={index} value={axe.index}>{axe.letter}</option>
                                            ))}
                                        </select>
                                    </Fragment>
                                )}
                                <span>
                                    Размер выноса эркера
                                </span>
                                <input type='number' value={erkerExtension} min={1000} step={10} onChange={(e) => setErkerExtension(e.target.value)} />
                                <button className={styles.step_button_action} onClick={handleAddErker}>
                                    <span>Добавить</span>
                                </button>
                            </div>
                            <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'DeleteErker' })} onClick={(e) => setAction('DeleteErker')}>
                                <DeleteErker />
                                <span>Удалить<br />эркер</span>
                            </button>
                        </div>
                    )}
                    {step === 2 && (
                        <div className={cn(styles.step)}>
                            <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'AddBake' || action === 'AddBakeMoving' })} onClick={(e) => setAction(ac => { return 'AddBake' })}>
                                <AddBake />
                                <span>Фундамент под печь<br />(отдельно)</span>
                            </button>
                            {action === 'AddBake' && (
                                <Fragment>
                                    <div className={cn(styles.add_bake)}>
                                        <div>
                                            <input type='number' value={bakeSize.width} min={100} step={10} onChange={(e) => setBakeSize(bs => { return { width: e.target.value, height: bakeSize.height } })} />
                                            x
                                            <input type='number' value={bakeSize.height} min={100} step={10} onChange={(e) => setBakeSize(bs => { return { width: bakeSize.height, height: e.target.value } })} />
                                            мм.
                                        </div>
                                        <button className={styles.step_button_action} onClick={(e) => setAction(ac => { return 'AddBakeMoving' })}>
                                            <span>Добавить</span>
                                        </button>
                                    </div>
                                </Fragment>
                            )}
                            <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'AddStaticBake' })} onClick={(e) => setAction('AddStaticBake')}>
                                <AddStaticBake />
                                <span>Фундамент под печь<br />(монолит)</span>
                            </button>
                            {action === 'AddStaticBake' && (
                                <Fragment>
                                    <div className={cn(styles.add_bake)}>
                                        <div>
                                            <input type='number' value={bakeSize.width} min={100} step={10} onChange={(e) => setBakeSize(bs => { return { width: e.target.value, height: bakeSize.height } })} />
                                            x
                                            <input type='number' value={bakeSize.height} min={100} step={10} onChange={(e) => setBakeSize(bs => { return { width: bakeSize.height, height: e.target.value } })} />
                                            мм.
                                        </div>
                                        <button className={styles.step_button_action} onClick={(e) => setAction(ac => { return 'AddStaticBakeMoving' })}>
                                            <span>Добавить</span>
                                        </button>
                                    </div>
                                </Fragment>
                            )}
                            <button className={cn(styles.step_button_primary, { [styles.step_button_primary_active]: action === 'DeleteBake' })} onClick={(e) => setAction('DeleteBake')}>
                                <DeleteBake />
                                <span>Удалить фундамент<br />под печь</span>
                            </button>
                        </div>
                    )}
                    {step === 3 && (
                        <div className={cn(styles.step)}>
                            <button className={cn(styles.step_button_primary)} onClick={(e) => {
                                const mywindow = window.open('', 'PRINT');
                                if (!mywindow) {
                                    return;
                                }
                                stageRef.current.toImage({
                                    callback(img) {
                                        mywindow.document.write(`<html><head></head><body>${img.outerHTML}</body></html>`);
                                        mywindow.document.close();
                                        mywindow.focus();
                                        mywindow.print();
                                        mywindow.close();
                                    },
                                    mimeType: 'image/jpeg',
                                    quality: 1,
                                    pixelRatio: 2
                                });
                            }}>
                                <PrintPlan />
                                <span>Распечатать<br />план</span>
                            </button>
                            <button className={cn(styles.step_button_primary)}>
                                <SavePlanPDF />
                                <span>Сохранить план<br />в PDF</span>
                            </button>
                            <button className={cn(styles.step_button_primary)} onClick={async (e) => {
                                const dataURI = stageRef.current.toDataURL({ mimeType: 'image/jpeg', quality: 1, pixelRatio: 2 });
                                const blob = await (await fetch(dataURI)).blob();
                                const imageURL = URL.createObjectURL(blob);

                                const link = document.createElement('a');
                                link.href = imageURL;
                                link.download = `${ID}.jpg`;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);

                            }}>
                                <SavePlanJPG />
                                <span>Сохранить план<br />в JPG</span>
                            </button>
                            <button className={cn(styles.step_button_primary)}>
                                <AddSize />
                                <span>Добавить<br />размер</span>
                            </button>
                            <button className={cn(styles.step_button_primary)}>
                                <DeleteSize />
                                <span>Удалить<br />размер</span>
                            </button>
                        </div>
                    )}
                    <button className={cn(styles.navigation_button, styles.navigation_button_next)} onClick={handleStepNext}>
                        <span>{step < 3 ? 'Следующий шаг' : 'Сохранить проект'}</span>
                        <NextBtn />
                    </button>
                </div>
            </div>
            {action === 'ChoosePlan' && (
                <div className={styles.plan_modal}>
                    <div>
                        <span>Выберите типовой план фундамента</span>
                        <input value={defaultPlanWidthStripe} onChange={(e) => { setDefaultPlanWidthStripe(ws => { return e.target.value }) }} />
                        <button className={styles.step_button_modal} onClick={handleSavePlan}>
                            <span>Применить</span>
                        </button>
                        <button className={styles.close_button_modal} onClick={(e) => { setAction(ac => { return '' }) }}>
                            <CloseBtn />
                        </button>
                    </div>
                </div>
            )}
            {action === 'SaveProject' && (
                <div className={styles.save_modal}>
                    <div>
                        <span>Для того, чтобы получить расчёты,<br />укажите свой e-mail:</span>
                        <input placeholder='введите e-mail' onChange={(e) => { setUserEmail(um => { return e.target.value }) }} />
                        <span>*На ваш e-mail будет выслан проект<br />с расчетом стоимости фундамента </span>
                        <button className={styles.step_button_modal} onClick={handleSavePlan}>
                            <span>Отправить</span>
                        </button>
                        <button className={styles.close_button_modal} onClick={(e) => { setAction(ac => { return '' }) }}>
                            <CloseBtn />
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}
