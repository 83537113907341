// Удаление и добавление осей
export const calculateAddDeleteAxes = (stripes, deletedStripes, erkers, bakes, _countX, countX, _countY, countY, length, width) => {
    let _stripes = JSON.parse(JSON.stringify(stripes));
    let _erkers = JSON.parse(JSON.stringify(erkers));
    let _bakes = [];
    let _deletedStripes = deletedStripes;
    const deltaX = _countX - countX;
    const deltaY = _countY - countY;

    if (deltaX === 1) {
        let horStripes = [];
        let verStripes = [];

        for (let i = 0; i < _countY; i++) {

            let addVerStripe = JSON.parse(JSON.stringify(_stripes.vertical.find((stripe) => stripe.name === `ver_${0}_${i}`)));
            const deltaStripe = length - addVerStripe.length;

            addVerStripe.length = length;
            addVerStripe.pointBottom.y = addVerStripe.pointBottom.y + deltaStripe;
            addVerStripe.pointLeftBottom.y = addVerStripe.pointLeftBottom.y + deltaStripe;
            addVerStripe.pointRightBottom.y = addVerStripe.pointRightBottom.y + deltaStripe;
            addVerStripe.capBottomLeft.y = addVerStripe.capBottomLeft.y + deltaStripe;
            addVerStripe.capBottomRight.y = addVerStripe.capBottomRight.y + deltaStripe;

            verStripes.push(addVerStripe);

            if (i !== _countY - 1) {
                let addHorStripe = JSON.parse(JSON.stringify(_stripes.horizontal.find((stripe) => stripe.name === `hor_${i}_${0}`)));
                horStripes.push(addHorStripe);
            }
        }

        // Идём по горизонтальным перемычкам
        _stripes.horizontal.forEach(stripe => {
            const indexes = stripe.name.split('_');
            stripe.name = `hor_${indexes[1]}_${Number(indexes[2]) + 1}`;

            stripe.pointLeft.y = stripe.pointLeft.y + length;
            stripe.pointRight.y = stripe.pointRight.y + length;

            stripe.pointTopLeft.y = stripe.pointTopLeft.y + length;
            stripe.pointTopRight.y = stripe.pointTopRight.y + length;

            stripe.pointBottomLeft.y = stripe.pointBottomLeft.y + length;
            stripe.pointBottomRight.y = stripe.pointBottomRight.y + length;

            stripe.capLeftTop.y = stripe.capLeftTop.y + length;
            stripe.capLeftBottom.y = stripe.capLeftBottom.y + length;

            stripe.capRightTop.y = stripe.capRightTop.y + length;
            stripe.capRightBottom.y = stripe.capRightBottom.y + length;
        });

        // Идём по вертикальным перемычкам
        _stripes.vertical.forEach(stripe => {
            const indexes = stripe.name.split('_');
            stripe.name = `ver_${Number(indexes[1]) + 1}_${indexes[2]}`;

            stripe.pointTop.y = stripe.pointTop.y + length;
            stripe.pointBottom.y = stripe.pointBottom.y + length;

            stripe.pointLeftTop.y = stripe.pointLeftTop.y + length;
            stripe.pointLeftBottom.y = stripe.pointLeftBottom.y + length;

            stripe.pointRightTop.y = stripe.pointRightTop.y + length;
            stripe.pointRightBottom.y = stripe.pointRightBottom.y + length;

            stripe.capTopLeft.y = stripe.capTopLeft.y + length;
            stripe.capTopRight.y = stripe.capTopRight.y + length;

            stripe.capBottomLeft.y = stripe.capBottomLeft.y + length;
            stripe.capBottomRight.y = stripe.capBottomRight.y + length;
        });

        // Идём по косым перемычкам
        _stripes.corner.forEach(cornerStripe => {
            const stripeNames = cornerStripe.name.split(':');
            const verIndexes1 = stripeNames[0].split('_');
            const ver1 = `ver_${Number(verIndexes1[1]) + 1}_${verIndexes1[2]}`;
            const horIndexes1 = stripeNames[1].split('_');
            const hor1 = `hor_${horIndexes1[1]}_${Number(horIndexes1[2]) + 1}`;
            const verIndexes2 = stripeNames[2].split('_');
            const ver2 = `ver_${Number(verIndexes2[1]) + 1}_${verIndexes2[2]}`;
            const horIndexes2 = stripeNames[3].split('_');
            const hor2 = `hor_${horIndexes2[1]}_${Number(horIndexes2[2]) + 1}`;
            cornerStripe.name = `${ver1}:${hor1}:${ver2}:${hor2}`;

            cornerStripe.pointTop.y = cornerStripe.pointTop.y + length;
            cornerStripe.pointBottom.y = cornerStripe.pointBottom.y + length;

            cornerStripe.pointTopLeft.y = cornerStripe.pointTopLeft.y + length;
            cornerStripe.pointTopRight.y = cornerStripe.pointTopRight.y + length;

            cornerStripe.pointBottomLeft.y = cornerStripe.pointBottomLeft.y + length;
            cornerStripe.pointBottomRight.y = cornerStripe.pointBottomRight.y + length;

            cornerStripe.pointTopCenterLeft.y = cornerStripe.pointTopCenterLeft.y + length;
            cornerStripe.pointTopCenterRight.y = cornerStripe.pointTopCenterRight.y + length;

            cornerStripe.pointBottomCenterLeft.y = cornerStripe.pointBottomCenterLeft.y + length;
            cornerStripe.pointBottomCenterRight.y = cornerStripe.pointBottomCenterRight.y + length;
        });

        _stripes.horizontal.push(...horStripes);
        _stripes.vertical.push(...verStripes);

        // Меняем нижние эркеры
        _erkers.bottom.filter(erker => erker.type === 'angel').forEach(erker => {
            erker.erkerPointLeft.y = erker.erkerPointLeft.y + length;
            erker.erkerPointRight.y = erker.erkerPointRight.y + length;
            erker.erkerPointBottomLeft.y = erker.erkerPointBottomLeft.y + length;
            erker.erkerPointBottomRight.y = erker.erkerPointBottomRight.y + length;
            erker.pathTop.y1 = erker.pathTop.y1 + length;
            erker.pathTop.y2 = erker.pathTop.y2 + length;
            erker.pathTop.y3 = erker.pathTop.y3 + length;
            erker.pathTop.y4 = erker.pathTop.y4 + length;
            erker.pathBottom.y1 = erker.pathBottom.y1 + length;
            erker.pathBottom.y2 = erker.pathBottom.y2 + length;
            erker.pathBottom.y3 = erker.pathBottom.y3 + length;
            erker.pathBottom.y4 = erker.pathBottom.y4 + length;
            let indexes = erker.startHorizontal.split('_');
            erker.startHorizontal = `hor_${indexes[1]}_${_countX - 1}`;
            indexes = erker.startVertical.split('_');
            erker.startVertical = `ver_${_countX - 2}_${indexes[2]}`;
            indexes = erker.endHorizontal.split('_');
            erker.endHorizontal = `hor_${indexes[1]}_${_countX - 1}`;
            indexes = erker.endVertical.split('_');
            erker.endVertical = `ver_${_countX - 2}_${indexes[2]}`;
        });
        _erkers.bottom.filter(erker => erker.type === 'round').forEach(erker => {
            erker.center.y = erker.center.y + length;
            let indexes = erker.startHorizontal.split('_');
            erker.startHorizontal = `hor_${indexes[1]}_${_countX - 1}`;
            indexes = erker.startVertical.split('_');
            erker.startVertical = `ver_${_countX - 2}_${indexes[2]}`;
            indexes = erker.endHorizontal.split('_');
            erker.endHorizontal = `hor_${indexes[1]}_${_countX - 1}`;
            indexes = erker.endVertical.split('_');
            erker.endVertical = `ver_${_countX - 2}_${indexes[2]}`;
        });
        // Сохраняем камины
        _bakes = JSON.parse(JSON.stringify(bakes));
        _bakes.forEach(bake => {
            bake.y = bake.y + length;
            bake.top.index = bake.top.index + 1;
            bake.top.left.y = bake.top.left.y + length;
            bake.top.right.y = bake.top.right.y + length;
            bake.bottom.index = bake.bottom.index + 1;
            bake.bottom.left.y = bake.bottom.left.y + length;
            bake.bottom.right.y = bake.bottom.right.y + length;
            bake.left.top.y = bake.left.top.y + length;
            bake.left.bottom.y = bake.left.bottom.y + length;
            bake.right.top.y = bake.right.top.y + length;
            bake.right.bottom.y = bake.right.bottom.y + length;
        });
    }

    if (deltaX === -1) {
        const tempStripe = _stripes.vertical.find((stripe) => stripe.name === `ver_${0}_${0}`);
        const _length = tempStripe.pointBottom.y - tempStripe.pointTop.y;
        // Меняем нижние эркеры
        _erkers.bottom.filter(erker => erker.type === 'angel').forEach(erker => {
            erker.erkerPointLeft.y = erker.erkerPointLeft.y - _length;
            erker.erkerPointRight.y = erker.erkerPointRight.y - _length;
            erker.erkerPointBottomLeft.y = erker.erkerPointBottomLeft.y - _length;
            erker.erkerPointBottomRight.y = erker.erkerPointBottomRight.y - _length;
            erker.pathTop.y1 = erker.pathTop.y1 - _length;
            erker.pathTop.y2 = erker.pathTop.y2 - _length;
            erker.pathTop.y3 = erker.pathTop.y3 - _length;
            erker.pathTop.y4 = erker.pathTop.y4 - _length;
            erker.pathBottom.y1 = erker.pathBottom.y1 - _length;
            erker.pathBottom.y2 = erker.pathBottom.y2 - _length;
            erker.pathBottom.y3 = erker.pathBottom.y3 - _length;
            erker.pathBottom.y4 = erker.pathBottom.y4 - _length;
            let indexes = erker.startHorizontal.split('_');
            erker.startHorizontal = `hor_${indexes[1]}_${_countX - 1}`;
            indexes = erker.startVertical.split('_');
            erker.startVertical = `ver_${_countX - 2}_${indexes[2]}`;
            indexes = erker.endHorizontal.split('_');
            erker.endHorizontal = `hor_${indexes[1]}_${_countX - 1}`;
            indexes = erker.endVertical.split('_');
            erker.endVertical = `ver_${_countX - 2}_${indexes[2]}`;
        });
        _erkers.bottom.filter(erker => erker.type === 'round').forEach(erker => {
            erker.center.y = erker.center.y - _length;
            let indexes = erker.startHorizontal.split('_');
            erker.startHorizontal = `hor_${indexes[1]}_${_countX - 1}`;
            indexes = erker.startVertical.split('_');
            erker.startVertical = `ver_${_countX - 2}_${indexes[2]}`;
            indexes = erker.endHorizontal.split('_');
            erker.endHorizontal = `hor_${indexes[1]}_${_countX - 1}`;
            indexes = erker.endVertical.split('_');
            erker.endVertical = `ver_${_countX - 2}_${indexes[2]}`;
        });
        // Удаляем горизонтальные перемычки
        for (let j = 0; j < countY - 1; j++) {
            _stripes.horizontal = _stripes.horizontal.filter((stripe) => stripe.name !== `hor_${j}_${0}`);
            _deletedStripes = _deletedStripes.filter((stripe) => stripe !== `hor_${j}_${0}`);
        }
        // Удаляем вертикальные перемычки
        for (let i = 0; i < countY; i++) {
            _stripes.vertical = _stripes.vertical.filter((stripe) => stripe.name !== `ver_${0}_${i}`);
            _stripes.corner = _stripes.corner.filter(stripe => !stripe.name.startsWith(`ver_${0}_${i}`));
            _deletedStripes = _deletedStripes.filter((stripe) => stripe !== `ver_${0}_${i}`);
        }
        // Идём по горизонтальным перемычкам
        _stripes.horizontal.forEach(stripe => {
            const indexes = stripe.name.split('_');
            stripe.name = `hor_${indexes[1]}_${Number(indexes[2]) - 1}`;

            stripe.pointLeft.y = stripe.pointLeft.y - _length;
            stripe.pointRight.y = stripe.pointRight.y - _length;

            stripe.pointTopLeft.y = stripe.pointTopLeft.y - _length;
            stripe.pointTopRight.y = stripe.pointTopRight.y - _length;

            stripe.pointBottomLeft.y = stripe.pointBottomLeft.y - _length;
            stripe.pointBottomRight.y = stripe.pointBottomRight.y - _length;

            stripe.capLeftTop.y = stripe.capLeftTop.y - _length;
            stripe.capLeftBottom.y = stripe.capLeftBottom.y - _length;

            stripe.capRightTop.y = stripe.capRightTop.y - _length;
            stripe.capRightBottom.y = stripe.capRightBottom.y - _length;
        });

        // Идём по вертикальным перемычкам
        _stripes.vertical.forEach(stripe => {
            const indexes = stripe.name.split('_');
            stripe.name = `ver_${Number(indexes[1]) - 1}_${indexes[2]}`;

            stripe.pointTop.y = stripe.pointTop.y - _length;
            stripe.pointBottom.y = stripe.pointBottom.y - _length;

            stripe.pointLeftTop.y = stripe.pointLeftTop.y - _length;
            stripe.pointLeftBottom.y = stripe.pointLeftBottom.y - _length;

            stripe.pointRightTop.y = stripe.pointRightTop.y - _length;
            stripe.pointRightBottom.y = stripe.pointRightBottom.y - _length;

            stripe.capTopLeft.y = stripe.capTopLeft.y - _length;
            stripe.capTopRight.y = stripe.capTopRight.y - _length;

            stripe.capBottomLeft.y = stripe.capBottomLeft.y - _length;
            stripe.capBottomRight.y = stripe.capBottomRight.y - _length;
        });

        // Идём по косым перемычкам
        _stripes.corner.forEach(cornerStripe => {
            const stripeNames = cornerStripe.name.split(':');
            const verIndexes1 = stripeNames[0].split('_');
            const ver1 = `ver_${Number(verIndexes1[1]) - 1}_${verIndexes1[2]}`;
            const horIndexes1 = stripeNames[1].split('_');
            const hor1 = `hor_${horIndexes1[1]}_${Number(horIndexes1[2]) - 1}`;
            const verIndexes2 = stripeNames[2].split('_');
            const ver2 = `ver_${Number(verIndexes2[1]) - 1}_${verIndexes2[2]}`;
            const horIndexes2 = stripeNames[3].split('_');
            const hor2 = `hor_${horIndexes2[1]}_${Number(horIndexes2[2]) - 1}`;
            cornerStripe.name = `${ver1}:${hor1}:${ver2}:${hor2}`;

            cornerStripe.pointTop.y = cornerStripe.pointTop.y - _length;
            cornerStripe.pointBottom.y = cornerStripe.pointBottom.y - _length;

            cornerStripe.pointTopLeft.y = cornerStripe.pointTopLeft.y - _length;
            cornerStripe.pointTopRight.y = cornerStripe.pointTopRight.y - _length;

            cornerStripe.pointBottomLeft.y = cornerStripe.pointBottomLeft.y - _length;
            cornerStripe.pointBottomRight.y = cornerStripe.pointBottomRight.y - _length;

            cornerStripe.pointTopCenterLeft.y = cornerStripe.pointTopCenterLeft.y - _length;
            cornerStripe.pointTopCenterRight.y = cornerStripe.pointTopCenterRight.y - _length;

            cornerStripe.pointBottomCenterLeft.y = cornerStripe.pointBottomCenterLeft.y - _length;
            cornerStripe.pointBottomCenterRight.y = cornerStripe.pointBottomCenterRight.y - _length;
        });

        // Удаляем лишние камины
        _bakes = JSON.parse(JSON.stringify(bakes)).filter(bake => bake.top.index !== 0);
        _bakes.forEach(bake => {
            bake.y = bake.y - _length;
            bake.top.index = bake.top.index - 1;
            bake.top.left.y = bake.top.left.y - _length;
            bake.top.right.y = bake.top.right.y - _length;
            bake.bottom.index = bake.bottom.index - 1;
            bake.bottom.left.y = bake.bottom.left.y - _length;
            bake.bottom.right.y = bake.bottom.right.y - _length;
            bake.left.top.y = bake.left.top.y - _length;
            bake.left.bottom.y = bake.left.bottom.y - _length;
            bake.right.top.y = bake.right.top.y - _length;
            bake.right.bottom.y = bake.right.bottom.y - _length;
        });

        //_bakes = _bakes ? _bakes : [];
    }

    if (deltaY === 1) {
        //add horizontal
        for (let i = 0; i < _countX; i++) {
            let addStripe = JSON.parse(JSON.stringify(stripes.horizontal.find((stripe) => stripe.name === `hor_${_countY - 3}_${i}`)));
            let tempStrip_1 = _stripes.vertical.find((stripe) => stripe.name === `ver_${i - 1}_${_countY - 2}`);
            let tempStrip_2 = _stripes.vertical.find((stripe) => stripe.name === `ver_${i}_${_countY - 2}`);
            let tempStrip_3 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${_countY - 3}_${i}`);

            addStripe.name = `hor_${_countY - 2}_${i}`;
            addStripe.length = length;
            addStripe.width = width;
            addStripe.leftWidth = width / 2;
            addStripe.rightWidth = width / 2;
            addStripe.pointLeft = {
                x: addStripe.pointRight.x,
                y: addStripe.pointRight.y,
            };
            addStripe.pointRight = {
                x: addStripe.pointRight.x + length,
                y: addStripe.pointRight.y,
            };
            addStripe.pointTopRight = {
                x: i === 0 ? addStripe.pointRight.x + width / 2 : addStripe.pointRight.x - width / 2,
                y: addStripe.pointRight.y - width / 2,
            };
            addStripe.pointTopLeft.y = addStripe.pointRight.y - width / 2;
            addStripe.pointBottomRight = {
                x: i === _countX - 1 ? addStripe.pointRight.x + width / 2 : addStripe.pointRight.x - width / 2,
                y: addStripe.pointRight.y + width / 2,
            };
            addStripe.pointBottomLeft.y = addStripe.pointRight.y + width / 2;
            addStripe.capLeftTop = {
                x: addStripe.capLeftTop.x + length,
                y: addStripe.capLeftTop.y,
            };
            addStripe.capLeftBottom = {
                x: addStripe.capLeftBottom.x + length,
                y: addStripe.capLeftBottom.y,
            };
            addStripe.capRight = false;
            addStripe.capRightTop = {
                x: addStripe.capRightTop.x + length,
                y: addStripe.capRightTop.y,
            };
            addStripe.capRightBottom = {
                x: addStripe.capRightBottom.x + length,
                y: addStripe.capRightBottom.y,
            };

            _stripes.horizontal.push(addStripe);
        }
        //add vertical
        for (let j = 0; j < _countX - 1; j++) {
            let addStripe = JSON.parse(JSON.stringify(stripes.vertical.find((stripe) => stripe.name === `ver_${j}_${_countY - 2}`)));
            addStripe.name = `ver_${j}_${_countY - 1}`;
            addStripe.width = width;
            addStripe.leftWidth = width / 2;
            addStripe.rightWidth = width / 2;
            addStripe.pointTop = {
                x: addStripe.pointTop.x + length,
                y: addStripe.pointTop.y,
            };
            addStripe.pointBottom = {
                x: addStripe.pointBottom.x + length,
                y: addStripe.pointBottom.y,
            };
            addStripe.pointLeftTop = {
                x: addStripe.pointTop.x - width / 2,
                y: addStripe.pointTop.y + width / 2,
            };
            addStripe.pointLeftBottom = {
                x: addStripe.pointBottom.x - width / 2,
                y: addStripe.pointBottom.y - width / 2,
            };
            addStripe.pointRightTop = {
                x: addStripe.pointTop.x + width / 2,
                y: j === 0 ? addStripe.pointTop.y - width / 2 : addStripe.pointTop.y,
            };
            addStripe.pointRightBottom = {
                x: addStripe.pointBottom.x + width / 2,
                y: j === countX - 2 ? addStripe.pointBottom.y + width / 2 : addStripe.pointBottom.y,
            };
            addStripe.capTop = false;
            addStripe.capTopLeft = {
                x: addStripe.capTopLeft.x + length,
                y: addStripe.capTopLeft.y,
            };
            addStripe.capTopRight = {
                x: addStripe.capTopRight.x + length,
                y: addStripe.capTopRight.y,
            };
            addStripe.capBottom = false;
            addStripe.capBottomLeft = {
                x: addStripe.capBottomLeft.x + length,
                y: addStripe.capBottomLeft.y,
            };
            addStripe.capBottomRight = {
                x: addStripe.capBottomRight.x + length,
                y: addStripe.capBottomRight.y,
            };

            _stripes.vertical.push(addStripe);
        }
        // Меняем правые эркеры
        _erkers.right.filter(erker => erker.type === 'angel').forEach(erker => {
            erker.erkerPointTop.x = erker.erkerPointTop.x + length;
            erker.erkerPointBottom.x = erker.erkerPointBottom.x + length;
            erker.erkerPointRightTop.x = erker.erkerPointRightTop.x + length;
            erker.erkerPointRightBottom.x = erker.erkerPointRightBottom.x + length;
            erker.pathLeft.x1 = erker.pathLeft.x1 + length;
            erker.pathLeft.x2 = erker.pathLeft.x2 + length;
            erker.pathLeft.x3 = erker.pathLeft.x3 + length;
            erker.pathLeft.x4 = erker.pathLeft.x4 + length;
            erker.pathRight.x1 = erker.pathRight.x1 + length;
            erker.pathRight.x2 = erker.pathRight.x2 + length;
            erker.pathRight.x3 = erker.pathRight.x3 + length;
            erker.pathRight.x4 = erker.pathRight.x4 + length;
            let indexes = erker.startHorizontal.split('_');
            erker.startHorizontal = `hor_${_countY - 2}_${indexes[2]}`;
            indexes = erker.startVertical.split('_');
            erker.startVertical = `ver_${indexes[1]}_${_countY - 1}`;
            indexes = erker.endHorizontal.split('_');
            erker.endHorizontal = `hor_${_countY - 2}_${indexes[2]}`;
            indexes = erker.endVertical.split('_');
            erker.endVertical = `ver_${indexes[1]}_${_countY - 1}`;
        });
        _erkers.right.filter(erker => erker.type === 'round').forEach(erker => {
            erker.center.x = erker.center.x + length;
            let indexes = erker.startHorizontal.split('_');
            erker.startHorizontal = `hor_${_countY - 2}_${indexes[2]}`;
            indexes = erker.startVertical.split('_');
            erker.startVertical = `ver_${indexes[1]}_${_countY - 1}`;
            indexes = erker.endHorizontal.split('_');
            erker.endHorizontal = `hor_${_countY - 2}_${indexes[2]}`;
            indexes = erker.endVertical.split('_');
            erker.endVertical = `ver_${indexes[1]}_${_countY - 1}`;
        });
        // Сохраняем камины
        _bakes = JSON.parse(JSON.stringify(bakes));
    }

    if (deltaY === -1) {
        const tempStripe = _stripes.horizontal.find((stripe) => stripe.name === `hor_${countY - 2}_${0}`);
        const _length = tempStripe.pointRight.x - tempStripe.pointLeft.x;
        // Меняем правые эркеры
        _erkers.right.filter(erker => erker.type === 'angel').forEach(erker => {
            erker.erkerPointTop.x = erker.erkerPointTop.x - _length;
            erker.erkerPointBottom.x = erker.erkerPointBottom.x - _length;
            erker.erkerPointRightTop.x = erker.erkerPointRightTop.x - _length;
            erker.erkerPointRightBottom.x = erker.erkerPointRightBottom.x - _length;
            erker.pathLeft.x1 = erker.pathLeft.x1 - _length;
            erker.pathLeft.x2 = erker.pathLeft.x2 - _length;
            erker.pathLeft.x3 = erker.pathLeft.x3 - _length;
            erker.pathLeft.x4 = erker.pathLeft.x4 - _length;
            erker.pathRight.x1 = erker.pathRight.x1 - _length;
            erker.pathRight.x2 = erker.pathRight.x2 - _length;
            erker.pathRight.x3 = erker.pathRight.x3 - _length;
            erker.pathRight.x4 = erker.pathRight.x4 - _length;
            let indexes = erker.startHorizontal.split('_');
            erker.startHorizontal = `hor_${_countY - 2}_${indexes[2]}`;
            indexes = erker.startVertical.split('_');
            erker.startVertical = `ver_${indexes[1]}_${_countY - 1}`;
            indexes = erker.endHorizontal.split('_');
            erker.endHorizontal = `hor_${_countY - 2}_${indexes[2]}`;
            indexes = erker.endVertical.split('_');
            erker.endVertical = `ver_${indexes[1]}_${_countY - 1}`;
        });
        _erkers.right.filter(erker => erker.type === 'round').forEach(erker => {
            erker.center.x = erker.center.x - _length;
            let indexes = erker.startHorizontal.split('_');
            erker.startHorizontal = `hor_${_countY - 2}_${indexes[2]}`;
            indexes = erker.startVertical.split('_');
            erker.startVertical = `ver_${indexes[1]}_${_countY - 1}`;
            indexes = erker.endHorizontal.split('_');
            erker.endHorizontal = `hor_${_countY - 2}_${indexes[2]}`;
            indexes = erker.endVertical.split('_');
            erker.endVertical = `ver_${indexes[1]}_${_countY - 1}`;
        });
        //delete vertical
        for (let j = 0; j < countX - 1; j++) {
            _stripes.vertical = _stripes.vertical.filter((stripe) => stripe.name !== `ver_${j}_${countY - 1}`);
            _deletedStripes = _deletedStripes.filter((stripe) => stripe !== `ver_${j}_${countY - 1}`);
        }
        //delete horizontal
        for (let i = 0; i < countX; i++) {
            _stripes.horizontal = _stripes.horizontal.filter((stripe) => stripe.name !== `hor_${countY - 2}_${i}`);
            _deletedStripes = _deletedStripes.filter((stripe) => stripe !== `hor_${countY - 2}_${i}`);

            let tempStrip_1 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${countY - 3}_${i}`);
            let tempStrip_2 = _stripes.vertical.find((stripe) => stripe.name === `ver_${i - 1}_${countY - 2}`);
            let tempStrip_3 = _stripes.vertical.find((stripe) => stripe.name === `ver_${i}_${countY - 2}`);
        }
        // Удаляем лишние камины
        _bakes = JSON.parse(JSON.stringify(bakes)).filter(bake => bake.right.index !== countY - 1);
    }

    const result = recalculateLines(_stripes, _deletedStripes, _erkers, _bakes, _countX, _countY);

    if (result) {
        return {
            stripes: result.stripes,
            erkers: result.erkers,
            bakes: result.bakes,
            deletedStripes: _deletedStripes,
        };
    } else return false;
}

// Изменение толщины ленты
export const calculateChangeWidth = (stripes, countX, countY, width) => {
    let _stripes = stripes;
    // Изменение горизонтальных перемычек
    for (let i = 0; i < countX; i++) {
        for (let j = 0; j < countY - 1; j++) {
            let tempStrip = _stripes.horizontal.find((stripe) => stripe.name === `hor_${j}_${i}`);
            const delta = (width - tempStrip.width) / 2;

            tempStrip.width = width;
            tempStrip.topWidth = width / 2;
            tempStrip.bottomWidth = width / 2;

            if (tempStrip.pointLeft.x - tempStrip.pointTopLeft.x > 0) {
                tempStrip.pointTopLeft = {
                    x: tempStrip.pointTopLeft.x - delta,
                    y: tempStrip.pointTopLeft.y - delta,
                }
            } else if (tempStrip.pointLeft.x - tempStrip.pointTopLeft.x === 0) {
                tempStrip.pointTopLeft = {
                    x: tempStrip.pointTopLeft.x,
                    y: tempStrip.pointTopLeft.y - delta,
                }
            } else {
                tempStrip.pointTopLeft = {
                    x: tempStrip.pointTopLeft.x + delta,
                    y: tempStrip.pointTopLeft.y - delta,
                }
            }

            if (tempStrip.pointRight.x - tempStrip.pointTopRight.x > 0) {
                tempStrip.pointTopRight = {
                    x: tempStrip.pointTopRight.x - delta,
                    y: tempStrip.pointTopRight.y - delta,
                }
            } else if (tempStrip.pointRight.x - tempStrip.pointTopRight.x === 0) {
                tempStrip.pointTopRight = {
                    x: tempStrip.pointTopRight.x,
                    y: tempStrip.pointTopRight.y - delta,
                }
            } else {
                tempStrip.pointTopRight = {
                    x: tempStrip.pointTopRight.x + delta,
                    y: tempStrip.pointTopRight.y - delta,
                }
            }

            if (tempStrip.pointLeft.x - tempStrip.pointBottomLeft.x > 0) {
                tempStrip.pointBottomLeft = {
                    x: tempStrip.pointBottomLeft.x - delta,
                    y: tempStrip.pointBottomLeft.y + delta,
                }
            } else if (tempStrip.pointLeft.x - tempStrip.pointBottomLeft.x === 0) {
                tempStrip.pointBottomLeft = {
                    x: tempStrip.pointBottomLeft.x,
                    y: tempStrip.pointBottomLeft.y + delta,
                }
            } else {
                tempStrip.pointBottomLeft = {
                    x: tempStrip.pointBottomLeft.x + delta,
                    y: tempStrip.pointBottomLeft.y + delta,
                }
            }

            if (tempStrip.pointRight.x - tempStrip.pointBottomRight.x > 0) {
                tempStrip.pointBottomRight = {
                    x: tempStrip.pointBottomRight.x - delta,
                    y: tempStrip.pointBottomRight.y + delta,
                }
            } else if (tempStrip.pointRight.x - tempStrip.pointBottomRight.x === 0) {
                tempStrip.pointBottomRight = {
                    x: tempStrip.pointBottomRight.x,
                    y: tempStrip.pointBottomRight.y + delta,
                }
            } else {
                tempStrip.pointBottomRight = {
                    x: tempStrip.pointBottomRight.x + delta,
                    y: tempStrip.pointBottomRight.y + delta,
                }
            }

            tempStrip.capLeftTop = {
                x: tempStrip.pointLeft.x - width / 2,
                y: tempStrip.pointLeft.y - width / 2,
            }

            tempStrip.capLeftBottom = {
                x: tempStrip.pointLeft.x - width / 2,
                y: tempStrip.pointLeft.y + width / 2,
            }

            tempStrip.capRightTop = {
                x: tempStrip.pointRight.x + width / 2,
                y: tempStrip.pointRight.y - width / 2,
            }

            tempStrip.capRightBottom = {
                x: tempStrip.pointRight.x + width / 2,
                y: tempStrip.pointRight.y + width / 2,
            }

        }
    }
    // Изменение вертикальных перемычек
    for (let i = 0; i < countY; i++) {
        for (let j = 0; j < countX - 1; j++) {
            let tempStrip = _stripes.vertical.find((stripe) => stripe.name === `ver_${j}_${i}`);
            const delta = (width - tempStrip.width) / 2;

            tempStrip.width = width;
            tempStrip.leftWidth = width / 2;
            tempStrip.rightWidth = width / 2;

            if (tempStrip.pointTop.y - tempStrip.pointLeftTop.y > 0) {
                tempStrip.pointLeftTop = {
                    x: tempStrip.pointLeftTop.x - delta,
                    y: tempStrip.pointLeftTop.y - delta,
                }
            } else if (tempStrip.pointTop.y - tempStrip.pointLeftTop.y === 0) {
                tempStrip.pointLeftTop = {
                    x: tempStrip.pointLeftTop.x - delta,
                    y: tempStrip.pointLeftTop.y,
                }
            } else {
                tempStrip.pointLeftTop = {
                    x: tempStrip.pointLeftTop.x - delta,
                    y: tempStrip.pointLeftTop.y + delta,
                }
            }

            if (tempStrip.pointBottom.y - tempStrip.pointLeftBottom.y > 0) {
                tempStrip.pointLeftBottom = {
                    x: tempStrip.pointLeftBottom.x - delta,
                    y: tempStrip.pointLeftBottom.y - delta,
                }
            } else if (tempStrip.pointBottom.y - tempStrip.pointLeftBottom.y === 0) {
                tempStrip.pointLeftBottom = {
                    x: tempStrip.pointLeftBottom.x - delta,
                    y: tempStrip.pointLeftBottom.y,
                }
            } else {
                tempStrip.pointLeftBottom = {
                    x: tempStrip.pointLeftBottom.x - delta,
                    y: tempStrip.pointLeftBottom.y + delta,
                }
            }

            if (tempStrip.pointTop.y - tempStrip.pointRightTop.y > 0) {
                tempStrip.pointRightTop = {
                    x: tempStrip.pointRightTop.x + delta,
                    y: tempStrip.pointRightTop.y - delta,
                }
            } else if (tempStrip.pointTop.y - tempStrip.pointRightTop.y === 0) {
                tempStrip.pointRightTop = {
                    x: tempStrip.pointRightTop.x + delta,
                    y: tempStrip.pointRightTop.y,
                }
            } else {
                tempStrip.pointRightTop = {
                    x: tempStrip.pointRightTop.x + delta,
                    y: tempStrip.pointRightTop.y + delta,
                }
            }

            if (tempStrip.pointBottom.y - tempStrip.pointRightBottom.y > 0) {
                tempStrip.pointRightBottom = {
                    x: tempStrip.pointRightBottom.x + delta,
                    y: tempStrip.pointRightBottom.y - delta,
                }
            } else if (tempStrip.pointBottom.y - tempStrip.pointRightBottom.y === 0) {
                tempStrip.pointRightBottom = {
                    x: tempStrip.pointRightBottom.x + delta,
                    y: tempStrip.pointRightBottom.y,
                }
            } else {
                tempStrip.pointRightBottom = {
                    x: tempStrip.pointRightBottom.x + delta,
                    y: tempStrip.pointRightBottom.y + delta,
                }
            }

            tempStrip.capTopLeft = {
                x: tempStrip.pointTop.x - width / 2,
                y: tempStrip.pointTop.y - width / 2,
            }

            tempStrip.capBottomLeft = {
                x: tempStrip.pointBottom.x - width / 2,
                y: tempStrip.pointBottom.y + width / 2,
            }

            tempStrip.capTopRight = {
                x: tempStrip.pointTop.x + width / 2,
                y: tempStrip.pointTop.y - width / 2,
            }

            tempStrip.capBottomRight = {
                x: tempStrip.pointBottom.x + width / 2,
                y: tempStrip.pointBottom.y + width / 2,
            }

        }
    }

    return _stripes;
}

// Расчёт свай
export const calculatePiles = (stripes, erkers, bakes, deletedStripes, piles, MIN_STEP_PILE, _MIN_STEP_PILE, countX, countY) => {
    let _piles = [];
    const extendedPiles = JSON.parse(JSON.stringify(piles.filter(pile => pile.extended)));
    _piles.push(...extendedPiles);
    // Идём по горизонтальным перемычкам
    for (let i = 0; i < countX; i++) {
        for (let j = 0; j < countY - 1; j++) {

            const tempStrip = stripes.horizontal.find((stripe) => stripe.name === `hor_${j}_${i}`);

            // Смежные горизонтальные
            let stripeHorizontal_1 = stripes.horizontal.find(stripe => stripe.name === `hor_${j - 1}_${i}`);
            let stripeHorizontal_2 = stripes.horizontal.find(stripe => stripe.name === `hor_${j + 1}_${i}`);

            // Смежные вертикальные
            let stripeVertical_1 = stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j}`);
            let stripeVertical_2 = stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j + 1}`);
            let stripeVertical_3 = stripes.vertical.find(stripe => stripe.name === `ver_${i}_${j}`);
            let stripeVertical_4 = stripes.vertical.find(stripe => stripe.name === `ver_${i}_${j + 1}`);

            const countPiles = Math.ceil(tempStrip.length / (tempStrip.pileStep ? tempStrip.pileStep : MIN_STEP_PILE));

            for (let count = 0; count <= countPiles; count++) {

                if (count === 0) {
                    if (!deletedStripes.includes(tempStrip.name) || tempStrip.savePiles) {
                        _piles.push({
                            axeX: i,
                            axeY: j,
                            deleted: false,
                            x: tempStrip.pointLeft.x + (tempStrip.length / countPiles) * count,
                            y: tempStrip.pointLeft.y,
                        })
                    } else if (
                        (((stripeVertical_3 && !deletedStripes.includes(stripeVertical_3.name)) && i < countX - 1) || (stripeVertical_3 && stripeVertical_3.savePiles)) ||
                        (((stripeVertical_1 && !deletedStripes.includes(stripeVertical_1.name)) && i > 0) || (stripeVertical_1 && stripeVertical_1.savePiles)) ||
                        ((stripeHorizontal_1 && !deletedStripes.includes(stripeHorizontal_1.name)) && j > 0)
                    ) {
                        _piles.push({
                            axeX: i,
                            axeY: j,
                            deleted: false,
                            x: tempStrip.pointLeft.x + (tempStrip.length / countPiles) * count,
                            y: tempStrip.pointLeft.y,
                        })
                    }
                }

                if (count === countPiles) {
                    if ((!deletedStripes.includes(tempStrip.name) && j === countY - 2) || tempStrip.savePiles) {
                        _piles.push({
                            axeX: i,
                            axeY: j + 1,
                            deleted: false,
                            x: tempStrip.pointLeft.x + (tempStrip.length / countPiles) * count,
                            y: tempStrip.pointLeft.y,
                        })
                    } else if (
                        (
                            (((stripeVertical_4 && !deletedStripes.includes(stripeVertical_4.name)) && i < countX - 1) || (stripeVertical_4 && stripeVertical_4.savePiles)) ||
                            (((stripeVertical_2 && !deletedStripes.includes(stripeVertical_2.name)) && i > 0) || (stripeVertical_2 && stripeVertical_2.savePiles))
                        ) && j === countY - 2
                    ) {
                        _piles.push({
                            axeX: i,
                            axeY: j + 1,
                            deleted: false,
                            x: tempStrip.pointLeft.x + (tempStrip.length / countPiles) * count,
                            y: tempStrip.pointLeft.y,
                        })
                    }
                }

                if (count > 0 && count < countPiles) {
                    if (!deletedStripes.includes(tempStrip.name) || tempStrip.savePiles) {
                        _piles.push({
                            axeX: -1,
                            axeY: -1,
                            deleted: false,
                            x: tempStrip.pointLeft.x + (tempStrip.length / countPiles) * count,
                            y: tempStrip.pointLeft.y,
                        })
                    }
                }
            }
        }
    }
    // Идём по вертикальным перемычкам
    for (let i = 0; i < countY; i++) {
        for (let j = 0; j < countX - 1; j++) {

            const tempStrip = stripes.vertical.find((stripe) => stripe.name === `ver_${j}_${i}`);

            const countPiles = Math.ceil(tempStrip.length / (tempStrip.pileStep ? tempStrip.pileStep : MIN_STEP_PILE));

            for (let count = 1; count < countPiles; count++) {
                if (!deletedStripes.includes(tempStrip.name) || tempStrip.savePiles) {
                    _piles.push({
                        axeX: -1,
                        axeY: -1,
                        deleted: false,
                        x: tempStrip.pointTop.x,
                        y: tempStrip.pointTop.y + (tempStrip.length / countPiles) * count,
                    });
                }
            }
        }
    }
    // Идём по перемычкам под углом
    stripes.corner.forEach(cornerStripe => {
        const cornerLength = Math.sqrt(Math.pow((cornerStripe.pointTop.x - cornerStripe.pointBottom.x), 2) + Math.pow((cornerStripe.pointTop.y - cornerStripe.pointBottom.y), 2));
        const cornerCountPiles = Math.ceil(cornerLength / (cornerStripe.pileStep ? cornerStripe.pileStep : MIN_STEP_PILE));
        for (let count = 1; count < cornerCountPiles; count++) {
            const k = ((cornerLength / cornerCountPiles) * count) / cornerLength;
            if (cornerStripe.direction === 'up') {
                _piles.push({
                    axeX: -1,
                    axeY: -1,
                    deleted: false,
                    x: cornerStripe.pointBottom.x + (cornerStripe.pointTop.x - cornerStripe.pointBottom.x) * k,
                    y: cornerStripe.pointBottom.y + (cornerStripe.pointTop.y - cornerStripe.pointBottom.y) * k,
                });
            } else {
                _piles.push({
                    axeX: -1,
                    axeY: -1,
                    deleted: false,
                    x: cornerStripe.pointTop.x + (cornerStripe.pointBottom.x - cornerStripe.pointTop.x) * k,
                    y: cornerStripe.pointTop.y + (cornerStripe.pointBottom.y - cornerStripe.pointTop.y) * k,
                });
            }
        }
    });
    // Идём по правым эркерам
    erkers.right.filter(erker => erker.type === "angel").forEach(erker => {
        _piles.push({
            axeX: -1,
            axeY: -1,
            deleted: false,
            x: erker.erkerPointRightTop.x,
            y: erker.erkerPointRightTop.y,
        });
        _piles.push({
            axeX: -1,
            axeY: -1,
            deleted: false,
            x: erker.erkerPointRightBottom.x,
            y: erker.erkerPointRightBottom.y,
        });
        const erkerSize = erker.erkerPointRightBottom.y - erker.erkerPointRightTop.y;
        const countPiles = Math.ceil(erkerSize / MIN_STEP_PILE);
        for (let count = 1; count < countPiles; count++) {
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: erker.erkerPointRightTop.x,
                y: erker.erkerPointRightTop.y + (erkerSize / countPiles) * count,
            });
        }
        const topLength = Math.sqrt(Math.pow((erker.erkerPointRightTop.x - erker.erkerPointTop.x), 2) + Math.pow((erker.erkerPointRightTop.y - erker.erkerPointTop.y), 2));
        const countTopPiles = Math.ceil(topLength / MIN_STEP_PILE);
        for (let count = 1; count < countTopPiles; count++) {
            const k = ((topLength / countTopPiles) * count) / topLength;
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: erker.erkerPointTop.x + (erker.erkerPointRightTop.x - erker.erkerPointTop.x) * k,
                y: erker.erkerPointTop.y + (erker.erkerPointRightTop.y - erker.erkerPointTop.y) * k,
            });
        }
        const bottomLength = Math.sqrt(Math.pow((erker.erkerPointRightBottom.x - erker.erkerPointBottom.x), 2) + Math.pow((erker.erkerPointRightBottom.y - erker.erkerPointBottom.y), 2));
        const countBottomPiles = Math.ceil(bottomLength / MIN_STEP_PILE);
        for (let count = 1; count < countBottomPiles; count++) {
            const k = ((bottomLength / countBottomPiles) * count) / bottomLength;
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: erker.erkerPointBottom.x + (erker.erkerPointRightBottom.x - erker.erkerPointBottom.x) * k,
                y: erker.erkerPointBottom.y + (erker.erkerPointRightBottom.y - erker.erkerPointBottom.y) * k,
            });
        }
    });
    erkers.right.filter(erker => erker.type === "round").forEach(erker => {
        const length = erker.radius * erker.angle * 2 * (Math.PI / 180);
        const countPiles = Math.ceil(length / MIN_STEP_PILE);
        let angleStep = -(erker.angle * (Math.PI / 180) * 2) / countPiles;
        for (let i = 1; i < countPiles; i++) {
            let angle = erker.angle * (Math.PI / 180) + angleStep * i;
            let x = erker.center.x + erker.radius * Math.cos(angle);
            let y = erker.center.y + erker.radius * Math.sin(angle);
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: x,
                y: y,
            });
        }
    });
    // Идём по левым эркерам
    erkers.left.filter(erker => erker.type === "angel").forEach(erker => {
        _piles.push({
            axeX: -1,
            axeY: -1,
            deleted: false,
            x: erker.erkerPointLeftTop.x,
            y: erker.erkerPointLeftTop.y,
        });
        _piles.push({
            axeX: -1,
            axeY: -1,
            deleted: false,
            x: erker.erkerPointLeftBottom.x,
            y: erker.erkerPointLeftBottom.y,
        });
        const erkerSize = erker.erkerPointLeftBottom.y - erker.erkerPointLeftTop.y;
        const countPiles = Math.ceil(erkerSize / MIN_STEP_PILE);
        for (let count = 1; count < countPiles; count++) {
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: erker.erkerPointLeftTop.x,
                y: erker.erkerPointLeftTop.y + (erkerSize / countPiles) * count,
            });
        }
        const topLength = Math.sqrt(Math.pow((erker.erkerPointLeftTop.x - erker.erkerPointTop.x), 2) + Math.pow((erker.erkerPointLeftTop.y - erker.erkerPointTop.y), 2));
        const countTopPiles = Math.ceil(topLength / MIN_STEP_PILE);
        for (let count = 1; count < countTopPiles; count++) {
            const k = ((topLength / countTopPiles) * count) / topLength;
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: erker.erkerPointTop.x + (erker.erkerPointLeftTop.x - erker.erkerPointTop.x) * k,
                y: erker.erkerPointTop.y + (erker.erkerPointLeftTop.y - erker.erkerPointTop.y) * k,
            });
        }
        const bottomLength = Math.sqrt(Math.pow((erker.erkerPointLeftBottom.x - erker.erkerPointBottom.x), 2) + Math.pow((erker.erkerPointLeftBottom.y - erker.erkerPointBottom.y), 2));
        const countBottomPiles = Math.ceil(bottomLength / MIN_STEP_PILE);
        for (let count = 1; count < countBottomPiles; count++) {
            const k = ((bottomLength / countBottomPiles) * count) / bottomLength;
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: erker.erkerPointBottom.x + (erker.erkerPointLeftBottom.x - erker.erkerPointBottom.x) * k,
                y: erker.erkerPointBottom.y + (erker.erkerPointLeftBottom.y - erker.erkerPointBottom.y) * k,
            });
        }
    });
    erkers.left.filter(erker => erker.type === "round").forEach(erker => {
        const length = erker.radius * erker.angle * 2 * (Math.PI / 180);
        const countPiles = Math.ceil(length / MIN_STEP_PILE);
        let angleStep = -(erker.angle * (Math.PI / 180) * 2) / countPiles;
        for (let i = 1; i < countPiles; i++) {
            let angle = (erker.angle + 180) * (Math.PI / 180) + angleStep * i;
            let x = erker.center.x + erker.radius * Math.cos(angle);
            let y = erker.center.y + erker.radius * Math.sin(angle);
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: x,
                y: y,
            });
        }
    });
    // Идём по верхним эркерам
    erkers.top.filter(erker => erker.type === "angel").forEach(erker => {
        _piles.push({
            axeX: -1,
            axeY: -1,
            deleted: false,
            x: erker.erkerPointTopLeft.x,
            y: erker.erkerPointTopLeft.y,
        });
        _piles.push({
            axeX: -1,
            axeY: -1,
            deleted: false,
            x: erker.erkerPointTopRight.x,
            y: erker.erkerPointTopRight.y,
        });
        const erkerSize = erker.erkerPointTopRight.x - erker.erkerPointTopLeft.x;
        const countPiles = Math.ceil(erkerSize / MIN_STEP_PILE);
        for (let count = 1; count < countPiles; count++) {
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: erker.erkerPointTopLeft.x + (erkerSize / countPiles) * count,
                y: erker.erkerPointTopLeft.y,
            });
        }
        const leftLength = Math.sqrt(Math.pow((erker.erkerPointTopLeft.x - erker.erkerPointLeft.x), 2) + Math.pow((erker.erkerPointTopLeft.y - erker.erkerPointLeft.y), 2));
        const countLeftPiles = Math.ceil(leftLength / MIN_STEP_PILE);
        for (let count = 1; count < countLeftPiles; count++) {
            const k = ((leftLength / countLeftPiles) * count) / leftLength;
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: erker.erkerPointLeft.x + (erker.erkerPointTopLeft.x - erker.erkerPointLeft.x) * k,
                y: erker.erkerPointLeft.y + (erker.erkerPointTopLeft.y - erker.erkerPointLeft.y) * k,
            });
        }
        const rightLength = Math.sqrt(Math.pow((erker.erkerPointTopRight.x - erker.erkerPointRight.x), 2) + Math.pow((erker.erkerPointTopRight.y - erker.erkerPointRight.y), 2));
        const countRightPiles = Math.ceil(rightLength / MIN_STEP_PILE);
        for (let count = 1; count < countRightPiles; count++) {
            const k = ((rightLength / countRightPiles) * count) / rightLength;
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: erker.erkerPointRight.x + (erker.erkerPointTopRight.x - erker.erkerPointRight.x) * k,
                y: erker.erkerPointRight.y + (erker.erkerPointTopRight.y - erker.erkerPointRight.y) * k,
            });
        }
    });
    erkers.top.filter(erker => erker.type === "round").forEach(erker => {
        const length = erker.radius * erker.angle * 2 * (Math.PI / 180);
        const countPiles = Math.ceil(length / MIN_STEP_PILE);
        let angleStep = -(erker.angle * (Math.PI / 180) * 2) / countPiles;
        for (let i = 1; i < countPiles; i++) {
            let angle = (erker.angle - 90) * (Math.PI / 180) + angleStep * i;
            let x = erker.center.x + erker.radius * Math.cos(angle);
            let y = erker.center.y + erker.radius * Math.sin(angle);
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: x,
                y: y,
            });
        }
    });
    // Идём по нижним эркерам
    erkers.bottom.filter(erker => erker.type === "angel").forEach(erker => {
        _piles.push({
            axeX: -1,
            axeY: -1,
            deleted: false,
            x: erker.erkerPointBottomLeft.x,
            y: erker.erkerPointBottomLeft.y,
        });
        _piles.push({
            axeX: -1,
            axeY: -1,
            deleted: false,
            x: erker.erkerPointBottomRight.x,
            y: erker.erkerPointBottomRight.y,
        });
        const erkerSize = erker.erkerPointBottomRight.x - erker.erkerPointBottomLeft.x;
        const countPiles = Math.ceil(erkerSize / MIN_STEP_PILE);
        for (let count = 1; count < countPiles; count++) {
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: erker.erkerPointBottomLeft.x + (erkerSize / countPiles) * count,
                y: erker.erkerPointBottomLeft.y,
            });
        }
        const leftLength = Math.sqrt(Math.pow((erker.erkerPointBottomLeft.x - erker.erkerPointLeft.x), 2) + Math.pow((erker.erkerPointBottomLeft.y - erker.erkerPointLeft.y), 2));
        const countLeftPiles = Math.ceil(leftLength / MIN_STEP_PILE);
        for (let count = 1; count < countLeftPiles; count++) {
            const k = ((leftLength / countLeftPiles) * count) / leftLength;
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: erker.erkerPointLeft.x + (erker.erkerPointBottomLeft.x - erker.erkerPointLeft.x) * k,
                y: erker.erkerPointLeft.y + (erker.erkerPointBottomLeft.y - erker.erkerPointLeft.y) * k,
            });
        }
        const rightLength = Math.sqrt(Math.pow((erker.erkerPointBottomRight.x - erker.erkerPointRight.x), 2) + Math.pow((erker.erkerPointBottomRight.y - erker.erkerPointRight.y), 2));
        const countRightPiles = Math.ceil(rightLength / MIN_STEP_PILE);
        for (let count = 1; count < countRightPiles; count++) {
            const k = ((rightLength / countRightPiles) * count) / rightLength;
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: erker.erkerPointRight.x + (erker.erkerPointBottomRight.x - erker.erkerPointRight.x) * k,
                y: erker.erkerPointRight.y + (erker.erkerPointBottomRight.y - erker.erkerPointRight.y) * k,
            });
        }
    });
    erkers.bottom.filter(erker => erker.type === "round").forEach(erker => {
        const length = erker.radius * erker.angle * 2 * (Math.PI / 180);
        const countPiles = Math.ceil(length / MIN_STEP_PILE);
        let angleStep = -(erker.angle * (Math.PI / 180) * 2) / countPiles;
        for (let i = 1; i < countPiles; i++) {
            let angle = (erker.angle + 90) * (Math.PI / 180) + angleStep * i;
            let x = erker.center.x + erker.radius * Math.cos(angle);
            let y = erker.center.y + erker.radius * Math.sin(angle);
            _piles.push({
                axeX: -1,
                axeY: -1,
                deleted: false,
                x: x,
                y: y,
            });
        }
    });

    //идём по каминам
    bakes.forEach(bake => {
        let _axeX = -1;
        let _axeY = -1;
        if (bake.top.offset < 0) _axeX = bake.top.index;
        if (bake.bottom.offset < 0) _axeX = bake.bottom.index;
        if (bake.left.offset < 0) _axeY = bake.left.index;
        if (bake.right.offset < 0) _axeY = bake.right.index;
        if (bake.top.offset < 0 || bake.left.offset < 0) _piles = _piles.filter(pile => calculateLength(pile.x, pile.y, bake.x - bake.width / 2 + 200, bake.y - bake.height / 2 + 200) > _MIN_STEP_PILE);
        if (bake.top.offset < 0 || bake.right.offset < 0) _piles = _piles.filter(pile => calculateLength(pile.x, pile.y, bake.x + bake.width / 2 - 200, bake.y - bake.height / 2 + 200) > _MIN_STEP_PILE);
        if (bake.bottom.offset < 0 || bake.left.offset < 0) _piles = _piles.filter(pile => calculateLength(pile.x, pile.y, bake.x - bake.width / 2 + 200, bake.y + bake.height / 2 - 200) > _MIN_STEP_PILE);
        if (bake.bottom.offset < 0 || bake.right.offset < 0) _piles = _piles.filter(pile => calculateLength(pile.x, pile.y, bake.x + bake.width / 2 - 200, bake.y + bake.height / 2 - 200) > _MIN_STEP_PILE);
        _piles.push({
            axeX: bake.top.offset < 0 ? _axeX : -1,
            axeY: bake.left.offset < 0 ? _axeY : -1,
            deleted: false,
            x: bake.x - bake.width / 2 + 200,
            y: bake.y - bake.height / 2 + 200,
        });
        _piles.push({
            axeX: bake.top.offset < 0 ? _axeX : -1,
            axeY: bake.right.offset < 0 ? _axeY : -1,
            deleted: false,
            x: bake.x + bake.width / 2 - 200,
            y: bake.y - bake.height / 2 + 200,
        });
        _piles.push({
            axeX: bake.bottom.offset < 0 ? _axeX : -1,
            axeY: bake.left.offset < 0 ? _axeY : -1,
            deleted: false,
            x: bake.x - bake.width / 2 + 200,
            y: bake.y + bake.height / 2 - 200,
        });
        _piles.push({
            axeX: bake.bottom.offset < 0 ? _axeX : -1,
            axeY: bake.right.offset < 0 ? _axeY : -1,
            deleted: false,
            x: bake.x + bake.width / 2 - 200,
            y: bake.y + bake.height / 2 - 200,
        });
    })

    return _piles;
}

// Добавление эркера
export const addErker = (type, direction, axes, erkerAxes, stripes, bakes, deletedStripes, widthStripe, erkers, erkerSize, erkerExtension, countX, countY) => {
    let _erkers = erkers;
    let _stripes = stripes;
    let _erkerAxe_1 = -1;
    let _erkerAxe_2 = -1;
    if (type === "angel") {
        if (direction === "leftErker") {

            if (erkerAxes.top < erkerAxes.bottom) {
                _erkerAxe_1 = erkerAxes.top;
                _erkerAxe_2 = erkerAxes.bottom;
            } else if (erkerAxes.top > erkerAxes.bottom) {
                _erkerAxe_2 = erkerAxes.top;
                _erkerAxe_1 = erkerAxes.bottom;
            } else {
                return false;
            }

            if (_erkers.left.find((erker) => erker.name === `${_erkerAxe_1}_${_erkerAxe_2}`)) return false;

            const axe_1 = axes.horizontal.find((axe) => axe.index === _erkerAxe_1);
            const axe_1_index = _erkerAxe_1;
            const axe_2 = axes.horizontal.find((axe) => axe.index === _erkerAxe_2);
            const axe_2_index = _erkerAxe_2;
            const axes_vertical_count = 0;
            const axes_horizontal_count = countX - 1;

            let cross = false;
            _erkers.left.forEach(erker => {
                if (axe_1_index < erker.axeEnd && axe_1_index >= erker.axeStart) cross = true;
                if (axe_2_index <= erker.axeEnd && axe_2_index > erker.axeStart) cross = true;
            });
            if (cross) return false;

            if (axe_1_index === 0) {
                if (deletedStripes.includes(`ver_${axe_1_index}_${axes_vertical_count}`) && deletedStripes.includes(`hor_${axes_vertical_count}_${axe_1_index}`)) return false;
            } else if (axe_2_index === axes_horizontal_count) {
                if (deletedStripes.includes(`ver_${axe_2_index - 1}_${axes_vertical_count}`) && deletedStripes.includes(`hor_${axes_vertical_count}_${axe_2_index}`)) return false;
            }

            let stripeVertical_1 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axe_1_index}_${axes_vertical_count}`);
            let stripeVertical_2 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axe_2_index - 1}_${axes_vertical_count}`);

            let stripeHorizontal_1 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axes_vertical_count}_${axe_1_index}`);
            let stripeHorizontal_2 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axes_vertical_count}_${axe_2_index}`);

            const stripeLength = stripeVertical_2.pointBottom.y - stripeVertical_1.pointTop.y;

            const angel = calculateAngle(stripeVertical_1.pointTop.x - erkerExtension, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x, stripeVertical_1.pointTop.y);
            
            const oneLeftPoint = calculateSecondPoint(stripeVertical_1.pointTop.x, stripeVertical_1.pointTop.y, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let twoLeftPoint = calculateSecondPoint(stripeVertical_1.pointTop.x - erkerExtension, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let threeLeftPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x - erkerExtension, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2, widthStripe / 2, - (90 * Math.PI) / 180 - angel);
            const fourLeftPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x, stripeVertical_2.pointBottom.y, widthStripe / 2, - (90 * Math.PI) / 180 - angel);

            let crossPoint = searchCross(oneLeftPoint.x, oneLeftPoint.y, twoLeftPoint.x, twoLeftPoint.y, stripeVertical_1.pointTop.x - erkerExtension - widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x - erkerExtension - widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            twoLeftPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeLeftPoint.x, threeLeftPoint.y, fourLeftPoint.x, fourLeftPoint.y, stripeVertical_1.pointTop.x - erkerExtension - widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x - erkerExtension - widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            threeLeftPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            const oneRightPoint = calculateSecondPoint(stripeVertical_1.pointTop.x, stripeVertical_1.pointTop.y, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let twoRightPoint = calculateSecondPoint(stripeVertical_1.pointTop.x - erkerExtension, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let threeRightPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x - erkerExtension, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2, widthStripe / 2, (90 * Math.PI) / 180 - angel);
            const fourRightPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x, stripeVertical_2.pointBottom.y, widthStripe / 2, (90 * Math.PI) / 180 - angel);

            crossPoint = searchCross(oneRightPoint.x, oneRightPoint.y, twoRightPoint.x, twoRightPoint.y, stripeVertical_1.pointTop.x - erkerExtension + widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x - erkerExtension + widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            twoRightPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeRightPoint.x, threeRightPoint.y, fourRightPoint.x, fourRightPoint.y, stripeVertical_1.pointTop.x - erkerExtension + widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x - erkerExtension + widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            threeRightPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            _erkers.left.push({
                name: `${_erkerAxe_1}_${_erkerAxe_2}`,
                axeStart: axe_1_index,
                axeEnd: axe_2_index,
                startVertical: `ver_${axe_1_index}_${axes_vertical_count}`,
                endVertical: `ver_${axe_2_index - 1}_${axes_vertical_count}`,
                startHorizontal: `hor_${axes_vertical_count}_${axe_1_index}`,
                endHorizontal: `hor_${axes_vertical_count}_${axe_2_index}`,
                type: type,
                direction: direction,
                extension: erkerExtension,
                width: widthStripe,
                angel: angel * (180 / Math.PI),
                erkerPointTop: stripeVertical_1.pointTop,
                erkerPointBottom: stripeVertical_2.pointBottom,
                erkerPointLeftTop: {
                    x: stripeVertical_1.pointTop.x - erkerExtension,
                    y: stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2,
                },
                erkerPointLeftBottom: {
                    x: stripeVertical_2.pointBottom.x - erkerExtension,
                    y: stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2,
                },
                pathRight: {
                    x1: oneRightPoint.x,
                    y1: oneRightPoint.y,
                    x2: twoRightPoint.x,
                    y2: twoRightPoint.y,
                    x3: threeRightPoint.x,
                    y3: threeRightPoint.y,
                    x4: fourRightPoint.x,
                    y4: fourRightPoint.y,
                },
                pathLeft: {
                    x1: oneLeftPoint.x,
                    y1: oneLeftPoint.y,
                    x2: twoLeftPoint.x,
                    y2: twoLeftPoint.y,
                    x3: threeLeftPoint.x,
                    y3: threeLeftPoint.y,
                    x4: fourLeftPoint.x,
                    y4: fourLeftPoint.y,
                }
            });

        } else if (direction === "rightErker") {

            if (erkerAxes.top < erkerAxes.bottom) {
                _erkerAxe_1 = erkerAxes.top;
                _erkerAxe_2 = erkerAxes.bottom;
            } else if (erkerAxes.top > erkerAxes.bottom) {
                _erkerAxe_2 = erkerAxes.top;
                _erkerAxe_1 = erkerAxes.bottom;
            } else {
                return false;
            }

            if (_erkers.right.find((erker) => erker.name === `${_erkerAxe_1}_${_erkerAxe_2}`)) return false;

            const axe_1 = axes.horizontal.find((axe) => axe.index === _erkerAxe_1);
            const axe_1_index = _erkerAxe_1;
            const axe_2 = axes.horizontal.find((axe) => axe.index === _erkerAxe_2);
            const axe_2_index = _erkerAxe_2;
            const axes_vertical_count = countY - 1;
            const axes_horizontal_count = countX - 1;

            let cross = false;
            _erkers.right.forEach(erker => {
                if (axe_1_index < erker.axeEnd && axe_1_index >= erker.axeStart) cross = true;
                if (axe_2_index <= erker.axeEnd && axe_2_index > erker.axeStart) cross = true;
            });
            if (cross) return false;

            if (axe_1_index === 0) {
                if (deletedStripes.includes(`ver_${axe_1_index}_${axes_vertical_count}`) && deletedStripes.includes(`hor_${axes_vertical_count - 1}_${axe_1_index}`)) return false;
            } else if (axe_2_index === axes_horizontal_count) {
                if (deletedStripes.includes(`ver_${axe_2_index - 1}_${axes_vertical_count}`) && deletedStripes.includes(`hor_${axes_vertical_count - 1}_${axe_2_index}`)) return false;
            }

            let stripeVertical_1 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axe_1_index}_${axes_vertical_count}`);
            let stripeVertical_2 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axe_2_index - 1}_${axes_vertical_count}`);

            let stripeHorizontal_1 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axes_vertical_count - 1}_${axe_1_index}`);
            let stripeHorizontal_2 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axes_vertical_count - 1}_${axe_2_index}`);

            const stripeLength = stripeVertical_2.pointBottom.y - stripeVertical_1.pointTop.y;

            const angel = calculateAngle(stripeVertical_1.pointTop.x + erkerExtension, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x, stripeVertical_1.pointTop.y);
            
            const oneRightPoint = calculateSecondPoint(stripeVertical_1.pointTop.x, stripeVertical_1.pointTop.y, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let twoRightPoint = calculateSecondPoint(stripeVertical_1.pointTop.x + erkerExtension, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let threeRightPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x + erkerExtension, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2, widthStripe / 2, (90 * Math.PI) / 180 - angel);
            const fourRightPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x, stripeVertical_2.pointBottom.y, widthStripe / 2, (90 * Math.PI) / 180 - angel);

            let crossPoint = searchCross(oneRightPoint.x, oneRightPoint.y, twoRightPoint.x, twoRightPoint.y, stripeVertical_1.pointTop.x + erkerExtension + widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x + erkerExtension + widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            twoRightPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeRightPoint.x, threeRightPoint.y, fourRightPoint.x, fourRightPoint.y, stripeVertical_1.pointTop.x + erkerExtension + widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x + erkerExtension + widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            threeRightPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            const oneLeftPoint = calculateSecondPoint(stripeVertical_1.pointTop.x, stripeVertical_1.pointTop.y, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let twoLeftPoint = calculateSecondPoint(stripeVertical_1.pointTop.x + erkerExtension, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let threeLeftPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x + erkerExtension, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2, widthStripe / 2, - (90 * Math.PI) / 180 - angel);
            const fourLeftPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x, stripeVertical_2.pointBottom.y, widthStripe / 2, - (90 * Math.PI) / 180 - angel);

            crossPoint = searchCross(oneLeftPoint.x, oneLeftPoint.y, twoLeftPoint.x, twoLeftPoint.y, stripeVertical_1.pointTop.x + erkerExtension - widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x + erkerExtension - widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            twoLeftPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeLeftPoint.x, threeLeftPoint.y, fourLeftPoint.x, fourLeftPoint.y, stripeVertical_1.pointTop.x + erkerExtension - widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x + erkerExtension - widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            threeLeftPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            _erkers.right.push({
                name: `${_erkerAxe_1}_${_erkerAxe_2}`,
                axeStart: axe_1_index,
                axeEnd: axe_2_index,
                startVertical: `ver_${axe_1_index}_${axes_vertical_count}`,
                endVertical: `ver_${axe_2_index - 1}_${axes_vertical_count}`,
                startHorizontal: `hor_${axes_vertical_count - 1}_${axe_1_index}`,
                endHorizontal: `hor_${axes_vertical_count - 1}_${axe_2_index}`,
                type: type,
                direction: direction,
                extension: erkerExtension,
                width: widthStripe,
                angel: angel * (180 / Math.PI),
                erkerPointTop: stripeVertical_1.pointTop,
                erkerPointBottom: stripeVertical_2.pointBottom,
                erkerPointRightTop: {
                    x: stripeVertical_1.pointTop.x + erkerExtension,
                    y: stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2,
                },
                erkerPointRightBottom: {
                    x: stripeVertical_2.pointBottom.x + erkerExtension,
                    y: stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2,
                },
                pathLeft: {
                    x1: oneLeftPoint.x,
                    y1: oneLeftPoint.y,
                    x2: twoLeftPoint.x,
                    y2: twoLeftPoint.y,
                    x3: threeLeftPoint.x,
                    y3: threeLeftPoint.y,
                    x4: fourLeftPoint.x,
                    y4: fourLeftPoint.y,
                },
                pathRight: {
                    x1: oneRightPoint.x,
                    y1: oneRightPoint.y,
                    x2: twoRightPoint.x,
                    y2: twoRightPoint.y,
                    x3: threeRightPoint.x,
                    y3: threeRightPoint.y,
                    x4: fourRightPoint.x,
                    y4: fourRightPoint.y,
                }
            });

        } else if (direction === "topErker") {
            if (erkerAxes.left < erkerAxes.right) {
                _erkerAxe_1 = erkerAxes.left;
                _erkerAxe_2 = erkerAxes.right;
            } else if (erkerAxes.left > erkerAxes.right) {
                _erkerAxe_2 = erkerAxes.left;
                _erkerAxe_1 = erkerAxes.right;
            } else {
                return false;
            }

            if (_erkers.top.find((erker) => erker.name === `${_erkerAxe_1}_${_erkerAxe_2}`)) return false;

            const axe_1 = axes.vertical.find((axe) => axe.index === _erkerAxe_1);
            const axe_1_index = _erkerAxe_1;
            const axe_2 = axes.vertical.find((axe) => axe.index === _erkerAxe_2);
            const axe_2_index = _erkerAxe_2;
            const axes_vertical_count = countY - 1;
            const axes_horizontal_count = 0;

            let cross = false;
            _erkers.top.forEach(erker => {
                if (axe_1_index < erker.axeEnd && axe_1_index >= erker.axeStart) cross = true;
                if (axe_2_index <= erker.axeEnd && axe_2_index > erker.axeStart) cross = true;
            });
            if (cross) return false;

            if (axe_1_index === 0) {
                if (deletedStripes.includes(`hor_${axe_1_index}_${axes_horizontal_count}`) && deletedStripes.includes(`ver_${axes_horizontal_count}_${axe_1_index}`)) return false;
            } else if (axe_2_index === axes_vertical_count) {
                if (deletedStripes.includes(`hor_${axe_2_index - 1}_${axes_horizontal_count}`) && deletedStripes.includes(`ver_${axes_horizontal_count}_${axe_2_index}`)) return false;
            }

            let stripeHorizontal_1 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axe_1_index}_${axes_horizontal_count}`);
            let stripeHorizontal_2 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axe_2_index - 1}_${axes_horizontal_count}`);

            let stripeVertical_1 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axes_horizontal_count}_${axe_1_index}`);
            let stripeVertical_2 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axes_horizontal_count}_${axe_2_index}`);

            const stripeLength = stripeHorizontal_2.pointRight.x - stripeHorizontal_1.pointLeft.x;

            const angel = calculateAngle(stripeHorizontal_1.pointLeft.x, stripeHorizontal_1.pointLeft.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension);

            const oneTopPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x, stripeHorizontal_1.pointLeft.y, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let twoTopPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let threeTopPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension, widthStripe / 2, (90 * Math.PI) / 180 - angel);
            const fourTopPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x, stripeHorizontal_2.pointRight.y, widthStripe / 2, (90 * Math.PI) / 180 - angel);

            let crossPoint = searchCross(oneTopPoint.x, oneTopPoint.y, twoTopPoint.x, twoTopPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension - widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension - widthStripe / 2);
            twoTopPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeTopPoint.x, threeTopPoint.y, fourTopPoint.x, fourTopPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension - widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension - widthStripe / 2);
            threeTopPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            const oneBottomPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x, stripeHorizontal_1.pointLeft.y, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let twoBottomPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let threeBottomPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension, widthStripe / 2, - (90 * Math.PI) / 180 - angel);
            const fourBottomPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x, stripeHorizontal_2.pointRight.y, widthStripe / 2, - (90 * Math.PI) / 180 - angel);

            crossPoint = searchCross(oneBottomPoint.x, oneBottomPoint.y, twoBottomPoint.x, twoBottomPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension + widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension + widthStripe / 2);
            twoBottomPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeBottomPoint.x, threeBottomPoint.y, fourBottomPoint.x, fourBottomPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension + widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension + widthStripe / 2);
            threeBottomPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            _erkers.top.push({
                name: `${_erkerAxe_1}_${_erkerAxe_2}`,
                axeStart: axe_1_index,
                axeEnd: axe_2_index,
                startVertical: `ver_${axes_horizontal_count}_${axe_1_index}`,
                endVertical: `ver_${axes_horizontal_count}_${axe_2_index}`,
                startHorizontal: `hor_${axe_1_index}_${axes_horizontal_count}`,
                endHorizontal: `hor_${axe_2_index - 1}_${axes_horizontal_count}`,
                type: type,
                direction: direction,
                extension: erkerExtension,
                width: widthStripe,
                angel: angel * (180 / Math.PI),
                erkerPointLeft: stripeHorizontal_1.pointLeft,
                erkerPointRight: stripeHorizontal_2.pointRight,
                erkerPointTopLeft: {
                    x: stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2,
                    y: stripeHorizontal_1.pointLeft.y - erkerExtension,
                },
                erkerPointTopRight: {
                    x: stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2,
                    y: stripeHorizontal_2.pointRight.y - erkerExtension,
                },
                pathBottom: {
                    x1: oneBottomPoint.x,
                    y1: oneBottomPoint.y,
                    x2: twoBottomPoint.x,
                    y2: twoBottomPoint.y,
                    x3: threeBottomPoint.x,
                    y3: threeBottomPoint.y,
                    x4: fourBottomPoint.x,
                    y4: fourBottomPoint.y,
                },
                pathTop: {
                    x1: oneTopPoint.x,
                    y1: oneTopPoint.y,
                    x2: twoTopPoint.x,
                    y2: twoTopPoint.y,
                    x3: threeTopPoint.x,
                    y3: threeTopPoint.y,
                    x4: fourTopPoint.x,
                    y4: fourTopPoint.y,
                }
            });

        } else if (direction === "bottomErker") {
            if (erkerAxes.left < erkerAxes.right) {
                _erkerAxe_1 = erkerAxes.left;
                _erkerAxe_2 = erkerAxes.right;
            } else if (erkerAxes.left > erkerAxes.right) {
                _erkerAxe_2 = erkerAxes.left;
                _erkerAxe_1 = erkerAxes.right;
            } else {
                return false;
            }

            if (_erkers.bottom.find((erker) => erker.name === `${_erkerAxe_1}_${_erkerAxe_2}`)) return false;

            const axe_1 = axes.vertical.find((axe) => axe.index === _erkerAxe_1);
            const axe_1_index = _erkerAxe_1;
            const axe_2 = axes.vertical.find((axe) => axe.index === _erkerAxe_2);
            const axe_2_index = _erkerAxe_2;
            const axes_vertical_count = countY - 1;
            const axes_horizontal_count = countX - 1;

            let cross = false;
            _erkers.bottom.forEach(erker => {
                if (axe_1_index < erker.axeEnd && axe_1_index >= erker.axeStart) cross = true;
                if (axe_2_index <= erker.axeEnd && axe_2_index > erker.axeStart) cross = true;
            });
            if (cross) return false;

            if (axe_1_index === 0) {
                if (deletedStripes.includes(`hor_${axe_1_index}_${axes_horizontal_count}`) && deletedStripes.includes(`ver_${axes_horizontal_count - 1}_${axe_1_index}`)) return false;
            } else if (axe_2_index === axes_vertical_count) {
                if (deletedStripes.includes(`hor_${axe_2_index - 1}_${axes_horizontal_count}`) && deletedStripes.includes(`ver_${axes_horizontal_count - 1}_${axe_2_index}`)) return false;
            }

            let stripeHorizontal_1 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axe_1_index}_${axes_horizontal_count}`);
            let stripeHorizontal_2 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axe_2_index - 1}_${axes_horizontal_count}`);

            let stripeVertical_1 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axes_horizontal_count - 1}_${axe_1_index}`);
            let stripeVertical_2 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axes_horizontal_count - 1}_${axe_2_index}`);

            const stripeLength = stripeHorizontal_2.pointRight.x - stripeHorizontal_1.pointLeft.x;

            const angel = calculateAngle(stripeHorizontal_1.pointLeft.x, stripeHorizontal_1.pointLeft.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension);

            const oneBottomPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x, stripeHorizontal_1.pointLeft.y, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let twoBottomPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let threeBottomPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension, widthStripe / 2, - (90 * Math.PI) / 180 - angel);
            const fourBottomPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x, stripeHorizontal_2.pointRight.y, widthStripe / 2, - (90 * Math.PI) / 180 - angel);

            let crossPoint = searchCross(oneBottomPoint.x, oneBottomPoint.y, twoBottomPoint.x, twoBottomPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension + widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension + widthStripe / 2);
            twoBottomPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeBottomPoint.x, threeBottomPoint.y, fourBottomPoint.x, fourBottomPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension + widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension + widthStripe / 2);
            threeBottomPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            const oneTopPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x, stripeHorizontal_1.pointLeft.y, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let twoTopPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let threeTopPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension, widthStripe / 2, (90 * Math.PI) / 180 - angel);
            const fourTopPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x, stripeHorizontal_2.pointRight.y, widthStripe / 2, (90 * Math.PI) / 180 - angel);

            crossPoint = searchCross(oneTopPoint.x, oneTopPoint.y, twoTopPoint.x, twoTopPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension - widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension - widthStripe / 2);
            twoTopPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeTopPoint.x, threeTopPoint.y, fourTopPoint.x, fourTopPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension - widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension - widthStripe / 2);
            threeTopPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            _erkers.bottom.push({
                name: `${_erkerAxe_1}_${_erkerAxe_2}`,
                axeStart: axe_1_index,
                axeEnd: axe_2_index,
                startVertical: `ver_${axes_horizontal_count - 1}_${axe_1_index}`,
                endVertical: `ver_${axes_horizontal_count - 1}_${axe_2_index}`,
                startHorizontal: `hor_${axe_1_index}_${axes_horizontal_count}`,
                endHorizontal: `hor_${axe_2_index - 1}_${axes_horizontal_count}`,
                type: type,
                direction: direction,
                extension: erkerExtension,
                width: widthStripe,
                angel: angel * (180 / Math.PI),
                erkerPointLeft: stripeHorizontal_1.pointLeft,
                erkerPointRight: stripeHorizontal_2.pointRight,
                erkerPointBottomLeft: {
                    x: stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2,
                    y: stripeHorizontal_1.pointLeft.y + erkerExtension,
                },
                erkerPointBottomRight: {
                    x: stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2,
                    y: stripeHorizontal_2.pointRight.y + erkerExtension,
                },
                pathTop: {
                    x1: oneTopPoint.x,
                    y1: oneTopPoint.y,
                    x2: twoTopPoint.x,
                    y2: twoTopPoint.y,
                    x3: threeTopPoint.x,
                    y3: threeTopPoint.y,
                    x4: fourTopPoint.x,
                    y4: fourTopPoint.y,
                },
                pathBottom: {
                    x1: oneBottomPoint.x,
                    y1: oneBottomPoint.y,
                    x2: twoBottomPoint.x,
                    y2: twoBottomPoint.y,
                    x3: threeBottomPoint.x,
                    y3: threeBottomPoint.y,
                    x4: fourBottomPoint.x,
                    y4: fourBottomPoint.y,
                }
            });

        }
    } else if (type === "round") {
        if (direction === "leftErker") {

            if (erkerAxes.top < erkerAxes.bottom) {
                _erkerAxe_1 = erkerAxes.top;
                _erkerAxe_2 = erkerAxes.bottom;
            } else if (erkerAxes.top > erkerAxes.bottom) {
                _erkerAxe_2 = erkerAxes.top;
                _erkerAxe_1 = erkerAxes.bottom;
            } else {
                return false;
            }

            if (_erkers.left.find((erker) => erker.name === `${_erkerAxe_1}_${_erkerAxe_2}`)) return false;

            const axe_1 = axes.horizontal.find((axe) => axe.index === _erkerAxe_1);
            const axe_1_index = _erkerAxe_1;
            const axe_2 = axes.horizontal.find((axe) => axe.index === _erkerAxe_2);
            const axe_2_index = _erkerAxe_2;
            const axes_vertical_count = 0;
            const axes_horizontal_count = countX - 1;

            let cross = false;
            _erkers.left.forEach(erker => {
                if (axe_1_index < erker.axeEnd && axe_1_index >= erker.axeStart) cross = true;
                if (axe_2_index <= erker.axeEnd && axe_2_index > erker.axeStart) cross = true;
            });
            if (cross) return false;

            if (axe_1_index === 0) {
                if (deletedStripes.includes(`ver_${axe_1_index}_${axes_vertical_count}`) && deletedStripes.includes(`hor_${axes_vertical_count}_${axe_1_index}`)) return false;
            } else if (axe_2_index === axes_horizontal_count) {
                if (deletedStripes.includes(`ver_${axe_2_index - 1}_${axes_vertical_count}`) && deletedStripes.includes(`hor_${axes_vertical_count}_${axe_2_index}`)) return false;
            }

            let stripeVertical_1 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axe_1_index}_${axes_vertical_count}`);
            let stripeVertical_2 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axe_2_index - 1}_${axes_vertical_count}`);

            let stripeHorizontal_1 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axes_vertical_count}_${axe_1_index}`);
            let stripeHorizontal_2 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axes_vertical_count}_${axe_2_index}`);

            const stripeLength = stripeVertical_2.pointBottom.y - stripeVertical_1.pointTop.y;

            if (erkerExtension > stripeLength / 2) erkerExtension = stripeLength / 2;

            const mainRadius = (Math.pow(stripeLength / 2, 2) + Math.pow(erkerExtension, 2)) / (2 * erkerExtension);
            const _innerRadius = mainRadius - widthStripe / 2;
            const _outerRadius = mainRadius + widthStripe / 2;
            const _angle = Math.acos((mainRadius - erkerExtension + stripeVertical_1.leftWidth) / mainRadius);
            const _innerAngle = Math.acos((mainRadius - erkerExtension + stripeVertical_1.leftWidth) / _innerRadius);
            const _outerAngle = Math.acos((mainRadius - erkerExtension + stripeVertical_1.leftWidth) / _outerRadius);

            _erkers.left.push({
                name: `${_erkerAxe_1}_${_erkerAxe_2}`,
                axeStart: axe_1_index,
                axeEnd: axe_2_index,
                startVertical: `ver_${axe_1_index}_${axes_vertical_count}`,
                endVertical: `ver_${axe_2_index - 1}_${axes_vertical_count}`,
                startHorizontal: `hor_${axes_vertical_count}_${axe_1_index}`,
                endHorizontal: `hor_${axes_vertical_count}_${axe_2_index}`,
                type: type,
                direction: direction,
                extension: erkerExtension,
                width: widthStripe,
                radius: mainRadius,
                angle: _angle * (180 / Math.PI),
                innerAngle: {
                    top: _innerAngle * (180 / Math.PI),
                    bottom: _innerAngle * (180 / Math.PI),
                },
                outerAngle: {
                    top: _outerAngle * (180 / Math.PI),
                    bottom: _outerAngle * (180 / Math.PI),
                },
                innerRadius: _innerRadius,
                outerRadius: _outerRadius,
                center: {
                    x: stripeVertical_1.pointTop.x - erkerExtension + mainRadius,
                    y: stripeVertical_1.pointTop.y + stripeLength / 2,
                },
                erkerPointTop: stripeHorizontal_1.pointLeft,
                erkerPointBottom: stripeHorizontal_2.pointLeft,
            });

        } else if (direction === "rightErker") {

            if (erkerAxes.top < erkerAxes.bottom) {
                _erkerAxe_1 = erkerAxes.top;
                _erkerAxe_2 = erkerAxes.bottom;
            } else if (erkerAxes.top > erkerAxes.bottom) {
                _erkerAxe_2 = erkerAxes.top;
                _erkerAxe_1 = erkerAxes.bottom;
            } else {
                return false;
            }

            if (_erkers.right.find((erker) => erker.name === `${_erkerAxe_1}_${_erkerAxe_2}`)) return false;

            const axe_1 = axes.horizontal.find((axe) => axe.index === _erkerAxe_1);
            const axe_1_index = _erkerAxe_1;
            const axe_2 = axes.horizontal.find((axe) => axe.index === _erkerAxe_2);
            const axe_2_index = _erkerAxe_2;
            const axes_vertical_count = countY - 1;
            const axes_horizontal_count = countX - 1;

            let cross = false;
            _erkers.right.forEach(erker => {
                if (axe_1_index < erker.axeEnd && axe_1_index >= erker.axeStart) cross = true;
                if (axe_2_index <= erker.axeEnd && axe_2_index > erker.axeStart) cross = true;
            });
            if (cross) return false;

            if (axe_1_index === 0) {
                if (deletedStripes.includes(`ver_${axe_1_index}_${axes_vertical_count}`) && deletedStripes.includes(`hor_${axes_vertical_count - 1}_${axe_1_index}`)) return false;
            } else if (axe_2_index === axes_horizontal_count) {
                if (deletedStripes.includes(`ver_${axe_2_index - 1}_${axes_vertical_count}`) && deletedStripes.includes(`hor_${axes_vertical_count - 1}_${axe_2_index}`)) return false;
            }

            let stripeVertical_1 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axe_1_index}_${axes_vertical_count}`);
            let stripeVertical_2 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axe_2_index - 1}_${axes_vertical_count}`);

            let stripeHorizontal_1 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axes_vertical_count - 1}_${axe_1_index}`);
            let stripeHorizontal_2 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axes_vertical_count - 1}_${axe_2_index}`);

            const stripeLength = stripeVertical_2.pointBottom.y - stripeVertical_1.pointTop.y;

            if (erkerExtension > stripeLength / 2) erkerExtension = stripeLength / 2;

            const mainRadius = (Math.pow(stripeLength / 2, 2) + Math.pow(erkerExtension, 2)) / (2 * erkerExtension);
            const _innerRadius = mainRadius - widthStripe / 2;
            const _outerRadius = mainRadius + widthStripe / 2;
            const _angle = Math.acos((mainRadius - erkerExtension + stripeVertical_1.rightWidth) / mainRadius);
            const _innerAngle = Math.acos((mainRadius - erkerExtension + stripeVertical_1.rightWidth) / _innerRadius);
            const _outerAngle = Math.acos((mainRadius - erkerExtension + stripeVertical_1.rightWidth) / _outerRadius);

            _erkers.right.push({
                name: `${_erkerAxe_1}_${_erkerAxe_2}`,
                axeStart: axe_1_index,
                axeEnd: axe_2_index,
                startVertical: `ver_${axe_1_index}_${axes_vertical_count}`,
                endVertical: `ver_${axe_2_index - 1}_${axes_vertical_count}`,
                startHorizontal: `hor_${axes_vertical_count - 1}_${axe_1_index}`,
                endHorizontal: `hor_${axes_vertical_count - 1}_${axe_2_index}`,
                type: type,
                direction: direction,
                extension: erkerExtension,
                width: widthStripe,
                radius: mainRadius,
                angle: _angle * (180 / Math.PI),
                innerAngle: {
                    top: _innerAngle * (180 / Math.PI),
                    bottom: _innerAngle * (180 / Math.PI),
                },
                outerAngle: {
                    top: _outerAngle * (180 / Math.PI),
                    bottom: _outerAngle * (180 / Math.PI),
                },
                innerRadius: _innerRadius,
                outerRadius: _outerRadius,
                center: {
                    x: stripeVertical_1.pointTop.x + erkerExtension - mainRadius,
                    y: stripeVertical_1.pointTop.y + stripeLength / 2,
                },
                erkerPointTop: stripeHorizontal_1.pointRight,
                erkerPointBottom: stripeHorizontal_2.pointRight,
            });

        } else if (direction === "topErker") {

            if (erkerAxes.left < erkerAxes.right) {
                _erkerAxe_1 = erkerAxes.left;
                _erkerAxe_2 = erkerAxes.right;
            } else if (erkerAxes.left > erkerAxes.right) {
                _erkerAxe_2 = erkerAxes.left;
                _erkerAxe_1 = erkerAxes.right;
            } else {
                return false;
            }

            if (_erkers.top.find((erker) => erker.name === `${_erkerAxe_1}_${_erkerAxe_2}`)) return false;

            const axe_1 = axes.vertical.find((axe) => axe.index === _erkerAxe_1);
            const axe_1_index = _erkerAxe_1;
            const axe_2 = axes.vertical.find((axe) => axe.index === _erkerAxe_2);
            const axe_2_index = _erkerAxe_2;
            const axes_vertical_count = countY - 1;
            const axes_horizontal_count = 0;

            let cross = false;
            _erkers.top.forEach(erker => {
                if (axe_1_index < erker.axeEnd && axe_1_index >= erker.axeStart) cross = true;
                if (axe_2_index <= erker.axeEnd && axe_2_index > erker.axeStart) cross = true;
            });
            if (cross) return false;

            if (axe_1_index === 0) {
                if (deletedStripes.includes(`hor_${axe_1_index}_${axes_horizontal_count}`) && deletedStripes.includes(`ver_${axes_horizontal_count}_${axe_1_index}`)) return false;
            } else if (axe_2_index === axes_vertical_count) {
                if (deletedStripes.includes(`hor_${axe_2_index - 1}_${axes_horizontal_count}`) && deletedStripes.includes(`ver_${axes_horizontal_count}_${axe_2_index}`)) return false;
            }

            let stripeHorizontal_1 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axe_1_index}_${axes_horizontal_count}`);
            let stripeHorizontal_2 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axe_2_index - 1}_${axes_horizontal_count}`);

            let stripeVertical_1 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axes_horizontal_count}_${axe_1_index}`);
            let stripeVertical_2 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axes_horizontal_count}_${axe_2_index}`);

            const stripeLength = stripeHorizontal_2.pointRight.x - stripeHorizontal_1.pointLeft.x;

            if (erkerExtension > stripeLength / 2) erkerExtension = stripeLength / 2;

            const mainRadius = (Math.pow(stripeLength / 2, 2) + Math.pow(erkerExtension, 2)) / (2 * erkerExtension);
            const _innerRadius = mainRadius - widthStripe / 2;
            const _outerRadius = mainRadius + widthStripe / 2;
            const _angle = Math.acos((mainRadius - erkerExtension + stripeHorizontal_1.topWidth) / mainRadius);
            const _innerAngle = Math.acos((mainRadius - erkerExtension + stripeHorizontal_1.topWidth) / _innerRadius);
            const _outerAngle = Math.acos((mainRadius - erkerExtension + stripeHorizontal_1.topWidth) / _outerRadius);

            _erkers.top.push({
                name: `${_erkerAxe_1}_${_erkerAxe_2}`,
                axeStart: axe_1_index,
                axeEnd: axe_2_index,
                startVertical: `ver_${axes_horizontal_count}_${axe_1_index}`,
                endVertical: `ver_${axes_horizontal_count}_${axe_2_index}`,
                startHorizontal: `hor_${axe_1_index}_${axes_horizontal_count}`,
                endHorizontal: `hor_${axe_2_index - 1}_${axes_horizontal_count}`,
                type: type,
                direction: direction,
                extension: erkerExtension,
                width: widthStripe,
                radius: mainRadius,
                angle: _angle * (180 / Math.PI),
                innerAngle: {
                    left: _innerAngle * (180 / Math.PI),
                    right: _innerAngle * (180 / Math.PI),
                },
                outerAngle: {
                    left: _outerAngle * (180 / Math.PI),
                    right: _outerAngle * (180 / Math.PI),
                },
                innerRadius: _innerRadius,
                outerRadius: _outerRadius,
                center: {
                    x: stripeHorizontal_1.pointLeft.x + stripeLength / 2,
                    y: stripeHorizontal_1.pointLeft.y - erkerExtension + mainRadius,
                },
                erkerPointLeft: stripeVertical_1.pointTop,
                erkerPointRight: stripeVertical_2.pointTop,
            });

        } else if (direction === "bottomErker") {

            if (erkerAxes.left < erkerAxes.right) {
                _erkerAxe_1 = erkerAxes.left;
                _erkerAxe_2 = erkerAxes.right;
            } else if (erkerAxes.left > erkerAxes.right) {
                _erkerAxe_2 = erkerAxes.left;
                _erkerAxe_1 = erkerAxes.right;
            } else {
                return false;
            }

            if (_erkers.bottom.find((erker) => erker.name === `${_erkerAxe_1}_${_erkerAxe_2}`)) return false;

            const axe_1 = axes.vertical.find((axe) => axe.index === _erkerAxe_1);
            const axe_1_index = _erkerAxe_1;
            const axe_2 = axes.vertical.find((axe) => axe.index === _erkerAxe_2);
            const axe_2_index = _erkerAxe_2;
            const axes_vertical_count = countY - 1;
            const axes_horizontal_count = countX - 1;

            let cross = false;
            _erkers.bottom.forEach(erker => {
                if (axe_1_index < erker.axeEnd && axe_1_index >= erker.axeStart) cross = true;
                if (axe_2_index <= erker.axeEnd && axe_2_index > erker.axeStart) cross = true;
            });
            if (cross) return false;

            if (axe_1_index === 0) {
                if (deletedStripes.includes(`hor_${axe_1_index}_${axes_horizontal_count}`) && deletedStripes.includes(`ver_${axes_horizontal_count - 1}_${axe_1_index}`)) return false;
            } else if (axe_2_index === axes_vertical_count) {
                if (deletedStripes.includes(`hor_${axe_2_index - 1}_${axes_horizontal_count}`) && deletedStripes.includes(`ver_${axes_horizontal_count - 1}_${axe_2_index}`)) return false;
            }

            let stripeHorizontal_1 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axe_1_index}_${axes_horizontal_count}`);
            let stripeHorizontal_2 = _stripes.horizontal.find((stripe) => stripe.name === `hor_${axe_2_index - 1}_${axes_horizontal_count}`);

            let stripeVertical_1 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axes_horizontal_count - 1}_${axe_1_index}`);
            let stripeVertical_2 = _stripes.vertical.find((stripe) => stripe.name === `ver_${axes_horizontal_count - 1}_${axe_2_index}`);

            const stripeLength = stripeHorizontal_2.pointRight.x - stripeHorizontal_1.pointLeft.x;

            if (erkerExtension > stripeLength / 2) erkerExtension = stripeLength / 2;

            const mainRadius = (Math.pow(stripeLength / 2, 2) + Math.pow(erkerExtension, 2)) / (2 * erkerExtension);
            const _innerRadius = mainRadius - widthStripe / 2;
            const _outerRadius = mainRadius + widthStripe / 2;
            const _angle = Math.acos((mainRadius - erkerExtension + stripeHorizontal_1.bottomWidth) / mainRadius);
            const _innerAngle = Math.acos((mainRadius - erkerExtension + stripeHorizontal_1.bottomWidth) / _innerRadius);
            const _outerAngle = Math.acos((mainRadius - erkerExtension + stripeHorizontal_1.bottomWidth) / _outerRadius);

            _erkers.bottom.push({
                name: `${_erkerAxe_1}_${_erkerAxe_2}`,
                axeStart: axe_1_index,
                axeEnd: axe_2_index,
                startVertical: `ver_${axes_horizontal_count - 1}_${axe_1_index}`,
                endVertical: `ver_${axes_horizontal_count - 1}_${axe_2_index}`,
                startHorizontal: `hor_${axe_1_index}_${axes_horizontal_count}`,
                endHorizontal: `hor_${axe_2_index - 1}_${axes_horizontal_count}`,
                type: type,
                direction: direction,
                extension: erkerExtension,
                width: widthStripe,
                radius: mainRadius,
                angle: _angle * (180 / Math.PI),
                innerAngle: {
                    left: _innerAngle * (180 / Math.PI),
                    right: _innerAngle * (180 / Math.PI),
                },
                outerAngle: {
                    left: _outerAngle * (180 / Math.PI),
                    right: _outerAngle * (180 / Math.PI),
                },
                innerRadius: _innerRadius,
                outerRadius: _outerRadius,
                center: {
                    x: stripeHorizontal_1.pointLeft.x + stripeLength / 2,
                    y: stripeHorizontal_1.pointLeft.y + erkerExtension - mainRadius,
                },
                erkerPointLeft: stripeVertical_1.pointBottom,
                erkerPointRight: stripeVertical_2.pointBottom,
            });

        }
    }

    const result = recalculateLines(_stripes, deletedStripes, _erkers, bakes, countX, countY);

    if (result) { return [result.erkers, result.stripes] } else return false;
}

// Вытягивание эркеров
export const resizeErker = (erker, stripes, delta) => {    
    if (erker.type === 'angel') {

        if (erker.direction === 'leftErker') {

            let stripeVertical_1 = stripes.vertical.find((stripe) => stripe.name === erker.startVertical);
            let stripeVertical_2 = stripes.vertical.find((stripe) => stripe.name === erker.endVertical);

            let stripeHorizontal_1 = stripes.horizontal.find((stripe) => stripe.name === erker.startHorizontal);
            let stripeHorizontal_2 = stripes.horizontal.find((stripe) => stripe.name === erker.endHorizontal);

            const erkerSize = erker.erkerPointLeftBottom.y - erker.erkerPointLeftTop.y;
            const erkerExtension = erker.extension;
            const widthStripe = erker.width;
            const stripeLength = stripeVertical_2.pointBottom.y - stripeVertical_1.pointTop.y;

            const angel = calculateAngle(stripeVertical_1.pointTop.x - erkerExtension, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x, stripeVertical_1.pointTop.y);
            
            const oneLeftPoint = calculateSecondPoint(stripeVertical_1.pointTop.x, stripeVertical_1.pointTop.y, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let twoLeftPoint = calculateSecondPoint(stripeVertical_1.pointTop.x - erkerExtension, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let threeLeftPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x - erkerExtension, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2, widthStripe / 2, - (90 * Math.PI) / 180 - angel);
            const fourLeftPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x, stripeVertical_2.pointBottom.y, widthStripe / 2, - (90 * Math.PI) / 180 - angel);

            let crossPoint = searchCross(oneLeftPoint.x, oneLeftPoint.y, twoLeftPoint.x, twoLeftPoint.y, stripeVertical_1.pointTop.x - erkerExtension - widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x - erkerExtension - widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            twoLeftPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeLeftPoint.x, threeLeftPoint.y, fourLeftPoint.x, fourLeftPoint.y, stripeVertical_1.pointTop.x - erkerExtension - widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x - erkerExtension - widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            threeLeftPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            const oneRightPoint = calculateSecondPoint(stripeVertical_1.pointTop.x, stripeVertical_1.pointTop.y, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let twoRightPoint = calculateSecondPoint(stripeVertical_1.pointTop.x - erkerExtension, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let threeRightPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x - erkerExtension, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2, widthStripe / 2, (90 * Math.PI) / 180 - angel);
            const fourRightPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x, stripeVertical_2.pointBottom.y, widthStripe / 2, (90 * Math.PI) / 180 - angel);

            crossPoint = searchCross(oneRightPoint.x, oneRightPoint.y, twoRightPoint.x, twoRightPoint.y, stripeVertical_1.pointTop.x - erkerExtension + widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x - erkerExtension + widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            twoRightPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeRightPoint.x, threeRightPoint.y, fourRightPoint.x, fourRightPoint.y, stripeVertical_1.pointTop.x - erkerExtension + widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x - erkerExtension + widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            threeRightPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            erker = {
                name: erker.name,
                axeStart: erker.axeStart,
                axeEnd: erker.axeEnd,
                startVertical: erker.startVertical,
                endVertical: erker.endVertical,
                startHorizontal: erker.startHorizontal,
                endHorizontal: erker.endHorizontal,
                type: erker.type,
                direction: erker.direction,
                extension: erkerExtension,
                width: widthStripe,
                angel: angel * (180 / Math.PI),
                erkerPointTop: stripeVertical_1.pointTop,
                erkerPointBottom: stripeVertical_2.pointBottom,
                erkerPointLeftTop: {
                    x: stripeVertical_1.pointTop.x - erkerExtension,
                    y: stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2,
                },
                erkerPointLeftBottom: {
                    x: stripeVertical_2.pointBottom.x - erkerExtension,
                    y: stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2,
                },
                pathRight: {
                    x1: oneRightPoint.x,
                    y1: oneRightPoint.y,
                    x2: twoRightPoint.x,
                    y2: twoRightPoint.y,
                    x3: threeRightPoint.x,
                    y3: threeRightPoint.y,
                    x4: fourRightPoint.x,
                    y4: fourRightPoint.y,
                },
                pathLeft: {
                    x1: oneLeftPoint.x,
                    y1: oneLeftPoint.y,
                    x2: twoLeftPoint.x,
                    y2: twoLeftPoint.y,
                    x3: threeLeftPoint.x,
                    y3: threeLeftPoint.y,
                    x4: fourLeftPoint.x,
                    y4: fourLeftPoint.y,
                }
            };

        } else if (erker.direction === 'rightErker') {

            let stripeVertical_1 = stripes.vertical.find((stripe) => stripe.name === erker.startVertical);
            let stripeVertical_2 = stripes.vertical.find((stripe) => stripe.name === erker.endVertical);

            let stripeHorizontal_1 = stripes.horizontal.find((stripe) => stripe.name === erker.startHorizontal);
            let stripeHorizontal_2 = stripes.horizontal.find((stripe) => stripe.name === erker.endHorizontal);

            const erkerSize = erker.erkerPointRightBottom.y - erker.erkerPointRightTop.y;
            const erkerExtension = erker.extension;
            const widthStripe = erker.width;
            const stripeLength = stripeVertical_2.pointBottom.y - stripeVertical_1.pointTop.y;
            
            const angel = calculateAngle(stripeVertical_1.pointTop.x + erkerExtension, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x, stripeVertical_1.pointTop.y);
            
            const oneRightPoint = calculateSecondPoint(stripeVertical_1.pointTop.x, stripeVertical_1.pointTop.y, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let twoRightPoint = calculateSecondPoint(stripeVertical_1.pointTop.x + erkerExtension, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let threeRightPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x + erkerExtension, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2, widthStripe / 2, (90 * Math.PI) / 180 - angel);
            const fourRightPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x, stripeVertical_2.pointBottom.y, widthStripe / 2, (90 * Math.PI) / 180 - angel);

            let crossPoint = searchCross(oneRightPoint.x, oneRightPoint.y, twoRightPoint.x, twoRightPoint.y, stripeVertical_1.pointTop.x + erkerExtension + widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x + erkerExtension + widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            twoRightPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeRightPoint.x, threeRightPoint.y, fourRightPoint.x, fourRightPoint.y, stripeVertical_1.pointTop.x + erkerExtension + widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x + erkerExtension + widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            threeRightPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            const oneLeftPoint = calculateSecondPoint(stripeVertical_1.pointTop.x, stripeVertical_1.pointTop.y, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let twoLeftPoint = calculateSecondPoint(stripeVertical_1.pointTop.x + erkerExtension, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let threeLeftPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x + erkerExtension, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2, widthStripe / 2, - (90 * Math.PI) / 180 - angel);
            const fourLeftPoint = calculateSecondPoint(stripeVertical_2.pointBottom.x, stripeVertical_2.pointBottom.y, widthStripe / 2, - (90 * Math.PI) / 180 - angel);

            crossPoint = searchCross(oneLeftPoint.x, oneLeftPoint.y, twoLeftPoint.x, twoLeftPoint.y, stripeVertical_1.pointTop.x + erkerExtension - widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x + erkerExtension - widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            twoLeftPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeLeftPoint.x, threeLeftPoint.y, fourLeftPoint.x, fourLeftPoint.y, stripeVertical_1.pointTop.x + erkerExtension - widthStripe / 2, stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2, stripeVertical_1.pointTop.x + erkerExtension - widthStripe / 2, stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2);
            threeLeftPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            erker = {
                name: erker.name,
                axeStart: erker.axeStart,
                axeEnd: erker.axeEnd,
                startVertical: erker.startVertical,
                endVertical: erker.endVertical,
                startHorizontal: erker.startHorizontal,
                endHorizontal: erker.endHorizontal,
                type: erker.type,
                direction: erker.direction,
                extension: erkerExtension,
                width: widthStripe,
                angel: angel * (180 / Math.PI),
                erkerPointTop: stripeVertical_1.pointTop,
                erkerPointBottom: stripeVertical_2.pointBottom,
                erkerPointRightTop: {
                    x: stripeVertical_1.pointTop.x + erkerExtension,
                    y: stripeVertical_1.pointTop.y + (stripeLength - erkerSize) / 2,
                },
                erkerPointRightBottom: {
                    x: stripeVertical_2.pointBottom.x + erkerExtension,
                    y: stripeVertical_2.pointBottom.y - (stripeLength - erkerSize) / 2,
                },
                pathLeft: {
                    x1: oneLeftPoint.x,
                    y1: oneLeftPoint.y,
                    x2: twoLeftPoint.x,
                    y2: twoLeftPoint.y,
                    x3: threeLeftPoint.x,
                    y3: threeLeftPoint.y,
                    x4: fourLeftPoint.x,
                    y4: fourLeftPoint.y,
                },
                pathRight: {
                    x1: oneRightPoint.x,
                    y1: oneRightPoint.y,
                    x2: twoRightPoint.x,
                    y2: twoRightPoint.y,
                    x3: threeRightPoint.x,
                    y3: threeRightPoint.y,
                    x4: fourRightPoint.x,
                    y4: fourRightPoint.y,
                }
            };
        } else if (erker.direction === 'topErker') {

            let stripeVertical_1 = stripes.vertical.find((stripe) => stripe.name === erker.startVertical);
            let stripeVertical_2 = stripes.vertical.find((stripe) => stripe.name === erker.endVertical);

            let stripeHorizontal_1 = stripes.horizontal.find((stripe) => stripe.name === erker.startHorizontal);
            let stripeHorizontal_2 = stripes.horizontal.find((stripe) => stripe.name === erker.endHorizontal);

            const erkerSize = erker.erkerPointTopRight.x - erker.erkerPointTopLeft.x;
            const erkerExtension = erker.extension;
            const widthStripe = erker.width;
            const stripeLength = stripeHorizontal_2.pointRight.x - stripeHorizontal_1.pointLeft.x;

            const angel = calculateAngle(stripeHorizontal_1.pointLeft.x, stripeHorizontal_1.pointLeft.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension);

            const oneTopPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x, stripeHorizontal_1.pointLeft.y, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let twoTopPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let threeTopPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension, widthStripe / 2, (90 * Math.PI) / 180 - angel);
            const fourTopPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x, stripeHorizontal_2.pointRight.y, widthStripe / 2, (90 * Math.PI) / 180 - angel);

            let crossPoint = searchCross(oneTopPoint.x, oneTopPoint.y, twoTopPoint.x, twoTopPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension - widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension - widthStripe / 2);
            twoTopPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeTopPoint.x, threeTopPoint.y, fourTopPoint.x, fourTopPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension - widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension - widthStripe / 2);
            threeTopPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            const oneBottomPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x, stripeHorizontal_1.pointLeft.y, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let twoBottomPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let threeBottomPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension, widthStripe / 2, - (90 * Math.PI) / 180 - angel);
            const fourBottomPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x, stripeHorizontal_2.pointRight.y, widthStripe / 2, - (90 * Math.PI) / 180 - angel);

            crossPoint = searchCross(oneBottomPoint.x, oneBottomPoint.y, twoBottomPoint.x, twoBottomPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension + widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension + widthStripe / 2);
            twoBottomPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeBottomPoint.x, threeBottomPoint.y, fourBottomPoint.x, fourBottomPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension + widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y - erkerExtension + widthStripe / 2);
            threeBottomPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            erker = {
                name: erker.name,
                axeStart: erker.axeStart,
                axeEnd: erker.axeEnd,
                startVertical: erker.startVertical,
                endVertical: erker.endVertical,
                startHorizontal: erker.startHorizontal,
                endHorizontal: erker.endHorizontal,
                type: erker.type,
                direction: erker.direction,
                extension: erkerExtension,
                width: widthStripe,
                angel: angel * (180 / Math.PI),
                erkerPointLeft: stripeHorizontal_1.pointLeft,
                erkerPointRight: stripeHorizontal_2.pointRight,
                erkerPointTopLeft: {
                    x: stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2,
                    y: stripeHorizontal_1.pointLeft.y - erkerExtension,
                },
                erkerPointTopRight: {
                    x: stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2,
                    y: stripeHorizontal_2.pointRight.y - erkerExtension,
                },
                pathBottom: {
                    x1: oneBottomPoint.x,
                    y1: oneBottomPoint.y,
                    x2: twoBottomPoint.x,
                    y2: twoBottomPoint.y,
                    x3: threeBottomPoint.x,
                    y3: threeBottomPoint.y,
                    x4: fourBottomPoint.x,
                    y4: fourBottomPoint.y,
                },
                pathTop: {
                    x1: oneTopPoint.x,
                    y1: oneTopPoint.y,
                    x2: twoTopPoint.x,
                    y2: twoTopPoint.y,
                    x3: threeTopPoint.x,
                    y3: threeTopPoint.y,
                    x4: fourTopPoint.x,
                    y4: fourTopPoint.y,
                }
            };

        } else if (erker.direction === 'bottomErker') {

            let stripeVertical_1 = stripes.vertical.find((stripe) => stripe.name === erker.startVertical);
            let stripeVertical_2 = stripes.vertical.find((stripe) => stripe.name === erker.endVertical);

            let stripeHorizontal_1 = stripes.horizontal.find((stripe) => stripe.name === erker.startHorizontal);
            let stripeHorizontal_2 = stripes.horizontal.find((stripe) => stripe.name === erker.endHorizontal);

            const erkerSize = erker.erkerPointBottomRight.x - erker.erkerPointBottomLeft.x;
            const erkerExtension = erker.extension;
            const widthStripe = erker.width;
            const stripeLength = stripeHorizontal_2.pointRight.x - stripeHorizontal_1.pointLeft.x;

            const angel = calculateAngle(stripeHorizontal_1.pointLeft.x, stripeHorizontal_1.pointLeft.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension);

            const oneBottomPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x, stripeHorizontal_1.pointLeft.y, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let twoBottomPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension, widthStripe / 2, angel - (90 * Math.PI) / 180);
            let threeBottomPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension, widthStripe / 2, - (90 * Math.PI) / 180 - angel);
            const fourBottomPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x, stripeHorizontal_2.pointRight.y, widthStripe / 2, - (90 * Math.PI) / 180 - angel);

            let crossPoint = searchCross(oneBottomPoint.x, oneBottomPoint.y, twoBottomPoint.x, twoBottomPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension + widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension + widthStripe / 2);
            twoBottomPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeBottomPoint.x, threeBottomPoint.y, fourBottomPoint.x, fourBottomPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension + widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension + widthStripe / 2);
            threeBottomPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            const oneTopPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x, stripeHorizontal_1.pointLeft.y, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let twoTopPoint = calculateSecondPoint(stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension, widthStripe / 2, angel + (90 * Math.PI) / 180);
            let threeTopPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension, widthStripe / 2, (90 * Math.PI) / 180 - angel);
            const fourTopPoint = calculateSecondPoint(stripeHorizontal_2.pointRight.x, stripeHorizontal_2.pointRight.y, widthStripe / 2, (90 * Math.PI) / 180 - angel);

            crossPoint = searchCross(oneTopPoint.x, oneTopPoint.y, twoTopPoint.x, twoTopPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension - widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension - widthStripe / 2);
            twoTopPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };
            crossPoint = searchCross(threeTopPoint.x, threeTopPoint.y, fourTopPoint.x, fourTopPoint.y, stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension - widthStripe / 2, stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2, stripeHorizontal_1.pointLeft.y + erkerExtension - widthStripe / 2);
            threeTopPoint = {
                x: crossPoint[0],
                y: crossPoint[1],
            };

            erker = {
                name: erker.name,
                axeStart: erker.axeStart,
                axeEnd: erker.axeEnd,
                startVertical: erker.startVertical,
                endVertical: erker.endVertical,
                startHorizontal: erker.startHorizontal,
                endHorizontal: erker.endHorizontal,
                type: erker.type,
                direction: erker.direction,
                extension: erkerExtension,
                width: widthStripe,
                angel: angel * (180 / Math.PI),
                erkerPointLeft: stripeHorizontal_1.pointLeft,
                erkerPointRight: stripeHorizontal_2.pointRight,
                erkerPointBottomLeft: {
                    x: stripeHorizontal_1.pointLeft.x + (stripeLength - erkerSize) / 2,
                    y: stripeHorizontal_1.pointLeft.y + erkerExtension,
                },
                erkerPointBottomRight: {
                    x: stripeHorizontal_2.pointRight.x - (stripeLength - erkerSize) / 2,
                    y: stripeHorizontal_2.pointRight.y + erkerExtension,
                },
                pathTop: {
                    x1: oneTopPoint.x,
                    y1: oneTopPoint.y,
                    x2: twoTopPoint.x,
                    y2: twoTopPoint.y,
                    x3: threeTopPoint.x,
                    y3: threeTopPoint.y,
                    x4: fourTopPoint.x,
                    y4: fourTopPoint.y,
                },
                pathBottom: {
                    x1: oneBottomPoint.x,
                    y1: oneBottomPoint.y,
                    x2: twoBottomPoint.x,
                    y2: twoBottomPoint.y,
                    x3: threeBottomPoint.x,
                    y3: threeBottomPoint.y,
                    x4: fourBottomPoint.x,
                    y4: fourBottomPoint.y,
                }
            };

        }

    }

    if (erker.type === 'round') {
        if (erker.direction === 'leftErker') {

            let stripeVertical_1 = stripes.vertical.find((stripe) => stripe.name === erker.startVertical);
            let stripeVertical_2 = stripes.vertical.find((stripe) => stripe.name === erker.endVertical);

            let stripeHorizontal_1 = stripes.horizontal.find((stripe) => stripe.name === erker.startHorizontal);
            let stripeHorizontal_2 = stripes.horizontal.find((stripe) => stripe.name === erker.endVertical);

            const erkerExtension = erker.extension;
            const widthStripe = erker.width;
            const stripeLength = stripeVertical_2.pointBottom.y - stripeVertical_1.pointTop.y;

            if (erkerExtension > stripeLength / 2) erkerExtension = stripeLength / 2;

            const mainRadius = (Math.pow(stripeLength / 2, 2) + Math.pow(erkerExtension, 2)) / (2 * erkerExtension);
            const _innerRadius = mainRadius - widthStripe / 2;
            const _outerRadius = mainRadius + widthStripe / 2;
            const _angle = Math.acos((mainRadius - erkerExtension + stripeVertical_1.leftWidth) / mainRadius);
            const _innerAngle = Math.acos((mainRadius - erkerExtension + stripeVertical_1.leftWidth) / _innerRadius);
            const _outerAngle = Math.acos((mainRadius - erkerExtension + stripeVertical_1.leftWidth) / _outerRadius);

            erker = {
                name: erker.name,
                axeStart: erker.axeStart,
                axeEnd: erker.axeEnd,
                startVertical: erker.startVertical,
                endVertical: erker.endVertical,
                startHorizontal: erker.startHorizontal,
                endHorizontal: erker.endHorizontal,
                type: erker.type,
                direction: erker.direction,
                extension: erkerExtension,
                width: widthStripe,
                radius: mainRadius,
                angle: _angle * (180 / Math.PI),
                innerAngle: {
                    top: _innerAngle * (180 / Math.PI),
                    bottom: _innerAngle * (180 / Math.PI),
                },
                outerAngle: {
                    top: _outerAngle * (180 / Math.PI),
                    bottom: _outerAngle * (180 / Math.PI),
                },
                innerRadius: _innerRadius,
                outerRadius: _outerRadius,
                center: {
                    x: stripeVertical_1.pointTop.x - erkerExtension + mainRadius,
                    y: stripeVertical_1.pointTop.y + stripeLength / 2,
                },
            };

        } else if (erker.direction === 'rightErker') {

            let stripeVertical_1 = stripes.vertical.find((stripe) => stripe.name === erker.startVertical);
            let stripeVertical_2 = stripes.vertical.find((stripe) => stripe.name === erker.endVertical);

            let stripeHorizontal_1 = stripes.horizontal.find((stripe) => stripe.name === erker.startHorizontal);
            let stripeHorizontal_2 = stripes.horizontal.find((stripe) => stripe.name === erker.endHorizontal);

            const erkerExtension = erker.extension;
            const widthStripe = erker.width;
            const stripeLength = stripeVertical_2.pointBottom.y - stripeVertical_1.pointTop.y;

            if (erkerExtension > stripeLength / 2) erkerExtension = stripeLength / 2;

            const mainRadius = (Math.pow(stripeLength / 2, 2) + Math.pow(erkerExtension, 2)) / (2 * erkerExtension);
            const _innerRadius = mainRadius - widthStripe / 2;
            const _outerRadius = mainRadius + widthStripe / 2;
            const _angle = Math.acos((mainRadius - erkerExtension + stripeVertical_1.rightWidth) / mainRadius);
            const _innerAngle = Math.acos((mainRadius - erkerExtension + stripeVertical_1.rightWidth) / _innerRadius);
            const _outerAngle = Math.acos((mainRadius - erkerExtension + stripeVertical_1.rightWidth) / _outerRadius);

            erker = {
                name: erker.name,
                axeStart: erker.axeStart,
                axeEnd: erker.axeEnd,
                startVertical: erker.startVertical,
                endVertical: erker.endVertical,
                startHorizontal: erker.startHorizontal,
                endHorizontal: erker.endHorizontal,
                type: erker.type,
                direction: erker.direction,
                extension: erkerExtension,
                width: widthStripe,
                radius: mainRadius,
                angle: _angle * (180 / Math.PI),
                innerAngle: {
                    top: _innerAngle * (180 / Math.PI),
                    bottom: _innerAngle * (180 / Math.PI),
                },
                outerAngle: {
                    top: _outerAngle * (180 / Math.PI),
                    bottom: _outerAngle * (180 / Math.PI),
                },
                innerRadius: _innerRadius,
                outerRadius: _outerRadius,
                center: {
                    x: stripeVertical_1.pointTop.x + erkerExtension - mainRadius,
                    y: stripeVertical_1.pointTop.y + stripeLength / 2,
                },
            };

        } else if (erker.direction === 'topErker') {

            let stripeHorizontal_1 = stripes.horizontal.find((stripe) => stripe.name === erker.startHorizontal);
            let stripeHorizontal_2 = stripes.horizontal.find((stripe) => stripe.name === erker.endHorizontal);

            let stripeVertical_1 = stripes.vertical.find((stripe) => stripe.name === erker.startVertical);
            let stripeVertical_2 = stripes.vertical.find((stripe) => stripe.name === erker.endVertical);

            const erkerExtension = erker.extension;
            const widthStripe = erker.width;
            const stripeLength = stripeHorizontal_2.pointRight.x - stripeHorizontal_1.pointLeft.x;

            if (erkerExtension > stripeLength / 2) erkerExtension = stripeLength / 2;

            const mainRadius = (Math.pow(stripeLength / 2, 2) + Math.pow(erkerExtension, 2)) / (2 * erkerExtension);
            const _innerRadius = mainRadius - widthStripe / 2;
            const _outerRadius = mainRadius + widthStripe / 2;
            const _angle = Math.acos((mainRadius - erkerExtension + stripeHorizontal_1.topWidth) / mainRadius);
            const _innerAngle = Math.acos((mainRadius - erkerExtension + stripeHorizontal_1.topWidth) / _innerRadius);
            const _outerAngle = Math.acos((mainRadius - erkerExtension + stripeHorizontal_1.topWidth) / _outerRadius);

            erker = {
                name: erker.name,
                axeStart: erker.axeStart,
                axeEnd: erker.axeEnd,
                startVertical: erker.startVertical,
                endVertical: erker.endVertical,
                startHorizontal: erker.startHorizontal,
                endHorizontal: erker.endHorizontal,
                type: erker.type,
                direction: erker.direction,
                extension: erkerExtension,
                width: widthStripe,
                radius: mainRadius,
                angle: _angle * (180 / Math.PI),
                innerAngle: {
                    left: _innerAngle * (180 / Math.PI),
                    right: _innerAngle * (180 / Math.PI),
                },
                outerAngle: {
                    left: _outerAngle * (180 / Math.PI),
                    right: _outerAngle * (180 / Math.PI),
                },
                innerRadius: _innerRadius,
                outerRadius: _outerRadius,
                center: {
                    x: stripeHorizontal_1.pointLeft.x + stripeLength / 2,
                    y: stripeHorizontal_1.pointLeft.y - erkerExtension + mainRadius,
                },
            };

        } else if (erker.direction === 'bottomErker') {

            let stripeHorizontal_1 = stripes.horizontal.find((stripe) => stripe.name === erker.startHorizontal);
            let stripeHorizontal_2 = stripes.horizontal.find((stripe) => stripe.name === erker.endHorizontal);

            let stripeVertical_1 = stripes.vertical.find((stripe) => stripe.name === erker.startVertical);
            let stripeVertical_2 = stripes.vertical.find((stripe) => stripe.name === erker.endVertical);

            const erkerExtension = erker.extension;
            const widthStripe = erker.width;
            const stripeLength = stripeHorizontal_2.pointRight.x - stripeHorizontal_1.pointLeft.x;

            if (erkerExtension > stripeLength / 2) erkerExtension = stripeLength / 2;

            const mainRadius = (Math.pow(stripeLength / 2, 2) + Math.pow(erkerExtension, 2)) / (2 * erkerExtension);
            const _innerRadius = mainRadius - widthStripe / 2;
            const _outerRadius = mainRadius + widthStripe / 2;
            const _angle = Math.acos((mainRadius - erkerExtension + stripeHorizontal_1.bottomWidth) / mainRadius);
            const _innerAngle = Math.acos((mainRadius - erkerExtension + stripeHorizontal_1.bottomWidth) / _innerRadius);
            const _outerAngle = Math.acos((mainRadius - erkerExtension + stripeHorizontal_1.bottomWidth) / _outerRadius);

            erker = {
                name: erker.name,
                axeStart: erker.axeStart,
                axeEnd: erker.axeEnd,
                startVertical: erker.startVertical,
                endVertical: erker.endVertical,
                startHorizontal: erker.startHorizontal,
                endHorizontal: erker.endHorizontal,
                type: erker.type,
                direction: erker.direction,
                extension: erkerExtension,
                width: widthStripe,
                radius: mainRadius,
                angle: _angle * (180 / Math.PI),
                innerAngle: {
                    left: _innerAngle * (180 / Math.PI),
                    right: _innerAngle * (180 / Math.PI),
                },
                outerAngle: {
                    left: _outerAngle * (180 / Math.PI),
                    right: _outerAngle * (180 / Math.PI),
                },
                innerRadius: _innerRadius,
                outerRadius: _outerRadius,
                center: {
                    x: stripeHorizontal_1.pointLeft.x + stripeLength / 2,
                    y: stripeHorizontal_1.pointLeft.y + erkerExtension - mainRadius,
                },
            };

        }
    }
    

    return erker;
}

// Добавление перемычки под углом
export const addCornerStripe = (addCornerStripe, stripes, widthStripe) => {
    let _stripes = JSON.parse(JSON.stringify(stripes));
    
    let direction = '';
    let left = '';
    let right = '';
    let top = '';
    let bottom = '';

    let pointTopLeft = null;
    let pointTopRight = null;
    let pointTopCenterLeft = null;
    let pointTopCenterRight = null;
    let pointBottomLeft = null;
    let pointBottomRight = null;
    let pointBottomCenterLeft = null;
    let pointBottomCenterRight = null;

    if (addCornerStripe.from.y === addCornerStripe.to.y) {
        return false;
    } else if (addCornerStripe.from.y < addCornerStripe.to.y) {
        if (addCornerStripe.to.y - addCornerStripe.from.y > 1) return false;
        left = `hor_${addCornerStripe.from.y}_${addCornerStripe.from.x}`;
        right = `hor_${addCornerStripe.to.y - 1}_${addCornerStripe.to.x}`;
        if (addCornerStripe.from.x < addCornerStripe.to.x) {
            direction = 'down';
        } else {
            direction = 'up';
        }
    } else {
        if (addCornerStripe.from.y - addCornerStripe.to.y > 1) return false;
        left = `hor_${addCornerStripe.to.y}_${addCornerStripe.to.x}`;
        right = `hor_${addCornerStripe.from.y - 1}_${addCornerStripe.from.x}`;
        if (addCornerStripe.from.x < addCornerStripe.to.x) {
            direction = 'up';
        } else {
            direction = 'down';
        }
    }

    if (addCornerStripe.from.x === addCornerStripe.to.x) {
        return false;
    } else if (addCornerStripe.from.x < addCornerStripe.to.x) {
        if (addCornerStripe.to.x - addCornerStripe.from.x > 1) return false;
        top = `ver_${addCornerStripe.from.x}_${addCornerStripe.from.y}`;
        bottom = `ver_${addCornerStripe.to.x - 1}_${addCornerStripe.to.y}`;
    } else {
        if (addCornerStripe.from.x - addCornerStripe.to.x > 1) return false;
        top = `ver_${addCornerStripe.to.x}_${addCornerStripe.to.y}`;
        bottom = `ver_${addCornerStripe.from.x - 1}_${addCornerStripe.from.y}`;
    }

    const stripeLeft = _stripes.horizontal.find(stripe => stripe.name === left);
    const stripeRight = _stripes.horizontal.find(stripe => stripe.name === right);

    const stripeTop = _stripes.vertical.find(stripe => stripe.name === top);
    const stripeBottom = _stripes.vertical.find(stripe => stripe.name === bottom);

    if (direction === 'up') {

        // Рассчитываем верхнюю левую точку
        const angelTopLeft = Math.atan(stripeRight.length / stripeBottom.length);
        const deltaTopLeft = (widthStripe / 2) / Math.sin(angelTopLeft);
        pointTopLeft = {
            x: stripeBottom.pointBottom.x,
            y: stripeBottom.pointBottom.y - deltaTopLeft,
        }

        // Рассчитываем верхнюю правую точку
        const angelTopRight = Math.atan(stripeBottom.length / stripeRight.length);
        const deltaTopRight = (widthStripe / 2) / Math.sin(angelTopRight);
        pointTopRight = {
            x: stripeRight.pointRight.x - deltaTopRight,
            y: stripeRight.pointRight.y,
        }

        // Рассчитываем верхнюю центральную точку
        pointTopCenterLeft = {
            x: pointTopLeft.x + (pointTopRight.x - pointTopLeft.x) / 2,
            y: pointTopLeft.y - (pointTopLeft.y - pointTopRight.y) / 2,
        }
        pointTopCenterRight = {
            x: pointTopLeft.x + (pointTopRight.x - pointTopLeft.x) / 2,
            y: pointTopLeft.y - (pointTopLeft.y - pointTopRight.y) / 2,
        }

        // Рассчитываем нижнюю левую точку
        const angelBottomLeft = Math.atan(stripeTop.length / stripeLeft.length);
        const deltaBottomLeft = (widthStripe / 2) / Math.sin(angelBottomLeft);
        pointBottomLeft = {
            x: stripeLeft.pointLeft.x + deltaBottomLeft,
            y: stripeLeft.pointLeft.y,
        }

        // Рассчитываем верхнюю правую точку
        const angelBottomRight = Math.atan(stripeLeft.length / stripeTop.length);
        const deltaBottomRight = (widthStripe / 2) / Math.sin(angelBottomRight);
        pointBottomRight = {
            x: stripeTop.pointTop.x,
            y: stripeTop.pointTop.y + deltaBottomRight,
        }

        // Рассчитываем нижнюю центральную точку
        pointBottomCenterLeft = {
            x: pointBottomLeft.x + (pointBottomRight.x - pointBottomLeft.x) / 2,
            y: pointBottomLeft.y - (pointBottomLeft.y - pointBottomRight.y) / 2,
        }
        pointBottomCenterRight = {
            x: pointBottomLeft.x + (pointBottomRight.x - pointBottomLeft.x) / 2,
            y: pointBottomLeft.y - (pointBottomLeft.y - pointBottomRight.y) / 2,
        }

    } else {

        // Рассчитываем верхнюю левую точку
        const angelTopLeft = Math.atan(stripeBottom.length / stripeLeft.length);
        const deltaTopLeft = (widthStripe / 2) / Math.sin(angelTopLeft);
        pointTopLeft = {
            x: stripeLeft.pointLeft.x + deltaTopLeft,
            y: stripeLeft.pointLeft.y,
        }

        // Рассчитываем верхнюю правую точку
        const angelTopRight = Math.atan(stripeLeft.length / stripeBottom.length);
        const deltaTopRight = (widthStripe / 2) / Math.sin(angelTopRight);
        pointTopRight = {
            x: stripeBottom.pointBottom.x,
            y: stripeBottom.pointBottom.y - deltaTopRight,
        }

        // Рассчитываем верхнюю центральную точку
        pointTopCenterLeft = {
            x: pointTopLeft.x + (pointTopRight.x - pointTopLeft.x) / 2,
            y: pointTopLeft.y + (pointTopRight.y - pointTopLeft.y) / 2,
        }
        pointTopCenterRight = {
            x: pointTopLeft.x + (pointTopRight.x - pointTopLeft.x) / 2,
            y: pointTopLeft.y + (pointTopRight.y - pointTopLeft.y) / 2,
        }

        // Рассчитываем нижнюю левую точку
        const angelBottomLeft = Math.atan(stripeRight.length / stripeTop.length);
        const deltaBottomLeft = (widthStripe / 2) / Math.sin(angelBottomLeft);
        pointBottomLeft = {
            x: stripeTop.pointTop.x,
            y: stripeTop.pointTop.y + deltaBottomLeft,
        }

        // Рассчитываем верхнюю правую точку
        const angelBottomRight = Math.atan(stripeTop.length / stripeRight.length);
        const deltaBottomRight = (widthStripe / 2) / Math.sin(angelBottomRight);
        pointBottomRight = {
            x: stripeRight.pointRight.x - deltaBottomRight,
            y: stripeRight.pointRight.y,
        }

        // Рассчитываем нижнюю центральную точку
        pointBottomCenterLeft = {
            x: pointBottomLeft.x + (pointBottomRight.x - pointBottomLeft.x) / 2,
            y: pointBottomLeft.y + (pointBottomRight.y - pointBottomLeft.y) / 2,
        }
        pointBottomCenterRight = {
            x: pointBottomLeft.x + (pointBottomRight.x - pointBottomLeft.x) / 2,
            y: pointBottomLeft.y + (pointBottomRight.y - pointBottomLeft.y) / 2,
        }

    }

    const cornerStripe = {
        name: direction === 'up' ? `${bottom}:${left}:${top}:${right}` : `${top}:${left}:${bottom}:${right}`,
        width: widthStripe,
        direction: direction,
        pointTop: stripeTop.pointTop,
        pointBottom: stripeBottom.pointBottom,
        pointTopLeft: pointTopLeft,
        pointTopRight: pointTopRight,
        pointTopCenterLeft: pointTopCenterLeft,
        pointTopCenterRight: pointTopCenterRight,
        pointBottomLeft: pointBottomLeft,
        pointBottomRight: pointBottomRight,
        pointBottomCenterLeft: pointBottomCenterLeft,
        pointBottomCenterRight: pointBottomCenterRight,
    }

    if (_stripes.corner.find(stripe => stripe.name === cornerStripe.name)) {
        return false;
    } else {
        _stripes.corner.push(cornerStripe);
        return _stripes;
    }
}

// Пересчёт точек линий
export const recalculateLines = (stripes, deletedStripes, erkers, bakes, countX, countY) => {
    let _stripes = JSON.parse(JSON.stringify(stripes));
    let _erkers = JSON.parse(JSON.stringify(erkers));
    let _bakes = JSON.parse(JSON.stringify(bakes));
    let _extendedStripes = [];

    for (let i = 0; i < countX; i++) {
        for (let j = 0; j < countY - 1; j++) {
            // Обрабатываемая перемычка
            let currentStripe = _stripes.horizontal.find(stripe => stripe.name === `hor_${j}_${i}`);

            // Смежные горизонтальные
            let stripeHorizontal_1 = _stripes.horizontal.find(stripe => stripe.name === `hor_${j - 1}_${i}`);
            let stripeHorizontal_2 = _stripes.horizontal.find(stripe => stripe.name === `hor_${j + 1}_${i}`);

            // Смежные вертикальные
            let stripeVertical_1 = _stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j}`);
            let stripeVertical_2 = _stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j + 1}`);
            let stripeVertical_3 = _stripes.vertical.find(stripe => stripe.name === `ver_${i}_${j}`);
            let stripeVertical_4 = _stripes.vertical.find(stripe => stripe.name === `ver_${i}_${j + 1}`);

            if (deletedStripes.includes(currentStripe.name)) {
                if ((!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name)) && (!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name)) && (!stripeVertical_3 || deletedStripes.includes(stripeVertical_3.name))) {
                    _erkers.top = _erkers.top.filter(erker => (erker.startHorizontal !== currentStripe.name && erker.endHorizontal !== currentStripe.name));
                    _erkers.bottom = _erkers.bottom.filter(erker => (erker.startHorizontal !== currentStripe.name && erker.endHorizontal !== currentStripe.name));
                    _stripes.corner = _stripes.corner.filter(stripe => !stripe.name.startsWith(`ver_${i - 1}_${j}:hor_${j}_${i}`) && !stripe.name.startsWith(`ver_${i}_${j}:hor_${j}_${i}`));
                }
                if ((!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name)) && (!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name)) && (!stripeVertical_4 || deletedStripes.includes(stripeVertical_4.name))) {
                    _erkers.top = _erkers.top.filter(erker => (erker.startHorizontal !== currentStripe.name && erker.endHorizontal !== currentStripe.name));
                    _erkers.bottom = _erkers.bottom.filter(erker => (erker.startHorizontal !== currentStripe.name && erker.endHorizontal !== currentStripe.name));
                    _stripes.corner = _stripes.corner.filter(stripe => !stripe.name.startsWith(`ver_${i - 1}_${j}:hor_${j}_${i - 1}`) && !stripe.name.startsWith(`ver_${i}_${j}:hor_${j}_${i + 1}`));
                }
            }

        }
    }

    for (let i = 0; i < countY; i++) {
        for (let j = 0; j < countX - 1; j++) {
            // Обрабатываемая перемычка
            let currentStripe = _stripes.vertical.find(stripe => stripe.name === `ver_${j}_${i}`);

            // Смежные вертикальные
            let stripeVertical_1 = _stripes.vertical.find(stripe => stripe.name === `ver_${j - 1}_${i}`);
            let stripeVertical_2 = _stripes.vertical.find(stripe => stripe.name === `ver_${j + 1}_${i}`);

            // Смежные горизонтальные
            let stripeHorizontal_1 = _stripes.horizontal.find(stripe => stripe.name === `hor_${i - 1}_${j}`);
            let stripeHorizontal_2 = _stripes.horizontal.find(stripe => stripe.name === `hor_${i - 1}_${j + 1}`);
            let stripeHorizontal_3 = _stripes.horizontal.find(stripe => stripe.name === `hor_${i}_${j}`);
            let stripeHorizontal_4 = _stripes.horizontal.find(stripe => stripe.name === `hor_${i}_${j + 1}`);

            if (deletedStripes.includes(currentStripe.name)) {
                if ((!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name)) && (!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name)) && (!stripeHorizontal_3 || deletedStripes.includes(stripeHorizontal_3.name))) {
                    _erkers.left = JSON.parse(JSON.stringify(_erkers.left.filter(erker => (erker.startVertical !== currentStripe.name && erker.endVertical !== currentStripe.name))));
                    _erkers.right = JSON.parse(JSON.stringify(_erkers.right.filter(erker => (erker.startVertical !== currentStripe.name && erker.endVertical !== currentStripe.name))));
                }
                if ((!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name)) && (!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name)) && (!stripeHorizontal_4 || deletedStripes.includes(stripeHorizontal_4.name))) {
                    _erkers.left = JSON.parse(JSON.stringify(_erkers.left.filter(erker => (erker.startVertical !== currentStripe.name && erker.endVertical !== currentStripe.name))));
                    _erkers.right = JSON.parse(JSON.stringify(_erkers.right.filter(erker => (erker.startVertical !== currentStripe.name && erker.endVertical !== currentStripe.name))));
                }
            }

        }
    }

    // Идём по горизонтальным перемычкам
    for (let i = 0; i < countX; i++) {
        for (let j = 0; j < countY - 1; j++) {
            // Обрабатываемая перемычка
            let currentStripe = _stripes.horizontal.find(stripe => stripe.name === `hor_${j}_${i}`);

            // Перемычки под углом
            let cornerStripeTopRight = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${i - 1}_${j}:hor_${j}_${i - 1}`));
            let cornerStripeTopLeft = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${i - 1}_${j}:hor_${j}_${i}`));
            let cornerStripeBottomRight = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${i}_${j}:hor_${j}_${i + 1}`));
            let cornerStripeBottomLeft = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${i}_${j}:hor_${j}_${i}`));

            let cornerStripeLeftTop = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${i - 1}_${j - 1}:hor_${j - 1}_${i - 1}`));
            let cornerStripeLeftBottom = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${i}_${j - 1}:hor_${j - 1}_${i + 1}`));
            let cornerStripeRightTop = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${i - 1}_${j + 1}:hor_${j + 1}_${i}`));
            let cornerStripeRightBottom = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${i}_${j + 1}:hor_${j + 1}_${i}`));

            // Смежные горизонтальные
            let stripeHorizontal_1 = _stripes.horizontal.find(stripe => stripe.name === `hor_${j - 1}_${i}`);
            let stripeHorizontal_2 = _stripes.horizontal.find(stripe => stripe.name === `hor_${j + 1}_${i}`);

            // Смежные вертикальные
            let stripeVertical_1 = _stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j}`);
            let stripeVertical_2 = _stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j + 1}`);
            let stripeVertical_3 = _stripes.vertical.find(stripe => stripe.name === `ver_${i}_${j}`);
            let stripeVertical_4 = _stripes.vertical.find(stripe => stripe.name === `ver_${i}_${j + 1}`);

            // Смежные эркеры - левые угловые
            let erkerLeft_start = _erkers.left.filter(erker => erker.type === 'angel').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerLeft_end = _erkers.left.filter(erker => erker.type === 'angel').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - левые круглые
            let erkerLeft_round_start = _erkers.left.filter(erker => erker.type === 'round').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerLeft_round_end = _erkers.left.filter(erker => erker.type === 'round').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - правые угловые
            let erkerRight_start = _erkers.right.filter(erker => erker.type === 'angel').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerRight_end = _erkers.right.filter(erker => erker.type === 'angel').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - правые круглые
            let erkerRight_round_start = _erkers.right.filter(erker => erker.type === 'round').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerRight_round_end = _erkers.right.filter(erker => erker.type === 'round').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - верхние угловые
            let erkerTop_start = _erkers.top.filter(erker => erker.type === 'angel').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerTop_end = _erkers.top.filter(erker => erker.type === 'angel').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - верхние круглые
            let erkerTop_round_start = _erkers.top.filter(erker => erker.type === 'round').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerTop_round_end = _erkers.top.filter(erker => erker.type === 'round').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - нижние угловые
            let erkerBottom_start = _erkers.bottom.filter(erker => erker.type === 'angel').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerBottom_end = _erkers.bottom.filter(erker => erker.type === 'angel').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - нижние круглые
            let erkerBottom_round_start = _erkers.bottom.filter(erker => erker.type === 'round').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerBottom_round_end = _erkers.bottom.filter(erker => erker.type === 'round').find(erker => erker.endHorizontal === currentStripe.name);

            // Переменная для точки перечения
            let crossPoint = [];

            currentStripe.capLeft = false;
            currentStripe.capRight = false;
            currentStripe.capLeftBottom.x = currentStripe.pointLeft.x - currentStripe.width / 2;
            currentStripe.capLeftBottom.y = currentStripe.pointLeft.y + currentStripe.bottomWidth;
            currentStripe.capRightBottom.x = currentStripe.pointRight.x + currentStripe.width / 2;
            currentStripe.capRightBottom.y = currentStripe.pointRight.y + currentStripe.bottomWidth;
            currentStripe.capLeftTop.x = currentStripe.pointLeft.x - currentStripe.width / 2;
            currentStripe.capLeftTop.y = currentStripe.pointLeft.y - currentStripe.topWidth;
            currentStripe.capRightTop.x = currentStripe.pointRight.x + currentStripe.width / 2;
            currentStripe.capRightTop.y = currentStripe.pointRight.y - currentStripe.topWidth;

            if (!deletedStripes.includes(currentStripe.name)) {

                // Верхняя левая точка
                if (cornerStripeTopLeft) {
                    crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, cornerStripeTopLeft.pointBottomLeft.x, cornerStripeTopLeft.pointBottomLeft.y, cornerStripeTopLeft.pointBottomCenterLeft.x, cornerStripeTopLeft.pointBottomCenterLeft.y);
                    currentStripe.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeTopLeft.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                } else if (erkerTop_start) {
                    crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, erkerTop_start.pathBottom.x1, erkerTop_start.pathBottom.y1, erkerTop_start.pathBottom.x2, erkerTop_start.pathBottom.y2);
                    currentStripe.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    erkerTop_start.pathBottom.x1 = crossPoint[0];
                    erkerTop_start.pathBottom.y1 = crossPoint[1];
                } else if(erkerTop_round_start) {
                    const innerAngle = Math.asin((erkerTop_round_start.center.y - currentStripe.pointTopLeft.y) / erkerTop_round_start.innerRadius);
                    erkerTop_round_start.innerAngle.left = 90 - innerAngle * (180 / Math.PI);
                    currentStripe.pointTopLeft.x = erkerTop_round_start.center.x - erkerTop_round_start.innerRadius * Math.cos(innerAngle);
                } else if (!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name)) {
                    if (erkerLeft_start && !erkerLeft_end) {
                        crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, erkerLeft_start.pathLeft.x1, erkerLeft_start.pathLeft.y1, erkerLeft_start.pathLeft.x2, erkerLeft_start.pathLeft.y2);
                        currentStripe.pointTopLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerLeft_start.pathLeft.x1 = crossPoint[0];
                        erkerLeft_start.pathLeft.y1 = crossPoint[1];
                    } else if (erkerLeft_end) {
                        crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, erkerLeft_end.pathRight.x3, erkerLeft_end.pathRight.y3, erkerLeft_end.pathRight.x4, erkerLeft_end.pathRight.y4);
                        currentStripe.pointTopLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerLeft_end.pathRight.x4 = crossPoint[0];
                        erkerLeft_end.pathRight.y4 = crossPoint[1];
                    } else if (erkerLeft_round_start && !erkerLeft_round_end) {
                        const outerAngle = Math.asin((erkerLeft_round_start.center.y - currentStripe.pointTopLeft.y) / erkerLeft_round_start.outerRadius);
                        erkerLeft_round_start.outerAngle.bottom = outerAngle * (180 / Math.PI);
                        currentStripe.pointTopLeft.x = erkerLeft_round_start.center.x - erkerLeft_round_start.outerRadius * Math.cos(outerAngle);
                    } else if (erkerLeft_round_end) {
                        const innerAngle = Math.asin((currentStripe.pointTopLeft.y - erkerLeft_round_end.center.y) / erkerLeft_round_end.innerRadius);
                        erkerLeft_round_end.innerAngle.top = innerAngle * (180 / Math.PI);
                        currentStripe.pointTopLeft.x = erkerLeft_round_end.center.x - erkerLeft_round_end.innerRadius * Math.cos(innerAngle);
                    } else if ((!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name)) && stripeVertical_3) {
                        crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, stripeVertical_3.pointLeftTop.x, stripeVertical_3.pointLeftTop.y, stripeVertical_3.pointLeftBottom.x, stripeVertical_3.pointLeftBottom.y);
                        currentStripe.pointTopLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    } else if (stripeHorizontal_1 && !deletedStripes.includes(stripeHorizontal_1.name) && !cornerStripeLeftTop) {
                        currentStripe.pointTopLeft.x = currentStripe.pointLeft.x;
                        if (currentStripe.pointTopLeft.y !== stripeHorizontal_1.pointTopRight.y) {
                            _extendedStripes.push({
                                points: [
                                    currentStripe.pointTopLeft.x,
                                    currentStripe.pointTopLeft.y,
                                    stripeHorizontal_1.pointTopRight.x,
                                    stripeHorizontal_1.pointTopRight.y,
                                ]
                            });
                        }
                    }
                } else {
                    crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, stripeVertical_1.pointRightTop.x, stripeVertical_1.pointRightTop.y, stripeVertical_1.pointRightBottom.x, stripeVertical_1.pointRightBottom.y);
                    currentStripe.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }

                // Верхняя правая точка
                if (cornerStripeTopRight) {
                    crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, cornerStripeTopRight.pointBottomRight.x, cornerStripeTopRight.pointBottomRight.y, cornerStripeTopRight.pointBottomCenterRight.x, cornerStripeTopRight.pointBottomCenterRight.y);
                    currentStripe.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeTopRight.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                } else if (erkerTop_end) {
                    crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, erkerTop_end.pathBottom.x3, erkerTop_end.pathBottom.y3, erkerTop_end.pathBottom.x4, erkerTop_end.pathBottom.y4);
                    currentStripe.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    erkerTop_end.pathBottom.x4 = crossPoint[0];
                    erkerTop_end.pathBottom.y4 = crossPoint[1];
                } else if (erkerTop_round_end) {
                    const innerAngle = Math.asin((erkerTop_round_end.center.y - currentStripe.pointTopRight.y) / erkerTop_round_end.innerRadius);
                    erkerTop_round_end.innerAngle.right = 90 - innerAngle * (180 / Math.PI);
                    currentStripe.pointTopRight.x = erkerTop_round_end.center.x + erkerTop_round_end.innerRadius * Math.cos(innerAngle);
                } else if (!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name)) {
                    if (erkerRight_start && !erkerRight_end) {
                        crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, erkerRight_start.pathRight.x1, erkerRight_start.pathRight.y1, erkerRight_start.pathRight.x2, erkerRight_start.pathRight.y2);
                        currentStripe.pointTopRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerRight_start.pathRight.x1 = crossPoint[0];
                        erkerRight_start.pathRight.y1 = crossPoint[1];
                    } else if (erkerRight_end) {
                        crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, erkerRight_end.pathLeft.x3, erkerRight_end.pathLeft.y3, erkerRight_end.pathLeft.x4, erkerRight_end.pathLeft.y4);
                        currentStripe.pointTopRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerRight_end.pathLeft.x4 = crossPoint[0];
                        erkerRight_end.pathLeft.y4 = crossPoint[1];
                    } else if (erkerRight_round_start && !erkerRight_round_end) {
                        const outerAngle = Math.asin((erkerRight_round_start.center.y - currentStripe.pointTopRight.y) / erkerRight_round_start.outerRadius);
                        erkerRight_round_start.outerAngle.top = outerAngle * (180 / Math.PI);
                        currentStripe.pointTopRight.x = erkerRight_round_start.center.x + erkerRight_round_start.outerRadius * Math.cos(outerAngle);
                    } else if (erkerRight_round_end) {
                        const innerAngle = Math.asin((currentStripe.pointTopRight.y - erkerRight_round_end.center.y) / erkerRight_round_end.innerRadius);
                        erkerRight_round_end.innerAngle.bottom = innerAngle * (180 / Math.PI);
                        currentStripe.pointTopRight.x = erkerRight_round_end.center.x + erkerRight_round_end.innerRadius * Math.cos(innerAngle);
                    } else if ((!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name)) && stripeVertical_4) {
                        crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, stripeVertical_4.pointRightTop.x, stripeVertical_4.pointRightTop.y, stripeVertical_4.pointRightBottom.x, stripeVertical_4.pointRightBottom.y);
                        currentStripe.pointTopRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    } else if (stripeHorizontal_2 && !deletedStripes.includes(stripeHorizontal_2.name)) {
                        currentStripe.pointTopRight.x = currentStripe.pointRight.x;
                    }
                } else {
                    crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, stripeVertical_2.pointLeftTop.x, stripeVertical_2.pointLeftTop.y, stripeVertical_2.pointLeftBottom.x, stripeVertical_2.pointLeftBottom.y);
                    currentStripe.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }

                // Нижняя левая точка
                if (cornerStripeBottomLeft) {
                    crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, cornerStripeBottomLeft.pointTopLeft.x, cornerStripeBottomLeft.pointTopLeft.y, cornerStripeBottomLeft.pointTopCenterLeft.x, cornerStripeBottomLeft.pointTopCenterLeft.y);
                    currentStripe.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeBottomLeft.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                } else if (erkerBottom_start) {
                    crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, erkerBottom_start.pathTop.x1, erkerBottom_start.pathTop.y1, erkerBottom_start.pathTop.x2, erkerBottom_start.pathTop.y2);
                    currentStripe.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    erkerBottom_start.pathTop.x1 = crossPoint[0];
                    erkerBottom_start.pathTop.y1 = crossPoint[1];
                } else if (erkerBottom_round_start) {
                    const innerAngle = Math.asin((currentStripe.pointBottomLeft.y - erkerBottom_round_start.center.y) / erkerBottom_round_start.innerRadius);
                    erkerBottom_round_start.innerAngle.right = 90 - innerAngle * (180 / Math.PI);
                    currentStripe.pointBottomLeft.x = erkerBottom_round_start.center.x - erkerBottom_round_start.innerRadius * Math.cos(innerAngle);
                } else if (!stripeVertical_3 || deletedStripes.includes(stripeVertical_3.name)) {
                    if (erkerLeft_start) {
                        crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, erkerLeft_start.pathRight.x1, erkerLeft_start.pathRight.y1, erkerLeft_start.pathRight.x2, erkerLeft_start.pathRight.y2);
                        currentStripe.pointBottomLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerLeft_start.pathRight.x1 = crossPoint[0];
                        erkerLeft_start.pathRight.y1 = crossPoint[1];
                    } else if (erkerLeft_end && !erkerLeft_start) {
                        crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, erkerLeft_end.pathLeft.x3, erkerLeft_end.pathLeft.y3, erkerLeft_end.pathLeft.x4, erkerLeft_end.pathLeft.y4);
                        currentStripe.pointBottomLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerLeft_end.pathLeft.x4 = crossPoint[0];
                        erkerLeft_end.pathLeft.y4 = crossPoint[1];
                    } else if (erkerLeft_round_start) {
                        const innerAngle = Math.asin((erkerLeft_round_start.center.y - currentStripe.pointBottomLeft.y) / erkerLeft_round_start.innerRadius);
                        erkerLeft_round_start.innerAngle.bottom = innerAngle * (180 / Math.PI);
                        currentStripe.pointBottomLeft.x = erkerLeft_round_start.center.x - erkerLeft_round_start.innerRadius * Math.cos(innerAngle);
                    } else if (erkerLeft_round_end && !erkerLeft_round_start) {
                        const outerAngle = Math.asin((currentStripe.pointBottomLeft.y - erkerLeft_round_end.center.y) / erkerLeft_round_end.outerRadius);
                        erkerLeft_round_end.outerAngle.top = outerAngle * (180 / Math.PI);
                        currentStripe.pointBottomLeft.x = erkerLeft_round_end.center.x - erkerLeft_round_end.outerRadius * Math.cos(outerAngle);
                    } else if ((!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name)) && stripeVertical_1) {
                        crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, stripeVertical_1.pointLeftTop.x, stripeVertical_1.pointLeftTop.y, stripeVertical_1.pointLeftBottom.x, stripeVertical_1.pointLeftBottom.y);
                        currentStripe.pointBottomLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    } else if (stripeHorizontal_1 && !deletedStripes.includes(stripeHorizontal_1.name) && !cornerStripeLeftBottom) {
                        currentStripe.pointBottomLeft.x = currentStripe.pointLeft.x;
                        if (currentStripe.pointBottomLeft.y !== stripeHorizontal_1.pointBottomRight.y) {
                            _extendedStripes.push({
                                points: [
                                    currentStripe.pointBottomLeft.x,
                                    currentStripe.pointBottomLeft.y,
                                    stripeHorizontal_1.pointBottomRight.x,
                                    stripeHorizontal_1.pointBottomRight.y,
                                ]
                            });
                        }
                    }
                } else {
                    crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, stripeVertical_3.pointRightTop.x, stripeVertical_3.pointRightTop.y, stripeVertical_3.pointRightBottom.x, stripeVertical_3.pointRightBottom.y);
                    currentStripe.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }

                // Нижняя правая точка
                if (cornerStripeBottomRight) {
                    crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, cornerStripeBottomRight.pointTopRight.x, cornerStripeBottomRight.pointTopRight.y, cornerStripeBottomRight.pointTopCenterRight.x, cornerStripeBottomRight.pointTopCenterRight.y);
                    currentStripe.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeBottomRight.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                } else if (erkerBottom_end) {
                    crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, erkerBottom_end.pathTop.x3, erkerBottom_end.pathTop.y3, erkerBottom_end.pathTop.x4, erkerBottom_end.pathTop.y4);
                    currentStripe.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    erkerBottom_end.pathTop.x4 = crossPoint[0];
                    erkerBottom_end.pathTop.y4 = crossPoint[1];
                } else if (erkerBottom_round_end) {
                    const innerAngle = Math.asin((currentStripe.pointBottomRight.y - erkerBottom_round_end.center.y) / erkerBottom_round_end.innerRadius);
                    erkerBottom_round_end.innerAngle.left = 90 - innerAngle * (180 / Math.PI);
                    currentStripe.pointBottomRight.x = erkerBottom_round_end.center.x + erkerBottom_round_end.innerRadius * Math.cos(innerAngle);
                } else if (!stripeVertical_4 || deletedStripes.includes(stripeVertical_4.name)) {
                    if (erkerRight_start) {
                        crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, erkerRight_start.pathLeft.x1, erkerRight_start.pathLeft.y1, erkerRight_start.pathLeft.x2, erkerRight_start.pathLeft.y2);
                        currentStripe.pointBottomRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerRight_start.pathLeft.x1 = crossPoint[0];
                        erkerRight_start.pathLeft.y1 = crossPoint[1];
                    } else if (erkerRight_end && !erkerRight_start) {
                        crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, erkerRight_end.pathRight.x3, erkerRight_end.pathRight.y3, erkerRight_end.pathRight.x4, erkerRight_end.pathRight.y4);
                        currentStripe.pointBottomRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerRight_end.pathRight.x4 = crossPoint[0];
                        erkerRight_end.pathRight.y4 = crossPoint[1];
                    } else if (erkerRight_round_start) {
                        const innerAngle = Math.asin((erkerRight_round_start.center.y - currentStripe.pointBottomRight.y) / erkerRight_round_start.innerRadius);
                        erkerRight_round_start.innerAngle.top = innerAngle * (180 / Math.PI);
                        currentStripe.pointBottomRight.x = erkerRight_round_start.center.x + erkerRight_round_start.innerRadius * Math.cos(innerAngle);
                    } else if (erkerRight_round_end && !erkerRight_round_start) {
                        const outerAngle = Math.asin((currentStripe.pointBottomRight.y - erkerRight_round_end.center.y) / erkerRight_round_end.outerRadius);
                        erkerRight_round_end.outerAngle.bottom = outerAngle * (180 / Math.PI);
                        currentStripe.pointBottomRight.x = erkerRight_round_end.center.x + erkerRight_round_end.outerRadius * Math.cos(outerAngle);
                    } else if ((!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name)) && stripeVertical_2) {
                        crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, stripeVertical_2.pointRightTop.x, stripeVertical_2.pointRightTop.y, stripeVertical_2.pointRightBottom.x, stripeVertical_2.pointRightBottom.y);
                        currentStripe.pointBottomRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    } else if (stripeHorizontal_2 && !deletedStripes.includes(stripeHorizontal_2.name)) {
                        currentStripe.pointBottomRight.x = currentStripe.pointRight.x;
                    }
                } else {
                    crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, stripeVertical_4.pointLeftTop.x, stripeVertical_4.pointLeftTop.y, stripeVertical_4.pointLeftBottom.x, stripeVertical_4.pointLeftBottom.y);
                    currentStripe.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }

                // Левая крышка
                if (!cornerStripeLeftTop && !cornerStripeLeftBottom && !erkerLeft_start && !erkerLeft_end && !erkerLeft_round_start && !erkerLeft_round_end && (!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name)) && (!stripeVertical_3 || deletedStripes.includes(stripeVertical_3.name)) && (!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name))) {
                    if (erkerTop_start) {
                        crossPoint = searchCross(currentStripe.capLeftTop.x, currentStripe.capLeftTop.y, currentStripe.capLeftBottom.x, currentStripe.capLeftBottom.y, erkerTop_start.pathTop.x1, erkerTop_start.pathTop.y1, erkerTop_start.pathTop.x2, erkerTop_start.pathTop.y2);
                        currentStripe.capLeftTop = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerTop_start.pathTop.x1 = crossPoint[0];
                        erkerTop_start.pathTop.y1 = crossPoint[1];
                        currentStripe.pointBottomLeft.x = currentStripe.capLeftBottom.x;
                    } else if (erkerTop_round_start) {
                        const outerAngle = Math.acos((erkerTop_round_start.center.x - currentStripe.capLeftTop.x) / erkerTop_round_start.outerRadius);
                        erkerTop_round_start.outerAngle.left = 90 - outerAngle * (180 / Math.PI);
                        currentStripe.capLeftTop.y = erkerTop_round_start.center.y - erkerTop_round_start.outerRadius * Math.sin(outerAngle);
                        currentStripe.pointBottomLeft.x = currentStripe.capLeftBottom.x;
                    } else if (erkerBottom_start) {
                        crossPoint = searchCross(currentStripe.capLeftTop.x, currentStripe.capLeftTop.y, currentStripe.capLeftBottom.x, currentStripe.capLeftBottom.y, erkerBottom_start.pathBottom.x1, erkerBottom_start.pathBottom.y1, erkerBottom_start.pathBottom.x2, erkerBottom_start.pathBottom.y2);
                        currentStripe.capLeftBottom = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerBottom_start.pathBottom.x1 = crossPoint[0];
                        erkerBottom_start.pathBottom.y1 = crossPoint[1];
                        currentStripe.pointTopLeft.x = currentStripe.capLeftTop.x;
                    } else if (erkerBottom_round_start) {
                        const outerAngle = Math.acos((erkerBottom_round_start.center.x - currentStripe.capLeftBottom.x) / erkerBottom_round_start.outerRadius);
                        erkerBottom_round_start.outerAngle.right = 90 - outerAngle * (180 / Math.PI);
                        currentStripe.capLeftBottom.y = erkerBottom_round_start.center.y + erkerBottom_round_start.outerRadius * Math.sin(outerAngle);
                        currentStripe.pointTopLeft.x = currentStripe.capLeftTop.x;
                    } else {
                        currentStripe.pointTopLeft.x = currentStripe.capLeftTop.x;
                        currentStripe.pointBottomLeft.x = currentStripe.capLeftBottom.x;
                    }
                    currentStripe.capLeft = true;
                }

                // Правая крышка
                if (!cornerStripeRightTop && !cornerStripeRightBottom && !erkerRight_start && !erkerRight_end && !erkerRight_round_start && !erkerRight_round_end && (!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name)) && (!stripeVertical_4 || deletedStripes.includes(stripeVertical_4.name)) && (!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name))) {
                    if (erkerTop_end) {
                        crossPoint = searchCross(currentStripe.capRightTop.x, currentStripe.capRightTop.y, currentStripe.capRightBottom.x, currentStripe.capRightBottom.y, erkerTop_end.pathTop.x3, erkerTop_end.pathTop.y3, erkerTop_end.pathTop.x4, erkerTop_end.pathTop.y4);
                        currentStripe.capRightTop = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerTop_end.pathTop.x4 = crossPoint[0];
                        erkerTop_end.pathTop.y4 = crossPoint[1];
                        currentStripe.pointBottomRight.x = currentStripe.capRightBottom.x;
                    } else if (erkerTop_round_end) {
                        const outerAngle = Math.acos((currentStripe.capRightTop.x - erkerTop_round_end.center.x) / erkerTop_round_end.outerRadius);
                        erkerTop_round_end.outerAngle.right = 90 - outerAngle * (180 / Math.PI);
                        currentStripe.capRightTop.y = erkerTop_round_end.center.y - erkerTop_round_end.outerRadius * Math.sin(outerAngle);
                        currentStripe.pointBottomRight.x = currentStripe.capRightBottom.x;
                    } else if (erkerBottom_end) {
                        crossPoint = searchCross(currentStripe.capRightTop.x, currentStripe.capRightTop.y, currentStripe.capRightBottom.x, currentStripe.capRightBottom.y, erkerBottom_end.pathBottom.x3, erkerBottom_end.pathBottom.y3, erkerBottom_end.pathBottom.x4, erkerBottom_end.pathBottom.y4);
                        currentStripe.capRightBottom = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerBottom_end.pathBottom.x4 = crossPoint[0];
                        erkerBottom_end.pathBottom.y4 = crossPoint[1];
                        currentStripe.pointTopRight.x = currentStripe.capRightTop.x;
                    } else if (erkerBottom_round_end) {
                        const outerAngle = Math.acos((currentStripe.capRightBottom.x - erkerBottom_round_end.center.x) / erkerBottom_round_end.outerRadius);
                        erkerBottom_round_end.outerAngle.left = 90 - outerAngle * (180 / Math.PI);
                        currentStripe.capRightBottom.y = erkerBottom_round_end.center.y + erkerBottom_round_end.outerRadius * Math.sin(outerAngle);
                        currentStripe.pointTopRight.x = currentStripe.capRightTop.x;
                    } else {
                        currentStripe.pointTopRight.x = currentStripe.capRightTop.x;
                        currentStripe.pointBottomRight.x = currentStripe.capRightBottom.x;
                    }
                    currentStripe.capRight = true;
                }

                if (cornerStripeLeftTop) {
                    if (deletedStripes.includes(stripeVertical_1.name)) {
                        crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, cornerStripeLeftTop.pointTopRight.x, cornerStripeLeftTop.pointTopRight.y, cornerStripeLeftTop.pointTopCenterRight.x, cornerStripeLeftTop.pointTopCenterRight.y);
                        currentStripe.pointTopLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeLeftTop.pointTopRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                }

                if (cornerStripeLeftBottom) {
                    if (!stripeVertical_3 || deletedStripes.includes(stripeVertical_3.name)) {
                        crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, cornerStripeLeftBottom.pointBottomRight.x, cornerStripeLeftBottom.pointBottomRight.y, cornerStripeLeftBottom.pointBottomCenterRight.x, cornerStripeLeftBottom.pointBottomCenterRight.y);
                        currentStripe.pointBottomLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeLeftBottom.pointBottomRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                }

                if (cornerStripeRightTop) {
                    if (!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name)) {
                        crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, cornerStripeRightTop.pointTopLeft.x, cornerStripeRightTop.pointTopLeft.y, cornerStripeRightTop.pointTopCenterLeft.x, cornerStripeRightTop.pointTopCenterLeft.y);
                        currentStripe.pointTopRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeRightTop.pointTopLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                }

                if (cornerStripeRightBottom) {
                    if (!stripeVertical_4 || deletedStripes.includes(stripeVertical_4.name)) {
                        crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, cornerStripeRightBottom.pointBottomLeft.x, cornerStripeRightBottom.pointBottomLeft.y, cornerStripeRightBottom.pointBottomCenterLeft.x, cornerStripeRightBottom.pointBottomCenterLeft.y);
                        currentStripe.pointBottomRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeRightBottom.pointBottomLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                }

                if (cornerStripeLeftTop && !cornerStripeLeftBottom) {
                    if ((!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name)) && (!stripeVertical_3 || deletedStripes.includes(stripeVertical_3.name)) && !erkerBottom_start && !erkerBottom_end && !erkerBottom_round_start && !erkerBottom_round_end) {
                        crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, cornerStripeLeftTop.pointBottomRight.x, cornerStripeLeftTop.pointBottomRight.y, cornerStripeLeftTop.pointBottomCenterRight.x, cornerStripeLeftTop.pointBottomCenterRight.y);
                        currentStripe.pointBottomLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeLeftTop.pointBottomRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                } else if (cornerStripeLeftBottom && !cornerStripeLeftTop) {
                    if ((!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name)) && (!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name)) && !erkerTop_end && !erkerTop_start && !erkerTop_round_end && !erkerTop_round_start) {
                        crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, cornerStripeLeftBottom.pointTopRight.x, cornerStripeLeftBottom.pointTopRight.y, cornerStripeLeftBottom.pointTopCenterRight.x, cornerStripeLeftBottom.pointTopCenterRight.y);
                        currentStripe.pointTopLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeLeftBottom.pointTopRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                }

                if (cornerStripeRightTop && !cornerStripeRightBottom) {
                    if ((!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name)) && (!stripeVertical_4 || deletedStripes.includes(stripeVertical_4.name)) && !erkerBottom_start && !erkerBottom_end && !erkerBottom_round_start && !erkerBottom_round_end) {
                        crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, cornerStripeRightTop.pointBottomLeft.x, cornerStripeRightTop.pointBottomLeft.y, cornerStripeRightTop.pointBottomCenterLeft.x, cornerStripeRightTop.pointBottomCenterLeft.y);
                        currentStripe.pointBottomRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeRightTop.pointBottomLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                } else if (cornerStripeRightBottom && !cornerStripeRightTop) {
                    if ((!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name)) && (!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name)) && !erkerTop_end && !erkerTop_start && !erkerTop_round_end && !erkerTop_round_start) {
                        crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, cornerStripeRightBottom.pointTopLeft.x, cornerStripeRightBottom.pointTopLeft.y, cornerStripeRightBottom.pointTopCenterLeft.x, cornerStripeRightBottom.pointTopCenterLeft.y);
                        currentStripe.pointTopRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeRightBottom.pointTopLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                }

            }
        }
    }

    // Идём по вертикальным перемычкам
    for (let i = 0; i < countY; i++) {
        for (let j = 0; j < countX - 1; j++) {
            // Обрабатываемая перемычка
            let currentStripe = _stripes.vertical.find(stripe => stripe.name === `ver_${j}_${i}`);

            // Перемычки под углом
            let cornerStripeLeftTop = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${j}_${i - 1}:hor_${i - 1}_${j + 1}`));
            let cornerStripeLeftBottom = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${j}_${i - 1}:hor_${i - 1}_${j}`));
            let cornerStripeRightTop = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${j}_${i}:hor_${i}_${j}`));
            let cornerStripeRightBottom = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${j}_${i}:hor_${i}_${j + 1}`));

            let cornerStripeTopLeft = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${j - 1}_${i - 1}:hor_${i - 1}_${j - 1}`));
            let cornerStripeTopRight = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${j - 1}_${i}:hor_${i}_${j}`));
            let cornerStripeBottomLeft = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${j + 1}_${i - 1}:hor_${i - 1}_${j + 2}`));
            let cornerStripeBottomRight = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${j + 1}_${i}:hor_${i}_${j + 1}`));

            // Смежные вертикальные
            let stripeVertical_1 = _stripes.vertical.find(stripe => stripe.name === `ver_${j - 1}_${i}`);
            let stripeVertical_2 = _stripes.vertical.find(stripe => stripe.name === `ver_${j + 1}_${i}`);

            // Смежные горизонтальные
            let stripeHorizontal_1 = _stripes.horizontal.find(stripe => stripe.name === `hor_${i - 1}_${j}`);
            let stripeHorizontal_2 = _stripes.horizontal.find(stripe => stripe.name === `hor_${i - 1}_${j + 1}`);
            let stripeHorizontal_3 = _stripes.horizontal.find(stripe => stripe.name === `hor_${i}_${j}`);
            let stripeHorizontal_4 = _stripes.horizontal.find(stripe => stripe.name === `hor_${i}_${j + 1}`);

            // Смежные эркеры - левые угловые
            let erkerLeft_start = _erkers.left.filter(erker => erker.type === 'angel').find(erker => erker.startVertical === currentStripe.name);
            let erkerLeft_end = _erkers.left.filter(erker => erker.type === 'angel').find(erker => erker.endVertical === currentStripe.name);

            // Смежные эркеры - левые круглые
            let erkerLeft_round_start = _erkers.left.filter(erker => erker.type === 'round').find(erker => erker.startVertical === currentStripe.name);
            let erkerLeft_round_end = _erkers.left.filter(erker => erker.type === 'round').find(erker => erker.endVertical === currentStripe.name);

            // Смежные эркеры - правые угловые
            let erkerRight_start = _erkers.right.filter(erker => erker.type === 'angel').find(erker => erker.startVertical === currentStripe.name);
            let erkerRight_end = _erkers.right.filter(erker => erker.type === 'angel').find(erker => erker.endVertical === currentStripe.name);

            // Смежные эркеры - правые круглые
            let erkerRight_round_start = _erkers.right.filter(erker => erker.type === 'round').find(erker => erker.startVertical === currentStripe.name);
            let erkerRight_round_end = _erkers.right.filter(erker => erker.type === 'round').find(erker => erker.endVertical === currentStripe.name);

            // Смежные эркеры - верхние угловые
            let erkerTop_start = _erkers.top.filter(erker => erker.type === 'angel').find(erker => erker.startVertical === currentStripe.name);
            let erkerTop_end = _erkers.top.filter(erker => erker.type === 'angel').find(erker => erker.endVertical === currentStripe.name);
            
            // Смежные эркеры - верхние круглые
            let erkerTop_round_start = _erkers.top.filter(erker => erker.type === 'round').find(erker => erker.startVertical === currentStripe.name);
            let erkerTop_round_end = _erkers.top.filter(erker => erker.type === 'round').find(erker => erker.endVertical === currentStripe.name);
            
            // Смежные эркеры - нижние угловые
            let erkerBottom_start = _erkers.bottom.filter(erker => erker.type === 'angel').find(erker => erker.startVertical === currentStripe.name);
            let erkerBottom_end = _erkers.bottom.filter(erker => erker.type === 'angel').find(erker => erker.endVertical === currentStripe.name);

            // Смежные эркеры - нижние круглые
            let erkerBottom_round_start = _erkers.bottom.filter(erker => erker.type === 'round').find(erker => erker.startVertical === currentStripe.name);
            let erkerBottom_round_end = _erkers.bottom.filter(erker => erker.type === 'round').find(erker => erker.endVertical === currentStripe.name);

            // Переменная для точки перечения
            let crossPoint = [];

            currentStripe.capTop = false;
            currentStripe.capBottom = false;
            currentStripe.capTopRight.x = currentStripe.pointTop.x + currentStripe.rightWidth;
            currentStripe.capTopRight.y = currentStripe.pointTop.y - currentStripe.width / 2;
            currentStripe.capBottomRight.x = currentStripe.pointBottom.x + currentStripe.rightWidth;
            currentStripe.capBottomRight.y = currentStripe.pointBottom.y + currentStripe.width / 2;
            currentStripe.capTopLeft.x = currentStripe.pointTop.x - currentStripe.leftWidth;
            currentStripe.capTopLeft.y = currentStripe.pointTop.y - currentStripe.width / 2;
            currentStripe.capBottomLeft.x = currentStripe.pointBottom.x - currentStripe.leftWidth;
            currentStripe.capBottomLeft.y = currentStripe.pointBottom.y + currentStripe.width / 2;

            if (cornerStripeRightTop && cornerStripeRightBottom) {
                crossPoint = searchCross(cornerStripeRightTop.pointBottomLeft.x, cornerStripeRightTop.pointBottomLeft.y, cornerStripeRightTop.pointBottomCenterLeft.x, cornerStripeRightTop.pointBottomCenterLeft.y, cornerStripeRightBottom.pointTopLeft.x, cornerStripeRightBottom.pointTopLeft.y, cornerStripeRightBottom.pointTopCenterLeft.x, cornerStripeRightBottom.pointTopCenterLeft.y);
                cornerStripeRightTop.pointBottomCenterLeft = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                cornerStripeRightBottom.pointTopCenterLeft = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                crossPoint = searchCross(cornerStripeRightTop.pointTopLeft.x, cornerStripeRightTop.pointTopLeft.y, cornerStripeRightTop.pointTopCenterLeft.x, cornerStripeRightTop.pointTopCenterLeft.y, cornerStripeRightBottom.pointTopRight.x, cornerStripeRightBottom.pointTopRight.y, cornerStripeRightBottom.pointTopCenterRight.x, cornerStripeRightBottom.pointTopCenterRight.y);
                cornerStripeRightTop.pointTopCenterLeft = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                cornerStripeRightBottom.pointTopCenterRight = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                crossPoint = searchCross(cornerStripeRightTop.pointTopRight.x, cornerStripeRightTop.pointTopRight.y, cornerStripeRightTop.pointTopCenterRight.x, cornerStripeRightTop.pointTopCenterRight.y, cornerStripeRightBottom.pointBottomRight.x, cornerStripeRightBottom.pointBottomRight.y, cornerStripeRightBottom.pointBottomCenterRight.x, cornerStripeRightBottom.pointBottomCenterRight.y);
                cornerStripeRightTop.pointTopCenterRight = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                cornerStripeRightBottom.pointBottomCenterRight = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                crossPoint = searchCross(cornerStripeRightTop.pointBottomRight.x, cornerStripeRightTop.pointBottomRight.y, cornerStripeRightTop.pointBottomCenterRight.x, cornerStripeRightTop.pointBottomCenterRight.y, cornerStripeRightBottom.pointBottomLeft.x, cornerStripeRightBottom.pointBottomLeft.y, cornerStripeRightBottom.pointBottomCenterLeft.x, cornerStripeRightBottom.pointBottomCenterLeft.y);
                cornerStripeRightTop.pointBottomCenterRight = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                cornerStripeRightBottom.pointBottomCenterLeft = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
            }

            if (!deletedStripes.includes(currentStripe.name)) {

                // Левая верхняя точка
                if (cornerStripeLeftTop) {
                    crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, cornerStripeLeftTop.pointBottomRight.x, cornerStripeLeftTop.pointBottomRight.y, cornerStripeLeftTop.pointBottomCenterRight.x, cornerStripeLeftTop.pointBottomCenterRight.y);
                    currentStripe.pointLeftTop = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeLeftTop.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                } else if (erkerLeft_start) {
                    crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, erkerLeft_start.pathRight.x1, erkerLeft_start.pathRight.y1, erkerLeft_start.pathRight.x2, erkerLeft_start.pathRight.y2);
                    currentStripe.pointLeftTop = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    erkerLeft_start.pathRight.x1 = crossPoint[0];
                    erkerLeft_start.pathRight.y1 = crossPoint[1];
                } else if (erkerLeft_round_start) {
                    const innerAngle = Math.acos((erkerLeft_round_start.center.x - currentStripe.pointLeftTop.x) / erkerLeft_round_start.innerRadius);
                    erkerLeft_round_start.innerAngle.top = innerAngle * (180 / Math.PI);
                    currentStripe.pointLeftTop.y = erkerLeft_round_start.center.y - erkerLeft_round_start.innerRadius * Math.sin(innerAngle);
                } else if (!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name)) {
                    if (erkerTop_start && !erkerTop_end) {
                        crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, erkerTop_start.pathTop.x1, erkerTop_start.pathTop.y1, erkerTop_start.pathTop.x2, erkerTop_start.pathTop.y2);
                        currentStripe.pointLeftTop = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerTop_start.pathTop.x1 = crossPoint[0];
                        erkerTop_start.pathTop.y1 = crossPoint[1];
                    } else if (erkerTop_end) {
                        crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, erkerTop_end.pathBottom.x3, erkerTop_end.pathBottom.y3, erkerTop_end.pathBottom.x4, erkerTop_end.pathBottom.y4);
                        currentStripe.pointLeftTop = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerTop_end.pathBottom.x4 = crossPoint[0];
                        erkerTop_end.pathBottom.y4 = crossPoint[1];
                    } else if (erkerTop_round_start && !erkerTop_round_end) {
                        const outerAngle = Math.acos((erkerTop_round_start.center.x - currentStripe.pointLeftTop.x) / erkerTop_round_start.outerRadius);
                        erkerTop_round_start.outerAngle.left = 90 - outerAngle * (180 / Math.PI);
                        currentStripe.pointLeftTop.y = erkerTop_round_start.center.y - erkerTop_round_start.outerRadius * Math.sin(outerAngle);
                    } else if (erkerTop_round_end) {
                        const innerAngle = Math.acos((currentStripe.pointLeftTop.x - erkerTop_round_end.center.x) / erkerTop_round_end.innerRadius);
                        erkerTop_round_end.innerAngle.right = 90 - innerAngle * (180 / Math.PI);
                        currentStripe.pointLeftTop.y = erkerTop_round_end.center.y - erkerTop_round_end.innerRadius * Math.sin(innerAngle);
                    } else if ((!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name)) && stripeHorizontal_3) {
                        crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, stripeHorizontal_3.pointTopLeft.x, stripeHorizontal_3.pointTopLeft.y, stripeHorizontal_3.pointTopRight.x, stripeHorizontal_3.pointTopRight.y);
                        currentStripe.pointLeftTop = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    } else if (stripeVertical_1 && !deletedStripes.includes(stripeVertical_1.name) && !cornerStripeTopLeft) {
                        currentStripe.pointLeftTop.y = currentStripe.pointTop.y;
                        if (currentStripe.pointLeftTop.x !== stripeVertical_1.pointLeftBottom.x) {
                            _extendedStripes.push({
                                points: [
                                    currentStripe.pointLeftTop.x,
                                    currentStripe.pointLeftTop.y,
                                    stripeVertical_1.pointLeftBottom.x,
                                    stripeVertical_1.pointLeftBottom.y,
                                ]
                            });
                        }
                    }
                } else {
                    crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, stripeHorizontal_1.pointBottomLeft.x, stripeHorizontal_1.pointBottomLeft.y, stripeHorizontal_1.pointBottomRight.x, stripeHorizontal_1.pointBottomRight.y);
                    currentStripe.pointLeftTop = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }

                // Левая нижняя точка
                if (cornerStripeLeftBottom) {
                    crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, cornerStripeLeftBottom.pointTopRight.x, cornerStripeLeftBottom.pointTopRight.y, cornerStripeLeftBottom.pointTopCenterRight.x, cornerStripeLeftBottom.pointTopCenterRight.y);
                    currentStripe.pointLeftBottom = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeLeftBottom.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                } else if (erkerLeft_end) {
                    crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, erkerLeft_end.pathRight.x3, erkerLeft_end.pathRight.y3, erkerLeft_end.pathRight.x4, erkerLeft_end.pathRight.y4);
                    currentStripe.pointLeftBottom = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    erkerLeft_end.pathRight.x4 = crossPoint[0];
                    erkerLeft_end.pathRight.y4 = crossPoint[1];
                } else if (erkerLeft_round_end) {
                    const innerAngle = Math.acos((erkerLeft_round_end.center.x - currentStripe.pointLeftBottom.x) / erkerLeft_round_end.innerRadius);
                    erkerLeft_round_end.innerAngle.bottom = innerAngle * (180 / Math.PI);
                    currentStripe.pointLeftBottom.y = erkerLeft_round_end.center.y + erkerLeft_round_end.innerRadius * Math.sin(innerAngle);
                } else if (!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name)) {
                    if (erkerBottom_start && !erkerBottom_end) {
                        crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, erkerBottom_start.pathBottom.x1, erkerBottom_start.pathBottom.y1, erkerBottom_start.pathBottom.x2, erkerBottom_start.pathBottom.y2);
                        currentStripe.pointLeftBottom = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerBottom_start.pathBottom.x1 = crossPoint[0];
                        erkerBottom_start.pathBottom.y1 = crossPoint[1];
                    } else if (erkerBottom_end) {
                        crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, erkerBottom_end.pathTop.x3, erkerBottom_end.pathTop.y3, erkerBottom_end.pathTop.x4, erkerBottom_end.pathTop.y4);
                        currentStripe.pointLeftBottom = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerBottom_end.pathTop.x4 = crossPoint[0];
                        erkerBottom_end.pathTop.y4 = crossPoint[1];
                    } else if (erkerBottom_round_start && !erkerBottom_round_end) {
                        const outerAngle = Math.acos((erkerBottom_round_start.center.x - currentStripe.pointLeftBottom.x) / erkerBottom_round_start.outerRadius);
                        erkerBottom_round_start.outerAngle.right = 90 - outerAngle * (180 / Math.PI);
                        currentStripe.pointLeftBottom.y = erkerBottom_round_start.center.y + erkerBottom_round_start.outerRadius * Math.sin(outerAngle);
                    } else if (erkerBottom_round_end) {
                        const innerAngle = Math.acos((currentStripe.pointLeftBottom.x - erkerBottom_round_end.center.x) / erkerBottom_round_end.innerRadius);
                        erkerBottom_round_end.innerAngle.left = 90 - innerAngle * (180 / Math.PI);
                        currentStripe.pointLeftBottom.y = erkerBottom_round_end.center.y + erkerBottom_round_end.innerRadius * Math.sin(innerAngle);
                    } else if ((!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name)) && stripeHorizontal_4) {
                        crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, stripeHorizontal_4.pointBottomLeft.x, stripeHorizontal_4.pointBottomLeft.y, stripeHorizontal_4.pointBottomRight.x, stripeHorizontal_4.pointBottomRight.y);
                        currentStripe.pointLeftBottom = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    } else if (stripeVertical_2 && !deletedStripes.includes(stripeVertical_2.name)) {
                        currentStripe.pointLeftBottom.y = currentStripe.pointBottom.y;
                    }
                } else {
                    crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, stripeHorizontal_2.pointTopLeft.x, stripeHorizontal_2.pointTopLeft.y, stripeHorizontal_2.pointTopRight.x, stripeHorizontal_2.pointTopRight.y);
                    currentStripe.pointLeftBottom = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }

                // Правая верхняя точка
                if (cornerStripeRightTop) {
                    crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, cornerStripeRightTop.pointBottomLeft.x, cornerStripeRightTop.pointBottomLeft.y, cornerStripeRightTop.pointBottomCenterLeft.x, cornerStripeRightTop.pointBottomCenterLeft.y);
                    currentStripe.pointRightTop = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeRightTop.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                } else if (erkerRight_start) {
                    crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, erkerRight_start.pathLeft.x1, erkerRight_start.pathLeft.y1, erkerRight_start.pathLeft.x2, erkerRight_start.pathLeft.y2);
                    currentStripe.pointRightTop = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    erkerRight_start.pathLeft.x1 = crossPoint[0];
                    erkerRight_start.pathLeft.y1 = crossPoint[1];
                } else if (erkerRight_round_start) {
                    const innerAngle = Math.acos((currentStripe.pointRightTop.x - erkerRight_round_start.center.x) / erkerRight_round_start.innerRadius);
                    erkerRight_round_start.innerAngle.top = innerAngle * (180 / Math.PI);
                    currentStripe.pointRightTop.y = erkerRight_round_start.center.y - erkerRight_round_start.innerRadius * Math.sin(innerAngle);
                } else if (!stripeHorizontal_3 || deletedStripes.includes(stripeHorizontal_3.name)) {
                    if (erkerTop_start) {
                        crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, erkerTop_start.pathBottom.x1, erkerTop_start.pathBottom.y1, erkerTop_start.pathBottom.x2, erkerTop_start.pathBottom.y2);
                        currentStripe.pointRightTop = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerTop_start.pathBottom.x1 = crossPoint[0];
                        erkerTop_start.pathBottom.y1 = crossPoint[1];
                    } else if (erkerTop_end && !erkerTop_start) {
                        crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, erkerTop_end.pathTop.x3, erkerTop_end.pathTop.y3, erkerTop_end.pathTop.x4, erkerTop_end.pathTop.y4);
                        currentStripe.pointRightTop = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerTop_end.pathTop.x4 = crossPoint[0];
                        erkerTop_end.pathTop.y4 = crossPoint[1];
                    } else if (erkerTop_round_start) {
                        const innerAngle = Math.acos((erkerTop_round_start.center.x - currentStripe.pointRightTop.x) / erkerTop_round_start.innerRadius);
                        erkerTop_round_start.innerAngle.left = 90 - innerAngle * (180 / Math.PI);
                        currentStripe.pointRightTop.y = erkerTop_round_start.center.y - erkerTop_round_start.innerRadius * Math.sin(innerAngle);
                    } else if (erkerTop_round_end && !erkerTop_round_start) {
                        const outerAngle = Math.acos((currentStripe.pointRightTop.x - erkerTop_round_end.center.x) / erkerTop_round_end.outerRadius);
                        erkerTop_round_end.outerAngle.right = 90 - outerAngle * (180 / Math.PI);
                        currentStripe.pointRightTop.y = erkerTop_round_end.center.y - erkerTop_round_end.outerRadius * Math.sin(outerAngle);
                    } else if ((!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name)) && stripeHorizontal_1) {
                        crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, stripeHorizontal_1.pointTopLeft.x, stripeHorizontal_1.pointTopLeft.y, stripeHorizontal_1.pointTopRight.x, stripeHorizontal_1.pointTopRight.y);
                        currentStripe.pointRightTop = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    } else if (stripeVertical_1 && !deletedStripes.includes(stripeVertical_1.name) && !cornerStripeTopRight) {
                        currentStripe.pointRightTop.y = currentStripe.pointTop.y;
                        if (currentStripe.pointRightTop.x !== stripeVertical_1.pointRightBottom.x) {
                            _extendedStripes.push({
                                points: [
                                    currentStripe.pointRightTop.x,
                                    currentStripe.pointRightTop.y,
                                    stripeVertical_1.pointRightBottom.x,
                                    stripeVertical_1.pointRightBottom.y,
                                ]
                            });
                        }
                    }
                } else {
                    crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, stripeHorizontal_3.pointBottomLeft.x, stripeHorizontal_3.pointBottomLeft.y, stripeHorizontal_3.pointBottomRight.x, stripeHorizontal_3.pointBottomRight.y);
                    currentStripe.pointRightTop = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }

                // Правая нижняя точка
                if (cornerStripeRightBottom) {
                    crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, cornerStripeRightBottom.pointTopLeft.x, cornerStripeRightBottom.pointTopLeft.y, cornerStripeRightBottom.pointTopCenterLeft.x, cornerStripeRightBottom.pointTopCenterLeft.y);
                    currentStripe.pointRightBottom = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeRightBottom.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                } else if (erkerRight_end) {
                    crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, erkerRight_end.pathLeft.x3, erkerRight_end.pathLeft.y3, erkerRight_end.pathLeft.x4, erkerRight_end.pathLeft.y4);
                    currentStripe.pointRightBottom = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    erkerRight_end.pathLeft.x4 = crossPoint[0];
                    erkerRight_end.pathLeft.y4 = crossPoint[1];
                } else if (erkerRight_round_end) {
                    const innerAngle = Math.acos((currentStripe.pointRightBottom.x - erkerRight_round_end.center.x) / erkerRight_round_end.innerRadius);
                    erkerRight_round_end.innerAngle.bottom = innerAngle * (180 / Math.PI);
                    currentStripe.pointRightBottom.y = erkerRight_round_end.center.y + erkerRight_round_end.innerRadius * Math.sin(innerAngle);
                } else if (!stripeHorizontal_4 || deletedStripes.includes(stripeHorizontal_4.name)) {
                    if (erkerBottom_start) {
                        crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, erkerBottom_start.pathTop.x1, erkerBottom_start.pathTop.y1, erkerBottom_start.pathTop.x2, erkerBottom_start.pathTop.y2);
                        currentStripe.pointRightBottom = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerBottom_start.pathTop.x1 = crossPoint[0];
                        erkerBottom_start.pathTop.y1 = crossPoint[1];
                    } else if (erkerBottom_end && !erkerBottom_start) {
                        crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, erkerBottom_end.pathBottom.x3, erkerBottom_end.pathBottom.y3, erkerBottom_end.pathBottom.x4, erkerBottom_end.pathBottom.y4);
                        currentStripe.pointRightBottom = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerBottom_end.pathBottom.x4 = crossPoint[0];
                        erkerBottom_end.pathBottom.y4 = crossPoint[1];
                    } else if (erkerBottom_round_start) {
                        const innerAngle = Math.acos((erkerBottom_round_start.center.x - currentStripe.pointRightBottom.x) / erkerBottom_round_start.innerRadius);
                        erkerBottom_round_start.innerAngle.right = 90 - innerAngle * (180 / Math.PI);
                        currentStripe.pointRightBottom.y = erkerBottom_round_start.center.y + erkerBottom_round_start.innerRadius * Math.sin(innerAngle);
                    } else if (erkerBottom_round_end && !erkerBottom_round_start) {
                        const outerAngle = Math.acos((currentStripe.pointRightBottom.x - erkerBottom_round_end.center.x) / erkerBottom_round_end.outerRadius);
                        erkerBottom_round_end.outerAngle.left = 90 - outerAngle * (180 / Math.PI);
                        currentStripe.pointRightBottom.y = erkerBottom_round_end.center.y + erkerBottom_round_end.outerRadius * Math.sin(outerAngle);
                    } else if ((!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name)) && stripeHorizontal_2) {
                        crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, stripeHorizontal_2.pointBottomLeft.x, stripeHorizontal_2.pointBottomLeft.y, stripeHorizontal_2.pointBottomRight.x, stripeHorizontal_2.pointBottomRight.y);
                        currentStripe.pointRightBottom = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    } else if (stripeVertical_2 && !deletedStripes.includes(stripeVertical_2.name)) {
                        currentStripe.pointRightBottom.y = currentStripe.pointBottom.y;
                    }
                } else {
                    crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, stripeHorizontal_4.pointTopLeft.x, stripeHorizontal_4.pointTopLeft.y, stripeHorizontal_4.pointTopRight.x, stripeHorizontal_4.pointTopRight.y);
                    currentStripe.pointRightBottom = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }

                // Верхняя крышка
                if (!cornerStripeTopLeft && !cornerStripeTopRight && !erkerTop_start && !erkerTop_end  && !erkerTop_round_start && !erkerTop_round_end && (!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name)) && (!stripeHorizontal_3 || deletedStripes.includes(stripeHorizontal_3.name)) && (!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name))) {
                    if (erkerLeft_start) {
                        crossPoint = searchCross(currentStripe.capTopLeft.x, currentStripe.capTopLeft.y, currentStripe.capTopRight.x, currentStripe.capTopRight.y, erkerLeft_start.pathLeft.x1, erkerLeft_start.pathLeft.y1, erkerLeft_start.pathLeft.x2, erkerLeft_start.pathLeft.y2);
                        currentStripe.capTopLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerLeft_start.pathLeft.x1 = crossPoint[0];
                        erkerLeft_start.pathLeft.y1 = crossPoint[1];
                        currentStripe.pointRightTop.y = currentStripe.capTopRight.y;
                    } else if (erkerLeft_round_start) {
                        const outerAngle = Math.asin((erkerLeft_round_start.center.y - currentStripe.capTopLeft.y) / erkerLeft_round_start.outerRadius);
                        erkerLeft_round_start.outerAngle.bottom = outerAngle * (180 / Math.PI);
                        currentStripe.capTopLeft.x = erkerLeft_round_start.center.x - erkerLeft_round_start.outerRadius * Math.cos(outerAngle);
                        currentStripe.pointRightTop.y = currentStripe.capTopRight.y;
                    } else if (erkerRight_start) {
                        crossPoint = searchCross(currentStripe.capTopLeft.x, currentStripe.capTopLeft.y, currentStripe.capTopRight.x, currentStripe.capTopRight.y, erkerRight_start.pathRight.x1, erkerRight_start.pathRight.y1, erkerRight_start.pathRight.x2, erkerRight_start.pathRight.y2);
                        currentStripe.capTopRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerRight_start.pathRight.x1 = crossPoint[0];
                        erkerRight_start.pathRight.y1 = crossPoint[1];
                        currentStripe.pointLeftTop.y = currentStripe.capTopLeft.y;
                    } else if (erkerRight_round_start) {
                        const outerAngle = Math.asin((erkerRight_round_start.center.y - currentStripe.capTopRight.y) / erkerRight_round_start.outerRadius);
                        erkerRight_round_start.outerAngle.top = outerAngle * (180 / Math.PI);
                        currentStripe.capTopRight.x = erkerRight_round_start.center.x + erkerRight_round_start.outerRadius * Math.cos(outerAngle);
                        currentStripe.pointLeftTop.y = currentStripe.capTopLeft.y;
                    } else {
                        currentStripe.pointLeftTop.y = currentStripe.capTopLeft.y;
                        currentStripe.pointRightTop.y = currentStripe.capTopRight.y;
                    }
                    currentStripe.capTop = true;
                }

                // Нижняя крышка
                if (!cornerStripeBottomLeft && !cornerStripeBottomRight && !erkerBottom_start && !erkerBottom_end && !erkerBottom_round_start && !erkerBottom_round_end && (!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name)) && (!stripeHorizontal_4 || deletedStripes.includes(stripeHorizontal_4.name)) && (!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name))) {
                    if (erkerLeft_end) {
                        crossPoint = searchCross(currentStripe.capBottomLeft.x, currentStripe.capBottomLeft.y, currentStripe.capBottomRight.x, currentStripe.capBottomRight.y, erkerLeft_end.pathLeft.x3, erkerLeft_end.pathLeft.y3, erkerLeft_end.pathLeft.x4, erkerLeft_end.pathLeft.y4);
                        currentStripe.capBottomLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerLeft_end.pathLeft.x4 = crossPoint[0];
                        erkerLeft_end.pathLeft.y4 = crossPoint[1];
                        currentStripe.pointRightBottom.y = currentStripe.capBottomRight.y;
                    } else if (erkerLeft_round_end) {
                        const outerAngle = Math.asin((currentStripe.capBottomLeft.y - erkerLeft_round_end.center.y) / erkerLeft_round_end.outerRadius);
                        erkerLeft_round_end.outerAngle.top = outerAngle * (180 / Math.PI);
                        currentStripe.capBottomLeft.x = erkerLeft_round_end.center.x - erkerLeft_round_end.outerRadius * Math.cos(outerAngle);
                        currentStripe.pointRightBottom.y = currentStripe.capBottomRight.y;
                    } else if (erkerRight_end) {
                        crossPoint = searchCross(currentStripe.capBottomLeft.x, currentStripe.capBottomLeft.y, currentStripe.capBottomRight.x, currentStripe.capBottomRight.y, erkerRight_end.pathRight.x3, erkerRight_end.pathRight.y3, erkerRight_end.pathRight.x4, erkerRight_end.pathRight.y4);
                        currentStripe.capBottomRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        erkerRight_end.pathRight.x4 = crossPoint[0];
                        erkerRight_end.pathRight.y4 = crossPoint[1];
                        currentStripe.pointLeftBottom.y = currentStripe.capBottomLeft.y;
                    } else if (erkerRight_round_end) {
                        const outerAngle = Math.asin((currentStripe.capBottomRight.y - erkerRight_round_end.center.y) / erkerRight_round_end.outerRadius);
                        erkerRight_round_end.outerAngle.bottom = outerAngle * (180 / Math.PI);
                        currentStripe.capTopRight.x = erkerRight_round_end.center.x + erkerRight_round_end.outerRadius * Math.cos(outerAngle);
                        currentStripe.pointLeftBottom.y = currentStripe.capBottomLeft.y;
                    } else {
                        currentStripe.pointLeftBottom.y = currentStripe.capBottomLeft.y;
                        currentStripe.pointRightBottom.y = currentStripe.capBottomRight.y;
                    }
                    currentStripe.capBottom = true;
                }

                if (cornerStripeTopLeft) {
                    if (!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name)) {
                        crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, cornerStripeTopLeft.pointBottomRight.x, cornerStripeTopLeft.pointBottomRight.y, cornerStripeTopLeft.pointBottomCenterRight.x, cornerStripeTopLeft.pointBottomCenterRight.y);
                        currentStripe.pointLeftTop = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeTopLeft.pointBottomRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                }

                if (cornerStripeTopRight) {
                    if (!stripeHorizontal_3 || deletedStripes.includes(stripeHorizontal_3.name)) {
                        crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, cornerStripeTopRight.pointBottomLeft.x, cornerStripeTopRight.pointBottomLeft.y, cornerStripeTopRight.pointBottomCenterLeft.x, cornerStripeTopRight.pointBottomCenterLeft.y);
                        currentStripe.pointRightTop = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeTopRight.pointBottomLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                }

                if (cornerStripeBottomLeft) {
                    if (!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name)) {
                        crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, cornerStripeBottomLeft.pointTopRight.x, cornerStripeBottomLeft.pointTopRight.y, cornerStripeBottomLeft.pointTopCenterRight.x, cornerStripeBottomLeft.pointTopCenterRight.y);
                        currentStripe.pointLeftBottom = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeBottomLeft.pointTopRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                }

                if (cornerStripeBottomRight) {
                    if (!stripeHorizontal_4 || deletedStripes.includes(stripeHorizontal_4.name)) {
                        crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, cornerStripeBottomRight.pointTopLeft.x, cornerStripeBottomRight.pointTopLeft.y, cornerStripeBottomRight.pointTopCenterLeft.x, cornerStripeBottomRight.pointTopCenterLeft.y);
                        currentStripe.pointRightBottom = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeBottomRight.pointTopLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                }

                if (cornerStripeTopLeft && !cornerStripeTopRight) {
                    if ((!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name)) && (!stripeHorizontal_3 || deletedStripes.includes(stripeHorizontal_3.name)) && !erkerRight_start && !erkerRight_end && !erkerRight_round_start && !erkerRight_round_end) {
                        crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, cornerStripeTopLeft.pointTopRight.x, cornerStripeTopLeft.pointTopRight.y, cornerStripeTopLeft.pointTopCenterRight.x, cornerStripeTopLeft.pointTopCenterRight.y);
                        currentStripe.pointRightTop = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeTopLeft.pointTopRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                } else if (cornerStripeTopRight && !cornerStripeTopLeft) {
                    if ((!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name)) && (!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name)) && !erkerLeft_start && !erkerLeft_end && !erkerLeft_round_start && !erkerLeft_round_end) {
                        crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, cornerStripeTopRight.pointTopLeft.x, cornerStripeTopRight.pointTopLeft.y, cornerStripeTopRight.pointTopCenterLeft.x, cornerStripeTopRight.pointTopCenterLeft.y);
                        currentStripe.pointLeftTop = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeTopRight.pointTopLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                }

                if (cornerStripeBottomLeft && !cornerStripeBottomRight) {
                    if ((!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name)) && (!stripeHorizontal_4 || deletedStripes.includes(stripeHorizontal_4.name)) && !erkerRight_start && !erkerRight_end && !erkerRight_round_start && !erkerRight_round_end) {
                        crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, cornerStripeBottomLeft.pointBottomRight.x, cornerStripeBottomLeft.pointBottomRight.y, cornerStripeBottomLeft.pointBottomCenterRight.x, cornerStripeBottomLeft.pointBottomCenterRight.y);
                        currentStripe.pointRightBottom = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeBottomLeft.pointBottomRight = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                } else if (cornerStripeBottomRight && !cornerStripeBottomLeft) {
                    if ((!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name)) && (!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name)) && !erkerLeft_start && !erkerLeft_end && !erkerLeft_round_start && !erkerLeft_round_end) {
                        crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, cornerStripeBottomRight.pointBottomLeft.x, cornerStripeBottomRight.pointBottomLeft.y, cornerStripeBottomRight.pointBottomCenterLeft.x, cornerStripeBottomRight.pointBottomCenterLeft.y);
                        currentStripe.pointLeftBottom = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                        cornerStripeBottomRight.pointBottomLeft = {
                            x: crossPoint[0],
                            y: crossPoint[1],
                        };
                    }
                }

            }
        }
    }

    // Идём по горизонтальным перемычкам (пересечение эркеров и перемычек под углом)
    for (let i = 0; i < countX; i++) {
        for (let j = 0; j < countY - 1; j++) {

            // Обрабатываемая перемычка
            let currentStripe = _stripes.horizontal.find(stripe => stripe.name === `hor_${j}_${i}`);

            // Перемычки под углом
            let cornerStripeLeftTop = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${i - 1}_${j}:hor_${j}_${i}`));
            let cornerStripeLeftBottom = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${i}_${j}:hor_${j}_${i}`));
            let cornerStripeRightTop = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${i - 1}_${j}:hor_${j}_${i - 1}`));
            let cornerStripeRightBottom = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${i}_${j}:hor_${j}_${i + 1}`));

            // Смежные горизонтальные
            let stripeHorizontal_1 = _stripes.horizontal.find(stripe => stripe.name === `hor_${j - 1}_${i}`);
            let stripeHorizontal_2 = _stripes.horizontal.find(stripe => stripe.name === `hor_${j + 1}_${i}`);

            // Смежные вертикальные
            let stripeVertical_1 = _stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j}`);
            let stripeVertical_2 = _stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j + 1}`);
            let stripeVertical_3 = _stripes.vertical.find(stripe => stripe.name === `ver_${i}_${j}`);
            let stripeVertical_4 = _stripes.vertical.find(stripe => stripe.name === `ver_${i}_${j + 1}`);

            // Смежные эркеры - левые угловые
            let erkerLeft_start = _erkers.left.filter(erker => erker.type === 'angel').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerLeft_end = _erkers.left.filter(erker => erker.type === 'angel').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - левые круглые
            let erkerLeft_round_start = _erkers.left.filter(erker => erker.type === 'round').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerLeft_round_end = _erkers.left.filter(erker => erker.type === 'round').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - правые угловые
            let erkerRight_start = _erkers.right.filter(erker => erker.type === 'angel').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerRight_end = _erkers.right.filter(erker => erker.type === 'angel').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - правые круглые
            let erkerRight_round_start = _erkers.right.filter(erker => erker.type === 'round').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerRight_round_end = _erkers.right.filter(erker => erker.type === 'round').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - верхние угловые
            let erkerTop_start = _erkers.top.filter(erker => erker.type === 'angel').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerTop_end = _erkers.top.filter(erker => erker.type === 'angel').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - верхние круглые
            let erkerTop_round_start = _erkers.top.filter(erker => erker.type === 'round').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerTop_round_end = _erkers.top.filter(erker => erker.type === 'round').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - нижние угловые
            let erkerBottom_start = _erkers.bottom.filter(erker => erker.type === 'angel').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerBottom_end = _erkers.bottom.filter(erker => erker.type === 'angel').find(erker => erker.endHorizontal === currentStripe.name);

            // Смежные эркеры - нижние круглые
            let erkerBottom_round_start = _erkers.bottom.filter(erker => erker.type === 'round').find(erker => erker.startHorizontal === currentStripe.name);
            let erkerBottom_round_end = _erkers.bottom.filter(erker => erker.type === 'round').find(erker => erker.endHorizontal === currentStripe.name);

            // Переменная для точки перечения
            let crossPoint = [];

            if (i > 0 && i < countX - 1) {

                if (j === 0) {

                    // Пересечение эркеров
                    if (erkerLeft_start && erkerLeft_end) {
                        crossPoint = searchCross(erkerLeft_start.pathLeft.x1, erkerLeft_start.pathLeft.y1, erkerLeft_start.pathLeft.x2, erkerLeft_start.pathLeft.y2, erkerLeft_end.pathLeft.x3, erkerLeft_end.pathLeft.y3, erkerLeft_end.pathLeft.x4, erkerLeft_end.pathLeft.y4);
                        erkerLeft_start.pathLeft.x1 = crossPoint[0];
                        erkerLeft_start.pathLeft.y1 = crossPoint[1];
                        erkerLeft_end.pathLeft.x4 = crossPoint[0];
                        erkerLeft_end.pathLeft.y4 = crossPoint[1];
                        if ((!stripeVertical_3 || deletedStripes.includes(stripeVertical_3.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeVertical_1.capBottom = false;
                            crossPoint = searchCross(stripeVertical_1.pointRightTop.x, stripeVertical_1.pointRightTop.y, stripeVertical_1.pointRightBottom.x, stripeVertical_1.pointRightBottom.y, erkerLeft_start.pathRight.x1, erkerLeft_start.pathRight.y1, erkerLeft_start.pathRight.x2, erkerLeft_start.pathRight.y2);
                            stripeVertical_1.pointRightBottom = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerLeft_start.pathRight.x1 = crossPoint[0];
                            erkerLeft_start.pathRight.y1 = crossPoint[1];
                        }
                        if ((!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeVertical_3.capTop = false;
                            crossPoint = searchCross(stripeVertical_3.pointRightTop.x, stripeVertical_3.pointRightTop.y, stripeVertical_3.pointRightBottom.x, stripeVertical_3.pointRightBottom.y, erkerLeft_end.pathRight.x3, erkerLeft_end.pathRight.y3, erkerLeft_end.pathRight.x4, erkerLeft_end.pathRight.y4);
                            stripeVertical_3.pointRightTop = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerLeft_end.pathRight.x4 = crossPoint[0];
                            erkerLeft_end.pathRight.y4 = crossPoint[1];
                        }
                    } else if (erkerLeft_start) {
                        if (stripeVertical_1 && !deletedStripes.includes(stripeVertical_1.name)) {
                            crossPoint = searchCross(erkerLeft_start.pathLeft.x1, erkerLeft_start.pathLeft.y1, erkerLeft_start.pathLeft.x2, erkerLeft_start.pathLeft.y2, stripeVertical_1.pointLeftTop.x, stripeVertical_1.pointLeftTop.y, stripeVertical_1.pointLeftBottom.x, stripeVertical_1.pointLeftBottom.y);
                            stripeVertical_1.pointLeftBottom = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerLeft_start.pathLeft.x1 = crossPoint[0];
                            erkerLeft_start.pathLeft.y1 = crossPoint[1];
                        }
                        if ((!stripeVertical_3 || deletedStripes.includes(stripeVertical_3.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeVertical_1.capBottom = false;
                            crossPoint = searchCross(stripeVertical_1.pointRightTop.x, stripeVertical_1.pointRightTop.y, stripeVertical_1.pointRightBottom.x, stripeVertical_1.pointRightBottom.y, erkerLeft_start.pathRight.x1, erkerLeft_start.pathRight.y1, erkerLeft_start.pathRight.x2, erkerLeft_start.pathRight.y2);
                            stripeVertical_1.pointRightBottom = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerLeft_start.pathRight.x1 = crossPoint[0];
                            erkerLeft_start.pathRight.y1 = crossPoint[1];
                        }
                    } else if (erkerLeft_end) {
                        if (stripeVertical_3 && !deletedStripes.includes(stripeVertical_3.name)) {
                            crossPoint = searchCross(stripeVertical_3.pointLeftTop.x, stripeVertical_3.pointLeftTop.y, stripeVertical_3.pointLeftBottom.x, stripeVertical_3.pointLeftBottom.y, erkerLeft_end.pathLeft.x3, erkerLeft_end.pathLeft.y3, erkerLeft_end.pathLeft.x4, erkerLeft_end.pathLeft.y4);
                            stripeVertical_3.pointLeftTop = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerLeft_end.pathLeft.x4 = crossPoint[0];
                            erkerLeft_end.pathLeft.y4 = crossPoint[1];
                        }
                        if ((!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeVertical_3.capTop = false;
                            crossPoint = searchCross(stripeVertical_3.pointRightTop.x, stripeVertical_3.pointRightTop.y, stripeVertical_3.pointRightBottom.x, stripeVertical_3.pointRightBottom.y, erkerLeft_end.pathRight.x3, erkerLeft_end.pathRight.y3, erkerLeft_end.pathRight.x4, erkerLeft_end.pathRight.y4);
                            stripeVertical_3.pointRightTop = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerLeft_end.pathRight.x4 = crossPoint[0];
                            erkerLeft_end.pathRight.y4 = crossPoint[1];
                        }
                    }

                } else if (j === countY - 2) {

                    // Пересечение эркеров
                    if (erkerRight_start && erkerRight_end) {
                        crossPoint = searchCross(erkerRight_start.pathRight.x1, erkerRight_start.pathRight.y1, erkerRight_start.pathRight.x2, erkerRight_start.pathRight.y2, erkerRight_end.pathRight.x3, erkerRight_end.pathRight.y3, erkerRight_end.pathRight.x4, erkerRight_end.pathRight.y4);
                        erkerRight_start.pathRight.x1 = crossPoint[0];
                        erkerRight_start.pathRight.y1 = crossPoint[1];
                        erkerRight_end.pathRight.x4 = crossPoint[0];
                        erkerRight_end.pathRight.y4 = crossPoint[1];
                        if ((!stripeVertical_4 || deletedStripes.includes(stripeVertical_4.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeVertical_2.capBottom = false;
                            crossPoint = searchCross(stripeVertical_2.pointLeftTop.x, stripeVertical_2.pointLeftTop.y, stripeVertical_2.pointLeftBottom.x, stripeVertical_2.pointLeftBottom.y, erkerRight_start.pathLeft.x1, erkerRight_start.pathLeft.y1, erkerRight_start.pathLeft.x2, erkerRight_start.pathLeft.y2);
                            stripeVertical_2.pointLeftBottom = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerRight_start.pathLeft.x1 = crossPoint[0];
                            erkerRight_start.pathLeft.y1 = crossPoint[1];
                        }
                        if ((!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeVertical_4.capTop = false;
                            crossPoint = searchCross(stripeVertical_4.pointLeftTop.x, stripeVertical_4.pointLeftTop.y, stripeVertical_4.pointLeftBottom.x, stripeVertical_4.pointLeftBottom.y, erkerRight_end.pathLeft.x3, erkerRight_end.pathLeft.y3, erkerRight_end.pathLeft.x4, erkerRight_end.pathLeft.y4);
                            stripeVertical_4.pointLeftTop = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerRight_end.pathLeft.x4 = crossPoint[0];
                            erkerRight_end.pathLeft.y4 = crossPoint[1];
                        }
                    } else if (erkerRight_start) {
                        if (stripeVertical_2 && !deletedStripes.includes(stripeVertical_2.name)) {
                            crossPoint = searchCross(erkerRight_start.pathRight.x1, erkerRight_start.pathRight.y1, erkerRight_start.pathRight.x2, erkerRight_start.pathRight.y2, stripeVertical_2.pointRightTop.x, stripeVertical_2.pointRightTop.y, stripeVertical_2.pointRightBottom.x, stripeVertical_2.pointRightBottom.y);
                            stripeVertical_2.pointRightBottom = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerRight_start.pathRight.x1 = crossPoint[0];
                            erkerRight_start.pathRight.y1 = crossPoint[1];
                        }
                        if ((!stripeVertical_4 || deletedStripes.includes(stripeVertical_4.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeVertical_2.capBottom = false;
                            crossPoint = searchCross(stripeVertical_2.pointLeftTop.x, stripeVertical_2.pointLeftTop.y, stripeVertical_2.pointLeftBottom.x, stripeVertical_2.pointLeftBottom.y, erkerRight_start.pathLeft.x1, erkerRight_start.pathLeft.y1, erkerRight_start.pathLeft.x2, erkerRight_start.pathLeft.y2);
                            stripeVertical_2.pointLeftBottom = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerRight_start.pathLeft.x1 = crossPoint[0];
                            erkerRight_start.pathLeft.y1 = crossPoint[1];
                        }
                    } else if (erkerRight_end) {
                        if (stripeVertical_4 && !deletedStripes.includes(stripeVertical_4.name)) {
                            crossPoint = searchCross(stripeVertical_4.pointRightTop.x, stripeVertical_4.pointRightTop.y, stripeVertical_4.pointRightBottom.x, stripeVertical_4.pointRightBottom.y, erkerRight_end.pathRight.x3, erkerRight_end.pathRight.y3, erkerRight_end.pathRight.x4, erkerRight_end.pathRight.y4);
                            stripeVertical_4.pointRightTop = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerRight_end.pathRight.x4 = crossPoint[0];
                            erkerRight_end.pathRight.y4 = crossPoint[1];
                        }
                        if ((!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeVertical_4.capTop = false;
                            crossPoint = searchCross(stripeVertical_4.pointLeftTop.x, stripeVertical_4.pointLeftTop.y, stripeVertical_4.pointLeftBottom.x, stripeVertical_4.pointLeftBottom.y, erkerRight_end.pathLeft.x3, erkerRight_end.pathLeft.y3, erkerRight_end.pathLeft.x4, erkerRight_end.pathLeft.y4);
                            stripeVertical_4.pointLeftTop = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerRight_end.pathLeft.x4 = crossPoint[0];
                            erkerRight_end.pathLeft.y4 = crossPoint[1];
                        }
                    }

                }

            }

            if (erkerTop_start && erkerLeft_start) {
                crossPoint = searchCross(erkerTop_start.pathTop.x1, erkerTop_start.pathTop.y1, erkerTop_start.pathTop.x2, erkerTop_start.pathTop.y2, erkerLeft_start.pathLeft.x1, erkerLeft_start.pathLeft.y1, erkerLeft_start.pathLeft.x2, erkerLeft_start.pathLeft.y2);
                if (crossPoint) {
                    erkerTop_start.pathTop.x1 = crossPoint[0];
                    erkerTop_start.pathTop.y1 = crossPoint[1];
                    erkerLeft_start.pathLeft.x1 = crossPoint[0];
                    erkerLeft_start.pathLeft.y1 = crossPoint[1];
                } else {
                    erkerTop_start.pathTop.x1 = (erkerTop_start.pathTop.x2 + erkerLeft_start.pathLeft.x2) / 2;
                    erkerTop_start.pathTop.y1 = (erkerTop_start.pathTop.y2 + erkerLeft_start.pathLeft.y2) / 2;
                    erkerLeft_start.pathLeft.x1 = (erkerTop_start.pathTop.x2 + erkerLeft_start.pathLeft.x2) / 2;
                    erkerLeft_start.pathLeft.y1 = (erkerTop_start.pathTop.y2 + erkerLeft_start.pathLeft.y2) / 2;
                }
            }

            if (erkerTop_round_start && erkerLeft_round_start) {
                crossPoint = findCrossCircles(erkerTop_round_start.center.x, erkerTop_round_start.center.y, erkerTop_round_start.outerRadius, erkerLeft_round_start.center.x, erkerLeft_round_start.center.y, erkerLeft_round_start.outerRadius);
                let _point = null;
                if (Math.abs(currentStripe.pointLeft.x - crossPoint.pointA.x) < Math.abs(currentStripe.pointLeft.x - crossPoint.pointB.x)) {
                    _point = crossPoint.pointA;
                } else {
                    _point = crossPoint.pointB;
                }
                const angleTop = calculateAngle(erkerTop_round_start.center.x, erkerTop_round_start.center.y, _point.x, _point.y) * (180 / Math.PI);
                const angleLeft = calculateAngle(erkerLeft_round_start.center.x, erkerLeft_round_start.center.y, _point.x, _point.y) * (180 / Math.PI);
                erkerTop_round_start.outerAngle.left = Math.abs(90 - angleTop);
                erkerLeft_round_start.outerAngle.bottom = Math.abs(angleLeft);
            }

            if (erkerTop_start && erkerLeft_round_start) {
                crossPoint = findCrossLineAndCircle(erkerTop_start.pathTop.x1, erkerTop_start.pathTop.y1, erkerTop_start.pathTop.x2, erkerTop_start.pathTop.y2, erkerLeft_round_start.center.x, erkerLeft_round_start.center.y, erkerLeft_round_start.outerRadius);
                if (!crossPoint.error) {
                    let _point = null;
                    if (Math.abs(currentStripe.pointLeft.x - crossPoint.pointA.x) < Math.abs(currentStripe.pointLeft.x - crossPoint.pointB.x)) {
                        _point = crossPoint.pointA;
                    } else {
                        _point = crossPoint.pointB;
                    }
                    const angleLeft = calculateAngle(erkerLeft_round_start.center.x, erkerLeft_round_start.center.y, _point.x, _point.y) * (180 / Math.PI);
                    erkerTop_start.pathTop.x1 = _point.x;
                    erkerTop_start.pathTop.y1 = _point.y;
                    erkerLeft_round_start.outerAngle.bottom = Math.abs(angleLeft);
                }
            }

            if (erkerTop_round_start && erkerLeft_start) {
                crossPoint = findCrossLineAndCircle(erkerLeft_start.pathLeft.x1, erkerLeft_start.pathLeft.y1, erkerLeft_start.pathLeft.x2, erkerLeft_start.pathLeft.y2, erkerTop_round_start.center.x, erkerTop_round_start.center.y, erkerTop_round_start.outerRadius);
                if (!crossPoint.error) {
                    let _point = null;
                    if (Math.abs(currentStripe.pointLeft.x - crossPoint.pointA.x) < Math.abs(currentStripe.pointLeft.x - crossPoint.pointB.x)) {
                        _point = crossPoint.pointA;
                    } else {
                        _point = crossPoint.pointB;
                    }
                    const angleTop = calculateAngle(erkerTop_round_start.center.x, erkerTop_round_start.center.y, _point.x, _point.y) * (180 / Math.PI);
                    erkerLeft_start.pathLeft.x1 = _point.x;
                    erkerLeft_start.pathLeft.y1 = _point.y;
                    erkerTop_round_start.outerAngle.left = Math.abs(90 - angleTop);
                }
            }

            if (erkerTop_end && erkerRight_start) {
                crossPoint = searchCross(erkerTop_end.pathTop.x3, erkerTop_end.pathTop.y3, erkerTop_end.pathTop.x4, erkerTop_end.pathTop.y4, erkerRight_start.pathRight.x1, erkerRight_start.pathRight.y1, erkerRight_start.pathRight.x2, erkerRight_start.pathRight.y2);
                if (crossPoint) {
                    erkerTop_end.pathTop.x4 = crossPoint[0];
                    erkerTop_end.pathTop.y4 = crossPoint[1];
                    erkerRight_start.pathRight.x1 = crossPoint[0];
                    erkerRight_start.pathRight.y1 = crossPoint[1];
                } else {
                    erkerTop_end.pathTop.x4 = (erkerTop_end.pathTop.x3 + erkerRight_start.pathRight.x2) / 2;
                    erkerTop_end.pathTop.y4 = (erkerTop_end.pathTop.y3 + erkerRight_start.pathRight.y2) / 2;
                    erkerRight_start.pathRight.x1 = (erkerTop_end.pathTop.x3 + erkerRight_start.pathRight.x2) / 2;
                    erkerRight_start.pathRight.y1 = (erkerTop_end.pathTop.y3 + erkerRight_start.pathRight.y2) / 2;
                }
            }

            if (erkerTop_round_end && erkerRight_round_start) {
                crossPoint = findCrossCircles(erkerTop_round_end.center.x, erkerTop_round_end.center.y, erkerTop_round_end.outerRadius, erkerRight_round_start.center.x, erkerRight_round_start.center.y, erkerRight_round_start.outerRadius);
                let _point = null;
                if (Math.abs(currentStripe.pointRight.x - crossPoint.pointA.x) < Math.abs(currentStripe.pointRight.x - crossPoint.pointB.x)) {
                    _point = crossPoint.pointA;
                } else {
                    _point = crossPoint.pointB;
                }
                const angleTop = calculateAngle(erkerTop_round_end.center.x, erkerTop_round_end.center.y, _point.x, _point.y) * (180 / Math.PI);
                const angleRight = calculateAngle(erkerRight_round_start.center.x, erkerRight_round_start.center.y, _point.x, _point.y) * (180 / Math.PI);
                erkerTop_round_end.outerAngle.right = Math.abs(angleTop - 90);
                erkerRight_round_start.outerAngle.top = Math.abs(180 - angleRight);
            }

            if (erkerBottom_start && erkerLeft_end) {
                crossPoint = searchCross(erkerBottom_start.pathBottom.x1, erkerBottom_start.pathBottom.y1, erkerBottom_start.pathBottom.x2, erkerBottom_start.pathBottom.y2, erkerLeft_end.pathLeft.x3, erkerLeft_end.pathLeft.y3, erkerLeft_end.pathLeft.x4, erkerLeft_end.pathLeft.y4);
                if (crossPoint) {
                    erkerBottom_start.pathBottom.x1 = crossPoint[0];
                    erkerBottom_start.pathBottom.y1 = crossPoint[1];
                    erkerLeft_end.pathLeft.x4 = crossPoint[0];
                    erkerLeft_end.pathLeft.y4 = crossPoint[1];
                } else {
                    erkerBottom_start.pathBottom.x1 = (erkerBottom_start.pathBottom.x2 + erkerLeft_end.pathLeft.x3) / 2;
                    erkerBottom_start.pathBottom.y1 = (erkerBottom_start.pathBottom.y2 + erkerLeft_end.pathLeft.y3) / 2;
                    erkerLeft_end.pathLeft.x4 = (erkerBottom_start.pathBottom.x2 + erkerLeft_end.pathLeft.x3) / 2;
                    erkerLeft_end.pathLeft.y4 = (erkerBottom_start.pathBottom.y2 + erkerLeft_end.pathLeft.y3) / 2;
                }
            }

            if (erkerBottom_round_start && erkerLeft_round_end) {
                crossPoint = findCrossCircles(erkerBottom_round_start.center.x, erkerBottom_round_start.center.y, erkerBottom_round_start.outerRadius, erkerLeft_round_end.center.x, erkerLeft_round_end.center.y, erkerLeft_round_end.outerRadius);
                let _point = null;
                if (Math.abs(currentStripe.pointLeft.x - crossPoint.pointA.x) < Math.abs(currentStripe.pointLeft.x - crossPoint.pointB.x)) {
                    _point = crossPoint.pointA;
                } else {
                    _point = crossPoint.pointB;
                }
                const angleBottom = calculateAngle(erkerBottom_round_start.center.x, erkerBottom_round_start.center.y, _point.x, _point.y) * (180 / Math.PI);
                const angleLeft = calculateAngle(erkerLeft_round_end.center.x, erkerLeft_round_end.center.y, _point.x, _point.y) * (180 / Math.PI);
                erkerBottom_round_start.outerAngle.right = Math.abs(angleBottom + 90);
                erkerLeft_round_end.outerAngle.top = Math.abs(angleLeft);
            }

            if (erkerBottom_end && erkerRight_end) {
                crossPoint = searchCross(erkerBottom_end.pathBottom.x3, erkerBottom_end.pathBottom.y3, erkerBottom_end.pathBottom.x4, erkerBottom_end.pathBottom.y4, erkerRight_end.pathRight.x3, erkerRight_end.pathRight.y3, erkerRight_end.pathRight.x4, erkerRight_end.pathRight.y4);
                if (crossPoint) {
                    erkerBottom_end.pathBottom.x4 = crossPoint[0];
                    erkerBottom_end.pathBottom.y4 = crossPoint[1];
                    erkerRight_end.pathRight.x4 = crossPoint[0];
                    erkerRight_end.pathRight.y4 = crossPoint[1];
                } else {
                    erkerBottom_end.pathBottom.x4 = (erkerBottom_end.pathBottom.x3 + erkerRight_end.pathRight.x3) / 2;
                    erkerBottom_end.pathBottom.y4 = (erkerBottom_end.pathBottom.y3 + erkerRight_end.pathRight.y3) / 2;
                    erkerRight_end.pathRight.x4 = (erkerBottom_end.pathBottom.x3 + erkerRight_end.pathRight.x3) / 2;
                    erkerRight_end.pathRight.y4 = (erkerBottom_end.pathBottom.y3 + erkerRight_end.pathRight.y3) / 2;
                }
            }

            if (erkerBottom_round_end && erkerRight_round_end) {
                crossPoint = findCrossCircles(erkerBottom_round_end.center.x, erkerBottom_round_end.center.y, erkerBottom_round_end.outerRadius, erkerRight_round_end.center.x, erkerRight_round_end.center.y, erkerRight_round_end.outerRadius);
                let _point = null;
                if (Math.abs(currentStripe.pointRight.x - crossPoint.pointA.x) < Math.abs(currentStripe.pointRight.x - crossPoint.pointB.x)) {
                    _point = crossPoint.pointA;
                } else {
                    _point = crossPoint.pointB;
                }
                const angleBottom = calculateAngle(erkerBottom_round_end.center.x, erkerBottom_round_end.center.y, _point.x, _point.y) * (180 / Math.PI);
                const angleRight = calculateAngle(erkerRight_round_end.center.x, erkerRight_round_end.center.y, _point.x, _point.y) * (180 / Math.PI);
                erkerBottom_round_end.outerAngle.left = Math.abs(angleBottom + 90);
                erkerRight_round_end.outerAngle.bottom = Math.abs(angleRight + 180);
            }

            if (cornerStripeLeftTop && cornerStripeLeftBottom) {
                if (deletedStripes.includes(currentStripe.name)) {
                    crossPoint = searchCross(cornerStripeLeftTop.pointBottomLeft.x, cornerStripeLeftTop.pointBottomLeft.y, cornerStripeLeftTop.pointBottomCenterLeft.x, cornerStripeLeftTop.pointBottomCenterLeft.y, cornerStripeLeftBottom.pointTopLeft.x, cornerStripeLeftBottom.pointTopLeft.y, cornerStripeLeftBottom.pointTopCenterLeft.x, cornerStripeLeftBottom.pointTopCenterLeft.y);
                    cornerStripeLeftTop.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeLeftBottom.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                } else if (!erkerLeft_start && !erkerLeft_end && deletedStripes.includes(stripeVertical_1.name) && deletedStripes.includes(stripeVertical_3.name)) {
                    crossPoint = searchCross(cornerStripeLeftTop.pointTopLeft.x, cornerStripeLeftTop.pointTopLeft.y, cornerStripeLeftTop.pointTopCenterLeft.x, cornerStripeLeftTop.pointTopCenterLeft.y, cornerStripeLeftBottom.pointBottomLeft.x, cornerStripeLeftBottom.pointBottomLeft.y, cornerStripeLeftBottom.pointBottomCenterLeft.x, cornerStripeLeftBottom.pointBottomCenterLeft.y);
                    cornerStripeLeftTop.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeLeftBottom.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, cornerStripeLeftTop.pointBottomLeft.x, cornerStripeLeftTop.pointBottomLeft.y, cornerStripeLeftTop.pointBottomCenterLeft.x, cornerStripeLeftTop.pointBottomCenterLeft.y);
                    currentStripe.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeLeftTop.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, cornerStripeLeftBottom.pointTopLeft.x, cornerStripeLeftBottom.pointTopLeft.y, cornerStripeLeftBottom.pointTopCenterLeft.x, cornerStripeLeftBottom.pointTopCenterLeft.y);
                    currentStripe.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeLeftBottom.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    currentStripe.capLeft = false;
                }
                if (stripeVertical_1 && !deletedStripes.includes(stripeVertical_1.name)) {
                    crossPoint = searchCross(stripeVertical_1.pointRightTop.x, stripeVertical_1.pointRightTop.y, stripeVertical_1.pointRightBottom.x, stripeVertical_1.pointRightBottom.y, cornerStripeLeftTop.pointTopLeft.x, cornerStripeLeftTop.pointTopLeft.y, cornerStripeLeftTop.pointTopCenterLeft.x, cornerStripeLeftTop.pointTopCenterLeft.y);
                    stripeVertical_1.pointRightBottom = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeLeftTop.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }
                if (stripeVertical_3 && !deletedStripes.includes(stripeVertical_3.name)) {
                    crossPoint = searchCross(stripeVertical_3.pointRightTop.x, stripeVertical_3.pointRightTop.y, stripeVertical_3.pointRightBottom.x, stripeVertical_3.pointRightBottom.y, cornerStripeLeftBottom.pointBottomLeft.x, cornerStripeLeftBottom.pointBottomLeft.y, cornerStripeLeftBottom.pointBottomCenterLeft.x, cornerStripeLeftBottom.pointBottomCenterLeft.y);
                    stripeVertical_3.pointRightTop = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeLeftBottom.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }
            }
            
            if (cornerStripeLeftTop && !cornerStripeRightTop) {
                cornerStripeLeftTop.pointTopCenterLeft.x = (cornerStripeLeftTop.pointTopLeft.x + cornerStripeLeftTop.pointTopRight.x) / 2;
                cornerStripeLeftTop.pointTopCenterLeft.y = (cornerStripeLeftTop.pointTopLeft.y + cornerStripeLeftTop.pointTopRight.y) / 2;
                cornerStripeLeftTop.pointTopCenterRight.x = (cornerStripeLeftTop.pointTopLeft.x + cornerStripeLeftTop.pointTopRight.x) / 2;
                cornerStripeLeftTop.pointTopCenterRight.y = (cornerStripeLeftTop.pointTopLeft.y + cornerStripeLeftTop.pointTopRight.y) / 2;
                cornerStripeLeftTop.pointBottomCenterLeft.x = (cornerStripeLeftTop.pointBottomLeft.x + cornerStripeLeftTop.pointBottomRight.x) / 2;
                cornerStripeLeftTop.pointBottomCenterLeft.y = (cornerStripeLeftTop.pointBottomLeft.y + cornerStripeLeftTop.pointBottomRight.y) / 2;
                cornerStripeLeftTop.pointBottomCenterRight.x = (cornerStripeLeftTop.pointBottomLeft.x + cornerStripeLeftTop.pointBottomRight.x) / 2;
                cornerStripeLeftTop.pointBottomCenterRight.y = (cornerStripeLeftTop.pointBottomLeft.y + cornerStripeLeftTop.pointBottomRight.y) / 2;
            }

            if (cornerStripeRightTop && !cornerStripeLeftTop) {
                cornerStripeRightTop.pointTopCenterLeft.x = (cornerStripeRightTop.pointTopLeft.x + cornerStripeRightTop.pointTopRight.x) / 2;
                cornerStripeRightTop.pointTopCenterLeft.y = (cornerStripeRightTop.pointTopLeft.y + cornerStripeRightTop.pointTopRight.y) / 2;
                cornerStripeRightTop.pointTopCenterRight.x = (cornerStripeRightTop.pointTopLeft.x + cornerStripeRightTop.pointTopRight.x) / 2;
                cornerStripeRightTop.pointTopCenterRight.y = (cornerStripeRightTop.pointTopLeft.y + cornerStripeRightTop.pointTopRight.y) / 2;
                cornerStripeRightTop.pointBottomCenterLeft.x = (cornerStripeRightTop.pointBottomLeft.x + cornerStripeRightTop.pointBottomRight.x) / 2;
                cornerStripeRightTop.pointBottomCenterLeft.y = (cornerStripeRightTop.pointBottomLeft.y + cornerStripeRightTop.pointBottomRight.y) / 2;
                cornerStripeRightTop.pointBottomCenterRight.x = (cornerStripeRightTop.pointBottomLeft.x + cornerStripeRightTop.pointBottomRight.x) / 2;
                cornerStripeRightTop.pointBottomCenterRight.y = (cornerStripeRightTop.pointBottomLeft.y + cornerStripeRightTop.pointBottomRight.y) / 2;
            }

            if (cornerStripeRightTop && cornerStripeRightBottom) {
                if (deletedStripes.includes(currentStripe.name)) {
                    crossPoint = searchCross(cornerStripeRightTop.pointBottomRight.x, cornerStripeRightTop.pointBottomRight.y, cornerStripeRightTop.pointBottomCenterRight.x, cornerStripeRightTop.pointBottomCenterRight.y, cornerStripeRightBottom.pointTopRight.x, cornerStripeRightBottom.pointTopRight.y, cornerStripeRightBottom.pointTopCenterRight.x, cornerStripeRightBottom.pointTopCenterRight.y);
                    cornerStripeRightTop.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeRightBottom.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                } else if (!erkerRight_start && !erkerRight_end && deletedStripes.includes(stripeVertical_2.name) && deletedStripes.includes(stripeVertical_4.name)) {
                    crossPoint = searchCross(cornerStripeRightTop.pointTopRight.x, cornerStripeRightTop.pointTopRight.y, cornerStripeRightTop.pointTopCenterRight.x, cornerStripeRightTop.pointTopCenterRight.y, cornerStripeRightBottom.pointBottomRight.x, cornerStripeRightBottom.pointBottomRight.y, cornerStripeRightBottom.pointBottomCenterRight.x, cornerStripeRightBottom.pointBottomCenterRight.y);
                    cornerStripeRightTop.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeRightBottom.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    crossPoint = searchCross(currentStripe.pointTopLeft.x, currentStripe.pointTopLeft.y, currentStripe.pointTopRight.x, currentStripe.pointTopRight.y, cornerStripeRightTop.pointBottomRight.x, cornerStripeRightTop.pointBottomRight.y, cornerStripeRightTop.pointBottomCenterRight.x, cornerStripeRightTop.pointBottomCenterRight.y);
                    currentStripe.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeRightTop.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    crossPoint = searchCross(currentStripe.pointBottomLeft.x, currentStripe.pointBottomLeft.y, currentStripe.pointBottomRight.x, currentStripe.pointBottomRight.y, cornerStripeRightBottom.pointTopRight.x, cornerStripeRightBottom.pointTopRight.y, cornerStripeRightBottom.pointTopCenterRight.x, cornerStripeRightBottom.pointTopCenterRight.y);
                    currentStripe.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeRightBottom.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    currentStripe.capRight = false;
                }
                if (stripeVertical_2 && !deletedStripes.includes(stripeVertical_2.name)) {
                    crossPoint = searchCross(stripeVertical_2.pointLeftTop.x, stripeVertical_2.pointLeftTop.y, stripeVertical_2.pointLeftBottom.x, stripeVertical_2.pointLeftBottom.y, cornerStripeRightTop.pointTopRight.x, cornerStripeRightTop.pointTopRight.y, cornerStripeRightTop.pointTopCenterRight.x, cornerStripeRightTop.pointTopCenterRight.y);
                    stripeVertical_2.pointLeftBottom = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeRightTop.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }
                if (stripeVertical_4 && !deletedStripes.includes(stripeVertical_4.name)) {
                    crossPoint = searchCross(stripeVertical_4.pointLeftTop.x, stripeVertical_4.pointLeftTop.y, stripeVertical_4.pointLeftBottom.x, stripeVertical_4.pointLeftBottom.y, cornerStripeRightBottom.pointBottomRight.x, cornerStripeRightBottom.pointBottomRight.y, cornerStripeRightBottom.pointBottomCenterRight.x, cornerStripeRightBottom.pointBottomCenterRight.y);
                    stripeVertical_4.pointLeftTop = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeRightBottom.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }
            }

            if (erkerLeft_end && cornerStripeLeftTop && deletedStripes.includes(stripeVertical_1.name)) {
                crossPoint = searchCross(cornerStripeLeftTop.pointTopLeft.x, cornerStripeLeftTop.pointTopLeft.y, cornerStripeLeftTop.pointTopCenterLeft.x, cornerStripeLeftTop.pointTopCenterLeft.y, erkerLeft_end.pathRight.x3, erkerLeft_end.pathRight.y3, erkerLeft_end.pathRight.x4, erkerLeft_end.pathRight.y4);
                cornerStripeLeftTop.pointTopLeft = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                erkerLeft_end.pathRight.x4 = crossPoint[0];
                erkerLeft_end.pathRight.y4 = crossPoint[1];
            }

            if (erkerLeft_start && cornerStripeLeftBottom && deletedStripes.includes(stripeVertical_3.name)) {
                crossPoint = searchCross(cornerStripeLeftBottom.pointBottomLeft.x, cornerStripeLeftBottom.pointBottomLeft.y, cornerStripeLeftBottom.pointBottomCenterLeft.x, cornerStripeLeftBottom.pointBottomCenterLeft.y, erkerLeft_start.pathRight.x1, erkerLeft_start.pathRight.y1, erkerLeft_start.pathRight.x2, erkerLeft_start.pathRight.y2);
                cornerStripeLeftBottom.pointBottomLeft = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                erkerLeft_start.pathRight.x1 = crossPoint[0];
                erkerLeft_start.pathRight.y1 = crossPoint[1];
            }

            if (erkerRight_end && cornerStripeRightTop && deletedStripes.includes(stripeVertical_2.name)) {
                crossPoint = searchCross(cornerStripeRightTop.pointTopRight.x, cornerStripeRightTop.pointTopRight.y, cornerStripeRightTop.pointTopCenterRight.x, cornerStripeRightTop.pointTopCenterRight.y, erkerRight_end.pathLeft.x3, erkerRight_end.pathLeft.y3, erkerRight_end.pathLeft.x4, erkerRight_end.pathLeft.y4);
                cornerStripeRightTop.pointTopRight = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                erkerRight_end.pathLeft.x4 = crossPoint[0];
                erkerRight_end.pathLeft.y4 = crossPoint[1];
            }

            if (erkerRight_start && cornerStripeRightBottom && deletedStripes.includes(stripeVertical_4.name)) {
                crossPoint = searchCross(cornerStripeRightBottom.pointBottomRight.x, cornerStripeRightBottom.pointBottomRight.y, cornerStripeRightBottom.pointBottomCenterRight.x, cornerStripeRightBottom.pointBottomCenterRight.y, erkerRight_start.pathLeft.x1, erkerRight_start.pathLeft.y1, erkerRight_start.pathLeft.x2, erkerRight_start.pathLeft.y2);
                cornerStripeRightBottom.pointBottomRight = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                erkerRight_start.pathLeft.x1 = crossPoint[0];
                erkerRight_start.pathLeft.y1 = crossPoint[1];
            }
            

        }
    }

    // Идём по вертикальным перемычкам (пересечение эркеров и перемычек под углом)
    for (let i = 0; i < countY; i++) {
        for (let j = 0; j < countX - 1; j++) {

            // Обрабатываемая перемычка
            let currentStripe = _stripes.vertical.find(stripe => stripe.name === `ver_${j}_${i}`);

            // Перемычки под углом
            let cornerStripeTopLeft = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${j}_${i - 1}:hor_${i - 1}_${j + 1}`));
            let cornerStripeTopRight = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${j}_${i}:hor_${i}_${j}`));
            let cornerStripeBottomLeft = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${j}_${i - 1}:hor_${i - 1}_${j}`));
            let cornerStripeBottomRight = _stripes.corner.find(stripe => stripe.name.startsWith(`ver_${j}_${i}:hor_${i}_${j + 1}`));

            // Смежные вертикальные
            let stripeVertical_1 = _stripes.vertical.find(stripe => stripe.name === `ver_${j - 1}_${i}`);
            let stripeVertical_2 = _stripes.vertical.find(stripe => stripe.name === `ver_${j + 1}_${i}`);

            // Смежные горизонтальные
            let stripeHorizontal_1 = _stripes.horizontal.find(stripe => stripe.name === `hor_${i - 1}_${j}`);
            let stripeHorizontal_2 = _stripes.horizontal.find(stripe => stripe.name === `hor_${i - 1}_${j + 1}`);
            let stripeHorizontal_3 = _stripes.horizontal.find(stripe => stripe.name === `hor_${i}_${j}`);
            let stripeHorizontal_4 = _stripes.horizontal.find(stripe => stripe.name === `hor_${i}_${j + 1}`);

            // Смежные эркеры - верхние угловые
            let erkerTop_start = _erkers.top.filter(erker => erker.type === 'angel').find(erker => erker.startVertical === currentStripe.name);
            let erkerTop_end = _erkers.top.filter(erker => erker.type === 'angel').find(erker => erker.endVertical === currentStripe.name);

            // Смежные эркеры - нижние угловые
            let erkerBottom_start = _erkers.bottom.filter(erker => erker.type === 'angel').find(erker => erker.startVertical === currentStripe.name);
            let erkerBottom_end = _erkers.bottom.filter(erker => erker.type === 'angel').find(erker => erker.endVertical === currentStripe.name);

            // Переменная для точки перечения
            let crossPoint = [];

            if (i > 0 && i < countY - 1) {

                if (j === 0) {

                    // Пересечение эркеров
                    if (erkerTop_start && erkerTop_end) {
                        crossPoint = searchCross(erkerTop_start.pathTop.x1, erkerTop_start.pathTop.y1, erkerTop_start.pathTop.x2, erkerTop_start.pathTop.y2, erkerTop_end.pathTop.x3, erkerTop_end.pathTop.y3, erkerTop_end.pathTop.x4, erkerTop_end.pathTop.y4);
                        erkerTop_start.pathTop.x1 = crossPoint[0];
                        erkerTop_start.pathTop.y1 = crossPoint[1];
                        erkerTop_end.pathTop.x4 = crossPoint[0];
                        erkerTop_end.pathTop.y4 = crossPoint[1];
                        if ((!stripeHorizontal_3 || deletedStripes.includes(stripeHorizontal_3.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeHorizontal_1.capRight = false;
                            crossPoint = searchCross(stripeHorizontal_1.pointBottomLeft.x, stripeHorizontal_1.pointBottomLeft.y, stripeHorizontal_1.pointBottomRight.x, stripeHorizontal_1.pointBottomRight.y, erkerTop_start.pathBottom.x1, erkerTop_start.pathBottom.y1, erkerTop_start.pathBottom.x2, erkerTop_start.pathBottom.y2);
                            stripeHorizontal_1.pointBottomRight = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerTop_start.pathBottom.x1 = crossPoint[0];
                            erkerTop_start.pathBottom.y1 = crossPoint[1];
                        }
                        if ((!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeHorizontal_3.capLeft = false;
                            crossPoint = searchCross(stripeHorizontal_3.pointBottomLeft.x, stripeHorizontal_3.pointBottomLeft.y, stripeHorizontal_3.pointBottomRight.x, stripeHorizontal_3.pointBottomRight.y, erkerTop_end.pathBottom.x3, erkerTop_end.pathBottom.y3, erkerTop_end.pathBottom.x4, erkerTop_end.pathBottom.y4);
                            stripeHorizontal_3.pointBottomLeft = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerTop_end.pathBottom.x4 = crossPoint[0];
                            erkerTop_end.pathBottom.y4 = crossPoint[1];
                        }
                    } else if (erkerTop_start) {
                        if (stripeHorizontal_1 && !deletedStripes.includes(stripeHorizontal_1.name)) {
                            crossPoint = searchCross(erkerTop_start.pathTop.x1, erkerTop_start.pathTop.y1, erkerTop_start.pathTop.x2, erkerTop_start.pathTop.y2, stripeHorizontal_1.pointTopLeft.x, stripeHorizontal_1.pointTopLeft.y, stripeHorizontal_1.pointTopRight.x, stripeHorizontal_1.pointTopRight.y);
                            stripeHorizontal_1.pointTopRight = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerTop_start.pathTop.x1 = crossPoint[0];
                            erkerTop_start.pathTop.y1 = crossPoint[1];
                        }
                        if ((!stripeHorizontal_3 || deletedStripes.includes(stripeHorizontal_3.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeHorizontal_1.capRight = false;
                            crossPoint = searchCross(stripeHorizontal_1.pointBottomLeft.x, stripeHorizontal_1.pointBottomLeft.y, stripeHorizontal_1.pointBottomRight.x, stripeHorizontal_1.pointBottomRight.y, erkerTop_start.pathBottom.x1, erkerTop_start.pathBottom.y1, erkerTop_start.pathBottom.x2, erkerTop_start.pathBottom.y2);
                            stripeHorizontal_1.pointBottomRight = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerTop_start.pathBottom.x1 = crossPoint[0];
                            erkerTop_start.pathBottom.y1 = crossPoint[1];
                        }
                    } else if (erkerTop_end) {
                        if (stripeHorizontal_3 && !deletedStripes.includes(stripeHorizontal_3.name)) {
                            crossPoint = searchCross(stripeHorizontal_3.pointTopLeft.x, stripeHorizontal_3.pointTopLeft.y, stripeHorizontal_3.pointTopRight.x, stripeHorizontal_3.pointTopRight.y, erkerTop_end.pathTop.x3, erkerTop_end.pathTop.y3, erkerTop_end.pathTop.x4, erkerTop_end.pathTop.y4);
                            stripeHorizontal_3.pointTopLeft = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerTop_end.pathTop.x4 = crossPoint[0];
                            erkerTop_end.pathTop.y4 = crossPoint[1];
                        }
                        if ((!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeHorizontal_3.capLeft = false;
                            crossPoint = searchCross(stripeHorizontal_3.pointBottomLeft.x, stripeHorizontal_3.pointBottomLeft.y, stripeHorizontal_3.pointBottomRight.x, stripeHorizontal_3.pointBottomRight.y, erkerTop_end.pathBottom.x3, erkerTop_end.pathBottom.y3, erkerTop_end.pathBottom.x4, erkerTop_end.pathBottom.y4);
                            stripeHorizontal_3.pointBottomLeft = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerTop_end.pathBottom.x4 = crossPoint[0];
                            erkerTop_end.pathBottom.y4 = crossPoint[1];
                        }
                    }

                } else if (j === countX - 2) {

                    // Пересечение эркеров
                    if (erkerBottom_start && erkerBottom_end) {
                        crossPoint = searchCross(erkerBottom_start.pathBottom.x1, erkerBottom_start.pathBottom.y1, erkerBottom_start.pathBottom.x2, erkerBottom_start.pathBottom.y2, erkerBottom_end.pathBottom.x3, erkerBottom_end.pathBottom.y3, erkerBottom_end.pathBottom.x4, erkerBottom_end.pathBottom.y4);
                        erkerBottom_start.pathBottom.x1 = crossPoint[0];
                        erkerBottom_start.pathBottom.y1 = crossPoint[1];
                        erkerBottom_end.pathBottom.x4 = crossPoint[0];
                        erkerBottom_end.pathBottom.y4 = crossPoint[1];
                        if ((!stripeHorizontal_4 || deletedStripes.includes(stripeHorizontal_4.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeHorizontal_2.capRight = false;
                            crossPoint = searchCross(stripeHorizontal_2.pointTopLeft.x, stripeHorizontal_2.pointTopLeft.y, stripeHorizontal_2.pointTopRight.x, stripeHorizontal_2.pointTopRight.y, erkerBottom_start.pathTop.x1, erkerBottom_start.pathTop.y1, erkerBottom_start.pathTop.x2, erkerBottom_start.pathTop.y2);
                            stripeHorizontal_2.pointTopRight = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerBottom_start.pathTop.x1 = crossPoint[0];
                            erkerBottom_start.pathTop.y1 = crossPoint[1];
                        }
                        if ((!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeHorizontal_4.capLeft = false;
                            crossPoint = searchCross(stripeHorizontal_4.pointTopLeft.x, stripeHorizontal_4.pointTopLeft.y, stripeHorizontal_4.pointTopRight.x, stripeHorizontal_4.pointTopRight.y, erkerBottom_end.pathTop.x3, erkerBottom_end.pathTop.y3, erkerBottom_end.pathTop.x4, erkerBottom_end.pathTop.y4);
                            stripeHorizontal_4.pointTopLeft = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerBottom_end.pathTop.x4 = crossPoint[0];
                            erkerBottom_end.pathTop.y4 = crossPoint[1];
                        }
                    } else if (erkerBottom_start) {
                        if (stripeHorizontal_2 && !deletedStripes.includes(stripeHorizontal_2.name)) {
                            crossPoint = searchCross(erkerBottom_start.pathBottom.x1, erkerBottom_start.pathBottom.y1, erkerBottom_start.pathBottom.x2, erkerBottom_start.pathBottom.y2, stripeHorizontal_2.pointBottomLeft.x, stripeHorizontal_2.pointBottomLeft.y, stripeHorizontal_2.pointBottomRight.x, stripeHorizontal_2.pointBottomRight.y);
                            stripeHorizontal_2.pointBottomRight = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerBottom_start.pathBottom.x1 = crossPoint[0];
                            erkerBottom_start.pathBottom.y1 = crossPoint[1];
                        }
                        if ((!stripeHorizontal_4 || deletedStripes.includes(stripeHorizontal_4.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeHorizontal_2.capRight = false;
                            crossPoint = searchCross(stripeHorizontal_2.pointTopLeft.x, stripeHorizontal_2.pointTopLeft.y, stripeHorizontal_2.pointTopRight.x, stripeHorizontal_2.pointTopRight.y, erkerBottom_start.pathTop.x1, erkerBottom_start.pathTop.y1, erkerBottom_start.pathTop.x2, erkerBottom_start.pathTop.y2);
                            stripeHorizontal_2.pointTopRight = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerBottom_start.pathTop.x1 = crossPoint[0];
                            erkerBottom_start.pathTop.y1 = crossPoint[1];
                        }
                    } else if (erkerBottom_end) {
                        if (stripeHorizontal_4 && !deletedStripes.includes(stripeHorizontal_4.name)) {
                            crossPoint = searchCross(stripeHorizontal_4.pointBottomLeft.x, stripeHorizontal_4.pointBottomLeft.y, stripeHorizontal_4.pointBottomRight.x, stripeHorizontal_4.pointBottomRight.y, erkerBottom_end.pathBottom.x3, erkerBottom_end.pathBottom.y3, erkerBottom_end.pathBottom.x4, erkerBottom_end.pathBottom.y4);
                            stripeHorizontal_4.pointBottomLeft = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerBottom_end.pathBottom.x4 = crossPoint[0];
                            erkerBottom_end.pathBottom.y4 = crossPoint[1];
                        }
                        if ((!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name)) && deletedStripes.includes(currentStripe.name)) {
                            stripeHorizontal_4.capLeft = false;
                            crossPoint = searchCross(stripeHorizontal_4.pointTopLeft.x, stripeHorizontal_4.pointTopLeft.y, stripeHorizontal_4.pointTopRight.x, stripeHorizontal_4.pointTopRight.y, erkerBottom_end.pathTop.x3, erkerBottom_end.pathTop.y3, erkerBottom_end.pathTop.x4, erkerBottom_end.pathTop.y4);
                            stripeHorizontal_4.pointTopLeft = {
                                x: crossPoint[0],
                                y: crossPoint[1],
                            };
                            erkerBottom_end.pathTop.x4 = crossPoint[0];
                            erkerBottom_end.pathTop.y4 = crossPoint[1];
                        }
                    }

                }

            }

            if (cornerStripeTopLeft && cornerStripeTopRight) {
                if (deletedStripes.includes(currentStripe.name)) {
                    crossPoint = searchCross(cornerStripeTopLeft.pointBottomRight.x, cornerStripeTopLeft.pointBottomRight.y, cornerStripeTopLeft.pointBottomCenterRight.x, cornerStripeTopLeft.pointBottomCenterRight.y, cornerStripeTopRight.pointBottomLeft.x, cornerStripeTopRight.pointBottomLeft.y, cornerStripeTopRight.pointBottomCenterLeft.x, cornerStripeTopRight.pointBottomCenterLeft.y);
                    cornerStripeTopLeft.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeTopRight.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                } else if (!erkerTop_start && !erkerTop_end && deletedStripes.includes(stripeHorizontal_1.name) && deletedStripes.includes(stripeHorizontal_3.name) && (!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name))) {
                    crossPoint = searchCross(cornerStripeTopLeft.pointTopRight.x, cornerStripeTopLeft.pointTopRight.y, cornerStripeTopLeft.pointTopCenterRight.x, cornerStripeTopLeft.pointTopCenterRight.y, cornerStripeTopRight.pointTopLeft.x, cornerStripeTopRight.pointTopLeft.y, cornerStripeTopRight.pointTopCenterRight.x, cornerStripeTopRight.pointTopCenterRight.y);
                    cornerStripeTopLeft.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeTopRight.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, cornerStripeTopLeft.pointBottomRight.x, cornerStripeTopLeft.pointBottomRight.y, cornerStripeTopLeft.pointBottomCenterRight.x, cornerStripeTopLeft.pointBottomCenterRight.y);
                    currentStripe.pointLeftTop = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeTopLeft.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, cornerStripeTopRight.pointBottomLeft.x, cornerStripeTopRight.pointBottomLeft.y, cornerStripeTopRight.pointBottomCenterLeft.x, cornerStripeTopRight.pointBottomCenterLeft.y);
                    currentStripe.pointRightTop = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeTopRight.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    currentStripe.capTop = false;
                }
                if (stripeHorizontal_1 && !deletedStripes.includes(stripeHorizontal_1.name)) {
                    crossPoint = searchCross(stripeHorizontal_1.pointBottomLeft.x, stripeHorizontal_1.pointBottomLeft.y, stripeHorizontal_1.pointBottomRight.x, stripeHorizontal_1.pointBottomRight.y, cornerStripeTopLeft.pointTopRight.x, cornerStripeTopLeft.pointTopRight.y, cornerStripeTopLeft.pointTopCenterRight.x, cornerStripeTopLeft.pointTopCenterRight.y);
                    stripeHorizontal_1.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeTopLeft.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }
                if (stripeHorizontal_3 && !deletedStripes.includes(stripeHorizontal_3.name)) {
                    crossPoint = searchCross(stripeHorizontal_3.pointBottomLeft.x, stripeHorizontal_3.pointBottomLeft.y, stripeHorizontal_3.pointBottomRight.x, stripeHorizontal_3.pointBottomRight.y, cornerStripeTopRight.pointTopLeft.x, cornerStripeTopRight.pointTopLeft.y, cornerStripeTopRight.pointTopCenterLeft.x, cornerStripeTopRight.pointTopCenterLeft.y);
                    stripeHorizontal_3.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeTopRight.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }
            }

            if (cornerStripeBottomLeft && cornerStripeBottomRight) {
                if (deletedStripes.includes(currentStripe.name)) {
                    crossPoint = searchCross(cornerStripeBottomLeft.pointTopRight.x, cornerStripeBottomLeft.pointTopRight.y, cornerStripeBottomLeft.pointTopCenterRight.x, cornerStripeBottomLeft.pointTopCenterRight.y, cornerStripeBottomRight.pointTopLeft.x, cornerStripeBottomRight.pointTopLeft.y, cornerStripeBottomRight.pointTopCenterLeft.x, cornerStripeBottomRight.pointTopCenterLeft.y);
                    cornerStripeBottomLeft.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeBottomRight.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                } else if (!erkerBottom_start && !erkerBottom_end && deletedStripes.includes(stripeHorizontal_2.name) && deletedStripes.includes(stripeHorizontal_4.name) && (!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name))) {
                    crossPoint = searchCross(cornerStripeBottomLeft.pointBottomRight.x, cornerStripeBottomLeft.pointBottomRight.y, cornerStripeBottomLeft.pointBottomCenterRight.x, cornerStripeBottomLeft.pointBottomCenterRight.y, cornerStripeBottomRight.pointBottomLeft.x, cornerStripeBottomRight.pointBottomLeft.y, cornerStripeBottomRight.pointBottomCenterRight.x, cornerStripeBottomRight.pointBottomCenterRight.y);
                    cornerStripeBottomLeft.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeBottomRight.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    crossPoint = searchCross(currentStripe.pointLeftTop.x, currentStripe.pointLeftTop.y, currentStripe.pointLeftBottom.x, currentStripe.pointLeftBottom.y, cornerStripeBottomLeft.pointTopRight.x, cornerStripeBottomLeft.pointTopRight.y, cornerStripeBottomLeft.pointTopCenterRight.x, cornerStripeBottomLeft.pointTopCenterRight.y);
                    currentStripe.pointLeftBottom = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeBottomLeft.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    crossPoint = searchCross(currentStripe.pointRightTop.x, currentStripe.pointRightTop.y, currentStripe.pointRightBottom.x, currentStripe.pointRightBottom.y, cornerStripeBottomRight.pointTopLeft.x, cornerStripeBottomRight.pointTopLeft.y, cornerStripeBottomRight.pointTopCenterLeft.x, cornerStripeBottomRight.pointTopCenterLeft.y);
                    currentStripe.pointRightBottom = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeBottomRight.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    currentStripe.capBottom = false;
                }
                if (stripeHorizontal_2 && !deletedStripes.includes(stripeHorizontal_2.name)) {
                    crossPoint = searchCross(stripeHorizontal_2.pointTopLeft.x, stripeHorizontal_2.pointTopLeft.y, stripeHorizontal_2.pointTopRight.x, stripeHorizontal_2.pointTopRight.y, cornerStripeBottomLeft.pointBottomRight.x, cornerStripeBottomLeft.pointBottomRight.y, cornerStripeBottomLeft.pointBottomCenterRight.x, cornerStripeBottomLeft.pointBottomCenterRight.y);
                    stripeHorizontal_2.pointTopRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeBottomLeft.pointBottomRight = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }
                if (stripeHorizontal_4 && !deletedStripes.includes(stripeHorizontal_4.name)) {
                    crossPoint = searchCross(stripeHorizontal_4.pointTopLeft.x, stripeHorizontal_4.pointTopLeft.y, stripeHorizontal_4.pointTopRight.x, stripeHorizontal_4.pointTopRight.y, cornerStripeBottomRight.pointBottomLeft.x, cornerStripeBottomRight.pointBottomLeft.y, cornerStripeBottomRight.pointBottomCenterLeft.x, cornerStripeBottomRight.pointBottomCenterLeft.y);
                    stripeHorizontal_4.pointTopLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                    cornerStripeBottomRight.pointBottomLeft = {
                        x: crossPoint[0],
                        y: crossPoint[1],
                    };
                }
            }
            if (erkerTop_end && cornerStripeTopLeft && deletedStripes.includes(stripeHorizontal_1.name)) {
                crossPoint = searchCross(cornerStripeTopLeft.pointTopRight.x, cornerStripeTopLeft.pointTopRight.y, cornerStripeTopLeft.pointTopCenterRight.x, cornerStripeTopLeft.pointTopCenterRight.y, erkerTop_end.pathBottom.x3, erkerTop_end.pathBottom.y3, erkerTop_end.pathBottom.x4, erkerTop_end.pathBottom.y4);
                cornerStripeTopLeft.pointTopRight = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                erkerTop_end.pathBottom.x4 = crossPoint[0];
                erkerTop_end.pathBottom.y4 = crossPoint[1];
            }
            if (erkerTop_start && cornerStripeTopRight && deletedStripes.includes(stripeHorizontal_3.name)) {
                crossPoint = searchCross(cornerStripeTopRight.pointTopLeft.x, cornerStripeTopRight.pointTopLeft.y, cornerStripeTopRight.pointTopCenterLeft.x, cornerStripeTopRight.pointTopCenterLeft.y, erkerTop_start.pathBottom.x1, erkerTop_start.pathBottom.y1, erkerTop_start.pathBottom.x2, erkerTop_start.pathBottom.y2);
                cornerStripeTopRight.pointTopLeft = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                erkerTop_start.pathBottom.x1 = crossPoint[0];
                erkerTop_start.pathBottom.y1 = crossPoint[1];
            }
            if (erkerBottom_end && cornerStripeBottomLeft && deletedStripes.includes(stripeHorizontal_2.name)) {
                crossPoint = searchCross(cornerStripeBottomLeft.pointBottomRight.x, cornerStripeBottomLeft.pointBottomRight.y, cornerStripeBottomLeft.pointBottomCenterRight.x, cornerStripeBottomLeft.pointBottomCenterRight.y, erkerBottom_end.pathTop.x3, erkerBottom_end.pathTop.y3, erkerBottom_end.pathTop.x4, erkerBottom_end.pathTop.y4);
                cornerStripeBottomLeft.pointBottomRight = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                erkerBottom_end.pathTop.x4 = crossPoint[0];
                erkerBottom_end.pathTop.y4 = crossPoint[1];
            }
            if (erkerBottom_start && cornerStripeBottomRight && deletedStripes.includes(stripeHorizontal_4.name)) {
                crossPoint = searchCross(cornerStripeBottomRight.pointBottomLeft.x, cornerStripeBottomRight.pointBottomLeft.y, cornerStripeBottomRight.pointBottomCenterLeft.x, cornerStripeBottomRight.pointBottomCenterLeft.y, erkerBottom_start.pathTop.x1, erkerBottom_start.pathTop.y1, erkerBottom_start.pathTop.x2, erkerBottom_start.pathTop.y2);
                cornerStripeBottomRight.pointBottomLeft = {
                    x: crossPoint[0],
                    y: crossPoint[1],
                };
                erkerBottom_start.pathTop.x1 = crossPoint[0];
                erkerBottom_start.pathTop.y1 = crossPoint[1];
            }

        }
    }

    //Идём по каминам (горизонтальные перемычки)
    for (let i = 0; i < countX; i++) {
        for (let j = 0; j < countY - 1; j++) {
            let currentStripe = _stripes.horizontal.find(stripe => stripe.name === `hor_${j}_${i}`);
            const bottomRightX = currentStripe.pointBottomRight.x;
            const topRightX = currentStripe.pointTopRight.x;

            // Нижние камины
            const bottomCrossBakes = _bakes.filter(bake => currentStripe.name === `hor_${bake.left.index}_${bake.top.index}`).filter(bake => currentStripe.pointBottomLeft.y > bake.top.left.y).sort((bakeA, bakeB) => { return bakeA.x - bakeB.x });
            if (bottomCrossBakes?.length > 0) {
                currentStripe.bottomBakes = true;
                for (let index = 0; index < bottomCrossBakes.length; index++) {
                    if (index === 0) {
                        if (currentStripe.pointBottomLeft.x < bottomCrossBakes[index].x - bottomCrossBakes[index].width / 2) {
                            _extendedStripes.push({
                                points: [
                                    currentStripe.pointBottomLeft.x,
                                    currentStripe.pointBottomLeft.y,
                                    bottomCrossBakes[index].x - bottomCrossBakes[index].width / 2,
                                    currentStripe.pointBottomLeft.y,
                                ]
                            });
                        }
                        if (bottomCrossBakes.length === 1) {
                            if (bottomRightX > bottomCrossBakes[index].x + bottomCrossBakes[index].width / 2) {
                                _extendedStripes.push({
                                    points: [
                                        bottomCrossBakes[index].x + bottomCrossBakes[index].width / 2,
                                        currentStripe.pointBottomLeft.y,
                                        bottomRightX,
                                        currentStripe.pointBottomRight.y,
                                    ]
                                });
                            }
                        }
                    } else if (index = bottomCrossBakes.length - 1) {
                        _extendedStripes.push({
                            points: [
                                bottomCrossBakes[index - 1].x + bottomCrossBakes[index - 1].width / 2,
                                currentStripe.pointBottomLeft.y,
                                bottomCrossBakes[index].x - bottomCrossBakes[index].width / 2,
                                currentStripe.pointBottomLeft.y,
                            ]
                        });
                        if (bottomRightX > bottomCrossBakes[index].x + bottomCrossBakes[index].width / 2) {
                            _extendedStripes.push({
                                points: [
                                    bottomCrossBakes[index].x + bottomCrossBakes[index].width / 2,
                                    currentStripe.pointBottomLeft.y,
                                    bottomRightX,
                                    currentStripe.pointBottomRight.y,
                                ]
                            });
                        }
                    } else {
                        _extendedStripes.push({
                            points: [
                                bottomCrossBakes[index - 1].x + bottomCrossBakes[index - 1].width / 2,
                                currentStripe.pointBottomLeft.y,
                                bottomCrossBakes[index].x - bottomCrossBakes[index].width / 2,
                                currentStripe.pointBottomLeft.y,
                            ]
                        });
                    }
                    bottomCrossBakes[index].left.bottom.y = currentStripe.pointBottomLeft.y;
                    bottomCrossBakes[index].right.bottom.y = currentStripe.pointBottomLeft.y;
                    
                    bottomCrossBakes[index].top.left.x = -1000;
                    bottomCrossBakes[index].top.right.x = -1000;
                    bottomCrossBakes[index].left.top.y = -1000;
                    bottomCrossBakes[index].right.top.y = -1000;
                }
            } else {
                delete currentStripe.bottomBakes;
            }

            // Верхние камины
            const topCrossBakes = _bakes.filter(bake => currentStripe.name === `hor_${bake.left.index}_${bake.bottom.index}`).filter(bake => currentStripe.pointTopLeft.y < bake.bottom.left.y).sort((bakeA, bakeB) => { return bakeA.x - bakeB.x });
            if (topCrossBakes?.length > 0) {
                currentStripe.topBakes = true;
                for (let index = 0; index < topCrossBakes.length; index++) {
                    if (index === 0) {
                        if (currentStripe.pointTopLeft.x < topCrossBakes[index].x - topCrossBakes[index].width / 2) {
                            _extendedStripes.push({
                                points: [
                                    currentStripe.pointTopLeft.x,
                                    currentStripe.pointTopLeft.y,
                                    topCrossBakes[index].x - topCrossBakes[index].width / 2,
                                    currentStripe.pointTopLeft.y,
                                ]
                            });
                        }
                        if (topCrossBakes.length === 1) {
                            if (topRightX > topCrossBakes[index].x + topCrossBakes[index].width / 2) {
                                _extendedStripes.push({
                                    points: [
                                        topCrossBakes[index].x + topCrossBakes[index].width / 2,
                                        currentStripe.pointTopLeft.y,
                                        topRightX,
                                        currentStripe.pointTopRight.y,
                                    ]
                                });
                            }
                        }
                    } else if (index = topCrossBakes.length - 1) {
                        _extendedStripes.push({
                            points: [
                                topCrossBakes[index - 1].x + topCrossBakes[index - 1].width / 2,
                                currentStripe.pointTopLeft.y,
                                topCrossBakes[index].x - topCrossBakes[index].width / 2,
                                currentStripe.pointTopLeft.y,
                            ]
                        });
                        if (topRightX > topCrossBakes[index].x + topCrossBakes[index].width / 2) {
                            _extendedStripes.push({
                                points: [
                                    topCrossBakes[index].x + topCrossBakes[index].width / 2,
                                    currentStripe.pointTopLeft.y,
                                    topRightX,
                                    currentStripe.pointTopRight.y,
                                ]
                            });
                        }
                    } else {
                        _extendedStripes.push({
                            points: [
                                topCrossBakes[index - 1].x + topCrossBakes[index - 1].width / 2,
                                currentStripe.pointTopLeft.y,
                                topCrossBakes[index].x - topCrossBakes[index].width / 2,
                                currentStripe.pointTopLeft.y,
                            ]
                        });
                    }
                    topCrossBakes[index].left.top.y = currentStripe.pointTopLeft.y;
                    topCrossBakes[index].right.top.y = currentStripe.pointTopLeft.y;
                    
                    topCrossBakes[index].bottom.left.x = -1000;
                    topCrossBakes[index].bottom.right.x = -1000;
                    topCrossBakes[index].left.bottom.y = -1000;
                    topCrossBakes[index].right.bottom.y = -1000;
                }
            } else {
                delete currentStripe.topBakes;
            }
            
        }
    }

    //Идём по каминам (вертикальные перемычки)
    for (let i = 0; i < countY; i++) {
        for (let j = 0; j < countX - 1; j++) {
            let currentStripe = _stripes.vertical.find(stripe => stripe.name === `ver_${j}_${i}`);
            const rightBottomY = currentStripe.pointRightBottom.y;
            const leftBottomY = currentStripe.pointLeftBottom.y;
            
            // Правые камины
            const rightCrossBakes = _bakes.filter(bake => currentStripe.name === `ver_${bake.top.index}_${bake.left.index}`).filter(bake => currentStripe.pointRightTop.x > bake.left.top.x).sort((bakeA, bakeB) => { return bakeA.y - bakeB.y });
            if (rightCrossBakes?.length > 0) {
                currentStripe.rightBakes = true;
                for (let index = 0; index < rightCrossBakes.length; index++) {
                    if (index === 0) {
                        if (currentStripe.pointRightTop.y < rightCrossBakes[index].y - rightCrossBakes[index].height / 2) {
                            _extendedStripes.push({
                                points: [
                                    currentStripe.pointRightTop.x,
                                    currentStripe.pointRightTop.y,
                                    currentStripe.pointRightTop.x,
                                    rightCrossBakes[index].y - rightCrossBakes[index].height / 2,
                                ]
                            });
                        }
                        if (rightCrossBakes.length === 1) {
                            if (rightBottomY > rightCrossBakes[index].y + rightCrossBakes[index].height / 2) {
                                _extendedStripes.push({
                                    points: [
                                        currentStripe.pointRightTop.x,
                                        rightCrossBakes[index].y + rightCrossBakes[index].height / 2,
                                        currentStripe.pointRightBottom.x,
                                        rightBottomY,
                                    ]
                                });
                            }
                        }
                    } else if (index = rightCrossBakes.length - 1) {
                        _extendedStripes.push({
                            points: [
                                currentStripe.pointRightTop.x,
                                rightCrossBakes[index - 1].y + rightCrossBakes[index - 1].height / 2,
                                currentStripe.pointRightTop.x,
                                rightCrossBakes[index].y - rightCrossBakes[index].height / 2,
                            ]
                        });
                        if (rightBottomY > rightCrossBakes[index].y + rightCrossBakes[index].height / 2) {
                            _extendedStripes.push({
                                points: [
                                    currentStripe.pointRightTop.x,
                                    rightCrossBakes[index].y + rightCrossBakes[index].height / 2,
                                    currentStripe.pointRightBottom.x,
                                    rightBottomY,
                                ]
                            });
                        }
                    } else {
                        _extendedStripes.push({
                            points: [
                                currentStripe.pointRightTop.x,
                                rightCrossBakes[index - 1].y + rightCrossBakes[index - 1].height / 2,
                                currentStripe.pointRightTop.x,
                                rightCrossBakes[index].y - rightCrossBakes[index].height / 2,
                            ]
                        });
                    }
                    rightCrossBakes[index].top.right.x = currentStripe.pointRightTop.x;
                    rightCrossBakes[index].bottom.right.x = currentStripe.pointRightTop.x;
                    
                    rightCrossBakes[index].left.top.y = -1000;
                    rightCrossBakes[index].left.bottom.y = -1000;
                    rightCrossBakes[index].top.left.x = -1000;
                    rightCrossBakes[index].bottom.left.x = -1000;
                }
            } else {
                delete currentStripe.rightBakes;
            }

            // Левые камины
            const leftCrossBakes = _bakes.filter(bake => currentStripe.name === `ver_${bake.top.index}_${bake.right.index}`).filter(bake => currentStripe.pointLeftTop.x < bake.right.top.x).sort((bakeA, bakeB) => { return bakeA.y - bakeB.y });
            if (leftCrossBakes?.length > 0) {
                currentStripe.leftBakes = true;
                for (let index = 0; index < leftCrossBakes.length; index++) {
                    if (index === 0) {
                        if (currentStripe.pointLeftTop.y < leftCrossBakes[index].y - leftCrossBakes[index].height / 2) {
                            _extendedStripes.push({
                                points: [
                                    currentStripe.pointLeftTop.x,
                                    currentStripe.pointLeftTop.y,
                                    currentStripe.pointLeftTop.x,
                                    leftCrossBakes[index].y - leftCrossBakes[index].height / 2,
                                ]
                            });
                        }
                        if (leftCrossBakes.length === 1) {
                            if (leftBottomY > leftCrossBakes[index].y + leftCrossBakes[index].height / 2) {
                                _extendedStripes.push({
                                    points: [
                                        currentStripe.pointLeftTop.x,
                                        leftCrossBakes[index].y + leftCrossBakes[index].height / 2,
                                        currentStripe.pointLeftBottom.x,
                                        leftBottomY,
                                    ]
                                });
                            }
                        }
                    } else if (index = leftCrossBakes.length - 1) {
                        _extendedStripes.push({
                            points: [
                                currentStripe.pointLeftTop.x,
                                leftCrossBakes[index - 1].y + leftCrossBakes[index - 1].height / 2,
                                currentStripe.pointLeftTop.x,
                                leftCrossBakes[index].y - leftCrossBakes[index].height / 2,
                            ]
                        });
                        if (leftBottomY > leftCrossBakes[index].y + leftCrossBakes[index].height / 2) {
                            _extendedStripes.push({
                                points: [
                                    currentStripe.pointLeftTop.x,
                                    leftCrossBakes[index].y + leftCrossBakes[index].height / 2,
                                    currentStripe.pointLeftBottom.x,
                                    leftBottomY,
                                ]
                            });
                        }
                    } else {
                        _extendedStripes.push({
                            points: [
                                currentStripe.pointRightTop.x,
                                leftCrossBakes[index - 1].y + leftCrossBakes[index - 1].height / 2,
                                currentStripe.pointRightTop.x,
                                leftCrossBakes[index].y - leftCrossBakes[index].height / 2,
                            ]
                        });
                    }
                    leftCrossBakes[index].top.left.x = currentStripe.pointLeftTop.x;
                    leftCrossBakes[index].bottom.left.x = currentStripe.pointLeftTop.x;
                    
                    leftCrossBakes[index].right.top.y = -1000;
                    leftCrossBakes[index].right.bottom.y = -1000;
                    leftCrossBakes[index].top.right.x = -1000;
                    leftCrossBakes[index].bottom.right.x = -1000;
                }
            } else {
                delete currentStripe.leftBakes;
            }

        }
    }

    return {
        erkers: _erkers,
        stripes: _stripes,
        extendedStripes: _extendedStripes,
        bakes: _bakes,
    }
}

export const calculationSizes = (piles, stripes, deletedStripes, erkers, countX, countY, stageRef) => {

    let _allSizes = 0;
    let _crossSizes = 0;
    let _square = 0;

    // Идём по горизонтальным перемычкам
    for (let i = 0; i < countX; i++) {
        for (let j = 0; j < countY - 1; j++) {
            // Обрабатываемая перемычка
            let currentStripe = stripes.horizontal.find(stripe => stripe.name === `hor_${j}_${i}`);

            // Смежные горизонтальные
            let stripeHorizontal_1 = stripes.horizontal.find(stripe => stripe.name === `hor_${j - 1}_${i}`);
            let stripeHorizontal_2 = stripes.horizontal.find(stripe => stripe.name === `hor_${j + 1}_${i}`);

            // Смежные вертикальные
            let stripeVertical_1 = stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j}`);
            let stripeVertical_2 = stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j + 1}`);
            let stripeVertical_3 = stripes.vertical.find(stripe => stripe.name === `ver_${i}_${j}`);
            let stripeVertical_4 = stripes.vertical.find(stripe => stripe.name === `ver_${i}_${j + 1}`);

            if (!deletedStripes.includes(currentStripe.name)) {
                _allSizes += currentStripe.length;
                _crossSizes += currentStripe.length;
                if (!stripeHorizontal_1 || deletedStripes.includes(stripeHorizontal_1.name)) {
                    if (stripeVertical_3) {
                        _allSizes += stripeVertical_3.leftWidth;
                        _crossSizes += stripeVertical_3.leftWidth;
                    } else if (stripeVertical_1) {
                        _allSizes += stripeVertical_1.leftWidth;
                        _crossSizes += stripeVertical_1.leftWidth;
                    }
                }
                if (!stripeHorizontal_2 || deletedStripes.includes(stripeHorizontal_2.name)) {
                    if (stripeVertical_4) {
                        _allSizes += stripeVertical_4.rightWidth;
                        _crossSizes += stripeVertical_4.rightWidth;
                    } else if (stripeVertical_2) {
                        _allSizes += stripeVertical_2.rightWidth;
                        _crossSizes += stripeVertical_2.rightWidth;
                    }
                }
            }
        }
    }

    // Идём по вертикальным перемычкам
    for (let i = 0; i < countY; i++) {
        for (let j = 0; j < countX - 1; j++) {
            // Обрабатываемая перемычка
            let currentStripe = stripes.vertical.find(stripe => stripe.name === `ver_${j}_${i}`);

            // Смежные вертикальные
            let stripeVertical_1 = stripes.vertical.find(stripe => stripe.name === `ver_${j - 1}_${i}`);
            let stripeVertical_2 = stripes.vertical.find(stripe => stripe.name === `ver_${j + 1}_${i}`);

            // Смежные горизонтальные
            let stripeHorizontal_1 = stripes.horizontal.find(stripe => stripe.name === `hor_${i - 1}_${j}`);
            let stripeHorizontal_2 = stripes.horizontal.find(stripe => stripe.name === `hor_${i - 1}_${j + 1}`);
            let stripeHorizontal_3 = stripes.horizontal.find(stripe => stripe.name === `hor_${i}_${j}`);
            let stripeHorizontal_4 = stripes.horizontal.find(stripe => stripe.name === `hor_${i}_${j + 1}`);

            if (!deletedStripes.includes(currentStripe.name)) {
                _allSizes += currentStripe.length;
                _crossSizes += currentStripe.length;
                if (!stripeVertical_1 || deletedStripes.includes(stripeVertical_1.name)) {
                    if (stripeHorizontal_3) {
                        _allSizes += stripeHorizontal_3.topWidth;
                    } else if (stripeHorizontal_1) {
                        _allSizes += stripeHorizontal_1.topWidth;
                    }
                    if (!stripeHorizontal_1) {
                        if (stripeHorizontal_3 && deletedStripes.includes(stripeHorizontal_3.name)) {
                            _crossSizes += stripeHorizontal_3.topWidth;
                        }
                    } else if (deletedStripes.includes(stripeHorizontal_1.name)) {
                        if (stripeHorizontal_3 && deletedStripes.includes(stripeHorizontal_3.name)) {
                            _crossSizes += stripeHorizontal_1.topWidth;
                        }
                    }
                }
                if (!stripeVertical_2 || deletedStripes.includes(stripeVertical_2.name)) {
                    if (stripeHorizontal_4) {
                        _allSizes += stripeHorizontal_4.bottomWidth;
                    } else if (stripeHorizontal_2) {
                        _allSizes += stripeHorizontal_2.bottomWidth;
                    }
                    if (!stripeHorizontal_2) {
                        if (stripeHorizontal_4 && deletedStripes.includes(stripeHorizontal_4.name)) {
                            _crossSizes += stripeHorizontal_4.bottomWidth;
                        }
                    } else if (deletedStripes.includes(stripeHorizontal_2.name)) {
                        if (stripeHorizontal_4 && deletedStripes.includes(stripeHorizontal_4.name)) {
                            _crossSizes += stripeHorizontal_2.bottomWidth;
                        }
                    }
                }
                if (stripeHorizontal_1 && !deletedStripes.includes(stripeHorizontal_1.name)) {
                    _crossSizes -= stripeHorizontal_1.bottomWidth;
                } else if (stripeHorizontal_3 && !deletedStripes.includes(stripeHorizontal_3.name)) {
                    _crossSizes -= stripeHorizontal_3.bottomWidth;
                }
                if (stripeHorizontal_2 && !deletedStripes.includes(stripeHorizontal_2.name)) {
                    _crossSizes -= stripeHorizontal_2.topWidth;
                } else if (stripeHorizontal_4 && !deletedStripes.includes(stripeHorizontal_4.name)) {
                    _crossSizes -= stripeHorizontal_4.topWidth;
                }
            }

        }
    }
    
    //расчитываем площадь
    const _perimeter = searchPerimeter(null, null, stripes, deletedStripes, erkers, [], countX, countY);
    _square = polygonArea(_perimeter);

    stripes.corner.forEach(stripe => {
        _allSizes += calculateLength(stripe.pointBottom.x, stripe.pointBottom.y, stripe.pointTop.x, stripe.pointTop.y);
        _crossSizes += calculateLength(stripe.pointBottom.x, stripe.pointBottom.y, stripe.pointTop.x, stripe.pointTop.y);
    });

    // Идём по эркерам
    erkers.top.filter(erker => erker.type === 'angel').forEach(erker => {
        const size = erker.erkerPointTopRight.x - erker.erkerPointTopLeft.x;
        _allSizes += calculateLength(erker.erkerPointLeft.x, erker.erkerPointLeft.y, erker.erkerPointTopLeft.x, erker.erkerPointTopLeft.y) * 2 + size;
        _crossSizes += calculateLength(erker.erkerPointLeft.x, erker.erkerPointLeft.y, erker.erkerPointTopLeft.x, erker.erkerPointTopLeft.y) * 2 + size;
    });
    erkers.top.filter(erker => erker.type === 'round').forEach(erker => {
        _allSizes += (Math.PI * erker.radius / 180) * (erker.angle * 2);
        _crossSizes += (Math.PI * erker.radius / 180) * (erker.angle * 2);
    });
    erkers.bottom.filter(erker => erker.type === 'angel').forEach(erker => {
        const size = erker.erkerPointBottomRight.x - erker.erkerPointBottomLeft.x;
        _allSizes += calculateLength(erker.erkerPointLeft.x, erker.erkerPointLeft.y, erker.erkerPointBottomLeft.x, erker.erkerPointBottomLeft.y) * 2 + size;
        _crossSizes += calculateLength(erker.erkerPointLeft.x, erker.erkerPointLeft.y, erker.erkerPointBottomLeft.x, erker.erkerPointBottomLeft.y) * 2 + size;
    });
    erkers.bottom.filter(erker => erker.type === 'round').forEach(erker => {
        _allSizes += (Math.PI * erker.radius / 180) * (erker.angle * 2);
        _crossSizes += (Math.PI * erker.radius / 180) * (erker.angle * 2);
    });
    erkers.left.filter(erker => erker.type === 'angel').forEach(erker => {
        const size = erker.erkerPointLeftBottom.y - erker.erkerPointLeftTop.y;
        _allSizes += calculateLength(erker.erkerPointTop.x, erker.erkerPointTop.y, erker.erkerPointLeftTop.x, erker.erkerPointLeftTop.y) * 2 + size;
        _crossSizes += calculateLength(erker.erkerPointTop.x, erker.erkerPointTop.y, erker.erkerPointLeftTop.x, erker.erkerPointLeftTop.y) * 2 + size;
    });
    erkers.left.filter(erker => erker.type === 'round').forEach(erker => {
        _allSizes += (Math.PI * erker.radius / 180) * (erker.angle * 2);
        _crossSizes += (Math.PI * erker.radius / 180) * (erker.angle * 2);
    });
    erkers.right.filter(erker => erker.type === 'angel').forEach(erker => {
        const size = erker.erkerPointRightBottom.y - erker.erkerPointRightTop.y;
        _allSizes += calculateLength(erker.erkerPointTop.x, erker.erkerPointTop.y, erker.erkerPointRightTop.x, erker.erkerPointRightTop.y) * 2 + size;
        _crossSizes += calculateLength(erker.erkerPointTop.x, erker.erkerPointTop.y, erker.erkerPointRightTop.x, erker.erkerPointRightTop.y) * 2 + size;
    });
    erkers.right.filter(erker => erker.type === 'round').forEach(erker => {
        _allSizes += (Math.PI * erker.radius / 180) * (erker.angle * 2);
        _crossSizes += (Math.PI * erker.radius / 180) * (erker.angle * 2);
    });

    const _pileCount = piles.filter((pile) => pile.deleted == false).length;

    return {
        allSizes: _allSizes / 1000,
        crossSizes: _crossSizes / 1000,
        pileCount: _pileCount,
        square: _square / 1000000,
        perimeter: _perimeter,
    }
}

export const changeAxeWidth = (index, value, type, stripes, erkers, countX, countY) => {
    if (type === "horizontal") {
        const tempStripe = stripes.horizontal.find((stripe) => stripe.name === `hor_${index}_0`);
        const deltaX = value - (tempStripe.pointRight.x - tempStripe.pointLeft.x);
        
        let _stripes = JSON.parse(JSON.stringify(stripes));
        //horizontal
        for (let i = 0; i < countX; i++) {
            for (let j = index; j < countY - 1; j++) {
                let currentStripe = _stripes.horizontal.find((stripe) => stripe.name === `hor_${j}_${i}`);

                currentStripe.length = j === index ? value : currentStripe.length;

                currentStripe.pointLeft.x = j === index ? currentStripe.pointLeft.x : currentStripe.pointLeft.x + deltaX;
                currentStripe.pointRight.x = currentStripe.pointRight.x + deltaX;

                currentStripe.pointTopLeft.x = j === index ? currentStripe.pointTopLeft.x : currentStripe.pointTopLeft.x + deltaX;
                currentStripe.pointTopRight.x = currentStripe.pointTopRight.x + deltaX;

                currentStripe.pointBottomLeft.x = j === index ? currentStripe.pointBottomLeft.x : currentStripe.pointBottomLeft.x + deltaX;
                currentStripe.pointBottomRight.x = currentStripe.pointBottomRight.x + deltaX;

                currentStripe.capLeftTop.x = j === index ? currentStripe.capLeftTop.x : currentStripe.capLeftTop.x + deltaX;
                currentStripe.capLeftBottom.x = j === index ? currentStripe.capLeftBottom.x : currentStripe.capLeftBottom.x + deltaX;

                currentStripe.capRightTop.x = currentStripe.capRightTop.x + deltaX;
                currentStripe.capRightBottom.x = currentStripe.capRightBottom.x + deltaX;
            }
        }
        //vertical
        for (let i = index + 1; i < countY; i++) {
            for (let j = 0; j < countX - 1; j++) {
                let currentStripe = _stripes.vertical.find((stripe) => stripe.name === `ver_${j}_${i}`);

                currentStripe.pointTop.x = currentStripe.pointTop.x + deltaX;
                currentStripe.pointBottom.x = currentStripe.pointBottom.x + deltaX;

                currentStripe.pointLeftTop.x = currentStripe.pointLeftTop.x + deltaX;
                currentStripe.pointLeftBottom.x = currentStripe.pointLeftBottom.x + deltaX;

                currentStripe.pointRightTop.x = currentStripe.pointRightTop.x + deltaX;
                currentStripe.pointRightBottom.x = currentStripe.pointRightBottom.x + deltaX;

                currentStripe.capTopLeft.x = currentStripe.capTopLeft.x + deltaX;
                currentStripe.capTopRight.x = currentStripe.capTopRight.x + deltaX;
                
                currentStripe.capBottomLeft.x = currentStripe.capBottomLeft.x + deltaX;
                currentStripe.capBottomRight.x = currentStripe.capBottomRight.x + deltaX;
            }
        }
        //corner
        for (let i = 0; i < countX; i++) {

            for (let j = index + 1; j < countY; j++) {
                let cornerStripeLeft = _stripes.corner.find((cornerStripe) => cornerStripe.name.startsWith(`ver_${i - 1}_${j}:hor_${j}_${i}`));
                let cornerStripeRight = _stripes.corner.find((cornerStripe) => cornerStripe.name.startsWith(`ver_${i - 1}_${j}:hor_${j}_${i - 1}`));

                if (cornerStripeLeft) {
                    cornerStripeLeft.pointTop.x = cornerStripeLeft.pointTop.x + deltaX;
                    cornerStripeLeft.pointBottom.x = cornerStripeLeft.pointBottom.x + deltaX;
                    cornerStripeLeft.pointTopLeft.x = cornerStripeLeft.pointTopLeft.x + deltaX;
                    cornerStripeLeft.pointTopRight.x = cornerStripeLeft.pointTopRight.x + deltaX;
                    cornerStripeLeft.pointTopCenterLeft.x = cornerStripeLeft.pointTopCenterLeft.x + deltaX;
                    cornerStripeLeft.pointTopCenterRight.x = cornerStripeLeft.pointTopCenterRight.x + deltaX;
                    cornerStripeLeft.pointBottomLeft.x = cornerStripeLeft.pointBottomLeft.x + deltaX;
                    cornerStripeLeft.pointBottomRight.x = cornerStripeLeft.pointBottomRight.x + deltaX;
                    cornerStripeLeft.pointBottomCenterLeft.x = cornerStripeLeft.pointBottomCenterLeft.x + deltaX;
                    cornerStripeLeft.pointBottomCenterRight.x = cornerStripeLeft.pointBottomCenterRight.x + deltaX;
                }

                if (cornerStripeRight) {
                    cornerStripeRight.pointTop.x = cornerStripeRight.pointTop.x + deltaX;
                    cornerStripeRight.pointBottom.x = cornerStripeRight.pointBottom.x + deltaX;
                    cornerStripeRight.pointTopLeft.x = cornerStripeRight.pointTopLeft.x + deltaX;
                    cornerStripeRight.pointTopRight.x = cornerStripeRight.pointTopRight.x + deltaX;
                    cornerStripeRight.pointTopCenterLeft.x = cornerStripeRight.pointTopCenterLeft.x + deltaX;
                    cornerStripeRight.pointTopCenterRight.x = cornerStripeRight.pointTopCenterRight.x + deltaX;
                    cornerStripeRight.pointBottomLeft.x = cornerStripeRight.pointBottomLeft.x + deltaX;
                    cornerStripeRight.pointBottomRight.x = cornerStripeRight.pointBottomRight.x + deltaX;
                    cornerStripeRight.pointBottomCenterLeft.x = cornerStripeRight.pointBottomCenterLeft.x + deltaX;
                    cornerStripeRight.pointBottomCenterRight.x = cornerStripeRight.pointBottomCenterRight.x + deltaX;
                }
            }

            const j = index;

            let cornerStripeLeft = _stripes.corner.find((cornerStripe) => cornerStripe.name.startsWith(`ver_${i - 1}_${j}:hor_${j}_${i}`));
            let cornerStripeRight = _stripes.corner.find((cornerStripe) => cornerStripe.name.startsWith(`ver_${i - 1}_${j}:hor_${j}_${i - 1}`));

            if (cornerStripeLeft) {
                const stripeLeft = _stripes.horizontal.find(stripe => stripe.name === `hor_${j}_${i}`);
                const stripeRight = _stripes.horizontal.find(stripe => stripe.name === `hor_${j}_${i - 1}`);

                const stripeTop = _stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j + 1}`);
                const stripeBottom = _stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j}`);

                const verticalLength = stripeTop.length;
                const horizontalLength = value;

                // Рассчитываем верхнюю левую точку
                const angelTopLeft = Math.atan(horizontalLength / verticalLength);
                const deltaTopLeft = (cornerStripeLeft.width / 2) / Math.sin(angelTopLeft);
                cornerStripeLeft.pointTopLeft = {
                    x: stripeBottom.pointBottom.x,
                    y: stripeBottom.pointBottom.y - deltaTopLeft,
                }

                // Рассчитываем верхнюю правую точку
                const angelTopRight = Math.atan(verticalLength / horizontalLength);
                const deltaTopRight = (cornerStripeLeft.width / 2) / Math.sin(angelTopRight);
                cornerStripeLeft.pointTopRight = {
                    x: stripeRight.pointRight.x - deltaTopRight,
                    y: stripeRight.pointRight.y,
                }

                // Рассчитываем верхнюю центральную точку
                cornerStripeLeft.pointTopCenterLeft = {
                    x: cornerStripeLeft.pointTopLeft.x + (cornerStripeLeft.pointTopRight.x - cornerStripeLeft.pointTopLeft.x) / 2,
                    y: cornerStripeLeft.pointTopLeft.y - (cornerStripeLeft.pointTopLeft.y - cornerStripeLeft.pointTopRight.y) / 2,
                }
                cornerStripeLeft.pointTopCenterRight = {
                    x: cornerStripeLeft.pointTopLeft.x + (cornerStripeLeft.pointTopRight.x - cornerStripeLeft.pointTopLeft.x) / 2,
                    y: cornerStripeLeft.pointTopLeft.y - (cornerStripeLeft.pointTopLeft.y - cornerStripeLeft.pointTopRight.y) / 2,
                }

                // Рассчитываем нижнюю левую точку
                const angelBottomLeft = Math.atan(verticalLength / horizontalLength);
                const deltaBottomLeft = (cornerStripeLeft.width / 2) / Math.sin(angelBottomLeft);
                cornerStripeLeft.pointBottomLeft = {
                    x: stripeLeft.pointLeft.x + deltaBottomLeft,
                    y: stripeLeft.pointLeft.y,
                }

                // Рассчитываем верхнюю правую точку
                const angelBottomRight = Math.atan(horizontalLength / verticalLength);
                const deltaBottomRight = (cornerStripeLeft.width / 2) / Math.sin(angelBottomRight);
                cornerStripeLeft.pointBottomRight = {
                    x: stripeTop.pointTop.x,
                    y: stripeTop.pointTop.y + deltaBottomRight,
                }

                // Рассчитываем нижнюю центральную точку
                cornerStripeLeft.pointBottomCenterLeft = {
                    x: cornerStripeLeft.pointBottomLeft.x + (cornerStripeLeft.pointBottomRight.x - cornerStripeLeft.pointBottomLeft.x) / 2,
                    y: cornerStripeLeft.pointBottomLeft.y - (cornerStripeLeft.pointBottomLeft.y - cornerStripeLeft.pointBottomRight.y) / 2,
                }
                cornerStripeLeft.pointBottomCenterRight = {
                    x: cornerStripeLeft.pointBottomLeft.x + (cornerStripeLeft.pointBottomRight.x - cornerStripeLeft.pointBottomLeft.x) / 2,
                    y: cornerStripeLeft.pointBottomLeft.y - (cornerStripeLeft.pointBottomLeft.y - cornerStripeLeft.pointBottomRight.y) / 2,
                }

                cornerStripeLeft.pointTop = stripeTop.pointTop;
                cornerStripeLeft.pointBottom = stripeBottom.pointBottom;
            }

            if (cornerStripeRight) {
                const stripeLeft = _stripes.horizontal.find(stripe => stripe.name === `hor_${j}_${i - 1}`);
                const stripeRight = _stripes.horizontal.find(stripe => stripe.name === `hor_${j}_${i}`);

                const stripeTop = _stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j}`);
                const stripeBottom = _stripes.vertical.find(stripe => stripe.name === `ver_${i - 1}_${j + 1}`);

                const verticalLength = stripeTop.length;
                const horizontalLength = value;

                // Рассчитываем верхнюю левую точку
                const angelTopLeft = Math.atan(verticalLength / horizontalLength);
                const deltaTopLeft = (cornerStripeRight.width / 2) / Math.sin(angelTopLeft);
                cornerStripeRight.pointTopLeft = {
                    x: stripeLeft.pointLeft.x + deltaTopLeft,
                    y: stripeLeft.pointLeft.y,
                }

                // Рассчитываем верхнюю правую точку
                const angelTopRight = Math.atan(horizontalLength / verticalLength);
                const deltaTopRight = (cornerStripeRight.width / 2) / Math.sin(angelTopRight);
                cornerStripeRight.pointTopRight = {
                    x: stripeBottom.pointBottom.x,
                    y: stripeBottom.pointBottom.y - deltaTopRight,
                }

                // Рассчитываем верхнюю центральную точку
                cornerStripeRight.pointTopCenterLeft = {
                    x: cornerStripeRight.pointTopLeft.x + (cornerStripeRight.pointTopRight.x - cornerStripeRight.pointTopLeft.x) / 2,
                    y: cornerStripeRight.pointTopLeft.y + (cornerStripeRight.pointTopRight.y - cornerStripeRight.pointTopLeft.y) / 2,
                }
                cornerStripeRight.pointTopCenterRight = {
                    x: cornerStripeRight.pointTopLeft.x + (cornerStripeRight.pointTopRight.x - cornerStripeRight.pointTopLeft.x) / 2,
                    y: cornerStripeRight.pointTopLeft.y + (cornerStripeRight.pointTopRight.y - cornerStripeRight.pointTopLeft.y) / 2,
                }

                // Рассчитываем нижнюю левую точку
                const angelBottomLeft = Math.atan(horizontalLength / verticalLength);
                const deltaBottomLeft = (cornerStripeRight.width / 2) / Math.sin(angelBottomLeft);
                cornerStripeRight.pointBottomLeft = {
                    x: stripeTop.pointTop.x,
                    y: stripeTop.pointTop.y + deltaBottomLeft,
                }

                // Рассчитываем верхнюю правую точку
                const angelBottomRight = Math.atan(verticalLength / horizontalLength);
                const deltaBottomRight = (cornerStripeRight.width / 2) / Math.sin(angelBottomRight);
                cornerStripeRight.pointBottomRight = {
                    x: stripeRight.pointRight.x - deltaBottomRight,
                    y: stripeRight.pointRight.y,
                }

                // Рассчитываем нижнюю центральную точку
                cornerStripeRight.pointBottomCenterLeft = {
                    x: cornerStripeRight.pointBottomLeft.x + (cornerStripeRight.pointBottomRight.x - cornerStripeRight.pointBottomLeft.x) / 2,
                    y: cornerStripeRight.pointBottomLeft.y + (cornerStripeRight.pointBottomRight.y - cornerStripeRight.pointBottomLeft.y) / 2,
                }
                cornerStripeRight.pointBottomCenterRight = {
                    x: cornerStripeRight.pointBottomLeft.x + (cornerStripeRight.pointBottomRight.x - cornerStripeRight.pointBottomLeft.x) / 2,
                    y: cornerStripeRight.pointBottomLeft.y + (cornerStripeRight.pointBottomRight.y - cornerStripeRight.pointBottomLeft.y) / 2,
                }

                cornerStripeRight.pointTop = stripeTop.pointTop;
                cornerStripeRight.pointBottom = stripeBottom.pointBottom;
            }

        }

        let _erkers = JSON.parse(JSON.stringify(erkers));
        //растягиваем эркеры
        const topErkers = [];
        _erkers.top.forEach(erker => {
            const newErker = resizeErker(erker, _stripes, deltaX);
            if (newErker) topErkers.push(newErker);
        });
        const bottomErkers = [];
        _erkers.bottom.forEach(erker => {
            const newErker = resizeErker(erker, _stripes, deltaX);
            if (newErker) bottomErkers.push(newErker);
        });
        const rightErkers = [];
        _erkers.right.forEach(erker => {
            const newErker = resizeErker(erker, _stripes, deltaX);
            if (newErker) rightErkers.push(newErker);
        })
        _erkers = {
            left: _erkers.left,
            right: rightErkers,
            top: topErkers,
            bottom: bottomErkers,
        };

        return {
            erkers: _erkers,
            stripes: _stripes,
        }
    } else {
        const tempStripe = stripes.vertical.find((stripe) => stripe.name === `ver_${index}_0`);
        const deltaY = value - (tempStripe.pointBottom.y - tempStripe.pointTop.y);

        let _stripes = JSON.parse(JSON.stringify(stripes));
        //vertical
        for (let i = 0; i < countY; i++) {
            for (let j = index; j < countX - 1; j++) {
                let currentStripe = _stripes.vertical.find((stripe) => stripe.name === `ver_${j}_${i}`);

                currentStripe.length = j === index ? value : currentStripe.length;

                currentStripe.pointTop.y = j === index ? currentStripe.pointTop.y : currentStripe.pointTop.y + deltaY;
                currentStripe.pointBottom.y = currentStripe.pointBottom.y + deltaY;

                currentStripe.pointLeftTop.y = j === index ? currentStripe.pointLeftTop.y : currentStripe.pointLeftTop.y + deltaY;
                currentStripe.pointLeftBottom.y = currentStripe.pointLeftBottom.y + deltaY;

                currentStripe.pointRightTop.y = j === index ? currentStripe.pointRightTop.y : currentStripe.pointRightTop.y + deltaY;
                currentStripe.pointRightBottom.y = currentStripe.pointRightBottom.y + deltaY;

                currentStripe.capTopLeft.y = j === index ? currentStripe.capTopLeft.y : currentStripe.capTopLeft.y + deltaY;
                currentStripe.capTopRight.y = j === index ? currentStripe.capTopRight.y : currentStripe.capTopRight.y + deltaY;

                currentStripe.capBottomLeft.y = currentStripe.capBottomLeft.y + deltaY;
                currentStripe.capBottomRight.y = currentStripe.capBottomRight.y + deltaY;
            }
        }
        //horizontal
        for (let i = index + 1; i < countX; i++) {
            for (let j = 0; j < countY - 1; j++) {
                let currentStripe = _stripes.horizontal.find((stripe) => stripe.name === `hor_${j}_${i}`);

                currentStripe.pointLeft.y = currentStripe.pointLeft.y + deltaY;
                currentStripe.pointRight.y = currentStripe.pointRight.y + deltaY;

                currentStripe.pointTopLeft.y = currentStripe.pointTopLeft.y + deltaY;
                currentStripe.pointTopRight.y = currentStripe.pointTopRight.y + deltaY;

                currentStripe.pointBottomLeft.y = currentStripe.pointBottomLeft.y + deltaY;
                currentStripe.pointBottomRight.y = currentStripe.pointBottomRight.y + deltaY;

                currentStripe.capLeftTop.y = currentStripe.capLeftTop.y + deltaY;
                currentStripe.capLeftBottom.y = currentStripe.capLeftBottom.y + deltaY;

                currentStripe.capRightTop.y = currentStripe.capRightTop.y + deltaY;
                currentStripe.capRightBottom.y = currentStripe.capRightBottom.y + deltaY;
            }
        }
        // corner
        for (let i = 0; i < countY; i++) {

            for (let j = index + 1; j < countX; j++) {
                let cornerStripeTop = _stripes.corner.find((cornerStripe) => cornerStripe.name.startsWith(`ver_${j}_${i}:hor_${i}_${j}`));
                let cornerStripeBottom = _stripes.corner.find((cornerStripe) => cornerStripe.name.startsWith(`ver_${j}_${i}:hor_${i}_${j + 1}`));

                if (cornerStripeTop) {
                    cornerStripeTop.pointTop.y = cornerStripeTop.pointTop.y + deltaY;
                    cornerStripeTop.pointBottom.y = cornerStripeTop.pointBottom.y + deltaY;
                    cornerStripeTop.pointTopLeft.y = cornerStripeTop.pointTopLeft.y + deltaY;
                    cornerStripeTop.pointTopRight.y = cornerStripeTop.pointTopRight.y + deltaY;
                    cornerStripeTop.pointTopCenterLeft.y = cornerStripeTop.pointTopCenterLeft.y + deltaY;
                    cornerStripeTop.pointTopCenterRight.y = cornerStripeTop.pointTopCenterRight.y + deltaY;
                    cornerStripeTop.pointBottomLeft.y = cornerStripeTop.pointBottomLeft.y + deltaY;
                    cornerStripeTop.pointBottomRight.y = cornerStripeTop.pointBottomRight.y + deltaY;
                    cornerStripeTop.pointBottomCenterLeft.y = cornerStripeTop.pointBottomCenterLeft.y + deltaY;
                    cornerStripeTop.pointBottomCenterRight.y = cornerStripeTop.pointBottomCenterRight.y + deltaY;
                }

                if (cornerStripeBottom) {
                    cornerStripeBottom.pointTop.y = cornerStripeBottom.pointTop.y + deltaY;
                    cornerStripeBottom.pointBottom.y = cornerStripeBottom.pointBottom.y + deltaY;
                    cornerStripeBottom.pointTopLeft.y = cornerStripeBottom.pointTopLeft.y + deltaY;
                    cornerStripeBottom.pointTopRight.y = cornerStripeBottom.pointTopRight.y + deltaY;
                    cornerStripeBottom.pointTopCenterLeft.y = cornerStripeBottom.pointTopCenterLeft.y + deltaY;
                    cornerStripeBottom.pointTopCenterRight.y = cornerStripeBottom.pointTopCenterRight.y + deltaY;
                    cornerStripeBottom.pointBottomLeft.y = cornerStripeBottom.pointBottomLeft.y + deltaY;
                    cornerStripeBottom.pointBottomRight.y = cornerStripeBottom.pointBottomRight.y + deltaY;
                    cornerStripeBottom.pointBottomCenterLeft.y = cornerStripeBottom.pointBottomCenterLeft.y + deltaY;
                    cornerStripeBottom.pointBottomCenterRight.y = cornerStripeBottom.pointBottomCenterRight.y + deltaY;
                }
            }

            const j = index;

            let cornerStripeTop = _stripes.corner.find((cornerStripe) => cornerStripe.name.startsWith(`ver_${j}_${i}:hor_${i}_${j}`));
            let cornerStripeBottom = _stripes.corner.find((cornerStripe) => cornerStripe.name.startsWith(`ver_${j}_${i}:hor_${i}_${j + 1}`));
            
            if (cornerStripeBottom) {
                const stripeLeft = _stripes.horizontal.find(stripe => stripe.name === `hor_${i}_${j + 1}`);
                const stripeRight = _stripes.horizontal.find(stripe => stripe.name === `hor_${i}_${j}`);

                const stripeTop = _stripes.vertical.find(stripe => stripe.name === `ver_${j}_${i + 1}`);
                const stripeBottom = _stripes.vertical.find(stripe => stripe.name === `ver_${j}_${i}`);

                const verticalLength = value;
                const horizontalLength = stripeLeft.length;

                // Рассчитываем верхнюю левую точку
                const angelTopLeft = Math.atan(horizontalLength / verticalLength);
                const deltaTopLeft = (cornerStripeBottom.width / 2) / Math.sin(angelTopLeft);
                cornerStripeBottom.pointTopLeft = {
                    x: stripeBottom.pointBottom.x,
                    y: stripeBottom.pointBottom.y - deltaTopLeft,
                }

                // Рассчитываем верхнюю правую точку
                const angelTopRight = Math.atan(verticalLength / horizontalLength);
                const deltaTopRight = (cornerStripeBottom.width / 2) / Math.sin(angelTopRight);
                cornerStripeBottom.pointTopRight = {
                    x: stripeRight.pointRight.x - deltaTopRight,
                    y: stripeRight.pointRight.y,
                }

                // Рассчитываем верхнюю центральную точку
                cornerStripeBottom.pointTopCenterLeft = {
                    x: cornerStripeBottom.pointTopLeft.x + (cornerStripeBottom.pointTopRight.x - cornerStripeBottom.pointTopLeft.x) / 2,
                    y: cornerStripeBottom.pointTopLeft.y - (cornerStripeBottom.pointTopLeft.y - cornerStripeBottom.pointTopRight.y) / 2,
                }
                cornerStripeBottom.pointTopCenterRight = {
                    x: cornerStripeBottom.pointTopLeft.x + (cornerStripeBottom.pointTopRight.x - cornerStripeBottom.pointTopLeft.x) / 2,
                    y: cornerStripeBottom.pointTopLeft.y - (cornerStripeBottom.pointTopLeft.y - cornerStripeBottom.pointTopRight.y) / 2,
                }

                // Рассчитываем нижнюю левую точку
                const angelBottomLeft = Math.atan(verticalLength / horizontalLength);
                const deltaBottomLeft = (cornerStripeBottom.width / 2) / Math.sin(angelBottomLeft);
                cornerStripeBottom.pointBottomLeft = {
                    x: stripeLeft.pointLeft.x + deltaBottomLeft,
                    y: stripeLeft.pointLeft.y,
                }

                // Рассчитываем верхнюю правую точку
                const angelBottomRight = Math.atan(horizontalLength / verticalLength);
                const deltaBottomRight = (cornerStripeBottom.width / 2) / Math.sin(angelBottomRight);
                cornerStripeBottom.pointBottomRight = {
                    x: stripeTop.pointTop.x,
                    y: stripeTop.pointTop.y + deltaBottomRight,
                }

                // Рассчитываем нижнюю центральную точку
                cornerStripeBottom.pointBottomCenterLeft = {
                    x: cornerStripeBottom.pointBottomLeft.x + (cornerStripeBottom.pointBottomRight.x - cornerStripeBottom.pointBottomLeft.x) / 2,
                    y: cornerStripeBottom.pointBottomLeft.y - (cornerStripeBottom.pointBottomLeft.y - cornerStripeBottom.pointBottomRight.y) / 2,
                }
                cornerStripeBottom.pointBottomCenterRight = {
                    x: cornerStripeBottom.pointBottomLeft.x + (cornerStripeBottom.pointBottomRight.x - cornerStripeBottom.pointBottomLeft.x) / 2,
                    y: cornerStripeBottom.pointBottomLeft.y - (cornerStripeBottom.pointBottomLeft.y - cornerStripeBottom.pointBottomRight.y) / 2,
                }

                cornerStripeBottom.pointTop = stripeTop.pointTop;
                cornerStripeBottom.pointBottom = stripeBottom.pointBottom;
            }

            if (cornerStripeTop) {
                const stripeLeft = _stripes.horizontal.find(stripe => stripe.name === `hor_${i}_${j}`);
                const stripeRight = _stripes.horizontal.find(stripe => stripe.name === `hor_${i}_${j + 1}`);

                const stripeTop = _stripes.vertical.find(stripe => stripe.name === `ver_${j}_${i}`);
                const stripeBottom = _stripes.vertical.find(stripe => stripe.name === `ver_${j}_${i + 1}`);

                const verticalLength = value;
                const horizontalLength = stripeLeft.length;

                // Рассчитываем верхнюю левую точку
                const angelTopLeft = Math.atan(verticalLength / horizontalLength);
                const deltaTopLeft = (cornerStripeTop.width / 2) / Math.sin(angelTopLeft);
                cornerStripeTop.pointTopLeft = {
                    x: stripeLeft.pointLeft.x + deltaTopLeft,
                    y: stripeLeft.pointLeft.y,
                }

                // Рассчитываем верхнюю правую точку
                const angelTopRight = Math.atan(horizontalLength / verticalLength);
                const deltaTopRight = (cornerStripeTop.width / 2) / Math.sin(angelTopRight);
                cornerStripeTop.pointTopRight = {
                    x: stripeBottom.pointBottom.x,
                    y: stripeBottom.pointBottom.y - deltaTopRight,
                }

                // Рассчитываем верхнюю центральную точку
                cornerStripeTop.pointTopCenterLeft = {
                    x: cornerStripeTop.pointTopLeft.x + (cornerStripeTop.pointTopRight.x - cornerStripeTop.pointTopLeft.x) / 2,
                    y: cornerStripeTop.pointTopLeft.y + (cornerStripeTop.pointTopRight.y - cornerStripeTop.pointTopLeft.y) / 2,
                }
                cornerStripeTop.pointTopCenterRight = {
                    x: cornerStripeTop.pointTopLeft.x + (cornerStripeTop.pointTopRight.x - cornerStripeTop.pointTopLeft.x) / 2,
                    y: cornerStripeTop.pointTopLeft.y + (cornerStripeTop.pointTopRight.y - cornerStripeTop.pointTopLeft.y) / 2,
                }

                // Рассчитываем нижнюю левую точку
                const angelBottomLeft = Math.atan(horizontalLength / verticalLength);
                const deltaBottomLeft = (cornerStripeTop.width / 2) / Math.sin(angelBottomLeft);
                cornerStripeTop.pointBottomLeft = {
                    x: stripeTop.pointTop.x,
                    y: stripeTop.pointTop.y + deltaBottomLeft,
                }

                // Рассчитываем верхнюю правую точку
                const angelBottomRight = Math.atan(verticalLength / horizontalLength);
                const deltaBottomRight = (cornerStripeTop.width / 2) / Math.sin(angelBottomRight);
                cornerStripeTop.pointBottomRight = {
                    x: stripeRight.pointRight.x - deltaBottomRight,
                    y: stripeRight.pointRight.y,
                }

                // Рассчитываем нижнюю центральную точку
                cornerStripeTop.pointBottomCenterLeft = {
                    x: cornerStripeTop.pointBottomLeft.x + (cornerStripeTop.pointBottomRight.x - cornerStripeTop.pointBottomLeft.x) / 2,
                    y: cornerStripeTop.pointBottomLeft.y + (cornerStripeTop.pointBottomRight.y - cornerStripeTop.pointBottomLeft.y) / 2,
                }
                cornerStripeTop.pointBottomCenterRight = {
                    x: cornerStripeTop.pointBottomLeft.x + (cornerStripeTop.pointBottomRight.x - cornerStripeTop.pointBottomLeft.x) / 2,
                    y: cornerStripeTop.pointBottomLeft.y + (cornerStripeTop.pointBottomRight.y - cornerStripeTop.pointBottomLeft.y) / 2,
                }

                cornerStripeTop.pointTop = stripeTop.pointTop;
                cornerStripeTop.pointBottom = stripeBottom.pointBottom;
            }
        }

        let _erkers = JSON.parse(JSON.stringify(erkers));
        //растягиваем эркеры
        const rightErkers = [];
        _erkers.right.forEach(erker => {
            const newErker = resizeErker(erker, _stripes, deltaY);
            if (newErker) rightErkers.push(newErker);
        });
        const leftErkers = [];
        _erkers.left.forEach(erker => {
            const newErker = resizeErker(erker, _stripes, deltaY);
            if (newErker) leftErkers.push(newErker);
        });
        const bottomErkers = [];
        _erkers.bottom.forEach(erker => {
            const newErker = resizeErker(erker, _stripes, deltaY);
            if (newErker) bottomErkers.push(newErker);
        });
        _erkers = {
            left: leftErkers,
            right: rightErkers,
            top: _erkers.top,
            bottom: bottomErkers,
        };
        
        return {
            erkers: _erkers,
            stripes: _stripes,
        }
    }
}

export const calculatePositionPile = (_position, scale, stripes, piles, erkers, axes, countX, countY, _MIN_STEP_PILE) => {
    let position = _position;
    position = {
        x: (position.x - scale.offsetX) / scale.value,
        y: (position.y - scale.offsetY) / scale.value,
        enable: false,
        axeX: -1,
        axeY: -1,
        leftDelta: 0,
        rightDelta: 0,
        topDelta: 0,
        bottomDelta: 0,
    };
    let axesIndex = {
        leftIndex: -1,
        rightIndex: -1,
        topIndex: -1,
        bottomIndex: -1,
        
    };
    axes.horizontal.filter(axe => axe.index > -1).forEach(axe => {
        if (position.y > axe.y) {
            axesIndex.topIndex = axe.index;
            position.topDelta = position.y - axe.y;
        }
    });
    axes.horizontal.filter(axe => axe.index > -1).reverse().forEach(axe => {
        if (position.y < axe.y) {
            axesIndex.bottomIndex = axe.index;
            position.bottomDelta = axe.y - position.y;
        }
    });
    axes.vertical.filter(axe => axe.index > -1).forEach(axe => {
        if (position.x > axe.x) {
            axesIndex.leftIndex = axe.index;
            position.leftDelta = position.x - axe.x;
        }
    });
    axes.vertical.filter(axe => axe.index > -1).reverse().forEach(axe => {
        if (position.x < axe.x) {
            axesIndex.rightIndex = axe.index;
            position.rightDelta = axe.x - position.x;
        }
    });
    const leftStripe = stripes.vertical.find(stripe => stripe.name === `ver_${axesIndex.topIndex === - 1 ? 0 : axesIndex.topIndex}_${axesIndex.leftIndex === - 1 ? 0 : axesIndex.leftIndex}`);
    const rightStripe = stripes.vertical.find(stripe => stripe.name === `ver_${axesIndex.topIndex === - 1 ? 0 : axesIndex.topIndex}_${axesIndex.rightIndex === - 1 ? countY - 1 : axesIndex.rightIndex}`);
    const topStripe = stripes.horizontal.find(stripe => stripe.name === `hor_${axesIndex.leftIndex === - 1 ? 0 : axesIndex.leftIndex}_${axesIndex.topIndex === - 1 ? 0 : axesIndex.topIndex}`);
    const bottomStripe = stripes.horizontal.find(stripe => stripe.name === `hor_${axesIndex.leftIndex === - 1 ? 0 : axesIndex.leftIndex}_${axesIndex.bottomIndex === - 1 ? countX - 1 : axesIndex.bottomIndex}`);
    const downStripe = leftStripe && topStripe ? stripes.corner.find(stripe => stripe.name.startsWith(`${leftStripe.name}:${topStripe.name}`)) : null;
    const upStripe = leftStripe && bottomStripe ? stripes.corner.find(stripe => stripe.name.startsWith(`${leftStripe.name}:${bottomStripe.name}`)) : null;
    if (leftStripe && position.x > leftStripe.pointLeftTop.x && position.x < leftStripe.pointRightTop.x) {
        position.x = leftStripe.pointTop.x;
        position.enable = true;
        position.axeX = -1;
        position.axeY = axesIndex.leftIndex;
    }
    if (rightStripe && position.x > rightStripe.pointLeftTop.x && position.x < rightStripe.pointRightTop.x) {
        position.x = rightStripe.pointTop.x;
        position.enable = true;
        position.axeX = -1;
        position.axeY = axesIndex.rightIndex;
    }
    if (topStripe && position.y > topStripe.pointTopLeft.y && position.y < topStripe.pointBottomLeft.y) {
        position.y = topStripe.pointLeft.y;
        position.enable = true;
        position.axeX = axesIndex.topIndex;
        position.axeY = -1;
    }
    if (bottomStripe && position.y > bottomStripe.pointTopLeft.y && position.y < bottomStripe.pointBottomLeft.y) {
        position.y = bottomStripe.pointLeft.y;
        position.enable = true;
        position.axeX = axesIndex.bottomIndex;
        position.axeY = -1;
    }
    if (downStripe) {
        const pointRight = downStripe.pointTopLeft.x + (downStripe.pointTopRight.x - downStripe.pointTopLeft.x) * ((position.y - downStripe.pointTopLeft.y) / (downStripe.pointTopRight.y - downStripe.pointTopLeft.y));
        const pointLeft = downStripe.pointBottomLeft.x + (downStripe.pointBottomRight.x - downStripe.pointBottomLeft.x) * ((position.y - downStripe.pointBottomLeft.y) / (downStripe.pointBottomRight.y - downStripe.pointBottomLeft.y));
        const pointCenter = downStripe.pointTop.x + (downStripe.pointBottom.x - downStripe.pointTop.x) * ((position.y - downStripe.pointTop.y) / (downStripe.pointBottom.y - downStripe.pointTop.y));
        if (position.x > pointLeft && position.x < pointRight) {
            position.x = pointCenter;
            position.enable = true;
            position.axeX = -1;
            position.axeY = -1;
        }
    }
    if (upStripe) {
        const pointLeft = upStripe.pointTopLeft.x + (upStripe.pointTopRight.x - upStripe.pointTopLeft.x) * ((position.y - upStripe.pointTopLeft.y) / (upStripe.pointTopRight.y - upStripe.pointTopLeft.y));
        const pointRight = upStripe.pointBottomLeft.x + (upStripe.pointBottomRight.x - upStripe.pointBottomLeft.x) * ((position.y - upStripe.pointBottomLeft.y) / (upStripe.pointBottomRight.y - upStripe.pointBottomLeft.y));
        const pointCenter = upStripe.pointTop.x + (upStripe.pointBottom.x - upStripe.pointTop.x) * ((position.y - upStripe.pointTop.y) / (upStripe.pointBottom.y - upStripe.pointTop.y));
        if (position.x > pointLeft && position.x < pointRight) {
            position.x = pointCenter;
            position.enable = true;
            position.axeX = -1;
            position.axeY = -1;
        }
    }
    if (piles.filter(pile => Math.pow((pile.x - position.x), 2) + Math.pow((pile.y - position.y), 2) <= Math.pow(_MIN_STEP_PILE, 2)).length > 0) {
        position.enable = false;
    }

    return position;
}

export const calculatePositionBake = (_position, bakeSize, scale, bakes, stripes, erkers, axes, countX, countY) => {
    let position = _position;
    position = {
        x: (position.x - scale.offsetX) / scale.value,
        y: (position.y - scale.offsetY) / scale.value,
        enable: true,
        top: {
            index: -1,
            offset: 0,
        },
        bottom: {
            index: -1,
            offset: 0,
        },
        left: {
            index: -1,
            offset: 0,
        },
        right: {
            index: -1,
            offset: 0,
        },
    };
    axes.horizontal.filter(axe => axe.index > -1).forEach(axe => {
        if (position.y > axe.y) {
            position.top.index = axe.index;
            position.top.offset = position.y - axe.y - (bakeSize.height / 2);
        }
    });
    axes.horizontal.filter(axe => axe.index > -1).reverse().forEach(axe => {
        if (position.y < axe.y) {
            position.bottom.index = axe.index;
            position.bottom.offset = axe.y - position.y - (bakeSize.height / 2);
        }
    });
    axes.vertical.filter(axe => axe.index > -1).forEach(axe => {
        if (position.x > axe.x) {
            position.left.index = axe.index;
            position.left.offset = position.x - axe.x - (bakeSize.width / 2);
        }
    });
    axes.vertical.filter(axe => axe.index > -1).reverse().forEach(axe => {
        if (position.x < axe.x) {
            position.right.index = axe.index;
            position.right.offset = axe.x - position.x - (bakeSize.width / 2);
        }
    });
    const leftStripe = stripes.vertical.find(stripe => stripe.name === `ver_${position.top.index === - 1 ? 0 : position.top.index}_${position.left.index}`);
    const rightStripe = stripes.vertical.find(stripe => stripe.name === `ver_${position.top.index === - 1 ? 0 : position.top.index}_${position.right.index}`);
    const topStripe = stripes.horizontal.find(stripe => stripe.name === `hor_${position.left.index === - 1 ? 0 : position.left.index}_${position.top.index}`);
    const bottomStripe = stripes.horizontal.find(stripe => stripe.name === `hor_${position.left.index === - 1 ? 0 : position.left.index}_${position.bottom.index}`);
    const downStripe = leftStripe && topStripe ? stripes.corner.find(stripe => stripe.name.startsWith(`${leftStripe.name}:${topStripe.name}`)) : null;
    const upStripe = leftStripe && bottomStripe ? stripes.corner.find(stripe => stripe.name.startsWith(`${leftStripe.name}:${bottomStripe.name}`)) : null;
    if (leftStripe && topStripe && position.top.offset < topStripe.bottomWidth) {
        position.y = topStripe.pointLeft.y + topStripe.bottomWidth + bakeSize.height / 2;
        position.top.offset = topStripe.bottomWidth;
        position.bottom.offset = leftStripe.length - position.top.offset - bakeSize.height;
    }
    if (leftStripe && bottomStripe && position.bottom.offset < bottomStripe.topWidth) {
        position.y = bottomStripe.pointLeft.y - bottomStripe.topWidth - bakeSize.height / 2;
        position.bottom.offset = bottomStripe.topWidth;
        position.top.offset = leftStripe.length - position.bottom.offset - bakeSize.height;
    }
    if (topStripe && leftStripe && position.left.offset < leftStripe.rightWidth) {
        position.x = leftStripe.pointTop.x + leftStripe.rightWidth + bakeSize.width / 2;
        position.left.offset = leftStripe.rightWidth;
        position.right.offset = topStripe.length - position.left.offset - bakeSize.width;
    }
    if (topStripe && rightStripe && position.right.offset < rightStripe.leftWidth) {
        position.x = rightStripe.pointTop.x - rightStripe.leftWidth - bakeSize.width / 2;
        position.right.offset = rightStripe.leftWidth;
        position.left.offset = topStripe.length - position.right.offset - bakeSize.width;
    }
    if (!bottomStripe && topStripe) {
        position.y = topStripe.pointLeft.y + topStripe.bottomWidth + bakeSize.height / 2;
    }
    if (!topStripe && bottomStripe) {
        position.y = bottomStripe.pointLeft.y - bottomStripe.topWidth - bakeSize.height / 2;
    }
    if (!rightStripe && leftStripe) {
        position.x = leftStripe.pointTop.x + leftStripe.rightWidth + bakeSize.width / 2; 
    }
    if (!leftStripe && rightStripe) {
        position.x = rightStripe.pointTop.x - rightStripe.leftWidth - bakeSize.width / 2; 
    }
    if (bakes.filter(bake => Math.abs(position.x - bake.x) < bakeSize.width / 2 + bake.width / 2 && Math.abs(position.y - bake.y) < bakeSize.height / 2 + bake.height / 2).length > 0) {
        position.enable = false;
    }
    if (position.top.index < 0 || position.bottom.index < 0 || position.left.index < 0 || position.right.index < 0) {
        position.top.offset = 0;
        position.bottom.offset = 0;
        position.left.offset = 0;
        position.right.offset = 0;
        position.enable = false;
    }

    return position;
}

export const calculatePositionStaticBake = (_position, bakeSize, scale, bakes, stripes, erkers, axes, countX, countY) => {
    let position = _position;
    position = {
        x: (position.x - scale.offsetX) / scale.value,
        y: (position.y - scale.offsetY) / scale.value,
        enable: false,
        top: {
            index: -1,
            offset: 0,
        },
        bottom: {
            index: -1,
            offset: 0,
        },
        left: {
            index: -1,
            offset: 0,
        },
        right: {
            index: -1,
            offset: 0,
        },
    };
    axes.horizontal.filter(axe => axe.index > -1).forEach(axe => {
        if (position.y > axe.y) {
            position.top.index = axe.index;
            position.top.offset = position.y - axe.y - (bakeSize.height / 2);
        }
    });
    axes.horizontal.filter(axe => axe.index > -1).reverse().forEach(axe => {
        if (position.y < axe.y) {
            position.bottom.index = axe.index;
            position.bottom.offset = axe.y - position.y - (bakeSize.height / 2);
        }
    });
    axes.vertical.filter(axe => axe.index > -1).forEach(axe => {
        if (position.x > axe.x) {
            position.left.index = axe.index;
            position.left.offset = position.x - axe.x - (bakeSize.width / 2);
        }
    });
    axes.vertical.filter(axe => axe.index > -1).reverse().forEach(axe => {
        if (position.x < axe.x) {
            position.right.index = axe.index;
            position.right.offset = axe.x - position.x - (bakeSize.width / 2);
        }
    });
    const leftStripe = stripes.vertical.find(stripe => stripe.name === `ver_${position.top.index === - 1 ? 0 : position.top.index}_${position.left.index}`);
    const rightStripe = stripes.vertical.find(stripe => stripe.name === `ver_${position.top.index === - 1 ? 0 : position.top.index}_${position.right.index}`);
    const topStripe = stripes.horizontal.find(stripe => stripe.name === `hor_${position.left.index === - 1 ? 0 : position.left.index}_${position.top.index}`);
    const bottomStripe = stripes.horizontal.find(stripe => stripe.name === `hor_${position.left.index === - 1 ? 0 : position.left.index}_${position.bottom.index}`);
    const downStripe = leftStripe && topStripe ? stripes.corner.find(stripe => stripe.name.startsWith(`${leftStripe.name}:${topStripe.name}`)) : null;
    const upStripe = leftStripe && bottomStripe ? stripes.corner.find(stripe => stripe.name.startsWith(`${leftStripe.name}:${bottomStripe.name}`)) : null;
    if (leftStripe && topStripe && position.top.offset < topStripe.bottomWidth) {
        position.y = topStripe.pointTopLeft.y + bakeSize.height / 2;
        position.top.offset = -topStripe.topWidth;
        position.bottom.offset = leftStripe.length - position.top.offset - bakeSize.height;
        position.enable = true;
    }
    if (leftStripe && bottomStripe && position.bottom.offset < bottomStripe.topWidth) {
        position.y = bottomStripe.pointBottomLeft.y - bakeSize.height / 2;
        position.bottom.offset = -bottomStripe.bottomWidth;
        position.top.offset = leftStripe.length - position.bottom.offset - bakeSize.height;
        position.enable = true;
    }
    if (topStripe && leftStripe && position.left.offset < leftStripe.rightWidth) {
        position.x = leftStripe.pointLeftTop.x + bakeSize.width / 2;
        position.left.offset = -leftStripe.leftWidth;
        position.right.offset = topStripe.length - position.left.offset - bakeSize.width;
        position.enable = true;
    }
    if (topStripe && rightStripe && position.right.offset < rightStripe.leftWidth) {
        position.x = rightStripe.pointRightTop.x - bakeSize.width / 2;
        position.right.offset = -rightStripe.rightWidth;
        position.left.offset = topStripe.length - position.right.offset - bakeSize.width;
        position.enable = true;
    }
    if (!bottomStripe && topStripe) {
        position.y = topStripe.pointLeft.y + topStripe.bottomWidth + bakeSize.height / 2;
    }
    if (!topStripe && bottomStripe) {
        position.y = bottomStripe.pointLeft.y - bottomStripe.topWidth - bakeSize.height / 2;
    }
    if (!rightStripe && leftStripe) {
        position.x = leftStripe.pointTop.x + leftStripe.rightWidth + bakeSize.width / 2; 
    }
    if (!leftStripe && rightStripe) {
        position.x = rightStripe.pointTop.x - rightStripe.leftWidth - bakeSize.width / 2; 
    }
    if (bakes.filter(bake => Math.abs(position.x - bake.x) < bakeSize.width / 2 + bake.width / 2 && Math.abs(position.y - bake.y) < bakeSize.height / 2 + bake.height / 2).length > 0) {
        position.enable = false;
    }
    if (position.top.index < 0 || position.bottom.index < 0 || position.left.index < 0 || position.right.index < 0) {
        position.top.offset = 0;
        position.bottom.offset = 0;
        position.left.offset = 0;
        position.right.offset = 0;
        position.enable = false;
    }

    return position;
}

export const deleteAxe = (axes, axeIndex, axeType, stripes, deletedStripes, erkers, bakes, countX, countY) => {
    let _stripes = JSON.parse(JSON.stringify(stripes));
    let _erkers = JSON.parse(JSON.stringify(erkers));
    let _bakes = JSON.parse(JSON.stringify(bakes));
    let _axes = JSON.parse(JSON.stringify(axes));
    let stripesH = [];
    let stripesV = [];
    let stripesC = [];
    
    if (axeType === "horizontal" && countX > 2) {
        if (axeIndex === 0) {
            const deltaLength = axes.horizontal.find(axe => axe.index === 1).y - axes.horizontal.find(axe => axe.index === 0).y;
            _stripes.horizontal = _stripes.horizontal.filter(stripe => stripe.name.split('_')[2] !== '0').map(stripe => {
                const indexes = stripe.name.split('_');
                const newIndex = Number(indexes[2]) - 1;
                stripe.name = `${indexes[0]}_${indexes[1]}_${newIndex}`;
                stripe = changeObjectCoord(stripe, 'y', -deltaLength);
                return stripe;
            });
            _stripes.vertical = _stripes.vertical.filter(stripe => stripe.name.split('_')[1] !== '0').map(stripe => {
                const indexes = stripe.name.split('_');
                const newIndex = Number(indexes[1]) - 1;
                stripe.name = `${indexes[0]}_${newIndex}_${indexes[2]}`;
                stripe = changeObjectCoord(stripe, 'y', -deltaLength);
                return stripe;
            });
            _stripes.corner = _stripes.corner.filter(stripe => stripe.name.split(':')[0].split('_')[1] !== (axeIndex).toString()).map(stripe => {
                const strs = stripe.name.split(':');
                let ver1 = strs[0].split('_'), ver2 = strs[2].split('_'), hor1 = strs[1].split('_'), hor2 = strs[3].split('_');
                stripe.name = `${ver1[0]}_${Number(ver1[1]) - 1}_${ver1[2]}:${hor1[0]}_${hor1[1]}_${Number(hor1[2]) - 1}:${ver2[0]}_${Number(ver2[1]) - 1}_${ver2[2]}:${hor2[0]}_${hor2[1]}_${Number(hor2[2]) - 1}`;
                stripe = changeObjectCoord(stripe, 'y', -deltaLength);
                return stripe;
            });
            _bakes = _bakes.filter(bake => bake.top.index !== 0).map(bake => {
                bake.top.index -= 1;
                bake.bottom.index -= 1;
                bake = changeObjectCoord(bake, 'y', -deltaLength);
                return bake;
            });
            _erkers.left = _erkers.left.filter(erker => erker.axeStart !== 0).map(erker => {
                erker.axeEnd -= 1;
                erker.axeStart -= 1;
                erker.name = `${erker.axeStart}_${erker.axeEnd}`;
                erker.startHorizontal = `hor_0_${erker.axeStart}`;
                erker.endHorizontal = `hor_0_${erker.axeEnd}`;
                erker.startVertical = `ver_${erker.axeStart}_0`;
                erker.endVertical = `ver_${erker.axeEnd - 1}_0`;
                erker = changeObjectCoord(erker, 'y', -deltaLength);
                erker = changeObjectCoord(erker, 'y1', -deltaLength);
                erker = changeObjectCoord(erker, 'y2', -deltaLength);
                erker = changeObjectCoord(erker, 'y3', -deltaLength);
                erker = changeObjectCoord(erker, 'y4', -deltaLength);
                return erker;
            });
            _erkers.right = _erkers.right.filter(erker => erker.axeStart !== 0).map(erker => {
                erker.axeEnd -= 1;
                erker.axeStart -= 1;
                erker.name = `${erker.axeStart}_${erker.axeEnd}`;
                erker.startHorizontal = `hor_${countY - 2}_${erker.axeStart}`;
                erker.endHorizontal = `hor_${countY - 2}_${erker.axeEnd}`;
                erker.startVertical = `ver_${erker.axeStart}_${countY - 1}`;
                erker.endVertical = `ver_${erker.axeEnd - 1}_${countY - 1}`;
                erker = changeObjectCoord(erker, 'y', -deltaLength);
                erker = changeObjectCoord(erker, 'y1', -deltaLength);
                erker = changeObjectCoord(erker, 'y2', -deltaLength);
                erker = changeObjectCoord(erker, 'y3', -deltaLength);
                erker = changeObjectCoord(erker, 'y4', -deltaLength);
                return erker;
            });
            _erkers.bottom = _erkers.bottom.map(erker => {
                erker.startHorizontal = `hor_${0}_${countX - 2}`;
                erker.endHorizontal = `hor_${countY - 2}_${countX - 2}`;
                erker.startVertical = `ver_${countX - 3}_${0}`;
                erker.endVertical = `ver_${countX - 3}_${countY - 1}`;
                erker = changeObjectCoord(erker, 'y', -deltaLength);
                erker = changeObjectCoord(erker, 'y1', -deltaLength);
                erker = changeObjectCoord(erker, 'y2', -deltaLength);
                erker = changeObjectCoord(erker, 'y3', -deltaLength);
                erker = changeObjectCoord(erker, 'y4', -deltaLength);
                return erker;
            });
        } else if (axeIndex === countX - 1) {
            _stripes.horizontal = _stripes.horizontal.filter(stripe => stripe.name.split('_')[2] !== (countX - 1).toString());
            _stripes.vertical = _stripes.vertical.filter(stripe => stripe.name.split('_')[1] !== (countX - 2).toString());
            _stripes.corner = _stripes.corner.filter(stripe => stripe.name.split(':')[0].split('_')[1] !== (axeIndex - 1).toString());
            _bakes = _bakes.filter(bake => bake.bottom.index !== countX - 1);
            _erkers.bottom = [];
            _erkers.left = _erkers.left.filter(erker => erker.axeEnd !== countX - 1);
            _erkers.right = _erkers.right.filter(erker => erker.axeEnd !== countX - 1);
        } else {
            const deltaLength = axes.horizontal.find(axe => axe.index === axeIndex + 1).y - axes.horizontal.find(axe => axe.index === axeIndex).y;
            _stripes.horizontal = _stripes.horizontal.filter(stripe => stripe.name.split('_')[2] !== axeIndex.toString());
            _stripes.horizontal.filter(stripe => Number(stripe.name.split('_')[2]) > axeIndex).map(stripe => {
                const indexes = stripe.name.split('_');
                const newIndex = Number(indexes[2]) - 1;
                stripe.name = `${indexes[0]}_${indexes[1]}_${newIndex}`;
                return stripe;
            });
            _stripes.vertical = _stripes.vertical.filter(stripe => stripe.name.split('_')[1] !== axeIndex.toString());
            _stripes.vertical.filter(stripe => Number(stripe.name.split('_')[1]) > axeIndex).map(stripe => {
                const indexes = stripe.name.split('_');
                const newIndex = Number(indexes[1]) - 1;
                stripe.name = `${indexes[0]}_${newIndex}_${indexes[2]}`;
                return stripe;
            });
            _stripes.vertical.filter(stripe => stripe.name.split('_')[1] === (axeIndex - 1).toString()).map(stripe => {
                stripe.pointBottom.y = stripe.pointBottom.y + deltaLength;
                stripe.length = stripe.length + deltaLength;
            });
            _stripes.corner = _stripes.corner.filter(stripe => stripe.name.split(':')[0].split('_')[1] !== (axeIndex).toString());
            _stripes.corner = _stripes.corner.filter(stripe => stripe.name.split(':')[0].split('_')[1] !== (axeIndex - 1).toString());
            _stripes.corner = _stripes.corner.filter(stripe => Number(stripe.name.split(':')[0].split('_')[1]) > axeIndex).map(stripe => {
                const strs = stripe.name.split(':');
                let ver1 = strs[0].split('_'), ver2 = strs[2].split('_'), hor1 = strs[1].split('_'), hor2 = strs[3].split('_');
                stripe.name = `${ver1[0]}_${Number(ver1[1] - 1)}_${ver1[2]}:${hor1[0]}_${hor1[1]}_${Number(hor1[2]) - 1}:${ver2[0]}_${Number(ver2[1] - 1)}_${ver2[2]}:${hor2[0]}_${hor2[1]}_${Number(hor2[2]) - 1}`;
                return stripe;
            });
            _bakes.filter(bake => bake.top.index >= axeIndex).map(bake => {
                bake.top.index -= 1;
                bake.bottom.index -= 1;
            });
            _erkers.left = _erkers.left.filter(erker => erker.axeStart !== axeIndex && erker.axeEnd !== axeIndex);
            _erkers.left = _erkers.left.map(erker => {
                if (erker.axeStart > axeIndex) {
                    erker.axeEnd -= 1;
                    erker.axeStart -= 1;
                    erker.name = `${erker.axeStart}_${erker.axeEnd}`;
                    erker.startHorizontal = `hor_0_${erker.axeStart}`;
                    erker.endHorizontal = `hor_0_${erker.axeEnd}`;
                    erker.startVertical = `ver_${erker.axeStart}_0`;
                    erker.endVertical = `ver_${erker.axeEnd - 1}_0`;
                } if (erker.axeEnd > axeIndex) {
                    erker.axeEnd -= 1;
                    erker.name = `${erker.axeStart}_${erker.axeEnd}`;
                    erker.endHorizontal = `hor_0_${erker.axeEnd}`;
                    erker.endVertical = `ver_${erker.axeEnd - 1}_0`;
                }
                return erker;
            });
            _erkers.right = _erkers.right.filter(erker => erker.axeStart !== axeIndex && erker.axeEnd !== axeIndex);
            _erkers.right = _erkers.right.map(erker => {
                if (erker.axeStart > axeIndex) {
                    erker.axeEnd -= 1;
                    erker.axeStart -= 1;
                    erker.name = `${erker.axeStart}_${erker.axeEnd}`;
                    erker.startHorizontal = `hor_${countY - 2}_${erker.axeStart}`;
                    erker.endHorizontal = `hor_${countY - 2}_${erker.axeEnd}`;
                    erker.startVertical = `ver_${erker.axeStart}_${countY - 1}`;
                    erker.endVertical = `ver_${erker.axeEnd - 1}_${countY - 1}`;
                } if (erker.axeEnd > axeIndex) {
                    erker.axeEnd -= 1;
                    erker.name = `${erker.axeStart}_${erker.axeEnd}`;
                    erker.endHorizontal = `hor_${countY - 2}_${erker.axeEnd}`;
                    erker.endVertical = `ver_${erker.axeEnd - 1}_${countY - 1}`;
                }
                return erker;
            });
            _erkers.bottom = _erkers.bottom.map(erker => {
                erker.startHorizontal = `hor_${0}_${countX - 2}`;
                erker.endHorizontal = `hor_${countY - 2}_${countX - 2}`;
                erker.startVertical = `ver_${countX - 3}_${0}`;
                erker.endVertical = `ver_${countX - 3}_${countY - 1}`;
                return erker;
            });
        }
        countX = countX - 1;
    }

    if (axeType === "vertical" && countY > 2) {
        if (axeIndex === 0) {
            const deltaLength = axes.vertical.find(axe => axe.index === 1).x - axes.vertical.find(axe => axe.index === 0).x;
            _stripes.horizontal = _stripes.horizontal.filter(stripe => stripe.name.split('_')[1] !== '0').map(stripe => {
                const indexes = stripe.name.split('_');
                const newIndex = Number(indexes[1]) - 1;
                stripe.name = `${indexes[0]}_${newIndex}_${indexes[2]}`;
                stripe = changeObjectCoord(stripe, 'x', -deltaLength);
                return stripe;
            });
            _stripes.vertical = _stripes.vertical.filter(stripe => stripe.name.split('_')[2] !== '0').map(stripe => {
                const indexes = stripe.name.split('_');
                const newIndex = Number(indexes[2]) - 1;
                stripe.name = `${indexes[0]}_${indexes[1]}_${newIndex}`;
                stripe = changeObjectCoord(stripe, 'x', -deltaLength);
                return stripe;
            });
            _stripes.corner = _stripes.corner.filter(stripe => stripe.name.split(':')[1].split('_')[1] !== (axeIndex).toString()).map(stripe => {
                const strs = stripe.name.split(':');
                let ver1 = strs[0].split('_'), ver2 = strs[2].split('_'), hor1 = strs[1].split('_'), hor2 = strs[3].split('_');
                stripe.name = `${ver1[0]}_${ver1[1]}_${Number(ver1[2]) - 1}:${hor1[0]}_${Number(hor1[1]) - 1}_${hor1[2]}:${ver2[0]}_${ver2[1]}_${Number(ver2[2] - 1)}:${hor2[0]}_${Number(hor2[1] - 1)}_${hor2[2]}`;
                stripe = changeObjectCoord(stripe, 'x', -deltaLength);
                return stripe;
            });
            _bakes = _bakes.filter(bake => bake.left.index !== 0).map(bake => {
                bake.left.index -= 1;
                bake.right.index -= 1;
                bake = changeObjectCoord(bake, 'x', -deltaLength);
                return bake;
            });
            _erkers.top = _erkers.top.filter(erker => erker.axeStart !== 0).map(erker => {
                erker.axeEnd -= 1;
                erker.axeStart -= 1;
                erker.name = `${erker.axeStart}_${erker.axeEnd}`;
                erker.startHorizontal = `hor_${erker.axeStart}_0`;
                erker.endHorizontal = `hor_${erker.axeEnd - 1}_0`;
                erker.startVertical = `ver_0_${erker.axeStart}`;
                erker.endVertical = `ver_0_${erker.axeEnd}`;
                erker = changeObjectCoord(erker, 'x', -deltaLength);
                erker = changeObjectCoord(erker, 'x1', -deltaLength);
                erker = changeObjectCoord(erker, 'x2', -deltaLength);
                erker = changeObjectCoord(erker, 'x3', -deltaLength);
                erker = changeObjectCoord(erker, 'x4', -deltaLength);
                return erker;
            });
            _erkers.bottom = _erkers.bottom.filter(erker => erker.axeStart !== 0).map(erker => {
                erker.axeEnd -= 1;
                erker.axeStart -= 1;
                erker.name = `${erker.axeStart}_${erker.axeEnd}`;
                erker.startHorizontal = `hor_${erker.axeStart}_${countX - 1}`;
                erker.endHorizontal = `hor_${erker.axeEnd - 1}_${countX - 1}`;
                erker.startVertical = `ver_${countX - 2}_${erker.axeStart}`;
                erker.endVertical = `ver_${countX - 2}_${erker.axeEnd}`;
                erker = changeObjectCoord(erker, 'x', -deltaLength);
                erker = changeObjectCoord(erker, 'x1', -deltaLength);
                erker = changeObjectCoord(erker, 'x2', -deltaLength);
                erker = changeObjectCoord(erker, 'x3', -deltaLength);
                erker = changeObjectCoord(erker, 'x4', -deltaLength);
                return erker;
            });
            _erkers.right = _erkers.right.map(erker => {
                erker.startHorizontal = `hor_${countY - 3}_${0}`;
                erker.endHorizontal = `hor_${countY - 3}_${countX - 1}`;
                erker.startVertical = `ver_${0}_${countY - 2}`;
                erker.endVertical = `ver_${countX - 2}_${countY - 2}`;
                erker = changeObjectCoord(erker, 'x', -deltaLength);
                erker = changeObjectCoord(erker, 'x1', -deltaLength);
                erker = changeObjectCoord(erker, 'x2', -deltaLength);
                erker = changeObjectCoord(erker, 'x3', -deltaLength);
                erker = changeObjectCoord(erker, 'x4', -deltaLength);
                return erker;
            });
        } else if (axeIndex === countY - 1) {
            _stripes.horizontal = _stripes.horizontal.filter(stripe => stripe.name.split('_')[1] !== (countY - 2).toString());
            _stripes.vertical = _stripes.vertical.filter(stripe => stripe.name.split('_')[2] !== (countY - 1).toString());
            _stripes.corner = _stripes.corner.filter(stripe => stripe.name.split(':')[1].split('_')[1] !== (axeIndex - 1).toString());
            _bakes = _bakes.filter(bake => bake.right.index !== countY - 1);
            _erkers.right = [];
            _erkers.top = _erkers.top.filter(erker => erker.axeEnd !== countY - 1);
            _erkers.bottom = _erkers.bottom.filter(erker => erker.axeEnd !== countY - 1);
        } else {
            const deltaLength = axes.vertical.find(axe => axe.index === axeIndex + 1).x - axes.vertical.find(axe => axe.index === axeIndex).x;
            _stripes.horizontal = _stripes.horizontal.filter(stripe => stripe.name.split('_')[1] !== axeIndex.toString());
            _stripes.horizontal.filter(stripe => Number(stripe.name.split('_')[1]) > axeIndex).map(stripe => {
                const indexes = stripe.name.split('_');
                const newIndex = Number(indexes[1]) - 1;
                stripe.name = `${indexes[0]}_${newIndex}_${indexes[2]}`;
                return stripe;
            });
            _stripes.horizontal.filter(stripe => stripe.name.split('_')[1] === (axeIndex - 1).toString()).map(stripe => {
                stripe.pointRight.x = stripe.pointRight.x + deltaLength;
                stripe.length = stripe.length + deltaLength;
            });
            _stripes.vertical = _stripes.vertical.filter(stripe => stripe.name.split('_')[2] !== axeIndex.toString());
            _stripes.vertical.filter(stripe => Number(stripe.name.split('_')[2]) > axeIndex).map(stripe => {
                const indexes = stripe.name.split('_');
                const newIndex = Number(indexes[2]) - 1;
                stripe.name = `${indexes[0]}_${indexes[1]}_${newIndex}`;
                return stripe;
            });
            _stripes.corner = _stripes.corner.filter(stripe => stripe.name.split(':')[1].split('_')[1] !== (axeIndex).toString());
            _stripes.corner = _stripes.corner.filter(stripe => stripe.name.split(':')[1].split('_')[1] !== (axeIndex - 1).toString());
            _stripes.corner = _stripes.corner.filter(stripe => Number(stripe.name.split(':')[1].split('_')[1]) > axeIndex).map(stripe => {
                const strs = stripe.name.split(':');
                let ver1 = strs[0].split('_'), ver2 = strs[2].split('_'), hor1 = strs[1].split('_'), hor2 = strs[3].split('_');
                stripe.name = `${ver1[0]}_${ver1[1]}_${Number(ver1[2]) - 1}:${hor1[0]}_${Number(hor1[1]) - 1}_${hor1[2]}:${ver2[0]}_${ver2[1]}_${Number(ver2[2] - 1)}:${hor2[0]}_${Number(hor2[1] - 1)}_${hor2[2]}`;
                return stripe;
            });
            _bakes.filter(bake => bake.left.index >= axeIndex).map(bake => {
                bake.left.index -= 1;
                bake.right.index -= 1;
            });
            _erkers.top = _erkers.top.filter(erker => erker.axeStart !== axeIndex && erker.axeEnd !== axeIndex);
            _erkers.top = _erkers.top.map(erker => {
                if (erker.axeStart > axeIndex) {
                    erker.axeEnd -= 1;
                    erker.axeStart -= 1;
                    erker.name = `${erker.axeStart}_${erker.axeEnd}`;
                    erker.startHorizontal = `hor_${erker.axeStart}_0`;
                    erker.endHorizontal = `hor_${erker.axeEnd - 1}_0`;
                    erker.startVertical = `ver_0_${erker.axeStart}`;
                    erker.endVertical = `ver_0_${erker.axeEnd}`;
                } if (erker.axeEnd > axeIndex) {
                    erker.axeEnd -= 1;
                    erker.name = `${erker.axeStart}_${erker.axeEnd}`;
                    erker.endHorizontal = `hor_${erker.axeEnd - 1}_0`;
                    erker.endVertical = `ver_0_${erker.axeEnd}`;
                }
                return erker;
            });
            _erkers.bottom = _erkers.bottom.filter(erker => erker.axeStart !== axeIndex && erker.axeEnd !== axeIndex);
            _erkers.bottom = _erkers.bottom.map(erker => {
                if (erker.axeStart > axeIndex) {
                    erker.axeEnd -= 1;
                    erker.axeStart -= 1;
                    erker.name = `${erker.axeStart}_${erker.axeEnd}`;
                    erker.startHorizontal = `hor_${erker.axeStart}_${countX - 1}`;
                    erker.endHorizontal = `hor_${erker.axeEnd - 1}_${countX - 1}`;
                    erker.startVertical = `ver_${countX - 2}_${erker.axeStart}`;
                    erker.endVertical = `ver_${countX - 2}_${erker.axeEnd}`;
                } if (erker.axeEnd > axeIndex) {
                    erker.axeEnd -= 1;
                    erker.name = `${erker.axeStart}_${erker.axeEnd}`;
                    erker.endHorizontal = `hor_${erker.axeEnd - 1}_${countX - 1}`;
                    erker.endVertical = `ver_${countX - 2}_${erker.axeEnd}`;
                }
                return erker;
            });
            _erkers.right = _erkers.right.map(erker => {
                erker.startHorizontal = `hor_${countY - 3}_${0}`;
                erker.endHorizontal = `hor_${countY - 3}_${countX - 1}`;
                erker.startVertical = `ver_${0}_${countY - 2}`;
                erker.endVertical = `ver_${countX - 2}_${countY - 2}`;
                return erker;
            });
        }
        countY = countY - 1;
    }

    const resultRecalculate = recalculateLines(_stripes, deletedStripes, _erkers, _bakes, countX, countY);

    return {
        stripes: resultRecalculate.stripes,
        erkers: resultRecalculate.erkers,
        deletedStripes: deletedStripes,
        bakes: resultRecalculate.bakes,
        countX: countX,
        countY: countY
    }
}

export const calculationAllSizes = (axes, scale, stripes, deletedStripes, erkers, bakes, piles, countX, countY, MIN_STEP_PILE, _MIN_STEP_PILE) => {
    let sizes = [];
    const _horizontalAxes = JSON.parse(JSON.stringify(axes.horizontal.sort((axeA, axeB) => { return axeA.y - axeB.y })));
    const _verticalAxes = JSON.parse(JSON.stringify(axes.vertical.sort((axeA, axeB) => { return axeA.x - axeB.x })));
    const horizontalLength = _verticalAxes[_verticalAxes.length - 1].x - _verticalAxes[0].x - axes.leftExtension;
    const verticalLength = _horizontalAxes[_horizontalAxes.length - 1].y - _horizontalAxes[0].y - axes.topExtension;

    sizes.push({
        type: 'all_axes',
        orientation: 'horizontal',
        extension: 50 / scale.value,
        size: _horizontalAxes[_horizontalAxes.length - 1].y - _horizontalAxes[0].y,
        points: {
            one: {
                x: horizontalLength,
                y: _horizontalAxes[0].y,
            },
            two: {
                x: horizontalLength,
                y: _horizontalAxes[_horizontalAxes.length - 1].y,
            },
        }
    });

    sizes.push({
        type: 'all_axes',
        orientation: 'vertical',
        extension: 50 / scale.value,
        size: _verticalAxes[_verticalAxes.length - 1].x - _verticalAxes[0].x,
        points: {
            one: {
                x: _verticalAxes[0].x,
                y: 0 - axes.topExtension,
            },
            two: {
                x: _verticalAxes[_verticalAxes.length - 1].x,
                y: 0 - axes.topExtension,
            },
        }
    });

    //идем по горизонтальым осям
    for (let index = 0; index < _horizontalAxes.length - 1; index++) {
        sizes.push({
            type: 'axe',
            orientation: 'horizontal',
            extension: 50 / scale.value,
            size: _horizontalAxes[index + 1].y - _horizontalAxes[index].y,
            points: {
                one: {
                    x: 0 - axes.leftExtension,
                    y: _horizontalAxes[index].y,
                },
                two: {
                    x: 0 - axes.leftExtension,
                    y: _horizontalAxes[index + 1].y,
                },
            }
        });
    }

    //идем по вертикальным осям
    for (let index = 0; index < _verticalAxes.length - 1; index++) {
        sizes.push({
            type: 'axe',
            orientation: 'vertical',
            extension: 50 / scale.value,
            size: _verticalAxes[index + 1].x - _verticalAxes[index].x,
            points: {
                one: {
                    x: _verticalAxes[index].x,
                    y: verticalLength,
                },
                two: {
                    x: _verticalAxes[index + 1].x,
                    y: verticalLength,
                },
            }
        });
    }

    //идем по горизонтальным перемычкам
    stripes.horizontal.forEach(stripe => {
        if (!deletedStripes.includes(stripe.name) || stripe.savePiles) {
            const _piles = piles.filter(pile => pile.y === stripe.pointLeft.y && stripe.pointLeft.x < pile.x).sort((pileA, pileB) => { return pileA.x - pileB.x });
            const _pile = _piles[0];
            _pile && sizes.push({
                type: 'stripe',
                orientation: 'horizontal',
                extension: 30 / scale.value,
                size: _pile.x - stripe.pointLeft.x,
                points: {
                    one: {
                        x: stripe.pointLeft.x,
                        y: stripe.pointLeft.y,
                    },
                    two: {
                        x: _pile.x,
                        y: _pile.y,
                    },
                }
            });
        }
    });

    //идем по вертикальным перемычкам
    stripes.vertical.forEach(stripe => {
        if (!deletedStripes.includes(stripe.name) || stripe.savePiles) {
            const _piles = piles.filter(pile => pile.x === stripe.pointTop.x && stripe.pointTop.y < pile.y).sort((pileA, pileB) => { return pileA.y - pileB.y });
            const _pile = _piles[0];
            _pile && sizes.push({
                type: 'stripe',
                orientation: 'vertical',
                extension: 30 / scale.value,
                size: _pile.y - stripe.pointTop.y,
                points: {
                    one: {
                        x: stripe.pointTop.x,
                        y: stripe.pointTop.y,
                    },
                    two: {
                        x: _pile.x,
                        y: _pile.y,
                    },
                }
            });
        }
    });

    //идём по наклонным перемычкам
    stripes.corner.forEach(stripe => {
        if (!deletedStripes.includes(stripe.name) || stripe.savePiles) {
            const _piles = piles.filter(pile => isPointOnLineSegment(pile.x, pile.y, stripe.pointTop.x, stripe.pointTop.y, stripe.pointBottom.x, stripe.pointBottom.y)).sort((pileA, pileB) => pileA.x - pileB.x);
            const _pile = _piles[1];
            let length = stripe.direction === 'up' ?
                calculateLength(stripe.pointBottom.x, stripe.pointBottom.y, _pile.x, _pile.y) :
                -calculateLength(stripe.pointTop.x, stripe.pointTop.y, _pile.x, _pile.y);
            const angle = calculateAngle(stripe.pointTop.x, stripe.pointTop.y, stripe.pointBottom.x, stripe.pointBottom.y);
            const sizeAngel = stripe.direction === 'up' ? angle - (90 * Math.PI) / 180 : angle + (90 * Math.PI) / 180;
            const _onePoint = {
                x: stripe.direction === 'up' ? stripe.pointBottom.x : stripe.pointTop.x,
                y: stripe.direction === 'up' ? stripe.pointBottom.y : stripe.pointTop.y,
            };
            const onePoint = calculateSecondPoint(_onePoint.x, _onePoint.y, 30 / scale.value, sizeAngel);
            const _twoPoint = {
                x: _pile.x,
                y: _pile.y,
            };
            const twoPoint = calculateSecondPoint(onePoint.x, onePoint.y, length, angle);
            const textPointOne = calculateSecondPoint(_onePoint.x, _onePoint.y, 44 / scale.value, sizeAngel);
            const textLength = stripe.direction === 'up' ? length - 60 / scale.value : length + 60 / scale.value;
            const textPointTwo = calculateSecondPoint(textPointOne.x, textPointOne.y, textLength, angle);
            sizes.push({
                type: 'stripe',
                orientation: 'corner',
                extension: 30 / scale.value,
                size: Math.abs(length),
                rotation: stripe.direction === 'up' ? angle * (180 / Math.PI) : angle * (180 / Math.PI) + 180,
                points: {
                    one: {
                        x: stripe.direction === 'up' ? stripe.pointBottom.x : stripe.pointTop.x,
                        y: stripe.direction === 'up' ? stripe.pointBottom.y : stripe.pointTop.y,
                        x_: onePoint.x,
                        y_: onePoint.y,
                    },
                    two: {
                        x: _twoPoint.x,
                        y: _twoPoint.y,
                        x_: twoPoint.x,
                        y_: twoPoint.y,
                    },
                    text: {
                        x: (textPointOne.x + textPointTwo.x) / 2,
                        y: (textPointOne.y + textPointTwo.y) / 2,
                    },
                }
            });

        }
    });

    //идем по каминам
    bakes.forEach(bake => {
        const offsetX = Math.round(bake.width / 5);
        const offsetY = Math.round(bake.height / 5);
        sizes.push({
            type: 'bake',
            orientation: 'normal',
            height: bake.height,
            width: bake.width,
            sizeX: bake.left.offset > bake.right.offset ? bake.left.offset : bake.right.offset,
            sizeY: bake.top.offset > bake.bottom.offset ? bake.top.offset : bake.bottom.offset,
            points: {
                one: {
                    x1: bake.left.offset > bake.right.offset ? bake.x + bake.width / 2 : bake.x - bake.width / 2,
                    y1: bake.top.offset > bake.bottom.offset ? bake.y - bake.height / 2 + offsetY : bake.y + bake.height / 2 - offsetY,
                    x2: bake.left.offset > bake.right.offset ? bake.x - bake.width / 2 : bake.x + bake.width / 2,
                    y2: bake.top.offset > bake.bottom.offset ? bake.y - bake.height / 2 + offsetY : bake.y + bake.height / 2 - offsetY,
                    x3: bake.left.offset > bake.right.offset ? bake.x - bake.width / 2 - bake.left.offset : bake.x + bake.width / 2 + bake.right.offset,
                    y3: bake.top.offset > bake.bottom.offset ? bake.y - bake.height / 2 + offsetY : bake.y + bake.height / 2 - offsetY,
                },
                two: {
                    x1: bake.left.offset > bake.right.offset ? bake.x - bake.width / 2 + offsetX : bake.x + bake.width / 2 - offsetX,
                    y1: bake.top.offset > bake.bottom.offset ? bake.y + bake.height / 2 : bake.y - bake.height / 2,
                    x2: bake.left.offset > bake.right.offset ? bake.x - bake.width / 2 + offsetX : bake.x + bake.width / 2 - offsetX,
                    y2: bake.top.offset > bake.bottom.offset ? bake.y - bake.height / 2 : bake.y + bake.height / 2,
                    x3: bake.left.offset > bake.right.offset ? bake.x - bake.width / 2 + offsetX : bake.x + bake.width / 2 - offsetX,
                    y3: bake.top.offset > bake.bottom.offset ? bake.y - bake.height / 2 - bake.top.offset : bake.y + bake.height / 2 + bake.bottom.offset,
                },
            }
        });
    });

    //идем по еркерам
    erkers.left.filter(erker => erker.type === 'angel').forEach(erker => {
        const _pilesTop = piles.filter(pile => isPointOnLineSegment(pile.x, pile.y, erker.erkerPointTop.x, erker.erkerPointTop.y, erker.erkerPointLeftTop.x, erker.erkerPointLeftTop.y) && pile.x !== erker.erkerPointTop.x && pile.y !== erker.erkerPointTop.y).sort((pileA, pileB) => pileA.y - pileB.y);
        const _pileTop = _pilesTop[0];
        let topLength = -calculateLength(erker.erkerPointTop.x, erker.erkerPointTop.y, _pileTop.x, _pileTop.y);
        const topAngle = calculateAngle(erker.erkerPointTop.x, erker.erkerPointTop.y, _pileTop.x, _pileTop.y);
        const sizeTopAngel = topAngle - (90 * Math.PI) / 180;
        const _oneTopPoint = {
            x: erker.erkerPointTop.x,
            y: erker.erkerPointTop.y,
        };
        const oneTopPoint = calculateSecondPoint(_oneTopPoint.x, _oneTopPoint.y, 30 / scale.value, sizeTopAngel);
        const _twoTopPoint = {
            x: _pileTop.x,
            y: _pileTop.y,
        };
        const twoTopPoint = calculateSecondPoint(oneTopPoint.x, oneTopPoint.y, topLength, topAngle);
        const textTopPointOne = calculateSecondPoint(_oneTopPoint.x, _oneTopPoint.y, 44 / scale.value, sizeTopAngel);
        const textTopLength = topLength - 60 / scale.value;
        const textTopPointTwo = calculateSecondPoint(textTopPointOne.x, textTopPointOne.y, textTopLength, topAngle);
        sizes.push({
            type: 'erker',
            orientation: 'normal',
            extension: 30 / scale.value,
            size: Math.abs(topLength),
            rotation: topAngle * (180 / Math.PI),
            points: {
                one: {
                    x: _oneTopPoint.x,
                    y: _oneTopPoint.y,
                    x_: oneTopPoint.x,
                    y_: oneTopPoint.y,
                },
                two: {
                    x: _twoTopPoint.x,
                    y: _twoTopPoint.y,
                    x_: twoTopPoint.x,
                    y_: twoTopPoint.y,
                },
                text: {
                    x: (textTopPointOne.x + textTopPointTwo.x) / 2,
                    y: (textTopPointOne.y + textTopPointTwo.y) / 2,
                },
            }
        });
        const _pilesBottom = piles.filter(pile => isPointOnLineSegment(pile.x, pile.y, erker.erkerPointBottom.x, erker.erkerPointBottom.y, erker.erkerPointLeftBottom.x, erker.erkerPointLeftBottom.y) && pile.x !== erker.erkerPointBottom.x && pile.y !== erker.erkerPointBottom.y).sort((pileA, pileB) => pileB.y - pileA.y);
        const _pileBottom = _pilesBottom[0];
        let bottomLength = calculateLength(erker.erkerPointBottom.x, erker.erkerPointBottom.y, _pileBottom.x, _pileBottom.y);
        const bottomAngle = calculateAngle(erker.erkerPointBottom.x, erker.erkerPointBottom.y, _pileBottom.x, _pileBottom.y);
        const sizeBottomAngel = bottomAngle + (90 * Math.PI) / 180;
        const _oneBottomPoint = {
            x: _pileBottom.x,
            y: _pileBottom.y,
        };
        const oneBottomPoint = calculateSecondPoint(_oneBottomPoint.x, _oneBottomPoint.y, 30 / scale.value, sizeBottomAngel);
        const _twoBottomPoint = {
            x: erker.erkerPointBottom.x,
            y: erker.erkerPointBottom.y,
        };
        const twoBottomPoint = calculateSecondPoint(oneBottomPoint.x, oneBottomPoint.y, bottomLength, bottomAngle);
        const textBottomPointOne = calculateSecondPoint(_oneBottomPoint.x, _oneBottomPoint.y, 44 / scale.value, sizeBottomAngel);
        const textBottomLength = bottomLength + 60 / scale.value;
        const textBottomPointTwo = calculateSecondPoint(textBottomPointOne.x, textBottomPointOne.y, textBottomLength, bottomAngle);
        sizes.push({
            type: 'erker',
            orientation: 'normal',
            extension: 30 / scale.value,
            size: Math.abs(bottomLength),
            rotation: bottomAngle * (180 / Math.PI) + 180,
            points: {
                one: {
                    x: _oneBottomPoint.x,
                    y: _oneBottomPoint.y,
                    x_: oneBottomPoint.x,
                    y_: oneBottomPoint.y,
                },
                two: {
                    x: _twoBottomPoint.x,
                    y: _twoBottomPoint.y,
                    x_: twoBottomPoint.x,
                    y_: twoBottomPoint.y,
                },
                text: {
                    x: (textBottomPointOne.x + textBottomPointTwo.x) / 2,
                    y: (textBottomPointOne.y + textBottomPointTwo.y) / 2,
                },
            }
        });
        sizes.push({
            type: 'erker',
            orientation: 'normal',
            extension: 30 / scale.value,
            size: Math.abs(erker.erkerPointLeftBottom.y - erker.erkerPointLeftTop.y),
            rotation: -90,
            points: {
                one: {
                    x: erker.erkerPointLeftTop.x,
                    y: erker.erkerPointLeftTop.y,
                    x_: erker.erkerPointLeftTop.x - 30 / scale.value,
                    y_: erker.erkerPointLeftTop.y,
                },
                two: {
                    x: erker.erkerPointLeftBottom.x,
                    y: erker.erkerPointLeftBottom.y,
                    x_: erker.erkerPointLeftBottom.x - 30 / scale.value,
                    y_: erker.erkerPointLeftBottom.y,
                },
                text: {
                    x: erker.erkerPointLeftBottom.x - 44 / scale.value,
                    y: (erker.erkerPointLeftTop.y + erker.erkerPointLeftBottom.y + 60 / scale.value) / 2,
                },
            }
        });
    });
    erkers.right.filter(erker => erker.type === 'angel').forEach(erker => {
        const _pilesTop = piles.filter(pile => isPointOnLineSegment(pile.x, pile.y, erker.erkerPointTop.x, erker.erkerPointTop.y, erker.erkerPointRightTop.x, erker.erkerPointRightTop.y) && pile.x !== erker.erkerPointTop.x && pile.y !== erker.erkerPointTop.y).sort((pileA, pileB) => pileA.y - pileB.y);
        const _pileTop = _pilesTop[0];
        let topLength = -calculateLength(erker.erkerPointTop.x, erker.erkerPointTop.y, _pileTop.x, _pileTop.y);
        const topAngle = calculateAngle(erker.erkerPointTop.x, erker.erkerPointTop.y, _pileTop.x, _pileTop.y);
        const sizeTopAngel = topAngle + (90 * Math.PI) / 180;
        const _oneTopPoint = {
            x: erker.erkerPointTop.x,
            y: erker.erkerPointTop.y,
        };
        const oneTopPoint = calculateSecondPoint(_oneTopPoint.x, _oneTopPoint.y, 30 / scale.value, sizeTopAngel);
        const _twoTopPoint = {
            x: _pileTop.x,
            y: _pileTop.y,
        };
        const twoTopPoint = calculateSecondPoint(oneTopPoint.x, oneTopPoint.y, topLength, topAngle);
        const textTopPointOne = calculateSecondPoint(_oneTopPoint.x, _oneTopPoint.y, 44 / scale.value, sizeTopAngel);
        const textTopLength = topLength + 60 / scale.value;
        const textTopPointTwo = calculateSecondPoint(textTopPointOne.x, textTopPointOne.y, textTopLength, topAngle);
        sizes.push({
            type: 'erker',
            orientation: 'normal',
            extension: 30 / scale.value,
            size: Math.abs(topLength),
            rotation: topAngle * (180 / Math.PI) + 180,
            points: {
                one: {
                    x: _oneTopPoint.x,
                    y: _oneTopPoint.y,
                    x_: oneTopPoint.x,
                    y_: oneTopPoint.y,
                },
                two: {
                    x: _twoTopPoint.x,
                    y: _twoTopPoint.y,
                    x_: twoTopPoint.x,
                    y_: twoTopPoint.y,
                },
                text: {
                    x: (textTopPointOne.x + textTopPointTwo.x) / 2,
                    y: (textTopPointOne.y + textTopPointTwo.y) / 2,
                },
            }
        });
        const _pilesBottom = piles.filter(pile => isPointOnLineSegment(pile.x, pile.y, erker.erkerPointBottom.x, erker.erkerPointBottom.y, erker.erkerPointRightBottom.x, erker.erkerPointRightBottom.y) && pile.x !== erker.erkerPointBottom.x && pile.y !== erker.erkerPointBottom.y).sort((pileA, pileB) => pileB.y - pileA.y);
        const _pileBottom = _pilesBottom[0];
        let bottomLength = calculateLength(erker.erkerPointBottom.x, erker.erkerPointBottom.y, _pileBottom.x, _pileBottom.y);
        const bottomAngle = calculateAngle(erker.erkerPointBottom.x, erker.erkerPointBottom.y, _pileBottom.x, _pileBottom.y);
        const sizeBottomAngel = bottomAngle - (90 * Math.PI) / 180;
        const _oneBottomPoint = {
            x: _pileBottom.x,
            y: _pileBottom.y,
        };
        const oneBottomPoint = calculateSecondPoint(_oneBottomPoint.x, _oneBottomPoint.y, 30 / scale.value, sizeBottomAngel);
        const _twoBottomPoint = {
            x: erker.erkerPointBottom.x,
            y: erker.erkerPointBottom.y,
        };
        const twoBottomPoint = calculateSecondPoint(oneBottomPoint.x, oneBottomPoint.y, bottomLength, bottomAngle);
        const textBottomPointOne = calculateSecondPoint(_oneBottomPoint.x, _oneBottomPoint.y, 44 / scale.value, sizeBottomAngel);
        const textBottomLength = bottomLength - 60 / scale.value;
        const textBottomPointTwo = calculateSecondPoint(textBottomPointOne.x, textBottomPointOne.y, textBottomLength, bottomAngle);
        sizes.push({
            type: 'erker',
            orientation: 'normal',
            extension: 30 / scale.value,
            size: Math.abs(bottomLength),
            rotation: bottomAngle * (180 / Math.PI),
            points: {
                one: {
                    x: _oneBottomPoint.x,
                    y: _oneBottomPoint.y,
                    x_: oneBottomPoint.x,
                    y_: oneBottomPoint.y,
                },
                two: {
                    x: _twoBottomPoint.x,
                    y: _twoBottomPoint.y,
                    x_: twoBottomPoint.x,
                    y_: twoBottomPoint.y,
                },
                text: {
                    x: (textBottomPointOne.x + textBottomPointTwo.x) / 2,
                    y: (textBottomPointOne.y + textBottomPointTwo.y) / 2,
                },
            }
        });
        sizes.push({
            type: 'erker',
            orientation: 'normal',
            extension: 30 / scale.value,
            size: Math.abs(erker.erkerPointRightBottom.y - erker.erkerPointRightTop.y),
            rotation: 90,
            points: {
                one: {
                    x: erker.erkerPointRightTop.x,
                    y: erker.erkerPointRightTop.y,
                    x_: erker.erkerPointRightTop.x + 30 / scale.value,
                    y_: erker.erkerPointRightTop.y,
                },
                two: {
                    x: erker.erkerPointRightBottom.x,
                    y: erker.erkerPointRightBottom.y,
                    x_: erker.erkerPointRightBottom.x + 30 / scale.value,
                    y_: erker.erkerPointRightBottom.y,
                },
                text: {
                    x: erker.erkerPointRightBottom.x + 44 / scale.value,
                    y: (erker.erkerPointRightTop.y + erker.erkerPointRightBottom.y - 60 / scale.value) / 2,
                },
            }
        });
    });
    erkers.top.filter(erker => erker.type === 'angel').forEach(erker => {
        const _pilesLeft = piles.filter(pile => isPointOnLineSegment(pile.x, pile.y, erker.erkerPointLeft.x, erker.erkerPointLeft.y, erker.erkerPointTopLeft.x, erker.erkerPointTopLeft.y) && pile.x !== erker.erkerPointLeft.x && pile.y !== erker.erkerPointLeft.y).sort((pileA, pileB) => pileA.x - pileB.x);
        const _pileLeft = _pilesLeft[0];
        let leftLength = -calculateLength(erker.erkerPointLeft.x, erker.erkerPointLeft.y, _pileLeft.x, _pileLeft.y);
        const leftAngle = calculateAngle(erker.erkerPointLeft.x, erker.erkerPointLeft.y, _pileLeft.x, _pileLeft.y);
        const sizeLeftAngel = leftAngle + (90 * Math.PI) / 180;
        const _oneLeftPoint = {
            x: erker.erkerPointLeft.x,
            y: erker.erkerPointLeft.y,
        };
        const oneLeftPoint = calculateSecondPoint(_oneLeftPoint.x, _oneLeftPoint.y, 30 / scale.value, sizeLeftAngel);
        const _twoLeftPoint = {
            x: _pileLeft.x,
            y: _pileLeft.y,
        };
        const twoLeftPoint = calculateSecondPoint(oneLeftPoint.x, oneLeftPoint.y, leftLength, leftAngle);
        const textLeftPointOne = calculateSecondPoint(_oneLeftPoint.x, _oneLeftPoint.y, 44 / scale.value, sizeLeftAngel);
        const textLeftLength = leftLength + 60 / scale.value;
        const textLeftPointTwo = calculateSecondPoint(textLeftPointOne.x, textLeftPointOne.y, textLeftLength, leftAngle);
        sizes.push({
            type: 'erker',
            orientation: 'normal',
            extension: 30 / scale.value,
            size: Math.abs(leftLength),
            rotation: leftAngle * (180 / Math.PI) + 180,
            points: {
                one: {
                    x: _oneLeftPoint.x,
                    y: _oneLeftPoint.y,
                    x_: oneLeftPoint.x,
                    y_: oneLeftPoint.y,
                },
                two: {
                    x: _twoLeftPoint.x,
                    y: _twoLeftPoint.y,
                    x_: twoLeftPoint.x,
                    y_: twoLeftPoint.y,
                },
                text: {
                    x: (textLeftPointOne.x + textLeftPointTwo.x) / 2,
                    y: (textLeftPointOne.y + textLeftPointTwo.y) / 2,
                },
            }
        });
        const _pilesRight = piles.filter(pile => isPointOnLineSegment(pile.x, pile.y, erker.erkerPointRight.x, erker.erkerPointRight.y, erker.erkerPointTopRight.x, erker.erkerPointTopRight.y) && pile.x !== erker.erkerPointRight.x && pile.y !== erker.erkerPointRight.y).sort((pileA, pileB) => pileB.x - pileA.x);
        const _pileRight = _pilesRight[0];
        let rightLength = calculateLength(erker.erkerPointRight.x, erker.erkerPointRight.y, _pileRight.x, _pileRight.y);
        const rightAngle = calculateAngle(erker.erkerPointRight.x, erker.erkerPointRight.y, _pileRight.x, _pileRight.y);
        const sizeRightAngel = rightAngle - (90 * Math.PI) / 180;
        const _oneRightPoint = {
            x: _pileRight.x,
            y: _pileRight.y,
        };
        const oneRightPoint = calculateSecondPoint(_oneRightPoint.x, _oneRightPoint.y, 30 / scale.value, sizeRightAngel);
        const _twoRightPoint = {
            x: erker.erkerPointRight.x,
            y: erker.erkerPointRight.y,
        };
        const twoRightPoint = calculateSecondPoint(oneRightPoint.x, oneRightPoint.y, rightLength, rightAngle);
        const textRightPointOne = calculateSecondPoint(_oneRightPoint.x, _oneRightPoint.y, 44 / scale.value, sizeRightAngel);
        const textRightLength = rightLength - 60 / scale.value;
        const textRightPointTwo = calculateSecondPoint(textRightPointOne.x, textRightPointOne.y, textRightLength, rightAngle);
        sizes.push({
            type: 'erker',
            orientation: 'normal',
            extension: 30 / scale.value,
            size: Math.abs(rightLength),
            rotation: rightAngle * (180 / Math.PI),
            points: {
                one: {
                    x: _oneRightPoint.x,
                    y: _oneRightPoint.y,
                    x_: oneRightPoint.x,
                    y_: oneRightPoint.y,
                },
                two: {
                    x: _twoRightPoint.x,
                    y: _twoRightPoint.y,
                    x_: twoRightPoint.x,
                    y_: twoRightPoint.y,
                },
                text: {
                    x: (textRightPointOne.x + textRightPointTwo.x) / 2,
                    y: (textRightPointOne.y + textRightPointTwo.y) / 2,
                },
            }
        });
        sizes.push({
            type: 'erker',
            orientation: 'normal',
            extension: 30 / scale.value,
            size: Math.abs(erker.erkerPointTopRight.x - erker.erkerPointTopLeft.x),
            rotation: 0,
            points: {
                one: {
                    x: erker.erkerPointTopLeft.x,
                    y: erker.erkerPointTopLeft.y,
                    x_: erker.erkerPointTopLeft.x,
                    y_: erker.erkerPointTopLeft.y - 30 / scale.value,
                },
                two: {
                    x: erker.erkerPointTopRight.x,
                    y: erker.erkerPointTopRight.y,
                    x_: erker.erkerPointTopRight.x,
                    y_: erker.erkerPointTopRight.y - 30 / scale.value,
                },
                text: {
                    x: (erker.erkerPointTopLeft.x + erker.erkerPointTopRight.x - 60 / scale.value) / 2,
                    y: erker.erkerPointTopLeft.y - 44 / scale.value,
                },
            }
        });
    });
    erkers.bottom.filter(erker => erker.type === 'angel').forEach(erker => {
        const _pilesLeft = piles.filter(pile => isPointOnLineSegment(pile.x, pile.y, erker.erkerPointLeft.x, erker.erkerPointLeft.y, erker.erkerPointBottomLeft.x, erker.erkerPointBottomLeft.y) && pile.x !== erker.erkerPointLeft.x && pile.y !== erker.erkerPointLeft.y).sort((pileA, pileB) => pileA.x - pileB.x);
        const _pileLeft = _pilesLeft[0];
        let leftLength = -calculateLength(erker.erkerPointLeft.x, erker.erkerPointLeft.y, _pileLeft.x, _pileLeft.y);
        const leftAngle = calculateAngle(erker.erkerPointLeft.x, erker.erkerPointLeft.y, _pileLeft.x, _pileLeft.y);
        const sizeLeftAngel = leftAngle - (90 * Math.PI) / 180;
        const _oneLeftPoint = {
            x: erker.erkerPointLeft.x,
            y: erker.erkerPointLeft.y,
        };
        const oneLeftPoint = calculateSecondPoint(_oneLeftPoint.x, _oneLeftPoint.y, 30 / scale.value, sizeLeftAngel);
        const _twoLeftPoint = {
            x: _pileLeft.x,
            y: _pileLeft.y,
        };
        const twoLeftPoint = calculateSecondPoint(oneLeftPoint.x, oneLeftPoint.y, leftLength, leftAngle);
        const textLeftPointOne = calculateSecondPoint(_oneLeftPoint.x, _oneLeftPoint.y, 44 / scale.value, sizeLeftAngel);
        const textLeftLength = leftLength - 60 / scale.value;
        const textLeftPointTwo = calculateSecondPoint(textLeftPointOne.x, textLeftPointOne.y, textLeftLength, leftAngle);
        sizes.push({
            type: 'erker',
            orientation: 'normal',
            extension: 30 / scale.value,
            size: Math.abs(leftLength),
            rotation: leftAngle * (180 / Math.PI),
            points: {
                one: {
                    x: _oneLeftPoint.x,
                    y: _oneLeftPoint.y,
                    x_: oneLeftPoint.x,
                    y_: oneLeftPoint.y,
                },
                two: {
                    x: _twoLeftPoint.x,
                    y: _twoLeftPoint.y,
                    x_: twoLeftPoint.x,
                    y_: twoLeftPoint.y,
                },
                text: {
                    x: (textLeftPointOne.x + textLeftPointTwo.x) / 2,
                    y: (textLeftPointOne.y + textLeftPointTwo.y) / 2,
                },
            }
        });
        const _pilesRight = piles.filter(pile => isPointOnLineSegment(pile.x, pile.y, erker.erkerPointRight.x, erker.erkerPointRight.y, erker.erkerPointBottomRight.x, erker.erkerPointBottomRight.y) && pile.x !== erker.erkerPointRight.x && pile.y !== erker.erkerPointRight.y).sort((pileA, pileB) => pileB.x - pileA.x);
        const _pileRight = _pilesRight[0];
        let rightLength = calculateLength(erker.erkerPointRight.x, erker.erkerPointRight.y, _pileRight.x, _pileRight.y);
        const rightAngle = calculateAngle(erker.erkerPointRight.x, erker.erkerPointRight.y, _pileRight.x, _pileRight.y);
        const sizeRightAngel = rightAngle + (90 * Math.PI) / 180;
        const _oneRightPoint = {
            x: _pileRight.x,
            y: _pileRight.y,
        };
        const oneRightPoint = calculateSecondPoint(_oneRightPoint.x, _oneRightPoint.y, 30 / scale.value, sizeRightAngel);
        const _twoRightPoint = {
            x: erker.erkerPointRight.x,
            y: erker.erkerPointRight.y,
        };
        const twoRightPoint = calculateSecondPoint(oneRightPoint.x, oneRightPoint.y, rightLength, rightAngle);
        const textRightPointOne = calculateSecondPoint(_oneRightPoint.x, _oneRightPoint.y, 44 / scale.value, sizeRightAngel);
        const textRightLength = rightLength + 60 / scale.value;
        const textRightPointTwo = calculateSecondPoint(textRightPointOne.x, textRightPointOne.y, textRightLength, rightAngle);
        sizes.push({
            type: 'erker',
            orientation: 'normal',
            extension: 30 / scale.value,
            size: Math.abs(rightLength),
            rotation: rightAngle * (180 / Math.PI) + 180,
            points: {
                one: {
                    x: _oneRightPoint.x,
                    y: _oneRightPoint.y,
                    x_: oneRightPoint.x,
                    y_: oneRightPoint.y,
                },
                two: {
                    x: _twoRightPoint.x,
                    y: _twoRightPoint.y,
                    x_: twoRightPoint.x,
                    y_: twoRightPoint.y,
                },
                text: {
                    x: (textRightPointOne.x + textRightPointTwo.x) / 2,
                    y: (textRightPointOne.y + textRightPointTwo.y) / 2,
                },
            }
        });
        sizes.push({
            type: 'erker',
            orientation: 'normal',
            extension: 30 / scale.value,
            size: Math.abs(erker.erkerPointBottomRight.x - erker.erkerPointBottomLeft.x),
            rotation: 180,
            points: {
                one: {
                    x: erker.erkerPointBottomLeft.x,
                    y: erker.erkerPointBottomLeft.y,
                    x_: erker.erkerPointBottomLeft.x,
                    y_: erker.erkerPointBottomLeft.y + 30 / scale.value,
                },
                two: {
                    x: erker.erkerPointBottomRight.x,
                    y: erker.erkerPointBottomRight.y,
                    x_: erker.erkerPointBottomRight.x,
                    y_: erker.erkerPointBottomRight.y + 30 / scale.value,
                },
                text: {
                    x: (erker.erkerPointBottomLeft.x + erker.erkerPointBottomRight.x + 60 / scale.value) / 2,
                    y: erker.erkerPointBottomLeft.y + 44 / scale.value,
                },
            }
        });
    });

    return sizes;
}

const searchPerimeter = (point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, direction) => {
    if (!point) {
        const pointHor = stripes.horizontal.filter(stripe => !deletedStripes.includes(stripe.name)).reduce((result, current) => {
            if (result.pointLeft.x < current.pointLeft.x) {
                return result;
            } else if (result.pointLeft.x === current.pointLeft.x) {
                if (result.pointLeft.y < current.pointLeft.y) {
                    return result;
                } else {
                    return current;
                }
            } else {
                return current;
            }
        });
        const pointVer = stripes.vertical.filter(stripe => !deletedStripes.includes(stripe.name)).reduce((result, current) => {
            if (result.pointTop.y < current.pointTop.y) {
                return result;
            } else if (result.pointTop.y === current.pointTop.y) {
                if (result.pointTop.x < current.pointTop.x) {
                    return result;
                } else {
                    return current;
                }
            } else {
                return current;
            }
        });
        if (pointHor.pointLeft.x < pointVer.pointTop.x) {
            point = pointHor.pointLeft;
        } else if (pointHor.pointLeft.x === pointVer.pointTop.x) {
            if (pointHor.pointLeft.y < pointVer.pointTop.y) {
                point = pointHor.pointLeft;
            } else {
                point = pointVer.pointTop;
            }
        } else {
            point = pointVer.pointTop;
        }
        firstPoint = point;
        perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, [], countX, countY, 'toptoright');
    } else {
        const erkerTop = erkers.top.find(erker => erker.type === 'angel' && erker.erkerPointLeft.x === point.x && erker.erkerPointLeft.y === point.y);
        const erkerRoundTop = erkers.top.find(erker => erker.type === 'round' && erker.erkerPointLeft.x === point.x && erker.erkerPointLeft.y === point.y);
        const erkerBottom = erkers.bottom.find(erker => erker.type==='angel' && erker.erkerPointRight.x === point.x && erker.erkerPointRight.y === point.y);
        const erkerRoundBottom = erkers.bottom.find(erker => erker.type==='round' && erker.erkerPointRight.x === point.x && erker.erkerPointRight.y === point.y);
        const erkerLeft = erkers.left.find(erker => erker.type==='angel' && erker.erkerPointBottom.x === point.x && erker.erkerPointBottom.y === point.y);
        const erkerRoundLeft = erkers.left.find(erker => erker.type==='round' && erker.erkerPointBottom.x === point.x && erker.erkerPointBottom.y === point.y);
        const erkerRight = erkers.right.find(erker => erker.type==='angel' && erker.erkerPointTop.x === point.x && erker.erkerPointTop.y === point.y);
        const erkerRoundRight = erkers.right.find(erker => erker.type==='round' && erker.erkerPointTop.x === point.x && erker.erkerPointTop.y === point.y);
        const horizontalLeft = stripes.horizontal.find(stripe => !deletedStripes.includes(stripe.name) && stripe.pointLeft.x === point.x && stripe.pointLeft.y === point.y);
        const horizontalRight = stripes.horizontal.find(stripe => !deletedStripes.includes(stripe.name) && stripe.pointRight.x === point.x && stripe.pointRight.y === point.y);
        const verticalTop = stripes.vertical.find(stripe => !deletedStripes.includes(stripe.name) && stripe.pointTop.x === point.x && stripe.pointTop.y === point.y);
        const verticalBottom = stripes.vertical.find(stripe => !deletedStripes.includes(stripe.name) && stripe.pointBottom.x === point.x && stripe.pointBottom.y === point.y);
        const cornerBottomLeft = stripes.corner.find(stripe => stripe.pointBottom.x === point.x && stripe.pointBottom.y === point.y && stripe.pointTop.x < point.x && stripe.pointTop.y < point.y);
        const cornerBottomRight = stripes.corner.find(stripe => stripe.pointBottom.x === point.x && stripe.pointBottom.y === point.y && stripe.pointTop.x > point.x && stripe.pointTop.y < point.y);
        const cornerTopLeft = stripes.corner.find(stripe => stripe.pointTop.x === point.x && stripe.pointTop.y === point.y && stripe.pointBottom.x < point.x && stripe.pointBottom.y > point.y);
        const cornerTopRight = stripes.corner.find(stripe => stripe.pointTop.x === point.x && stripe.pointTop.y === point.y && stripe.pointBottom.x > point.x && stripe.pointBottom.y > point.y);
        if (direction === 'toptoright') {
            if (erkerTop) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: erkerTop.pathTop.x1,
                        y: erkerTop.pathTop.y1,
                    });
                }
                perimeter.push({
                    x: erkerTop.pathTop.x2,
                    y: erkerTop.pathTop.y2,
                });
                perimeter.push({
                    x: erkerTop.pathTop.x3,
                    y: erkerTop.pathTop.y3,
                });
                perimeter.push({
                    x: erkerTop.pathTop.x4,
                    y: erkerTop.pathTop.y4,
                });
                point = erkerTop.erkerPointRight;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'toptoright');
                }
            } else if (verticalBottom) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: verticalBottom.pointLeftBottom.x,
                        y: verticalBottom.pointLeftBottom.y,
                    });
                }
                perimeter.push({
                    x: verticalBottom.pointLeftTop.x,
                    y: verticalBottom.pointLeftTop.y,
                });
                point = verticalBottom.pointTop;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'lefttotop');
                }
            } else if (horizontalLeft) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: horizontalLeft.pointTopLeft.x,
                        y: horizontalLeft.pointTopLeft.y,
                    });
                }
                perimeter.push({
                    x: horizontalLeft.pointTopRight.x,
                    y: horizontalLeft.pointTopRight.y,
                });
                point = horizontalLeft.pointRight;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'toptoright');
                }
            } else if (erkerRight) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: erkerRight.pathRight.x1,
                        y: erkerRight.pathRight.y1,
                    });
                }
                perimeter.push({
                    x: erkerRight.pathRight.x2,
                    y: erkerRight.pathRight.y2,
                });
                perimeter.push({
                    x: erkerRight.pathRight.x3,
                    y: erkerRight.pathRight.y3,
                });
                perimeter.push({
                    x: erkerRight.pathRight.x4,
                    y: erkerRight.pathRight.y4,
                });
                point = erkerRight.erkerPointBottom;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'righttobottom');
                }
            } else if (verticalTop) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: verticalTop.pointRightTop.x,
                        y: verticalTop.pointRightTop.y,
                    });
                }
                perimeter.push({
                    x: verticalTop.pointRightBottom.x,
                    y: verticalTop.pointRightBottom.y,
                });
                point = verticalTop.pointBottom;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'righttobottom');
                }
            } else if (erkerBottom) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: erkerBottom.pathBottom.x4,
                        y: erkerBottom.pathBottom.y4,
                    });
                }
                perimeter.push({
                    x: erkerBottom.pathBottom.x3,
                    y: erkerBottom.pathBottom.y3,
                });
                perimeter.push({
                    x: erkerBottom.pathBottom.x2,
                    y: erkerBottom.pathBottom.y2,
                });
                perimeter.push({
                    x: erkerBottom.pathBottom.x1,
                    y: erkerBottom.pathBottom.y1,
                });
                point = erkerBottom.erkerPointLeft;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'bottomtoleft');
                }
            } else if (horizontalRight) {
                if (perimeter.length === 0 || horizontalRight.capRight) {
                    perimeter.push({
                        x: horizontalRight.pointBottomRight.x,
                        y: horizontalRight.pointBottomRight.y,
                    });
                }
                perimeter.push({
                    x: horizontalRight.pointBottomLeft.x,
                    y: horizontalRight.pointBottomLeft.y,
                });
                point = horizontalRight.pointLeft;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'bottomtoleft');
                }
            }
        }
        if (direction === 'righttobottom') {
            if (erkerRight) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: erkerRight.pathRight.x1,
                        y: erkerRight.pathRight.y1,
                    });
                }
                perimeter.push({
                    x: erkerRight.pathRight.x2,
                    y: erkerRight.pathRight.y2,
                });
                perimeter.push({
                    x: erkerRight.pathRight.x3,
                    y: erkerRight.pathRight.y3,
                });
                perimeter.push({
                    x: erkerRight.pathRight.x4,
                    y: erkerRight.pathRight.y4,
                });
                point = erkerRight.erkerPointBottom;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'righttobottom');
                }
            } else if (horizontalLeft) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: horizontalLeft.pointTopLeft.x,
                        y: horizontalLeft.pointTopLeft.y,
                    });
                }
                perimeter.push({
                    x: horizontalLeft.pointTopRight.x,
                    y: horizontalLeft.pointTopRight.y,
                });
                point = horizontalLeft.pointRight;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'toptoright');
                }
            } else if (verticalTop) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: verticalTop.pointRightTop.x,
                        y: verticalTop.pointRightTop.y,
                    });
                }
                perimeter.push({
                    x: verticalTop.pointRightBottom.x,
                    y: verticalTop.pointRightBottom.y,
                });
                point = verticalTop.pointBottom;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'righttobottom');
                }
            } else if (erkerBottom) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: erkerBottom.pathBottom.x4,
                        y: erkerBottom.pathBottom.y4,
                    });
                }
                perimeter.push({
                    x: erkerBottom.pathBottom.x3,
                    y: erkerBottom.pathBottom.y3,
                });
                perimeter.push({
                    x: erkerBottom.pathBottom.x2,
                    y: erkerBottom.pathBottom.y2,
                });
                perimeter.push({
                    x: erkerBottom.pathBottom.x1,
                    y: erkerBottom.pathBottom.y1,
                });
                point = erkerBottom.erkerPointLeft;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'bottomtoleft');
                }
            } else if (horizontalRight) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: horizontalRight.pointBottomRight.x,
                        y: horizontalRight.pointBottomRight.y,
                    });
                }
                perimeter.push({
                    x: horizontalRight.pointBottomLeft.x,
                    y: horizontalRight.pointBottomLeft.y,
                });
                point = horizontalRight.pointLeft;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'bottomtoleft');
                }
            } else if (erkerLeft) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: erkerLeft.pathLeft.x4,
                        y: erkerLeft.pathLeft.y4,
                    });
                }
                perimeter.push({
                    x: erkerLeft.pathLeft.x3,
                    y: erkerLeft.pathLeft.y3,
                });
                perimeter.push({
                    x: erkerLeft.pathLeft.x2,
                    y: erkerLeft.pathLeft.y2,
                });
                perimeter.push({
                    x: erkerLeft.pathLeft.x1,
                    y: erkerLeft.pathLeft.y1,
                });
                point = erkerLeft.erkerPointTop;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'lefttotop');
                }
            } else if (verticalBottom) {
                if (perimeter.length === 0 || verticalBottom.capBottom) {
                    perimeter.push({
                        x: verticalBottom.pointLeftBottom.x,
                        y: verticalBottom.pointLeftBottom.y,
                    });
                }
                perimeter.push({
                    x: verticalBottom.pointLeftTop.x,
                    y: verticalBottom.pointLeftTop.y,
                });
                point = verticalBottom.pointTop;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'lefttotop');
                }
            }
        }
        if (direction === 'bottomtoleft') {
            if (erkerBottom) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: erkerBottom.pathBottom.x4,
                        y: erkerBottom.pathBottom.y4,
                    });
                }
                perimeter.push({
                    x: erkerBottom.pathBottom.x3,
                    y: erkerBottom.pathBottom.y3,
                });
                perimeter.push({
                    x: erkerBottom.pathBottom.x2,
                    y: erkerBottom.pathBottom.y2,
                });
                perimeter.push({
                    x: erkerBottom.pathBottom.x1,
                    y: erkerBottom.pathBottom.y1,
                });
                point = erkerBottom.erkerPointLeft;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'bottomtoleft');
                }
            } else if (verticalTop) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: verticalTop.pointRightTop.x,
                        y: verticalTop.pointRightTop.y,
                    });
                }
                perimeter.push({
                    x: verticalTop.pointRightBottom.x,
                    y: verticalTop.pointRightBottom.y,
                });
                point = verticalTop.pointBottom;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'righttobottom');
                }
            } else if (horizontalRight) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: horizontalRight.pointBottomRight.x,
                        y: horizontalRight.pointBottomRight.y,
                    });
                }
                perimeter.push({
                    x: horizontalRight.pointBottomLeft.x,
                    y: horizontalRight.pointBottomLeft.y,
                });
                point = horizontalRight.pointLeft;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'bottomtoleft');
                }
            } else if (erkerLeft) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: erkerLeft.pathLeft.x4,
                        y: erkerLeft.pathLeft.y4,
                    });
                }
                perimeter.push({
                    x: erkerLeft.pathLeft.x3,
                    y: erkerLeft.pathLeft.y3,
                });
                perimeter.push({
                    x: erkerLeft.pathLeft.x2,
                    y: erkerLeft.pathLeft.y2,
                });
                perimeter.push({
                    x: erkerLeft.pathLeft.x1,
                    y: erkerLeft.pathLeft.y1,
                });
                point = erkerLeft.erkerPointTop;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'lefttotop');
                }
            } else if (verticalBottom) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: verticalBottom.pointLeftBottom.x,
                        y: verticalBottom.pointLeftBottom.y,
                    });
                }
                perimeter.push({
                    x: verticalBottom.pointLeftTop.x,
                    y: verticalBottom.pointLeftTop.y,
                });
                point = verticalBottom.pointTop;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'lefttotop');
                }
            } else if (erkerTop) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: erkerTop.pathTop.x1,
                        y: erkerTop.pathTop.y1,
                    });
                }
                perimeter.push({
                    x: erkerTop.pathTop.x2,
                    y: erkerTop.pathTop.y2,
                });
                perimeter.push({
                    x: erkerTop.pathTop.x3,
                    y: erkerTop.pathTop.y3,
                });
                perimeter.push({
                    x: erkerTop.pathTop.x4,
                    y: erkerTop.pathTop.y4,
                });
                point = erkerTop.erkerPointRight;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'toptoright');
                }
            } else if (horizontalLeft) {
                if (perimeter.length === 0 || horizontalLeft.capLeft) {
                    perimeter.push({
                        x: horizontalLeft.pointTopLeft.x,
                        y: horizontalLeft.pointTopLeft.y,
                    });
                }
                perimeter.push({
                    x: horizontalLeft.pointTopRight.x,
                    y: horizontalLeft.pointTopRight.y,
                });
                point = horizontalLeft.pointRight;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'toptoright');
                }
            }
        }
        if (direction === 'lefttotop') {
            if (erkerLeft) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: erkerLeft.pathLeft.x4,
                        y: erkerLeft.pathLeft.y4,
                    });
                }
                perimeter.push({
                    x: erkerLeft.pathLeft.x3,
                    y: erkerLeft.pathLeft.y3,
                });
                perimeter.push({
                    x: erkerLeft.pathLeft.x2,
                    y: erkerLeft.pathLeft.y2,
                });
                perimeter.push({
                    x: erkerLeft.pathLeft.x1,
                    y: erkerLeft.pathLeft.y1,
                });
                point = erkerLeft.erkerPointTop;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'lefttotop');
                }
            } else if (horizontalRight) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: horizontalRight.pointBottomRight.x,
                        y: horizontalRight.pointBottomRight.y,
                    });
                }
                perimeter.push({
                    x: horizontalRight.pointBottomLeft.x,
                    y: horizontalRight.pointBottomLeft.y,
                });
                point = horizontalRight.pointLeft;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'bottomtoleft');
                }
            } else if (verticalBottom) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: verticalBottom.pointLeftBottom.x,
                        y: verticalBottom.pointLeftBottom.y,
                    });
                }
                perimeter.push({
                    x: verticalBottom.pointLeftTop.x,
                    y: verticalBottom.pointLeftTop.y,
                });
                point = verticalBottom.pointTop;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'lefttotop');
                }
            } else if (erkerTop) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: erkerTop.pathTop.x1,
                        y: erkerTop.pathTop.y1,
                    });
                }
                perimeter.push({
                    x: erkerTop.pathTop.x2,
                    y: erkerTop.pathTop.y2,
                });
                perimeter.push({
                    x: erkerTop.pathTop.x3,
                    y: erkerTop.pathTop.y3,
                });
                perimeter.push({
                    x: erkerTop.pathTop.x4,
                    y: erkerTop.pathTop.y4,
                });
                point = erkerTop.erkerPointRight;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'toptoright');
                }
            } else if (horizontalLeft) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: horizontalLeft.pointTopLeft.x,
                        y: horizontalLeft.pointTopLeft.y,
                    });
                }
                perimeter.push({
                    x: horizontalLeft.pointTopRight.x,
                    y: horizontalLeft.pointTopRight.y,
                });
                point = horizontalLeft.pointRight;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'toptoright');
                }
            } else if (erkerRight) {
                if (perimeter.length === 0) {
                    perimeter.push({
                        x: erkerRight.pathRight.x1,
                        y: erkerRight.pathRight.y1,
                    });
                }
                perimeter.push({
                    x: erkerRight.pathRight.x2,
                    y: erkerRight.pathRight.y2,
                });
                perimeter.push({
                    x: erkerRight.pathRight.x3,
                    y: erkerRight.pathRight.y3,
                });
                perimeter.push({
                    x: erkerRight.pathRight.x4,
                    y: erkerRight.pathRight.y4,
                });
                point = erkerRight.erkerPointBottom;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'righttobottom');
                }
            } else if (verticalTop) {
                if (perimeter.length === 0 || verticalTop.capTop) {
                    perimeter.push({
                        x: verticalTop.pointRightTop.x,
                        y: verticalTop.pointRightTop.y,
                    });
                }
                perimeter.push({
                    x: verticalTop.pointRightBottom.x,
                    y: verticalTop.pointRightBottom.y,
                });
                point = verticalTop.pointBottom;
                if (point.x !== firstPoint.x || point.y !== firstPoint.y) {
                    perimeter = searchPerimeter(point, firstPoint, stripes, deletedStripes, erkers, perimeter, countX, countY, 'righttobottom');
                }
            }
        }
    }

    if (perimeter[0].x === perimeter[perimeter.length - 1].x && perimeter[0].y === perimeter[perimeter.length - 1].y) {
        perimeter.pop();
    }
    
    return perimeter;
}

//расчет площади дуги
const calculateArcArea = (centerX, centerY, startAngle, endAngle, radius) => {
    const angle = Math.abs(endAngle - startAngle);
    const area = ((angle / 360) * Math.PI * radius ** 2) / 2;
    return area;
}

// расчет площади многоугольника
const polygonArea = (vertices) => {
    let area = 0;
    const n = vertices.length;
  
    for (let i = 0; i < n; i++) {
      const j = (i + 1) % n;
      area += vertices[i].x * vertices[j].y - vertices[j].x * vertices[i].y;
    }
  
    return Math.abs(area / 2);
  }
  

// Поиск точек пересечения окружностей
const findCrossCircles = (x1, y1, r1, x2, y2, r2) => {
    const d = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    if (d > r1 + r2) {
        // окружности не пересекаются
        return {
            error: 'not_cross',
        };
    }
    if (d < Math.abs(r1 - r2)) {
        // одна окружность внутри другой
        return {
            error: 'not_cross',
        };
    }
    const a = (Math.pow(r1, 2) - Math.pow(r2, 2) + Math.pow(d, 2)) / (2 * d);
    const h = Math.sqrt(Math.pow(r1, 2) - Math.pow(a, 2));
    const cx = x1 + a * (x2 - x1) / d;
    const cy = y1 + a * (y2 - y1) / d;
    const x_1 = cx + h * (y2 - y1) / d;
    const y_1 = cy - h * (x2 - x1) / d;
    const x_2 = cx - h * (y2 - y1) / d;
    const y_2 = cy + h * (x2 - x1) / d;
    return {
        pointA: {
            x: x_1,
            y: y_1,
        },
        pointB: {
            x: x_2,
            y: y_2,
        },
    };
}

// Посик точек пересечения окружности и линии
const findCrossLineAndCircle = (x1, y1, x2, y2, cx, cy, r) => {
    // Рассчитываем коэффициенты уравнения прямой, проходящей через две заданные точки
    let a = (y2 - y1) / (x2 - x1);
    let b = y1 - a * x1;

    // Решаем квадратное уравнение для нахождения точек пересечения с окружностью
    let A = 1 + a * a;
    let B = -2 * cx + 2 * a * b - 2 * a * cy;
    let C = cx * cx + b * b + cy * cy - r * r - 2 * b * cy;

    let D = B * B - 4 * A * C;

    if (D < 0) {
        // Окружность и прямая не пересекаются
        return {
            error: 'not_cross',
        };
    } else if (D === 0) {
        // Окружность и прямая пересекаются в одной точке
        let x = -B / (2 * A);
        let y = a * x + b;
        return {
            pointA: {
                x: x,
                y: y,
            },
            pointB: {
                x: -10000,
                y: -10000,
            },
        };
    } else {
        // Окружность и прямая пересекаются в двух точках
        let x1 = (-B + Math.sqrt(D)) / (2 * A);
        let y1 = a * x1 + b;

        let x2 = (-B - Math.sqrt(D)) / (2 * A);
        let y2 = a * x2 + b;

        return {
            pointA: {
                x: x1,
                y: y1,
            },
            pointB: {
                x: x2,
                y: y2,
            },
        };
    }
}

// Поиск точки пересечения
const searchCross = (x1, y1, x2, y2, x3, y3, x4, y4) => {
    let dot = [];
    let n = 0;
    if (y2 - y1 != 0) {
        const q = (x2 - x1) / (y1 - y2);
        const sn = (x3 - x4) + (y3 - y4) * q;
        if (!sn) return false;
        const fn = (x3 - x1) + (y3 - y1) * q;
        n = fn / sn;
    }
    else {
        if (!(y3 - y4)) return false;
        n = (y3 - y1) / (y3 - y4);
    }
    dot.push(x3 + (x4 - x3) * n);
    dot.push(y3 + (y4 - y3) * n);
    return dot;
}

// Изменение координат объекта глубокой вложенности (рекурсивно)
const changeObjectCoord = (object, propName, delta) => {
    for (let property in object) {
        if (typeof object[property] === 'object') {
            object[property] = changeObjectCoord(object[property], propName, delta);
        } else if (property === propName) {
            object[property] = object[property] + delta;
        }
    }
    return object;
}

const calculateSecondPoint = (x1, y1, length, angle) => {
    let x2 = x1 + length * Math.cos(angle);
    let y2 = y1 + length * Math.sin(angle);

    return { x: x2, y: y2 };
}

const calculateAngle = (x1, y1, x2, y2) => {
    let deltaX = x1 - x2;
    let deltaY = y1 - y2;

    let radians = Math.atan2(deltaY, deltaX);
    let degrees = radians * 180 / Math.PI;

    return radians;
}

const calculateLength = (x1, y1, x2, y2) => {
    let deltaX = x2 - x1;
    let deltaY = y2 - y1;

    let length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    return length;
}

const isPointOnLineSegment = (x, y, x1, y1, x2, y2) => {
    const minX = Math.min(x1, x2);
    const maxX = Math.max(x1, x2);
    const minY = Math.min(y1, y2);
    const maxY = Math.max(y1, y2);
    if (x >= minX && x <= maxX && y >= minY && y <= maxY) {
        const slope = (y2 - y1) / (x2 - x1);
        const yInt = -slope * x1 + y1;
        const yOnLine = slope * x + yInt;
        const tolerance = 0.1;
        if (Math.abs(y - yOnLine) < tolerance) {
            return true;
        }
    }
    return false;
}