import React, { useEffect, useState, Fragment } from 'react';
import styles from './Management.module.css';
import cn from 'classnames';
import { getToken } from "./helpers";
import { API, BEARER } from "../constants";
import GridLoader from 'react-spinners/GridLoader';

import { Login } from './Login';
import { Panel } from './Panel';

import { ReactComponent as Logo } from '../../logo.svg';

export function Management() {
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const authToken = getToken();

    const fetchLoggedInUser = async (token) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${API}/api/users/me`, {
                headers: { Authorization: `${BEARER} ${token}` },
            });
            const data = await response.json();

            setUserData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (authToken) {
            fetchLoggedInUser(authToken);
        }
    }, [authToken]);

    const handleUser = (user) => {
        setUserData(user);
    };

    return (
        <Fragment>
            <div id='loading' className={cn(styles.loading, { [styles.hide]: !isLoading })}>
                <GridLoader color="#F48D70" />
            </div>
            <div className={styles.main}>
                {!userData && (
                    <Login setUser={handleUser} />
                )}
                {userData && (
                    <Panel user={userData} setUser={handleUser} />
                )}
            </div>
        </Fragment>
    )
}