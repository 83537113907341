import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import logo from './logo.svg';
import { Calculator } from './features/calculator/Calculator';
import { Management } from './features/management/Management';
import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Calculator />} />
      <Route path="/manage" element={<Management />} />
    </Routes>
    // <div className="App">
    //     <Calculator />
    // </div>
  );
}

export default App;
